import { UpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Collapse, Form, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import { UPDATE_CPQ_THANK_YOU } from '../graphql/Mutations';

const { Panel } = Collapse;
const { required, requiredWhiteSpaceAllowed } = formValidatorRules;
const { Option } = SelectComponent;
const { TextArea } = InputComponent;

const ThankYouForm = ({ initialValue, questionnaireId, setThankYouConfig }) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'title',
    'email_me',
    'call_me',
    'call_us',
    'button'
  ]);
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const [validationTriggered, setValidationTriggered] = useState(false);

  const onValuesChange = (changedValues, allValues) => {
    setThankYouConfig({
      ...allValues
    });
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };

  const [updateThankYouPageData] = useMutation(UPDATE_CPQ_THANK_YOU, {
    onCompleted() {
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onFinish = (values) => {
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setDisableBtn(true);
    setLoading(true);
    const tempObj = {
      title: {
        title: values?.title,
        description: values?.titleDescription
      },
      callUsMessage: values?.callUsDescription,
      callMeMessage: values?.callMeDescription,
      emailMeMessage: values?.emailMeDescription,
      buttonLabel: values?.label
    };
    updateThankYouPageData({
      variables: {
        data: tempObj,
        where: {
          questionnaireId
        }
      }
    });
  };
  return (
    <div>
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="contact-me-save-btn"
            loading={loading}
            disabled={disableBtn}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        name="thankyouForm"
        form={form}
        initialValues={initialValue}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_QUESTIONNAIRE_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="TITLE" key="title">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Title"
                    rules={[{ ...required, message: 'Please Enter Title' }]}
                    name="title"
                  >
                    <InputComponent placeholder="Enter Title" />
                  </Form.Item>
                  <Form.Item name="titleDescription" label="Description">
                    <TextArea
                      className="common-textarea"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder="Enter Description"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel forceRender header="CALL US THANK YOU MESSAGE" key="call_us">
              <Form.Item name="callUsDescription" label="Description">
                <TextArea
                  className="common-textarea"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Panel>
            <Panel forceRender header="CALL ME THANK YOU MESSAGE" key="call_me">
              <Form.Item name="callMeDescription" label="Description">
                <TextArea
                  className="common-textarea"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Panel>
            <Panel
              forceRender
              header="EMAIL ME THANK YOU MESSAGE"
              key="email_me"
            >
              <Form.Item name="emailMeDescription" label="Description">
                <TextArea
                  className="common-textarea"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Panel>
            <Panel forceRender header="BUTTON" key="button">
              <Form.Item
                label="Label"
                name="label"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Label'
                  }
                ]}
              >
                <SelectComponent>
                  <Option key="BACK_TO_HOME" value="Back to Homepage">
                    Back to Homepage
                  </Option>
                  <Option key="ANOTHER_REQUEST" value="Make Another Request">
                    Make Another Request
                  </Option>
                  <Option key="START_AGAIN" value="Start Again">
                    Start Again
                  </Option>
                  <Option key="GO_TO_HOME" value=" Go to Homepage. ">
                    Go to Homepage.
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};
export default ThankYouForm;
