import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import PropertyHeatSourceForm from '../components/PropertyHeatSourceForm';
import CardWrapper from '../components/PropertyHeatSourceTabs';
import { CREATE_PROPERTY_HEAT_SOURCE } from '../graphql/Mutations';

const PropertyHeatSourceCreate = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [createPropertyHeatSource] = useMutation(CREATE_PROPERTY_HEAT_SOURCE, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = newFormValues;

    try {
      const response = await createPropertyHeatSource({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createPropertyHeatSource) {
        history?.push(ROUTES?.PROPERTY_HEAT_SOURCE);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: ''
  };
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PROPERTY_HEAT_SOURCE} />
          <span className="portal-header">Add Property Heat Source</span>
        </div>
      </Portal>
      <CardWrapper>
        <PropertyHeatSourceForm
          propertyHeatSourceData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
};

export default withRouter(PropertyHeatSourceCreate);
