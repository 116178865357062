import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import LineOfBusinessForm from '../components/LineOfBusinessForm';
import CardWrapper from '../components/LineOfBusinessTabs';
import { CREATE_LINE_OF_BUSINESS } from '../graphql/Mutations';

function LineOfBusinessCreate() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [createLineOfBusinessMutate] = useMutation(CREATE_LINE_OF_BUSINESS, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = newFormValues;

    try {
      const response = await createLineOfBusinessMutate({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createLineOfBusiness) {
        history?.push(ROUTES?.LINE_OF_BUSINESSES);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: ''
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.LINE_OF_BUSINESSES} />
          <span className="portal-header">Add Line of Business</span>
        </div>
      </Portal>
      <CardWrapper>
        <LineOfBusinessForm
          lineOfBusinessData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
}

export default withRouter(LineOfBusinessCreate);
