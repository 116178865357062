import React from 'react';
import ModalComponent from './ModalComponent';
import './styles/PreviewModal.less';

const PreviewModal = (props) => {
  const {
    previewVisible = false,
    previewImage = '',
    previewTitle = '',
    setPreviewVisible
  } = props;

  return (
    <ModalComponent
      wrapClassName="preview-modal"
      width={1000}
      destroyOnClose
      visible={previewVisible}
      title={previewTitle}
      footer={null}
      onCancel={() => {
        if (setPreviewVisible) {
          setPreviewVisible(false);
        }
      }}
    >
      <div className="center-image">
        <img alt="preview" src={previewImage} className="preview-image" />
      </div>
    </ModalComponent>
  );
};

export default PreviewModal;
