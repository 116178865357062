import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, message, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { ALLOW_IMAGE_TYPES, ROUTES } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const { Option } = SelectComponent;
const { TextArea } = InputComponent;

const HeightForm = (props) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const { heightData, handleSubmit, isSubmit, isEdit = false } = props;

  const [fileList, setFileList] = useState([]);

  const [validationTriggered, setValidationTriggered] = useState(false);

  const [form] = Form?.useForm();

  useEffect(() => {
    if (heightData?.logo && !fileList?.length) {
      setFileList([heightData?.logo]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url }
    } = info;
    if (url) {
      setFileList([]);
      form?.setFieldsValue({
        logo: null
      });
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      if (info?.file?.status === 'removed') {
        form?.setFieldsValue({
          logo: null
        });
      } else {
        form?.setFieldsValue({
          logo: info?.file
        });
      }
      setFileList([...info?.fileList]);
    } else {
      setFileList([]);
      form?.setFieldsValue({
        logo: null
      });
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <div>
      <AccessControl
        allowedPermissions={
          isEdit ? ['FET_MASTER_UPDATE'] : ['FET_MASTER_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="height-table-discard-btn"
            onClick={() => history?.push(ROUTES?.HEIGHTS)}
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="height-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        initialValues={heightData}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit ? ['FET_MASTER_UPDATE'] : ['FET_MASTER_CREATE']
            )
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider " />
          <Row gutter={16} className="required-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Label' }]}
                name="label"
                label="Label"
              >
                <InputComponent allowClear placeholder="Enter Label" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status'
                  }
                ]}
              >
                <SelectComponent placeholder="Please select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <span className="form-divider-text">OPTIONAL</span>
          <Divider className="form-divider optional-divider" />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="logo" label="Image">
                <DraggerUploadComponent
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                    showRemoveIcon: checkPermissions(permissions, [
                      'FET_MASTER_UPDATE'
                    ])
                  }}
                  fileList={fileList}
                  onChange={onChangeUpload}
                >
                  <p className="icon-header">
                    <DownloadOutlined />
                  </p>
                  <p className="upload-title">
                    Drag the height's image here or browse your files
                  </p>
                  <p className="upload-description">
                    130px width minimum | JPG, PNG, SVG
                  </p>
                </DraggerUploadComponent>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" label="Description">
                <TextArea
                  className="common-textarea"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default HeightForm;
