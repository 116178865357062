import { useLazyQuery } from '@apollo/client';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoaderComponent from '../../../components/LoaderComponent';
import ContactMeForm from '../components/ContactMeForm';
import ContactMeComponent from '../components/previewComponents/contactMe/components/ContactMeComponent';
import { CPQ_CONTACT_ME } from '../graphql/Queries';
import CpqTemplate from './CpqTemplate';

const ContactMePage = () => {
  const { id } = useParams();
  const [initialValue, setInitialValue] = useState({});
  const [contactMeConfig, setContactMeConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  const [getContactMeFormData, { data }] = useLazyQuery(CPQ_CONTACT_ME, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const tempObj = {
        thinLabel: res?.cpqContactMe?.title?.thinLabel,
        boldLabel: res?.cpqContactMe?.title?.boldLabel,
        showCountDown: res?.cpqContactMe?.countDown?.checked,
        countDownTime: res?.cpqContactMe?.countDown?.key,
        showComment: res?.cpqContactMe?.optionalCommentsPlaceholder?.checked,
        commentPlaceHolder: res?.cpqContactMe?.optionalCommentsPlaceholder?.key,
        additionalOptions: map(
          res?.cpqContactMe?.additionalCheckBoxOptions,
          (singleCheckbox) => {
            if (singleCheckbox?.checked) {
              return singleCheckbox?.key;
            }
          }
        ),
        additionalCheckBoxOptions: res?.cpqContactMe?.additionalCheckBoxOptions,
        email: res?.cpqContactMe?.email,
        showConfirmation:
          res?.cpqContactMe?.additionalCheckBoxOptions?.[0]?.checked,
        isUrgent: res?.cpqContactMe?.additionalCheckBoxOptions[1]?.checked,
        label: res?.cpqContactMe?.buttonLabel
      };
      setInitialValue(tempObj);
      setContactMeConfig(tempObj);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    getContactMeFormData({
      variables: {
        where: {
          questionnaireId: id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CpqTemplate
      centerContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ContactMeComponent
            contactMeConfig={contactMeConfig}
            logoUrl={data?.cpqContactMe?.brandInfo?.logo?.url}
          />
        )
      }
      rightSideContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ContactMeForm
            initialValue={initialValue}
            questionnaireId={id}
            setContactMeConfig={setContactMeConfig}
            contactMeConfig={contactMeConfig}
          />
        )
      }
    />
  );
};

export default ContactMePage;
