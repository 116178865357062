import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import ProductItemForm from '../components/ProductItemForm';
import CardWrapper from '../components/ProductItemTabs';
import { CREATE_PRODUCT_ITEM } from '../graphql/Mutations';

function ProductItemCreate() {
  const [showModal, setShowModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [itemFormData, setItemFormData] = useState({});
  const [createProductItem] = useMutation(CREATE_PRODUCT_ITEM, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const sendToDB = async (formValues) => {
    const newFormValues = {
      ...formValues,
      productCategoryId: formValues?.productCategories,
      productItemWarranty: parseFloat(formValues?.productItemWarranty),
      labourWarranty: parseFloat(formValues?.labourWarranty),
      hsn: formValues?.hsn,
      stock: Number(formValues?.stock),
      heightInNumber: Number(formValues?.heightInNumber)
    };

    delete newFormValues?.productCategories;
    delete newFormValues?.createProduct;
    const variables = newFormValues;
    try {
      const response = await createProductItem({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createProductItem) {
        const { id } = response?.data?.createProductItem?.data;
        history?.push(`${ROUTES?.PRODUCTS}/edit${ROUTES?.GALLERY}/${id}`);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const handleProductItemForm = (formValues) => {
    const uuid = uuidv4();
    setSubmitLoading(true);
    if (formValues?.createProduct) {
      setShowModal(true);
      setSubmitLoading(false);
      setItemFormData(formValues);
      return;
    }
    const tempFormValues = {
      uuid,
      ...formValues
    };
    sendToDB(tempFormValues);
  };

  const handleCreateProductForm = (formValues) => {
    const uuid = uuidv4();
    const productObj = {
      title: itemFormData?.name,
      uuid,
      sku: itemFormData?.sku,
      sellingPrice: itemFormData?.defaultPrice,
      price: itemFormData?.defaultPrice,
      lineOfBusinessId: itemFormData?.lineOfBusinessId,
      subAreaId: itemFormData?.subAreaId,
      isActive: itemFormData?.isActive,
      brands: itemFormData?.brands,
      regions: itemFormData?.regions,
      productQuality: itemFormData?.productQuality,
      description: itemFormData?.description,
      productType: 'Single',
      ...formValues
    };

    const finalObj = {
      ...itemFormData,
      uuid,
      product: productObj
    };

    delete finalObj?.brands;
    delete finalObj?.regions;
    sendToDB(finalObj);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const initialValues = {
    description: '',
    quoteDescription: '',
    isSellable: false,
    manageInventory: false,
    options: false,
    compostable: false,
    allowPriceChange: false,
    lowVoc: false,
    paintable: false,
    asset: false
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRODUCTS} />
          <span className="portal-header">Add Product</span>
        </div>
      </Portal>
      <CardWrapper>
        <ProductItemForm
          productItemData={initialValues}
          isSubmit={submitLoading}
          showCreateProductModal={showModal}
          handleCreateProductCancel={handleCancel}
          handleProductItemForm={handleProductItemForm}
          handleCreateProductForm={handleCreateProductForm}
        />
      </CardWrapper>
    </Card>
  );
}

export default withRouter(ProductItemCreate);
