import { Tree } from 'antd';
import React from 'react';

const TreeComponent = ({
  data = [],
  checkedKeys = [],
  setCheckedKeys,
  setCheckedObject,
  ...rest
}) => {
  const onCheck = (checkedKeysValue, event) => {
    if (setCheckedKeys) {
      setCheckedKeys(checkedKeysValue);
    }
    if (setCheckedObject) {
      setCheckedObject(event?.checkedNodes);
    }
  };

  return (
    <Tree
      checkable
      autoExpandParent
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      selectable={false}
      treeData={data}
      {...rest}
    />
  );
};

export default TreeComponent;
