import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ProductItemDetails from '../components/ProductItemDetails';
import CardWrapper from '../components/ProductItemTabs';
import { GET_PRODUCT_ITEM } from '../graphql/Queries';

const ProductItemView = (props) => {
  const { match: { params: { id } = {} } = {}, history } = props;
  const [loading, setLoading] = useState(true);
  const { data: { productItem } = {} } = useQuery(GET_PRODUCT_ITEM, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRODUCTS} />
          {productItem && (
            <span className="portal-header">{productItem?.name}</span>
          )}
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="manufacturer-table-save-btn"
            type="primary"
            onClick={() => history?.push(`${ROUTES?.PRODUCTS}/edit/${id}`)}
          >
            Edit
          </Button>
        </Portal>
      </AccessControl>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ProductItemDetails data={productItem} />
        )}
      </CardWrapper>
    </Card>
  );
};

export default withRouter(ProductItemView);
