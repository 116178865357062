import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, message, Upload } from 'antd';
import { get } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../AppContext';
import CsvIcon from '../assets/csv.svg';
import api from '../common/api';
import { ALLOW_IMPORT_TYPES, WRITE } from '../common/constants';
import { downloadFromURL, handleCsvDownload } from '../common/utils';
import { GET_SIGN_URL } from './graphql/Mutation';
import LoaderComponent from './LoaderComponent';
import './styles/CommonGoogleFileUpload.less';

const { Dragger } = Upload;
const CommonGoogleFileUpload = (props) => {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const history = useHistory();
  const {
    folder = 'unwanted',
    url = '',
    fromImport = false,
    setShowImportModal,
    callback
  } = props;
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileKey, setFileKey] = useState('');
  const [loading, setLoading] = useState(false);

  const [uploadFile] = useMutation(GET_SIGN_URL, {
    onCompleted: async (res) => {
      if (res?.generateSignedUrl) {
        const { signedRequest, url: URL } = res?.generateSignedUrl;
        // above url is send to the backend
        try {
          const response = await api(signedRequest, {
            method: 'PUT',
            data: file,
            headers: {
              'Content-Type': 'text/csv'
            }
          });
          if (response) {
            api({
              method: 'POST',
              url: `${process?.env?.REACT_APP_SERVER_REST_URL}/import`,
              data: {
                importType: folder,
                fileObj: {
                  url: URL,
                  name: fileName,
                  key: fileKey
                }
              }
            })
              .then((finalResponse) => {
                const responseMsg =
                  get(finalResponse, 'headers.x-message') || '';
                if (finalResponse?.data) {
                  const errorFileName =
                    get(finalResponse, 'headers.x-error') || '';

                  handleCsvDownload(finalResponse?.data, errorFileName);
                  message?.error(responseMsg);
                } else {
                  message?.success(responseMsg);
                }
                if (setShowImportModal) {
                  setShowImportModal(false);
                }
                if (callback) {
                  callback();
                }
                if (fromImport) {
                  history?.push('/imports');
                }
                setLoading(false);
              })
              .catch((error) => {
                const responseMsg =
                  get(error?.response, 'headers.x-message') || '';
                message?.error(responseMsg);
                setLoading(false);
              });
          }
        } catch (error) {
          const responseMsg = get(error?.response, 'headers.x-message') || '';
          message?.error(responseMsg);
          setLoading(false);
        }
      }
    },
    onError: () => {
      setLoading(false);
    }
  });

  const handleUploadFile = () => {
    setLoading(true);
    const timestamp = Date?.now();
    const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
    const newFilename = `${timestamp}_${fileName}`;
    const key = `organization/${currentUser?.tenantId}/imports/${folder}/${newFilename}`;
    setFileKey(key);
    // for Upload file on Bucket
    uploadFile({
      variables: {
        action: WRITE,
        extension: `.${ext}`,
        contentType: fileType,
        key
      }
    });
  };

  const onChange = (info) => {
    const {
      file: { name, type }
    } = info;
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMPORT_TYPES.includes(ext)) {
      setFileList([...info?.fileList]);
      setFile(info?.file);
      setFileName(name);
      setFileType(type);
    } else {
      message?.destroy();
      message?.error(`${info?.file?.name} file is not .CSV file.`);
      form?.resetFields();
    }
  };
  return (
    <div className="google-upload">
      <LoaderComponent spinning={loading} setHeight={15}>
        <Form form={form}>
          <div
            className={`upload-logo ${
              fileList?.length > 0 ? 'uploaded-file' : ''
            }`}
          >
            <Dragger
              showUploadList={{ showPreviewIcon: false }}
              listType="picture-card"
              beforeUpload={() => {
                return false; // For stopping the default upload action of antd
              }}
              fileList={fileList}
              maxCount={1}
              onChange={onChange}
            >
              <p className="ant-upload-drag-icon">
                <PlusOutlined />
              </p>
              <p className="ant-upload-text">
                Choose a CSV file or drag it here.
              </p>
            </Dragger>
          </div>
        </Form>
      </LoaderComponent>
      <div className="d-flex justify-between">
        <span className="d-flex align-center">
          <img src={CsvIcon} alt="csv-icon" />
          <span
            className="link-btn"
            onClick={() => downloadFromURL(url, 'sample.csv')}
          >
            Sample.csv
          </span>
          <InfoCircleOutlined className="info-icon" />
        </span>
        <Button
          type="primary"
          disabled={!fileList?.length}
          loading={loading}
          className="import-btn"
          onClick={handleUploadFile}
        >
          Import
        </Button>
      </div>
    </div>
  );
};

export default CommonGoogleFileUpload;
