import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_ROLE, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import RoleCreate from './pages/RoleCreate';
import RoleEdit from './pages/RoleEdit';
import RoleList from './pages/RoleList';
import './roleModule.less';

const RoleWrapper = () => {
  return (
    <div className="role-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.ROLES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_ROLE} showNoAccess>
              <RoleList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.ROLES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_ROLE_CREATE']}
              showNoAccess
            >
              <RoleCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.ROLES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_ROLE_UPDATE', 'FET_ROLE_VIEW']}
              showNoAccess
            >
              <RoleEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default RoleWrapper;
