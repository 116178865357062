import { gql } from '@apollo/client';

export const GET_PRODUCT_ITEMS = gql`
  query productItems(
    $filter: ProductItemFilter!
    $where: ProductItemsWhereFilter
  ) {
    productItems(filter: $filter, where: $where) {
      count
      data {
        id
        sku
        uuid
        name
        isActive
        stock
        manufacturer {
          id
          name
        }
        manufacturerId
        lineOfBusiness {
          id
          label
        }
        lineOfBusinessId
        subArea {
          id
          label
        }
        subAreaId
        costPrice
        defaultPrice
        quoteDescription
      }
    }
  }
`;

export const GET_PRODUCT_SUB_ITEMS = gql`
  query productSubItems($filter: ProductSubItemFilter!) {
    productSubItems(filter: $filter) {
      count
      data {
        id
        productId
        productItemId
        order
        productItems {
          id
          name
          sku
        }
        manufacturer {
          id
          name
        }
        lineOfBusiness {
          label
        }
        subArea {
          label
        }
        quantity
        costPrice
        defaultPrice
        isPrimary
      }
    }
  }
`;

export const GET_PRODUCT_ITEM = gql`
  query productItem($id: ID!) {
    productItem(where: { id: $id }) {
      id
      name
      productItemCode
      refData
      description
      quoteDescription
      sku
      uuid
      lineOfBusinessId
      subAreaId
      manufacturer {
        id
        name
      }
      lineOfBusiness {
        id
        label
      }
      subArea {
        id
        label
      }
      ventTypeData {
        id
        key
        label
      }
      fuelSourceData {
        id
        key
        label
      }
      productTypeData {
        id
        key
        label
      }
      capacityData {
        id
        key
        label
      }
      heightData {
        id
        key
        label
      }
      productCategory {
        id
        title
      }
      productType
      defaultPrice
      hsn
      btu
      manufacturerId
      productCategoryId
      costPrice
      isActive
      isSellable
      unitForMeasure
      manageInventory
      width
      height
      heightInNumber
      depth
      weight
      productItemModel
      productItemMaterial
      productItemWarranty
      labourWarranty
      fuelSource
      ventType
      capacity
      energyEfficiency
      style
      softness
      noXLevel
      locationType
      options
      compostable
      allowPriceChange
      lowVoc
      paintable
      asset
      stock
      tenantId
    }
  }
`;

export const PRODUCT_ITEM_FILTER = gql`
  query productItemFilters($filter: ProductItemFilter!) {
    productItemFilters(filter: $filter) {
      count
      data {
        name
        productItemCode
        refData
        description
        quoteDescription
        sku
        lineOfBusinessId
        manufacturer {
          id
          name
        }
        lineOfBusiness {
          id
          label
        }
        subArea {
          id
          label
        }
        subAreaId
        productType
        defaultPrice
        hsn
        btu
        manufacturerId
        productCategoryId
        costPrice
        isActive
        isSellable
        unitForMeasure
        manageInventory
        width
        height
        heightInNumber
        depth
        weight
        productItemModel
        productItemMaterial
        productItemWarranty
        labourWarranty
        fuelSource
        ventType
        capacity
        energyEfficiency
        style
        softness
        noXLevel
        locationType
        options
        compostable
        allowPriceChange
        lowVoc
        paintable
        asset
        tenantId
      }
    }
  }
`;

export const LINE_OF_BUSINESS = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const SUB_AREA = gql`
  query subAreas($filter: SubAreaFilter!, $where: SubAreasWhereFilter) {
    subAreas(filter: $filter, where: $where) {
      count
      data {
        id
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        referenceId
      }
    }
  }
`;

export const MANUFACTURER = gql`
  query manufacturers(
    $filter: ManufacturerFilter!
    $where: ManufacturersWhereFilter
  ) {
    manufacturers(filter: $filter, where: $where) {
      count
      data {
        id
        name
        manufacturerCode
        refData
        contactNumber
        isActive
        uuid
        certification
        fax
        url
        tenantId

        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const PRODUCT_CATEGORY = gql`
  query productCategories(
    $filter: ProductCategoryFilter!
    $where: ProductCategoriesWhereFilter
  ) {
    productCategories(filter: $filter, where: $where) {
      count
      data {
        id
        title
        productCategoryCode
        refData
        description
        isActive
        tenantId
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const SUB_AREA_FILTER = gql`
  query subAreaFilters($filter: SubAreaFilter!) {
    subAreaFilters(filter: $filter) {
      count
      data {
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        referenceId
      }
    }
  }
`;

export const MANUFACTURER_FILTER = gql`
  query manufacturerFilters($filter: ManufacturerFilter!) {
    manufacturerFilters(filter: $filter) {
      count
      data {
        name
        manufacturerCode
        refData
        contactNumber
        isActive
        uuid
        certification
        fax
        url
        tenantId
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const PRODUCT_CATEGORY_FILTER = gql`
  query productCategoryFilters($filter: ProductCategoryFilter!) {
    productCategoryFilters(filter: $filter) {
      count
      data {
        title
        productCategoryCode
        refData
        description
        isActive
        tenantId
      }
    }
  }
`;

export const FETCH_HEIGHTS = gql`
  query heights($filter: HeightFilter!, $where: HeightWhereFilter) {
    heights(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
        tenantId
        referenceId
      }
    }
  }
`;
