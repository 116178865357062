import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductItemTypeCreate from './pages/ProductItemTypeCreate';
import ProductItemTypeEdit from './pages/ProductItemTypeEdit';
import ProductItemTypeList from './pages/ProductItemTypeList';
import './productItemTypeModule.less';

const ProductItemTypeWrapper = () => {
  return (
    <div className="product-item-type-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PRODUCT_ITEM_TYPE}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <ProductItemTypeList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PRODUCT_ITEM_TYPE}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <ProductItemTypeCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PRODUCT_ITEM_TYPE}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <ProductItemTypeEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default ProductItemTypeWrapper;
