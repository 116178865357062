import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Portal from '../../../components/Portal';
import PropertyTable from '../components/PropertyTable';

const PropertyList = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <span className="portal-header">Properties</span>
      </Portal>
      {pageSize && <PropertyTable />}
    </Card>
  );
};

export default PropertyList;
