import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoaderComponent from '../../../components/LoaderComponent';
import ThankYouComponent from '../components/previewComponents/thankYou/components/ThankYouComponent';
import ThankYouForm from '../components/ThankYouForm';
import { CPQ_THANK_YOU } from '../graphql/Queries';
import CpqTemplate from './CpqTemplate';

const ThankYouPage = () => {
  const { id } = useParams();
  const [initialValue, setInitialValue] = useState({});
  const [thankYouConfig, setThankYouConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  const [getThanYouFormData, { data }] = useLazyQuery(CPQ_THANK_YOU, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const tempObj = {
        title: res?.cpqThankyou?.title?.title,
        titleDescription: res?.cpqThankyou?.title?.description,
        callUsDescription: res?.cpqThankyou?.callUsMessage,
        callMeDescription: res?.cpqThankyou?.callMeMessage,
        emailMeDescription: res?.cpqThankyou?.emailMeMessage,
        label: res?.cpqThankyou?.buttonLabel
      };
      setInitialValue(tempObj);
      setThankYouConfig(tempObj);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    getThanYouFormData({
      variables: {
        where: {
          questionnaireId: id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CpqTemplate
      centerContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ThankYouComponent
            thankYouConfig={thankYouConfig}
            logoUrl={data?.cpqThankyou?.brandInfo?.logo?.url}
          />
        )
      }
      rightSideContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ThankYouForm
            initialValue={initialValue}
            questionnaireId={id}
            setThankYouConfig={setThankYouConfig}
          />
        )
      }
    />
  );
};

export default ThankYouPage;
