import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './capacityModule.less';
import CapacityCreate from './pages/CapacityCreate';
import CapacityEdit from './pages/CapacityEdit';
import CapacityList from './pages/CapacityList';

const CapacityWrapper = () => {
  return (
    <div className="capacity-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.CAPACITIES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <CapacityList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.CAPACITIES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <CapacityCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.CAPACITIES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <CapacityEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default CapacityWrapper;
