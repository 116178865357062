import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_QUOTE_VIEW, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import QuoteViewerList from './pages/QuoteViewerList';
import './quoteViewerModule.less';

const QuoteViewerWrapper = () => {
  return (
    <div className="quote-viewer-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.QUOTE_VIEWER}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_QUOTE_VIEW}
              showNoAccess
            >
              <QuoteViewerList {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default QuoteViewerWrapper;
