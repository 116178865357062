import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_QUESTIONNAIRE, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './myQuestionnaireModule.less';
import ContactMePage from './pages/ContactMePage';
import CurrentProductPage from './pages/CurrentProductPage';
import CustomerGoalPage from './pages/CustomerGoalPage';
import NewProductPage from './pages/NewProductPage';
import PostalCodePage from './pages/PostalCodePage';
import QuestionnaireList from './pages/QuestionnaireList';
import QuotesPage from './pages/QuotesPage';
import ScheduledAppointmentPage from './pages/ScheduledAppointmentPage';
import ThankYouPage from './pages/ThankYouPage';
import UpgradePage from './pages/UpgradePage';

const MyQuestionnaireWrapper = () => {
  return (
    <div className="questionnaire-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.QUESTIONNAIRES}
          render={(props) => (
            <AccessControl
              allowedPermissions={[...PERMISSION_QUESTIONNAIRE]}
              showNoAccess
            >
              <QuestionnaireList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.POSTAl_CODE}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <PostalCodePage {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.CUSTOMER_GOAL}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <CustomerGoalPage {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.CURRENT_PRODUCT}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <CurrentProductPage {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.UPGRADE}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <UpgradePage {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.NEW_PRODUCT}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <NewProductPage {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.QUOTES}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <QuotesPage {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.SCHEDULED_APPOINTMENT}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <ScheduledAppointmentPage {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.CONTACT_ME}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <ContactMePage {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.THANK_YOU}/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW'
              ]}
              showNoAccess
            >
              <ThankYouPage {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default MyQuestionnaireWrapper;
