import { gql } from '@apollo/client';

export const UPDATE_CAPACITY = gql`
  mutation updateCapacity($where: CapacityWhereInput!, $data: CapacityInput!) {
    updateCapacity(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_CAPACITY = gql`
  mutation createCapacity($data: CapacityInput!) {
    createCapacity(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_CAPACITY = gql`
  mutation deleteCapacity($where: CapacityWhereInput!) {
    deleteCapacity(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
