import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import ProductTable from '../components/ProductTable';

const ProductList = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <span className="portal-header">{MODULES?.PACKAGES}</span>
      </Portal>
      {pageSize && <ProductTable />}
    </Card>
  );
};

export default ProductList;
