import { gql } from '@apollo/client';

export const CPQ_STATISTICS = gql`
  query cpqStatistics($where: CPQBuilderQuestionnaireWhereInput) {
    cpqStatistics(where: $where) {
      id
      key
      questionnaireId
      title
      clickRate
      averageTimeSpent
      skip
    }
  }
`;

export const GET_QUESTIONNAIRES = gql`
  query questionnaires(
    $filter: QuestionnaireFilter!
    $where: QuestionnaireWhereFilter
  ) {
    questionnaires(filter: $filter, where: $where) {
      count
      data {
        id
        title
        uuid
        link
        slug
        brandId
        default
        isScheduleEnabled
        tenantSubDomain
        brand {
          name
          logo {
            url
            key
            name
            extension
            contentType
          }
        }
        startDate
        endDate
        quoteLabels {
          lowCost
          recommended
          highEnd
        }
        closeRate
        isActive
        cutOffTime {
          monday {
            checked
            time
          }
          tuesday {
            checked
            time
          }
          wednesday {
            checked
            time
          }
          thursday {
            checked
            time
          }
          friday {
            checked
            time
          }
          saturday {
            checked
            time
          }
          sunday {
            checked
            time
          }
        }
        showPricingInEmailQuote
        useRetailerLogoAndColor
      }
    }
  }
`;
export const GET_QUESTIONNAIRE = gql`
  query questionnaire($id: ID!) {
    questionnaire(where: { id: $id }) {
      title
      uuid
      link
      slug
      brandId
      isActive
      startDate
      endDate
      tenantSubDomain
      default
      isScheduleEnabled
      quoteLabels {
        lowCost
        recommended
        highEnd
      }
      cutOffTime {
        monday {
          checked
          time
        }
        tuesday {
          checked
          time
        }
        wednesday {
          checked
          time
        }
        thursday {
          checked
          time
        }
        friday {
          checked
          time
        }
        saturday {
          checked
          time
        }
        sunday {
          checked
          time
        }
      }
      showPricingInEmailQuote
      useRetailerLogoAndColor
    }
  }
`;

export const QUESTIONNAIRE_FILTER = gql`
  query questionnaireFilters($filter: QuestionnaireFilter!) {
    questionnaireFilters(filter: $filter) {
      count
      data {
        title
        link
        brandId
        quoteLabels {
          lowCost
          recommended
          highEnd
        }
        closeRate
        startDate
        endDate
        cutOffTime {
          monday {
            checked
            time
          }
          tuesday {
            checked
            time
          }
          wednesday {
            checked
            time
          }
          thursday {
            checked
            time
          }
          friday {
            checked
            time
          }
          saturday {
            checked
            time
          }
          sunday {
            checked
            time
          }
        }
        isActive
        tenantId
      }
    }
  }
`;

export const BRAND_FILTER = gql`
  query brandFilters($filter: BrandFilter!) {
    brandFilters(filter: $filter) {
      count
      data {
        name
        refData
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
        secondaryColors
        tertiaryColors
        colorPallete
        description
        waiverText
        priceGuarantee
        offerText
        productDetails
        isActive
        tenantId
        createdAt
      }
    }
  }
`;

export const CPQ_CUSTOMER_GOAL = gql`
  query cpqCustomerGoal($id: ID!) {
    cpqCustomerGoal(where: { questionnaireId: $id }) {
      title
      link
      skip
      questionnaireId
      clickRate
      averageTimeSpent
      upgradeReplacement {
        show
        enable
        description
        summary
        replaceButtonLabel
        upgradeButtonLabel
      }
      repair {
        show
        enable
        description
        videoTutorial
        summary
        buttonLabel
      }
      footShowLocation
      brandInfo {
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const CPQ_POSTAL_CODE = gql`
  query cpqZipCode($id: ID!) {
    cpqZipCode(where: { questionnaireId: $id }) {
      link
      questionnaireId
      title {
        thinLabel
        boldLabel
      }
      map {
        key
        label
        checked
      }
      buttonLabel
      footNote
      brandInfo {
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const CPQ_NEW_LINE_OF_BUSINESS = gql`
  query cpqNewLineOfBusiness($where: CPQBuilderQuestionnaireWhereInput!) {
    cpqNewLineOfBusiness(where: $where) {
      title
      image {
        url
        key
        name
        extension
        contentType
      }
      fuelSourceType {
        title
        fuelSource {
          key
          label
          checked
        }
        ventType {
          key
          label
          checked
        }
      }
      capacity {
        title
        waterHeaterCapacity {
          key
          label
          checked
          description
        }
      }
      tanklessTitle
      tankless {
        enable
        title
        videoUrl
        bulletPoints
        secondTitle
        button1
        button2
        linkTitle
      }
      height {
        title
        waterHeaterHeight {
          key
          label
          checked
        }
      }
      description
      link
      questionnaireId
      clickRate
      averageTimeSpent
      buttonLabel
      footNote
      brandInfo {
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const CPQ_CURRENT_LINE_OF_BUSINESS = gql`
  query cpqCurrentLineOfBusiness($where: CPQBuilderQuestionnaireWhereInput!) {
    cpqCurrentLineOfBusiness(where: $where) {
      title
      image {
        url
        key
        name
        extension
        contentType
      }
      fuelSourceType {
        title
        fuelSource {
          key
          label
          checked
        }
        ventType {
          key
          label
          checked
        }
      }
      capacity {
        title
        waterHeaterCapacity {
          key
          label
          checked
          description
        }
      }
      height {
        title
        waterHeaterHeight {
          key
          label
          checked
        }
      }
      link
      questionnaireId
      clickRate
      averageTimeSpent
      buttonLabel
      footNoteLocation
      brandInfo {
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const CPQ_UPGRADE = gql`
  query cpqUpgrade($where: CPQBuilderQuestionnaireWhereInput!) {
    cpqUpgrade(where: $where) {
      title {
        mainTitle
        shortDescription
      }
      link
      questionnaireId
      clickRate
      averageTimeSpent
      search
      checkList {
        mainCheckList
        inputBoxPlaceholder
      }
      buttonLabel
      footNoteLocation
      showSearchBar
      brandInfo {
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const CPQ_SCHEDULE = gql`
  query cpqSchedule($where: CPQBuilderQuestionnaireWhereInput!) {
    cpqSchedule(where: $where) {
      id
      title
      link
      questionnaireId
      clickRate
      averageTimeSpent
      countDown {
        label
        key
        checked
      }
      location {
        title
        description
        label
      }
      appointmentTime {
        title
        firstOfWeek
        timeOfDayChecked
        timeOfDay
      }
      appointmentReservation {
        title
        creditCard {
          enabled
          title
          buttonLabel
          formDescription
          formLabel
        }
        phoneCall {
          enabled
          title
          buttonLabel
        }
        emailMe {
          description
          buttonLabel
        }
      }
      emailMeButtonLabel
      holidayList {
        title
        date
        message
      }
      contactInfo
      contactData {
        liveChat
        phoneNo
        email
        helpPage
      }
      brandInfo {
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const CPQ_QUOTES = gql`
  query cpqQuote($where: CPQBuilderQuestionnaireWhereInput!) {
    cpqQuote(where: $where) {
      questionnaireId
      marketingMessage {
        customerPromotion {
          checked
          icon
          title
          description
        }
        price {
          icon
          description
        }
        priceFactors {
          label
          description
        }
      }
      quoteExpiration {
        checked
        time
        value
      }
      contactInfo
      contactData {
        liveChat
        phoneNo
        email
        helpPage
      }
      backLabel
      buttonLabel
      serviceCountDownLabel {
        key
        checked
      }
      tenantId
      id
      quoteLabels {
        lowCost
        recommended
        highEnd
      }
      brandInfo {
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
      emailMeButtonLabel
      showCostPerDay
    }
  }
`;

export const CPQ_CONTACT_ME = gql`
  query cpqContactMe($where: CPQBuilderQuestionnaireWhereInput!) {
    cpqContactMe(where: $where) {
      link
      questionnaireId
      title {
        thinLabel
        boldLabel
      }
      countDown {
        key
        label
        checked
      }
      additionalCheckBoxOptions {
        key
        label
        checked
      }
      optionalCommentsPlaceholder {
        key
        label
        checked
      }
      email
      buttonLabel
      brandInfo {
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const CPQ_THANK_YOU = gql`
  query cpqThankyou($where: CPQBuilderQuestionnaireWhereInput!) {
    cpqThankyou(where: $where) {
      questionnaireId
      title {
        title
        description
      }
      callUsMessage
      callMeMessage
      emailMeMessage
      buttonLabel
      brandInfo {
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const CPQ_LINE_OF_BUSINESS = gql`
  query lineOfBusiness {
    lineOfBusiness {
      id
      label
    }
  }
`;

export const GET_TENANT = gql`
  query tenant($where: TenantWhereInput!) {
    tenant(where: $where) {
      subDomain
    }
  }
`;
