import { gql } from '@apollo/client';

export const UPDATE_PROPERTY_WATER_HOOK_UP = gql`
  mutation updatePropertyWaterHookUp(
    $where: PropertyWaterHookUpWhereInput!
    $data: PropertyWaterHookUpInput!
  ) {
    updatePropertyWaterHookUp(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_PROPERTY_WATER_HOOK_UP = gql`
  mutation createPropertyWaterHookUp($data: PropertyWaterHookUpInput!) {
    createPropertyWaterHookUp(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_PROPERTY_WATER_HOOK_UP = gql`
  mutation deletePropertyWaterHookUp($where: PropertyWaterHookUpWhereInput!) {
    deletePropertyWaterHookUp(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
