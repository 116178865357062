import React from 'react';
import LeftSideContent from '../../../../modules/myQuestionnaire/components/LeftSideContent';
import '../cpqPanel.less';
import PanelBtn from './PanelBtn';

const CpqPanel = () => {
  return (
    <>
      <PanelBtn />
      <div className="side-panel-wrapper cpq-panel">
        <LeftSideContent />
      </div>
    </>
  );
};

export default CpqPanel;
