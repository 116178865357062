import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { getLogo, objectWithoutKey, uploadLogo } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import HeightForm from '../components/HeightForm';
import CardWrapper from '../components/HeightTabs';
import { UPDATE_HEIGHT } from '../graphql/Mutations';
import { GET_HEIGHT } from '../graphql/Queries';

function HeightEdit(props) {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const { match: { params: { id } = {} } = {}, history } = props;

  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { height } = {} } = useQuery(GET_HEIGHT, {
    variables: { id },
    onCompleted: (res) => {
      if (!res?.height?.logo?.url) {
        setLoading(false);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateHeight] = useMutation(UPDATE_HEIGHT, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    if (height?.logo?.url) {
      getLogo({
        logoObject: height?.logo,
        setDataState: setLogoUrl,
        setLoadingState: setLoading
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const uuid = height?.uuid || uuidv4();
    let logo = null;
    if (formValues?.logo && !formValues?.logo?.url) {
      logo = await uploadLogo({
        logoObject: formValues?.logo,
        currentUser,
        uuid,
        folder: 'height'
      });
    }

    const newFormValues = {
      ...formValues,
      ...(formValues?.logo?.url
        ? {
            logo: {
              ...height?.logo,
              __typename: undefined
            }
          }
        : {
            ...(logo !== null && { logo })
          }),
      uuid
    };

    const variables = { data: newFormValues, where: { id: height?.id } };

    try {
      const response = await updateHeight({
        variables: { ...variables }
      });
      if (response?.data?.updateHeight) {
        setSubmitLoading(false);
        history?.push(ROUTES?.HEIGHTS);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.HEIGHTS} />;
  }

  const initialValues = {
    ...(height && { ...objectWithoutKey(height, 'logo') }),
    ...(height?.logo?.url && { logo: { ...height?.logo, ...logoUrl } }),
    description: height?.description || '',
    isActive: height?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.HEIGHTS} />
          {height && <span className="portal-header">{height?.label}</span>}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={75} />
        ) : (
          <HeightForm
            heightData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
            isEdit
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(HeightEdit);
