import { useLazyQuery } from '@apollo/client';
import { forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoaderComponent from '../../../components/LoaderComponent';
import CustomerGoalForm from '../components/CustomerGoalForm';
import CustomerGoalComponent from '../components/previewComponents/customerGoal/components/CustomerGoalComponent';
import { CPQ_CUSTOMER_GOAL } from '../graphql/Queries';
import CpqTemplate from './CpqTemplate';

const CustomerGoalPage = () => {
  const { id } = useParams();
  const [initialValue, setInitialValue] = useState({});
  const [customerGoalConfig, setCustomerGoalConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cpqCustomerGoal, { data }] = useLazyQuery(CPQ_CUSTOMER_GOAL, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const upgradeBulletPoints = [];
      const repairBulletPoints = [];
      forEach(res?.cpqCustomerGoal?.upgradeReplacement?.summary, (item) => {
        upgradeBulletPoints?.push({
          upgradeBulletPoint: item
        });
      });
      forEach(res?.cpqCustomerGoal?.repair?.summary, (item) => {
        repairBulletPoints?.push({
          repairBulletPoint: item
        });
      });
      const tempObj = {
        title: res?.cpqCustomerGoal?.title,
        skip: res?.cpqCustomerGoal?.skip,
        upgrade_replacement: res?.cpqCustomerGoal?.upgradeReplacement?.enable,
        upgradeDescription:
          res?.cpqCustomerGoal?.upgradeReplacement?.description,
        upgradeBulletPoints,
        replaceBtnLabel:
          res?.cpqCustomerGoal?.upgradeReplacement?.replaceButtonLabel,
        upgradeBtnLabel:
          res?.cpqCustomerGoal?.upgradeReplacement?.upgradeButtonLabel,
        repair: res?.cpqCustomerGoal?.repair?.enable,
        repairDescription: res?.cpqCustomerGoal?.repair?.description,
        repairBulletPoints,
        url: res?.cpqCustomerGoal?.repair?.videoTutorial,
        repairButtonLabel: res?.cpqCustomerGoal?.repair?.buttonLabel,
        showLocation: res?.cpqCustomerGoal?.footShowLocation
      };
      setInitialValue(tempObj);
      setCustomerGoalConfig(tempObj);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    cpqCustomerGoal({
      variables: {
        id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CpqTemplate
      centerContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <CustomerGoalComponent
            customerGoalConfig={customerGoalConfig}
            logo={data?.cpqCustomerGoal?.brandInfo?.logo?.url}
          />
        )
      }
      rightSideContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <CustomerGoalForm
            questionnaireId={id}
            initialValue={initialValue}
            setCustomerGoalConfig={setCustomerGoalConfig}
            showRepair={data?.cpqCustomerGoal?.repair?.show}
            showUpgrade={data?.cpqCustomerGoal?.upgradeReplacement?.show}
          />
        )
      }
    />
  );
};

export default CustomerGoalPage;
