import { gql } from '@apollo/client';

export const SKIP_CUSTOMER_GOAL = gql`
  mutation skipCustomerGoal(
    $value: Boolean!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    skipCustomerGoal(value: $value, where: $where) {
      message
      status
    }
  }
`;

export const CREATE_QUESTIONNAIRES = gql`
  mutation createQuestionnaire($data: QuestionnaireInput!) {
    createQuestionnaire(data: $data) {
      message
      status
      data {
        id
        title
      }
    }
  }
`;

export const UPDATE_QUESTIONNAIRES = gql`
  mutation updateQuestionnaire(
    $data: QuestionnaireInput!
    $where: QuestionnaireWhereInput!
  ) {
    updateQuestionnaire(data: $data, where: $where) {
      message
      status
      data {
        id
        title
      }
    }
  }
`;

export const DELETE_QUESTIONNAIRES = gql`
  mutation deleteQuestionnaire($where: QuestionnaireWhereInput!) {
    deleteQuestionnaire(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const UPDATE_CUSTOMER_GOAL = gql`
  mutation updateCpqCustomerGoal(
    $data: CpqCustomerGoalInput!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    updateCpqCustomerGoal(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_CPQ_POSTAL_CODE = gql`
  mutation updateCpqZipCode(
    $data: CpqZipCodeInput!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    updateCpqZipCode(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_CPQ_NEW_LINE_OF_BUSINESS = gql`
  mutation updateCpqNewLineOfBusiness(
    $data: CpqNewLineOfBusinessInput!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    updateCpqNewLineOfBusiness(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_CPQ_CURRENT_LINE_OF_BUSINESS = gql`
  mutation updateCpqCurrentLineOfBusiness(
    $data: CpqCurrentLineOfBusinessInput!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    updateCpqCurrentLineOfBusiness(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_CPQ_UPGRADE = gql`
  mutation updateCpqUpgrade(
    $data: CpqUpgradeInput!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    updateCpqUpgrade(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_CPQ_QUOTES = gql`
  mutation updateCpqQuotes(
    $data: CpqQuotesInput!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    updateCpqQuotes(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_CPQ_CONTACT_ME = gql`
  mutation updateCpqContactMe(
    $data: CpqContactMeInput!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    updateCpqContactMe(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_CPQ_THANK_YOU = gql`
  mutation updateCpqThankyou(
    $data: CpqThankyouInput!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    updateCpqThankyou(data: $data, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_CPQ_SCHEDULE = gql`
  mutation updateCpqSchedule(
    $data: CpqScheduleInput!
    $where: CPQBuilderQuestionnaireWhereInput!
  ) {
    updateCpqSchedule(data: $data, where: $where) {
      message
      status
    }
  }
`;
