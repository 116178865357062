import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Dropdown, Menu, message, notification } from 'antd';
import { cloneDeep, find, findIndex, forEach, get, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import ExportIcon from '../../../assets/export.svg';
import ImportIcon from '../../../assets/import.svg';
import api from '../../../common/api';
import { PRODUCT_PRICING } from '../../../common/constants';
import {
  checkPermissions,
  formatPrice,
  handleCsvDownload
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import PriceComponent from '../../../components/PriceComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import ImportModal from '../../imports/components/ImportModal';
import { UPDATE_PRODUCT_AVAILABILITY } from '../graphql/Mutations';
import { GET_PRODUCT_PRICING_LIST } from '../graphql/Queries';
import CostUpdateModal from './CostUpdateModal';

const getData = (key = '') => {
  const localStorageData =
    // eslint-disable-next-line no-undef
    JSON?.parse(localStorage?.getItem(PRODUCT_PRICING)) || null;
  if (key?.length > 0) {
    return get(localStorageData, key);
  }
  return localStorageData;
};

const updateData = (data = null) => {
  // eslint-disable-next-line no-undef
  localStorage?.setItem(PRODUCT_PRICING, JSON?.stringify(data));
};

const ProductPricingTable = () => {
  const {
    state: {
      pageSize,
      currentUser,
      productPricingConfig,
      firstPricingConfigSet,
      permissions,
      showNotificationWarning
    },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialProductPricingFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const whereConfig = {
    manufacturerIds:
      productPricingConfig?.manufacturerIds?.length > 0
        ? map(productPricingConfig?.manufacturerIds, (item) => item?.id)
        : [],
    baseCost: productPricingConfig?.baseCost,
    defaultCost: productPricingConfig?.defaultCost,
    regionIds:
      productPricingConfig?.regionIds?.length > 0
        ? productPricingConfig?.regionIds
        : [],
    subAreas:
      productPricingConfig?.subAreas?.length > 0
        ? map(productPricingConfig?.subAreas, (item) => item?.id)
        : [],
    ...(productPricingConfig?.profit?.value && {
      profit: productPricingConfig?.profit
    })
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [productPricingFilter, setProductPricingFilter] = useState(
    initialProductPricingFilter
  );
  const [exportLoading, setExportLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [updateFrom, setUpdateFrom] = useState('');
  const [updateInputFrom, setUpdateInputFrom] = useState('');
  const [availableLoading, setAvailableLoading] = useState(false);
  const [productPricingData, setProductPricingData] = useState([]);
  const [columnsData, setColumnsData] = useState([]);

  const [updateProductAvailability] = useMutation(UPDATE_PRODUCT_AVAILABILITY, {
    onError() {
      setAvailableLoading(false);
    }
  });

  const [productPricingList, { loading }] = useLazyQuery(
    GET_PRODUCT_PRICING_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.productPricingList?.count
        };
        setProductPricingData(res?.productPricingList?.data);
        setPaginationProp(pagination);
        const localStorageData = getData();
        if (!localStorageData) {
          dispatch({
            type: 'SET_FIRST_PRICE_CONFIG_SET',
            data: true
          });
          const config = {
            regionIds: [],
            profit: {
              operation: 'GREATER',
              operationType: '$'
            }
          };
          forEach(res?.productPricingList?.data?.[0]?.regions, (item) => {
            config?.regionIds?.push(item?.regionId);
            if (item?.subRegions?.length > 0) {
              forEach(item?.subRegions, (subRegion) =>
                config?.regionIds?.push(subRegion?.regionId)
              );
            }
          });
          updateData(config);
          dispatch({
            type: 'SET_PRODUCT_PRICING_CONFIG',
            data: config
          });
        }
      },
      onError() {}
    }
  );

  useEffect(() => {
    const localStorageData = getData();
    if (localStorageData) {
      dispatch({
        type: 'SET_PRODUCT_PRICING_CONFIG',
        data: localStorageData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showModal) {
      setUpdateInputFrom('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (!firstPricingConfigSet && !showNotificationWarning) {
      productPricingList({
        variables: {
          filter: productPricingFilter,
          ...(productPricingConfig && {
            where: whereConfig
          })
        }
      });
    } else {
      dispatch({
        type: 'SET_FIRST_PRICE_CONFIG_SET',
        data: false
      });
      if (showNotificationWarning) {
        notification?.warn({
          className: 'top-notification',
          description:
            'Please create/select some Lines of Business, Sub Areas, Regions, Products, and Packages for a seamless experience.'
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPricingConfig]);

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    }
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setProductPricingFilter({
      ...productPricingFilter,
      skip,
      limit: pagination?.pageSize,
      sortOn: 'createdAt',
      sortBy: 'DESC'
    });
    productPricingList({
      variables: {
        filter: {
          ...productPricingFilter,
          skip,
          limit: pagination?.pageSize,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(productPricingConfig && {
          where: whereConfig
        })
      }
    });
  };

  const handleShowModal = (record, from, fromInput) => {
    setShowModal(true);
    setUpdateFrom(from);
    if (fromInput) {
      setUpdateInputFrom(fromInput);
    }

    switch (from) {
      case 'PRODUCT':
        setInitialValues({
          ...record,
          tree: record?.regions
        });
        break;
      case 'REGION':
        setInitialValues({
          ...record,
          tree: [record]
        });
        break;
      case 'SUBREGION':
        setInitialValues({
          ...record,
          tree: [record]
        });
        break;

      default:
        break;
    }
  };

  const setClassNameAsAmount = (record, item) => {
    const itemProfit = Number(item?.profit?.toFixed(2));
    const recordProfit = Number(record?.profit?.toFixed(2));

    if (itemProfit < recordProfit) {
      return 'red-amount';
    }
    if (itemProfit > recordProfit) {
      return 'green-amount';
    }
  };

  const handleChangeAvailability = async (
    e,
    item,
    record,
    from,
    regionRecord
  ) => {
    setAvailableLoading(true);
    try {
      const response = await updateProductAvailability({
        variables: {
          data: {
            available: e?.target?.checked
          },
          where: {
            regionIds: [item?.regionId],
            productItemId: record?.id
          }
        }
      });
      if (response) {
        const recordIndex = findIndex(
          productPricingData,
          (product) => product?.id === record?.id
        );

        const updatedData = cloneDeep(productPricingData);
        if (from === 'REGION') {
          const regionIndex = findIndex(
            productPricingData?.[recordIndex]?.regions,
            (region) => region?.regionId === item?.regionId
          );
          if (productPricingData && recordIndex > -1 && regionIndex > -1) {
            updatedData[recordIndex].regions[regionIndex].available =
              e?.target?.checked;
          }
        } else {
          const regionIndex = findIndex(
            productPricingData?.[recordIndex]?.regions,
            (region) => region?.regionId === regionRecord?.regionId
          );
          const subRegionIndex = findIndex(
            productPricingData?.[recordIndex]?.regions?.[regionIndex]
              ?.subRegions,
            (subRegion) => subRegion?.regionId === item?.regionId
          );
          if (
            productPricingData &&
            recordIndex > -1 &&
            regionIndex > -1 &&
            subRegionIndex > -1
          ) {
            updatedData[recordIndex].regions[regionIndex].subRegions[
              subRegionIndex
            ].available = e?.target?.checked;
          }
        }
        setProductPricingData(updatedData);
        setAvailableLoading(false);
      }
    } catch (error) {
      setAvailableLoading(false);
    }
  };

  const commonCard = (record, item, from, regionRecord) => {
    return (
      <>
        <Checkbox
          className="common-checkbox available-checkbox"
          onChange={(e) =>
            handleChangeAvailability(e, item, record, from, regionRecord)
          }
          disabled={
            !checkPermissions(permissions, 'FET_PRODUCT_PRICING_UPDATE')
          }
          checked={item?.available}
        >
          Available
        </Checkbox>
        <div
          className={`d-flex flex-vertical p-2 ${
            !item?.available && 'not-available-card'
          }`}
        >
          <PriceComponent
            readOnly
            disabled={!item?.available}
            onClick={() => {
              if (checkPermissions(permissions, 'FET_PRODUCT_PRICING_UPDATE')) {
                handleShowModal(
                  {
                    ...item,
                    id: record?.id,
                    productName: record?.name,
                    manufacturer: record?.manufacturer,
                    sku: record?.sku
                  },
                  from,
                  'baseCost'
                );
              }
            }}
            onKeyPress={() => {
              if (checkPermissions(permissions, 'FET_PRODUCT_PRICING_UPDATE')) {
                handleShowModal(
                  {
                    ...item,
                    id: record?.id,
                    productName: record?.name,
                    manufacturer: record?.manufacturer,
                    sku: record?.sku
                  },
                  from,
                  'baseCost'
                );
              }
            }}
            placeholder="Base Cost"
            className="base-cost region-input"
            value={item?.baseCost}
            isPrice
            prefix="$"
          />
          <PriceComponent
            readOnly
            disabled={!item?.available}
            onClick={() => {
              if (checkPermissions(permissions, 'FET_PRODUCT_PRICING_UPDATE')) {
                handleShowModal(
                  {
                    ...item,
                    id: record?.id,
                    productName: record?.name,
                    manufacturer: record?.manufacturer,
                    sku: record?.sku
                  },
                  from,
                  'defaultCost'
                );
              }
            }}
            onKeyPress={() => {
              if (checkPermissions(permissions, 'FET_PRODUCT_PRICING_UPDATE')) {
                handleShowModal(
                  {
                    ...item,
                    id: record?.id,
                    productName: record?.name,
                    manufacturer: record?.manufacturer,
                    sku: record?.sku
                  },
                  from,
                  'defaultCost'
                );
              }
            }}
            placeholder="Default Price"
            className="default-price region-input"
            value={item?.defaultCost}
            isPrice
            prefix="$"
          />
        </div>
        <div
          className={`profit-section ${
            !item?.available && 'not-available-card'
          }`}
        >
          <span className="profit-text">Profit</span>
          <span className={`amount ${setClassNameAsAmount(record, item)}`}>
            {formatPrice(item?.profit)}
          </span>
        </div>
      </>
    );
  };

  useEffect(() => {
    const columns = [];
    columns?.push(
      {
        width: 100,
        fixed: 'left',
        ellipsis: true,
        className: 'max-width-column',
        render: () => {
          return (
            <div className="first-column">
              <span className="availability-text">Availability</span>
              <span className="base-cost-text">Base Cost</span>
              <span className="default-price-text">Default Price</span>
              <span className="margin-target-text">Margin Target</span>
            </div>
          );
        }
      },
      {
        title: 'PRODUCT',
        dataIndex: 'id',
        key: 'id',
        ellipsis: true,
        fixed: 'left',
        className: 'max-width-column',
        render: (id, record) => {
          return (
            <div className="second-column">
              <span className="product-name" title={record?.name}>
                {record?.name}
              </span>
              <div className="product-details">
                <span title={record?.manufacturer}>
                  MFT: {record?.manufacturer}
                </span>
                <span title={record?.sku}>SKU: {record?.sku}</span>
              </div>
              <div className="d-flex flex-vertical p-2">
                <PriceComponent
                  readOnly
                  onClick={() => {
                    if (
                      checkPermissions(
                        permissions,
                        'FET_PRODUCT_PRICING_UPDATE'
                      )
                    ) {
                      handleShowModal(
                        { ...record, productName: record?.name },
                        'PRODUCT',
                        'baseCost'
                      );
                    }
                  }}
                  onKeyPress={() => {
                    if (
                      checkPermissions(
                        permissions,
                        'FET_PRODUCT_PRICING_UPDATE'
                      )
                    ) {
                      handleShowModal(
                        { ...record, productName: record?.name },
                        'PRODUCT',
                        'baseCost'
                      );
                    }
                  }}
                  placeholder="Base Cost"
                  className="base-cost"
                  value={record?.baseCost}
                  isPrice
                  prefix="$"
                />
                <PriceComponent
                  readOnly
                  onClick={() => {
                    if (
                      checkPermissions(
                        permissions,
                        'FET_PRODUCT_PRICING_UPDATE'
                      )
                    ) {
                      handleShowModal(
                        { ...record, productName: record?.name },
                        'PRODUCT',
                        'defaultCost'
                      );
                    }
                  }}
                  onKeyPress={() => {
                    if (
                      checkPermissions(
                        permissions,
                        'FET_PRODUCT_PRICING_UPDATE'
                      )
                    ) {
                      handleShowModal(
                        { ...record, productName: record?.name },
                        'PRODUCT',
                        'defaultCost'
                      );
                    }
                  }}
                  placeholder="Default Price"
                  className="default-price"
                  value={record?.defaultCost}
                  isPrice
                  prefix="$"
                />
                <PriceComponent
                  readOnly
                  onClick={() => {
                    if (
                      checkPermissions(
                        permissions,
                        'FET_PRODUCT_PRICING_UPDATE'
                      )
                    ) {
                      handleShowModal(
                        { ...record, productName: record?.name },
                        'PRODUCT'
                      );
                    }
                  }}
                  onKeyPress={() => {
                    if (
                      checkPermissions(
                        permissions,
                        'FET_PRODUCT_PRICING_UPDATE'
                      )
                    ) {
                      handleShowModal(
                        { ...record, productName: record?.name },
                        'PRODUCT'
                      );
                    }
                  }}
                  placeholder="Profit Margin"
                  className="profit-margin"
                  value={record?.profit}
                  isPrice
                  prefix={record?.profitType === '$' ? '$' : ''}
                  suffix={record?.profitType === '%' ? '%' : ''}
                />
              </div>
            </div>
          );
        }
      }
    );

    if (
      productPricingData?.length > 0 &&
      productPricingData?.[0]?.regions?.length > 0
    ) {
      forEach(productPricingData?.[0]?.regions, (item, regionIndex) => {
        const columnData = {
          title: <div className="region-title">{item?.name}</div>,
          key: item?.regionId,
          width: 121,
          className: `region-bg-color no-horizontal-padding ${
            regionIndex > 0 && 'padding-left'
          }`,
          render: (region, record) => {
            const singleRegion = find(
              record?.regions,
              (regionItem) => regionItem?.regionId === item?.regionId
            );
            return (
              <div className="card-section">
                <div className="region-card">
                  {commonCard(record, singleRegion, 'REGION')}
                </div>
              </div>
            );
          }
        };
        columns?.push(columnData);
        if (item?.subRegions?.length > 0) {
          forEach(item?.subRegions, (subRegion, index) => {
            const SubRegionColumnData = {
              title: (
                <div className="sub-region-title">
                  <span>{subRegion?.name}</span>
                  <span className="sub-region-text">Subregion</span>
                </div>
              ),
              className: `region-bg-color ${
                item?.subRegions?.[index + 1]
                  ? 'sub-region-right-padding'
                  : 'no-horizontal-padding'
              }`,
              key: subRegion?.regionId,
              onHeaderCell: () => {
                return {
                  style: {
                    paddingTop: '8px',
                    paddingBottom: '0px'
                  }
                };
              },
              width: 121,
              render: (subRegions, record) => {
                let regionRecord;
                let subRegionRecord;
                forEach(record?.regions, (regionObj) => {
                  if (regionObj?.subRegions?.length > 0) {
                    forEach(regionObj?.subRegions, (subRegionObj) => {
                      if (subRegionObj?.regionId === subRegion?.regionId) {
                        regionRecord = regionObj;
                        subRegionRecord = subRegionObj;
                      }
                    });
                  }
                });
                return (
                  <div className="card-section">
                    <div className="sub-region-card">
                      {commonCard(
                        record,
                        subRegionRecord,
                        'SUBREGION',
                        regionRecord
                      )}
                    </div>
                  </div>
                );
              }
            };
            columns?.push(SubRegionColumnData);
          });
        }
      });
    }
    setColumnsData(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPricingData]);

  const onSearchChange = (value) => {
    setProductPricingFilter({
      ...productPricingFilter,
      skip: value
        ? 0
        : productPricingFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    productPricingList({
      variables: {
        filter: {
          ...productPricingFilter,
          skip: value
            ? 0
            : productPricingFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(productPricingConfig && {
          where: whereConfig
        })
      }
    });
  };

  const handleExport = (e) => {
    const { key } = e;
    if (key === 'SELECTED' && !selectedKeys?.length) {
      message?.destroy();
      message?.error('Please select records!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process?.env?.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module: 'PRODUCT_PRICING',
        userId: currentUser?.id,
        filtersObj: {
          ...whereConfig,
          id: key === 'SELECTED' ? selectedKeys : undefined,
          ...(key === 'PENDING' && {
            status: [key]
          })
        }
      }
    })
      .then((res) => {
        handleCsvDownload(res?.data, 'product-pricing.csv');
        setExportLoading(false);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setProductPricingFilter(initialProductPricingFilter);
    productPricingList({
      variables: {
        filter: productPricingFilter,
        ...(productPricingConfig && {
          where: whereConfig
        })
      }
    });
  };

  const exportContent = (
    <Menu onClick={handleExport}>
      <Menu.Item key="SELECTED" id="selected-export">
        <span>Selected</span>
      </Menu.Item>
      <Menu.Item key="ALL" id="all-export">
        <span>All</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="product-pricing-table">
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="product-pricings"
        folder="PRODUCT_PRICING"
      />
      {showModal && (
        <CostUpdateModal
          showModal={showModal}
          setShowModal={setShowModal}
          initialValues={initialValues}
          updateFrom={updateFrom}
          setUpdateFrom={setUpdateFrom}
          productPricingList={productPricingList}
          updateInputFrom={updateInputFrom}
          variables={{
            filter: productPricingFilter,
            ...(productPricingConfig && {
              where: whereConfig
            })
          }}
        />
      )}
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_PRODUCT_PRICING_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Product Name, ID, SKU, or other detail..."
              name="Products"
              getData={onSearchChange}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_PRODUCT_PRICING_IMPORT']}>
            <Button
              size="small"
              className="common-button import-button"
              icon={<img src={ImportIcon} alt="import-icon" width={11} />}
              id="user-table-import-btn"
              type="primary"
              onClick={() => handleShowImportModal(true)}
            >
              Import
            </Button>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_PRODUCT_PRICING_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              overlay={exportContent}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="user-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl
        allowedPermissions={['FET_PRODUCT_PRICING_LIST']}
        showNoAccess
      >
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={loading || availableLoading}
              columns={[...columnsData?.filter((item) => item !== false)]}
              data={productPricingData || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default ProductPricingTable;
