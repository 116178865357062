import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Card, Checkbox, Divider, message } from 'antd';
import { filter, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { MODULES } from '../../../common/constants';
import { checkPermissions } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import CardWrapper from '../components/BrandSettingTab';
import { UPDATE_BRAND_LOB } from '../graphql/Mutations';
import { GET_LOB_LIST, GET_TENANT_BRAND_LOB } from '../graphql/Queries';

const CheckboxGroup = Checkbox.Group;

const BrandSettingLob = () => {
  const {
    state: { permissions }
  } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [lobList, setLobList] = useState([]);
  const [defaultLobList, setDefaultLobList] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [brandId, setBrandId] = useState(null);
  const {
    state: { pageSize }
  } = useContext(AppContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [checkBoxArray, setCheckBoxArray] = useState([]);

  useEffect(() => {
    setCheckBoxArray((prevArray) => [...prevArray, ...lobList]);
  }, [lobList]);

  const [updateBrandLob] = useMutation(UPDATE_BRAND_LOB, {
    onCompleted: () => {
      setDisabled(true);
    },
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const initialBrandLobFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
    justShow: true
  };

  const onChange = (list, lob, index) => {
    setDisabled(false);
    const checkboxArrayCopy = [...checkBoxArray];
    if (list?.length > 0) {
      checkboxArrayCopy[index].checkedChildren = list;
    } else {
      checkboxArrayCopy[index].checkedChildren = [];
    }
    if (list?.length === lob?.subAreas?.length) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = true;
    } else if (list?.length === 0) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = false;
    } else {
      checkboxArrayCopy[index].indeterminate = true;
      checkboxArrayCopy[index].checkedAll = false;
    }

    setCheckBoxArray(checkboxArrayCopy);
  };

  const onCheckAllChange = (e, lob, index) => {
    setDisabled(false);
    const checkboxArrayCopy = [...checkBoxArray];
    const checked = [];
    if (e?.target?.checked) {
      forEach(lob?.subAreas, (area) => checked?.push(area.id));
    }
    if (checkboxArrayCopy[index]) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = e?.target?.checked;
      checkboxArrayCopy[index].checkedChildren = checked;
      setCheckBoxArray(checkboxArrayCopy);
    }
  };

  const saveBrandLob = async () => {
    setSubmitLoading(true);
    const sendData = [];

    forEach(checkBoxArray, (item) => {
      if (item?.checkedChildren?.length > 0) {
        sendData?.push({
          lineOfBusinessId: item?.id,
          subAreaId: item?.checkedChildren
        });
      }
    });
    if (sendData?.length > 0) {
      const response = await updateBrandLob({
        variables: {
          data: sendData,
          where: {
            id: brandId
          }
        }
      });
      if (response?.data?.updateBrandLineOfBusiness) {
        setSubmitLoading(false);
      }
    } else {
      setSubmitLoading(false);
      message?.destroy();
      message?.error(`Please select Line Of Business and Subareas.`);
    }
  };

  const createLobArray = (brandRes) => {
    const tempArray = [];

    if (brandRes?.fetchTenantBrandLineOfBusinesses?.length > 0) {
      forEach(defaultLobList?.lineOfBusinesses?.data, (lob) => {
        tempArray?.push({
          indeterminate: !!(
            brandRes?.fetchTenantBrandLineOfBusinesses?.length > 0 &&
            filter(
              brandRes?.fetchTenantBrandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            )?.[0]?.subAreaIds?.length > 0 &&
            filter(
              brandRes?.fetchTenantBrandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            )?.[0].subAreaIds?.length !== lob?.subAreas?.length
          ),
          checkedAll: !!(
            brandRes?.fetchTenantBrandLineOfBusinesses?.length > 0 &&
            filter(
              brandRes?.fetchTenantBrandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            ) &&
            filter(
              brandRes?.fetchTenantBrandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            )?.[0]?.subAreaIds?.length === lob?.subAreas?.length
          ),
          checkedChildren:
            brandRes?.fetchTenantBrandLineOfBusinesses?.length > 0 &&
            filter(
              brandRes?.fetchTenantBrandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            )?.length > 0
              ? filter(
                  brandRes?.fetchTenantBrandLineOfBusinesses,
                  (item) => item?.lineOfBusinessId === lob?.id
                )?.[0]?.subAreaIds
              : [],
          id: lob?.id
        });
      });
      setLobList(tempArray);
    } else {
      forEach(defaultLobList?.lineOfBusinesses?.data, (lob) => {
        tempArray?.push({
          indeterminate: false,
          checkedAll: false,
          checkedChildren: [],
          id: lob?.id
        });
      });
      setLobList(tempArray);
    }
  };

  const [tenantBrandLob] = useLazyQuery(GET_TENANT_BRAND_LOB, {
    fetchPolicy: 'network-only',
    onCompleted: (brandRes) => {
      setLoading(false);
      const { fetchTenantBrandLineOfBusinesses: brandData } = brandRes;
      setBrandId(brandData?.[0]?.brandId);
      createLobArray(brandRes);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const { loading: lobLoading, data: defaultLob } = useQuery(GET_LOB_LIST, {
    variables: {
      filter: initialBrandLobFilter,
      where: {
        isActive: [true]
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLoading(false);
      setDefaultLobList(res);
      tenantBrandLob();
    },
    onError: () => {
      setLoading(false);
    }
  });

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <span className="portal-header">{MODULES?.BUSINESS_INFORMATION}</span>
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_RETAILER_SETTINGS_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="brand-table-save-btn"
            loading={submitLoading}
            type="primary"
            disabled={disabled}
            onClick={saveBrandLob}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>

      <CardWrapper>
        {lobLoading && loading ? (
          <LoaderComponent />
        ) : (
          <fieldset
            disabled={
              !checkPermissions(permissions, ['FET_RETAILER_SETTINGS_UPDATE'])
            }
          >
            <div>
              <div className="lob">
                <div className="d-flex justify-between flex-wrap block-parent">
                  {map(defaultLob?.lineOfBusinesses?.data, (lob, index) => {
                    return (
                      <div className="lob-block" key={index}>
                        <Checkbox
                          className="lob-checkbox"
                          id={lob?.id}
                          indeterminate={
                            checkBoxArray?.length > 0 &&
                            checkBoxArray?.[index]?.indeterminate
                          }
                          onChange={(e) => onCheckAllChange(e, lob, index)}
                          checked={
                            checkBoxArray?.length > 0 &&
                            checkBoxArray?.[index]?.checkedAll
                          }
                        >
                          <span className="bold-label">{lob?.label}</span>
                        </Checkbox>
                        <Divider />
                        <div className="d-flex">
                          <CheckboxGroup
                            options={map(lob?.subAreas, (area) => {
                              return {
                                label: area?.label,
                                value: area?.id
                              };
                            })}
                            value={
                              checkBoxArray?.length > 0 &&
                              checkBoxArray?.[index]?.checkedChildren
                            }
                            onChange={(list) => onChange(list, lob, index)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </fieldset>
        )}
      </CardWrapper>
    </Card>
  );
};

export default BrandSettingLob;
