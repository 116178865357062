import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Col, message, notification, Row, Skeleton } from 'antd';
import { get, map, sortBy, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import { DASHBOARD } from '../../../common/constants';
import {
  checkPermissions,
  handleCsvDownload,
  removeDuplicateData
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import Portal from '../../../components/Portal';
import { GET_CPQ_ANALYTICS_STATS } from '../graphql/Queries';
import ChartSection from './ChartSection';
import FunnelSection from './FunnelSection';
import NoChartData from './NoChartData';

const updateData = (data = null) => {
  // eslint-disable-next-line no-undef
  localStorage?.setItem(DASHBOARD, JSON?.stringify(data));
};

const getData = (key = '') => {
  // eslint-disable-next-line no-undef
  const data = JSON?.parse(localStorage?.getItem(DASHBOARD)) || null;
  if (key?.length > 0) {
    return get(data, key);
  }
  return data;
};

const colorsArray = [
  {
    'Started Questionnaire': '#36DBFF',
    'Provided Contact Info': '#3AC4FF',
    'Total Appointments': '#1859FF',
    'Total Sold': '#2926E2'
  },
  {
    'Started Questionnaire': '#FFA048',
    'Provided Contact Info': '#FF8845',
    'Total Appointments': '#EE501F',
    'Total Sold': '#D43A09'
  },
  {
    'Started Questionnaire': '#7A48AD',
    'Provided Contact Info': '#663C90',
    'Total Appointments': '#523073',
    'Total Sold': '#3D2456'
  },
  {
    'Started Questionnaire': '#41508B',
    'Provided Contact Info': '#34406F',
    'Total Appointments': '#273053',
    'Total Sold': '#1A2037'
  },
  {
    'Started Questionnaire': '#468684',
    'Provided Contact Info': '#386B69',
    'Total Appointments': '#2A504F',
    'Total Sold': '#1C3534'
  },
  {
    'Started Questionnaire': '#834952',
    'Provided Contact Info': '#683B42',
    'Total Appointments': '#4E2C31',
    'Total Sold': '#341D20'
  },
  {
    'Started Questionnaire': '#707543',
    'Provided Contact Info': '#585B34',
    'Total Appointments': '#3F4125',
    'Total Sold': '#262716'
  },
  {
    'Started Questionnaire': '#CC8500',
    'Provided Contact Info': '#A36A00',
    'Total Appointments': '#7A5000',
    'Total Sold': '#523500'
  },
  {
    'Started Questionnaire': '#AE311E',
    'Provided Contact Info': '#8B2818',
    'Total Appointments': '#681E12',
    'Total Sold': '#45140C'
  },
  {
    'Started Questionnaire': '#438947',
    'Provided Contact Info': '#366D39',
    'Total Appointments': '#28522A',
    'Total Sold': '#1B371C'
  },
  {
    'Started Questionnaire': '#194FB3',
    'Provided Contact Info': '#143F8F',
    'Total Appointments': '#0F2F6B',
    'Total Sold': '#0A1F47'
  },
  {
    'Started Questionnaire': '#CC005F',
    'Provided Contact Info': '#A3004C',
    'Total Appointments': '#7A0039',
    'Total Sold': '#520026'
  },
  {
    'Started Questionnaire': '#666666',
    'Provided Contact Info': '#525252',
    'Total Appointments': '#3D3D3D',
    'Total Sold': '#292929'
  },
  {
    'Started Questionnaire': '#EC9898',
    'Provided Contact Info': '#E67575',
    'Total Appointments': '#DF5353',
    'Total Sold': '#D62828'
  },
  {
    'Started Questionnaire': '#E090D9',
    'Provided Contact Info': '#D770CE',
    'Total Appointments': '#CE50C3',
    'Total Sold': '#BF36B4'
  }
];

const DashboardCharts = () => {
  const {
    dispatch,
    state: { dashboardConfig, permissions, showNotificationWarning }
  } = useContext(AppContext);

  const [timePeriodData] = useState([
    {
      key: 'LAST7DAYS',
      label: 'Last 7 Days'
    },
    {
      key: 'LAST30DAYS',
      label: 'Last 30 Days'
    },
    {
      key: 'CURRENTMONTH',
      label: 'Current Month'
    },
    {
      key: 'PREVIOUSMONTH',
      label: 'Previous Month'
    },
    {
      key: 'YEARTODATE',
      label: 'Year to Date'
    },
    {
      key: 'PREVYEAR',
      label: 'Previous Year'
    },
    {
      key: 'CUSTOM',
      label: 'Custom Date'
    }
  ]);
  const [funnelInfoData, setFunnelInfoData] = useState([
    {
      key: 'startedQuestionnaire',
      title: 'Started Questionnaire'
    },
    {
      key: 'providedContactInfo',
      title: 'Provided Contact Info'
    },
    {
      key: 'totalAppointments',
      title: 'Total Appointments'
    }
    // commented for now. will use in future
    // {
    //   key: 'totalSold',
    //   title: 'Total Sold'
    // }
  ]);
  const [chartData, setChartData] = useState([]);
  const [uniqueDateData, setUniqueDateData] = useState([]);
  const [colors, setColors] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const [loading, setLoading] = useState(true);

  const [timePeriodValue, setTimePeriodValue] = useState(
    getData('timePeriodValue') || 'LAST7DAYS'
  );
  const [funnelInfoValue, setFunnelInfoValue] = useState(
    getData('getStats')?.length > 0 ? getData('getStats') : []
  );
  const [selectedDate, setSelectedDate] = useState(
    getData('selectedDate')?.length > 0
      ? [
          moment(getData('selectedDate')?.[0]),
          moment(getData('selectedDate')?.[1])
        ]
      : []
  );
  const [chartType, setChartType] = useState('column');

  const [cpqAnalyticsStats] = useLazyQuery(GET_CPQ_ANALYTICS_STATS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const funnelConfigCopy = [
        {
          key: 'startedQuestionnaire',
          title: 'Started Questionnaire',
          ...res?.cpqAnalyticsStats?.startedQuestionnaire
        },
        {
          key: 'providedContactInfo',
          title: 'Provided Contact Info',
          ...res?.cpqAnalyticsStats?.providedContactInfo
        },
        {
          key: 'totalAppointments',
          title: 'Total Appointments',
          ...res?.cpqAnalyticsStats?.totalAppointments
        }
        // commented for now. will use in future
        // {
        //   key: 'totalSold',
        //   title: 'Total Sold',
        //   ...res?.cpqAnalyticsStats?.totalSold
        // }
      ];

      const result = sortBy(
        removeDuplicateData(res?.cpqAnalyticsStats?.data, [
          'questionnaire',
          'statsTitle'
        ]),
        'questionnaire'
      );

      let i = 0;

      const colorsCopy = map(result, (item, index) => {
        if (index === 0) {
          return colorsArray?.[i]?.[item?.statsTitle];
        }
        if (result?.[index - 1]?.questionnaire !== item?.questionnaire) {
          // eslint-disable-next-line no-plusplus
          ++i;
          return colorsArray?.[i]?.[item?.statsTitle];
        }
        return colorsArray?.[i]?.[item?.statsTitle];
      });

      const uniqueData = uniqBy(res?.cpqAnalyticsStats?.data, 'timePeriod');

      setChartData(sortBy(res?.cpqAnalyticsStats?.data, 'questionnaire'));
      setUniqueDateData(uniqueData);
      setColors(colorsCopy);
      setFunnelInfoData(funnelConfigCopy);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    const localStorageData = getData();
    if (!localStorageData) {
      dispatch({
        type: 'SET_DASHBOARD_CONFIG',
        data: {
          timeOfDay: {
            id: '4',
            title: 'All'
          }
        }
      });
    } else {
      dispatch({
        type: 'SET_DASHBOARD_CONFIG',
        data: localStorageData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_DASHBOARD_LIST']);
    if (permitted && !showNotificationWarning) {
      setLoading(true);
      cpqAnalyticsStats({
        variables: {
          where: {
            timeFunnel: dashboardConfig?.timePeriodValue || 'LAST7DAYS',
            questionniareId:
              dashboardConfig?.question?.length > 0
                ? map(dashboardConfig?.question, (item) => item?.id)
                : [],
            ...(timePeriodValue === 'CUSTOM' && {
              dateRange: selectedDate
            }),
            timeOffSet: moment()?.utcOffset(),
            timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
            browser:
              dashboardConfig?.browser?.length > 0
                ? map(dashboardConfig?.browser, (item) => item?.key)
                : [],
            regionId:
              dashboardConfig?.region?.length > 0
                ? map(dashboardConfig?.region, (item) => item?.id)
                : [],
            device:
              dashboardConfig?.device?.length > 0
                ? map(dashboardConfig?.device, (item) => item?.key)
                : [],
            cutOffTime: {
              ...(dashboardConfig?.timeOfDay?.time && {
                customTime: {
                  startTime: dashboardConfig?.timeOfDay?.time?.[0],
                  endTime: dashboardConfig?.timeOfDay?.time?.[1]
                }
              }),
              workingHoursOnly: dashboardConfig?.timeOfDay?.id === '1',
              beforeCutOffTime: dashboardConfig?.timeOfDay?.id === '2',
              afterCutOffTime: dashboardConfig?.timeOfDay?.id === '3',
              all: dashboardConfig?.timeOfDay?.id === '4'
            },
            getStats:
              dashboardConfig?.getStats?.length > 0
                ? dashboardConfig?.getStats
                : [],
            lineOfBusinessId:
              dashboardConfig?.lineOfBusiness?.length > 0
                ? map(dashboardConfig?.lineOfBusiness, (item) => item?.id)
                : [],
            subAreaId:
              dashboardConfig?.subArea?.length > 0
                ? map(dashboardConfig?.subArea, (item) => item?.key)
                : []
          }
        }
      });
    } else {
      setLoading(false);
    }
    if (showNotificationWarning) {
      notification?.warn({
        className: 'top-notification',
        description:
          'Please create/select some Lines of Business, Sub Areas, Regions, Products, and Packages for a seamless experience.'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardConfig]);

  const setFunnelInfoDataFunc = (value) => {
    setFunnelInfoValue(value);
    const newConfig = {
      ...dashboardConfig,
      getStats: value
    };
    dispatch({
      type: 'SET_DASHBOARD_CONFIG',
      data: newConfig
    });
    updateData(newConfig);
  };

  const handleChangeTimePeriod = (value) => {
    setTimePeriodValue(value);
    setSelectedDate([]);
    if (value !== 'CUSTOM') {
      const newConfig = {
        ...dashboardConfig,
        timePeriodValue: value,
        selectedDate: []
      };
      dispatch({
        type: 'SET_DASHBOARD_CONFIG',
        data: newConfig
      });
      updateData(newConfig);
    }
  };

  const handleChangeDate = (value) => {
    setSelectedDate([
      moment(value?.[0])?.startOf('day'),
      moment(value?.[1])?.endOf('day')
    ]);
    const newConfig = {
      ...dashboardConfig,
      timePeriodValue: 'CUSTOM',
      selectedDate: [
        moment(value?.[0])?.startOf('day'),
        moment(value?.[1])?.endOf('day')
      ]
    };
    dispatch({
      type: 'SET_DASHBOARD_CONFIG',
      data: newConfig
    });
    updateData(newConfig);
  };

  const handleChangeChart = () => {
    if (chartType === 'column') {
      setChartType('line');
    } else {
      setChartType('column');
    }
  };

  const handleExport = () => {
    if (!chartData?.length) {
      message?.destroy();
      message?.error('There are no records!');
      return;
    }
    if (timePeriodValue === 'CUSTOM' && !selectedDate?.length) {
      message?.destroy();
      message?.error('Please select date!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process?.env?.REACT_APP_SERVER_REST_URL}/export/cpq-analytics`,
      data: {
        where: {
          timeFunnel: dashboardConfig?.timePeriodValue || 'LAST7DAYS',
          questionniareId:
            dashboardConfig?.question?.length > 0
              ? map(dashboardConfig?.question, (item) => item?.id)
              : [],
          ...(timePeriodValue === 'CUSTOM' && {
            dateRange: selectedDate
          }),
          timeOffSet: moment()?.utcOffset(),
          timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
          browser:
            dashboardConfig?.browser?.length > 0
              ? map(dashboardConfig?.browser, (item) => item?.key)
              : [],
          regionId:
            dashboardConfig?.region?.length > 0
              ? map(dashboardConfig?.region, (item) => item?.id)
              : [],
          device:
            dashboardConfig?.device?.length > 0
              ? map(dashboardConfig?.device, (item) => item?.key)
              : [],
          cutOffTime: {
            ...(dashboardConfig?.timeOfDay?.time && {
              customTime: {
                startTime: dashboardConfig?.timeOfDay?.time?.[0],
                endTime: dashboardConfig?.timeOfDay?.time?.[1]
              }
            }),
            workingHoursOnly: dashboardConfig?.timeOfDay?.id === '1',
            beforeCutOffTime: dashboardConfig?.timeOfDay?.id === '2',
            afterCutOffTime: dashboardConfig?.timeOfDay?.id === '3',
            all: dashboardConfig?.timeOfDay?.id === '4'
          },
          getStats:
            dashboardConfig?.getStats?.length > 0
              ? dashboardConfig?.getStats
              : [],
          lineOfBusinessId:
            dashboardConfig?.lineOfBusiness?.length > 0
              ? map(dashboardConfig?.lineOfBusiness, (item) => item?.id)
              : [],
          subAreaId:
            dashboardConfig?.subArea?.length > 0
              ? map(dashboardConfig?.subArea, (item) => item?.key)
              : []
        }
      }
    })
      .then((res) => {
        handleCsvDownload(res?.data, 'CPQ Statistics.csv');
        setExportLoading(false);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  return (
    <div className="chart-section">
      <AccessControl allowedPermissions={['FET_DASHBOARD_EXPORT']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<UploadOutlined />}
            loading={exportLoading}
            size="small"
            id="export-stats-btn"
            type="primary"
            onClick={handleExport}
          >
            Export Stats
          </Button>
        </Portal>
      </AccessControl>
      <AccessControl allowedPermissions={['FET_DASHBOARD_LIST']} showNoAccess>
        <Row>
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 6 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <FunnelSection
              handleChangeTimePeriod={handleChangeTimePeriod}
              timePeriodValue={timePeriodValue}
              timePeriodData={timePeriodData}
              handleChangeDate={handleChangeDate}
              selectedDate={selectedDate}
              funnelInfoValue={funnelInfoValue}
              funnelInfoData={funnelInfoData}
              setFunnelInfoDataFunc={setFunnelInfoDataFunc}
            />
          </Col>
          <Col
            xxl={{ span: 18 }}
            xl={{ span: 18 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            {loading ? (
              <div className="skeleton-height">
                <Skeleton paragraph active />
              </div>
            ) : (
              <>
                {chartData?.length > 0 ? (
                  <ChartSection
                    handleChangeChart={handleChangeChart}
                    chartType={chartType}
                    chartData={chartData}
                    uniqueDateData={uniqueDateData}
                    colors={colors}
                    loading={loading}
                  />
                ) : (
                  <NoChartData />
                )}
              </>
            )}
          </Col>
        </Row>
      </AccessControl>
    </div>
  );
};

export default DashboardCharts;
