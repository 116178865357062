import { gql } from '@apollo/client';

export const GET_CAPACITIES = gql`
  query capacities($filter: CapacityFilter!, $where: CapacityWhereFilter) {
    capacities(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
        thumbnail {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const GET_CAPACITY = gql`
  query capacity($id: ID!) {
    capacity(where: { id: $id }) {
      id
      label
      key
      description
      order
      isActive
      logo {
        url
        key
        name
        extension
        contentType
      }
    }
  }
`;

export const CAPACITY_FILTER = gql`
  query capacityFilters($filter: CapacityFilter!) {
    capacityFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
