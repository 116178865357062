import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_PRICING_MODIFIER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PricingModifierCreate from './pages/PricingModifierCreate';
import PricingModifierEdit from './pages/PricingModifierEdit';
import PricingModifierList from './pages/PricingModifierList';
import './pricingModifierModule.less';

const PricingModifierWrapper = () => {
  return (
    <div className="pricing-modifier-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PRICING_MODIFIERS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRICING_MODIFIER}
              showNoAccess
            >
              <PricingModifierList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PRICING_MODIFIERS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_PRICING_MODIFIER_CREATE']}
              showNoAccess
            >
              <PricingModifierCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PRICING_MODIFIERS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={[
                'FET_PRICING_MODIFIER_UPDATE',
                'FET_PRICING_MODIFIER_VIEW'
              ]}
              showNoAccess
            >
              <PricingModifierEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default PricingModifierWrapper;
