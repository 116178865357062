import { DeleteOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Col, Collapse, Form, Row } from 'antd';
import { forEach } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { CPQ_MAX_LIMIT } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import TextAreaComponent from '../../../components/TextAreaComponent';
import { UPDATE_CUSTOMER_GOAL } from '../graphql/Mutations';

const { Panel } = Collapse;
const {
  required,
  url: urlCheck,
  requiredWhiteSpaceAllowed
} = formValidatorRules;
const { Option } = SelectComponent;

const CustomerGoalForm = ({
  initialValue,
  questionnaireId,
  setCustomerGoalConfig,
  showRepair = true,
  showUpgrade = true
}) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const [validationTriggered, setValidationTriggered] = useState(false);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'title',
    'upgrade_replacement',
    'repair',
    'footnote'
  ]);

  const [updateCustomerGoal] = useMutation(UPDATE_CUSTOMER_GOAL, {
    onCompleted() {
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  const onValuesChange = (changedValues, allValues) => {
    setCustomerGoalConfig({
      ...allValues
    });
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onFinish = (values) => {
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setDisableBtn(true);
    setLoading(true);
    const newUpgradeBulletPoints = [];
    const newRepairBulletPoints = [];
    forEach(values?.upgradeBulletPoints, (bullet) => {
      newUpgradeBulletPoints?.push(bullet?.upgradeBulletPoint);
    });
    forEach(values?.repairBulletPoints, (bullet) => {
      newRepairBulletPoints?.push(bullet?.repairBulletPoint);
    });

    const repairObj = {
      enable: values?.repair,
      show: showRepair,
      description: values?.repairDescription,
      videoTutorial: values?.url,
      summary: newRepairBulletPoints,
      buttonLabel: values?.repairButtonLabel
    };

    const upgradeReplacementObj = {
      enable: values?.upgrade_replacement,
      show: showUpgrade,
      description: values?.upgradeDescription,
      summary: newUpgradeBulletPoints,
      replaceButtonLabel: values?.replaceBtnLabel,
      upgradeButtonLabel: values?.upgradeBtnLabel
    };
    const tempObj = {
      title: values?.title,
      skip: initialValue?.skip,
      upgradeReplacement: showUpgrade ? upgradeReplacementObj : null,
      repair: showRepair ? repairObj : null,
      footShowLocation: values?.showLocation
    };

    updateCustomerGoal({
      variables: {
        data: tempObj,
        where: {
          questionnaireId
        }
      }
    });
  };

  return (
    <div>
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="customer-goal-save-btn"
            loading={loading}
            disabled={disableBtn}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={initialValue}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        className="customer-goal-form"
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_QUESTIONNAIRE_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="TITLE" key="title">
              <Row>
                <Col xs={24} sm={24} md={8} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Title"
                    rules={[{ ...required, message: 'Please Enter Title' }]}
                    name="title"
                  >
                    <InputComponent placeholder="Enter Title" />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            {showUpgrade && (
              <Panel
                forceRender
                header="UPGRADE & REPLACEMENT"
                key="upgrade_replacement"
              >
                <Form.Item name="upgrade_replacement" valuePropName="checked">
                  <Checkbox className="common-checkbox customer-goal-checkbox">
                    Upgrade & Replacement
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  label="Short Description"
                  rules={[
                    { ...required, message: 'Please Enter Short Description' }
                  ]}
                  name="upgradeDescription"
                >
                  <TextAreaComponent
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    className="common-textarea"
                    placeholder="Enter Description"
                  />
                </Form.Item>
                <div className="bullet-section">
                  <span className="bullet-text">Bullet point list</span>
                  <Form.List name="upgradeBulletPoints">
                    {(fields, { add, remove }) => (
                      <>
                        {fields?.map(
                          ({ key, name, fieldKey, ...restField }, index) => (
                            <div key={key} className="d-flex align-baseline">
                              <Form.Item
                                className="fill-width"
                                {...restField}
                                name={[name, 'upgradeBulletPoint']}
                                fieldKey={[fieldKey, 'upgradeBulletPoint']}
                                rules={[required]}
                              >
                                <InputComponent placeholder="Add point" />
                              </Form.Item>
                              {index > 1 && (
                                <DeleteOutlined
                                  className="delete-icon"
                                  onClick={() => remove(name)}
                                />
                              )}
                            </div>
                          )
                        )}
                        <Button
                          icon={<PlusOutlined />}
                          onClick={() => {
                            if (fields?.length < CPQ_MAX_LIMIT) {
                              add();
                            }
                          }}
                          type="link"
                          className="add-link-btn"
                        >
                          Add
                        </Button>
                      </>
                    )}
                  </Form.List>
                </div>
                <Form.Item
                  label="Button"
                  name="replaceBtnLabel"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Label'
                    }
                  ]}
                >
                  <SelectComponent>
                    <Option key="REPLACE" value="Replace">
                      Replace
                    </Option>
                    <Option key="CHANGE" value="Change">
                      Change
                    </Option>
                  </SelectComponent>
                </Form.Item>
                <Form.Item
                  label="Button"
                  name="upgradeBtnLabel"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Label'
                    }
                  ]}
                >
                  <SelectComponent>
                    <Option key="UPGRADE" value="Upgrade">
                      Upgrade
                    </Option>
                    <Option key="ADVANCE" value="Advance">
                      Advance
                    </Option>
                  </SelectComponent>
                </Form.Item>
              </Panel>
            )}
            {showRepair && (
              <Panel forceRender header="REPAIR" key="repair">
                <Form.Item name="repair" valuePropName="checked">
                  <Checkbox className="common-checkbox customer-goal-checkbox">
                    Repair
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  label="Short Description"
                  rules={[
                    { ...required, message: 'Please Enter Short Description' }
                  ]}
                  name="repairDescription"
                >
                  <TextAreaComponent
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    className="common-textarea"
                    placeholder="Enter Description"
                  />
                </Form.Item>
                <div className="bullet-section">
                  <span className="bullet-text">Bullet point list</span>
                  <Form.List name="repairBulletPoints">
                    {(fields, { add, remove }) => (
                      <>
                        {fields?.map(
                          ({ key, name, fieldKey, ...restField }, index) => (
                            <div key={key} className="d-flex align-baseline">
                              <Form.Item
                                className="fill-width"
                                {...restField}
                                name={[name, 'repairBulletPoint']}
                                fieldKey={[fieldKey, 'repairBulletPoint']}
                                rules={[required]}
                              >
                                <InputComponent placeholder="Add point" />
                              </Form.Item>
                              {index > 1 && (
                                <DeleteOutlined
                                  className="delete-icon"
                                  onClick={() => remove(name)}
                                />
                              )}
                            </div>
                          )
                        )}
                        <Button
                          icon={<PlusOutlined />}
                          onClick={() => {
                            if (fields?.length < CPQ_MAX_LIMIT) {
                              add();
                            }
                          }}
                          type="link"
                          className="add-link-btn"
                        >
                          Add
                        </Button>
                      </>
                    )}
                  </Form.List>
                </div>
                <Form.Item
                  label="Video URL"
                  rules={[required, urlCheck]}
                  name="url"
                >
                  <InputComponent placeholder="Enter video URL" />
                </Form.Item>
                <Form.Item
                  label="Label"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Label'
                    }
                  ]}
                  name="repairButtonLabel"
                >
                  <SelectComponent>
                    <Option key="BEGIN" value="Begin">
                      Begin
                    </Option>
                    <Option key="START" value="Start">
                      Start
                    </Option>
                    <Option key="CONTINUE" value="Continue">
                      Continue
                    </Option>
                    <Option key="NEXT" value="Next">
                      Next
                    </Option>
                  </SelectComponent>
                </Form.Item>
              </Panel>
            )}
            <Panel forceRender header="FOOTNOTE" key="footnote">
              <Form.Item name="showLocation" valuePropName="checked">
                <Checkbox className="common-checkbox customer-goal-checkbox">
                  Location
                </Checkbox>
              </Form.Item>
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};

export default CustomerGoalForm;
