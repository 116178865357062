import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  PERMISSION_LOB,
  PERMISSION_SUBAREA,
  ROUTES
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './lineofBusinessModule.less';
import LineOfBusinessCreate from './pages/LineOfBusinessCreate';
import LineOfBusinessEdit from './pages/LineOfBusinessEdit';
import LineOfBusinessList from './pages/LineOfBusinessList';
import SubAreaCreate from './pages/SubAreaCreate';
import SubAreaEdit from './pages/SubAreaEdit';
import SubAreaList from './pages/SubAreaList';

const LineOfBusinessWrapper = () => {
  return (
    <div className="line-of-business-module">
      <Switch>
        <>
          <Route
            exact
            path={ROUTES?.LINE_OF_BUSINESSES}
            render={(props) => (
              <AccessControl allowedPermissions={PERMISSION_LOB} showNoAccess>
                <LineOfBusinessList {...props} />
              </AccessControl>
            )}
          />
          <Route
            path={`${ROUTES?.LINE_OF_BUSINESSES}/add`}
            render={(props) => (
              <AccessControl
                allowedPermissions={['FET_LINE_OF_BUSINESS_CREATE']}
                showNoAccess
              >
                <LineOfBusinessCreate {...props} />
              </AccessControl>
            )}
          />
          <Route
            path={`${ROUTES?.LINE_OF_BUSINESSES}/edit/:lobId`}
            render={(props) => (
              <AccessControl
                allowedPermissions={[
                  'FET_LINE_OF_BUSINESS_UPDATE',
                  'FET_LINE_OF_BUSINESS_VIEW'
                ]}
                showNoAccess
              >
                <LineOfBusinessEdit {...props} />
              </AccessControl>
            )}
          />
          <Route
            exact
            path={`${ROUTES?.LINE_OF_BUSINESSES}/:lobId${ROUTES?.SUB_AREAS}`}
            render={(props) => (
              <AccessControl
                allowedPermissions={PERMISSION_SUBAREA}
                showNoAccess
              >
                <SubAreaList {...props} />
              </AccessControl>
            )}
          />
          <Route
            path={`${ROUTES?.LINE_OF_BUSINESSES}/:lobId${ROUTES?.SUB_AREAS}/add`}
            render={(props) => (
              <AccessControl
                allowedPermissions={['FET_SUBAREA_CREATE']}
                showNoAccess
              >
                <SubAreaCreate {...props} />
              </AccessControl>
            )}
          />
          <Route
            path={`${ROUTES?.LINE_OF_BUSINESSES}/:lobId${ROUTES?.SUB_AREAS}/edit/:subId`}
            render={(props) => (
              <AccessControl
                allowedPermissions={['FET_SUBAREA_UPDATE', 'FET_SUBAREA_VIEW']}
                showNoAccess
              >
                <SubAreaEdit {...props} />
              </AccessControl>
            )}
          />
        </>
      </Switch>
    </div>
  );
};

export default LineOfBusinessWrapper;
