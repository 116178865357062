import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import gallerySvg from '../../../assets/gallery.svg';
import { ROUTES } from '../../../common/constants';

const { TabPane } = Tabs;

const PropertyTabs = ({ match: { params: { id } = {} } = {}, children }) => {
  const location = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState('INFORMATION');

  const infoTitle = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">Information</span>
    </div>
  );
  const infoTitleCustomer = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">Customer</span>
    </div>
  );

  const infoTitleContacts = (
    <div>
      <img src={gallerySvg} alt="info-icon" />
      <span className="card-title">Contacts</span>
    </div>
  );

  useEffect(() => {
    if (
      location?.pathname?.includes('/edit') ||
      location?.pathname?.includes('/view') ||
      location?.pathname?.includes('/add')
    ) {
      setActiveKey('INFORMATION');
    }
    if (location?.pathname?.includes('customer')) {
      setActiveKey('CUSTOMER');
    }
    if (location?.pathname?.includes('contacts')) {
      setActiveKey('CONTACTS');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTab = (key) => {
    setActiveKey(key);
    switch (key) {
      case 'INFORMATION':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PROPERTIES}/edit/${id}`);
        } else {
          history?.push(`${ROUTES?.PROPERTIES}/view/${id}`);
        }
        break;

      case 'CUSTOMER':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PROPERTIES}/edit${ROUTES?.CUSTOMERS}/${id}`);
        } else {
          history?.push(`${ROUTES?.PROPERTIES}/view${ROUTES?.CUSTOMERS}/${id}`);
        }
        break;

      case 'CONTACTS':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PROPERTIES}/edit${ROUTES?.CONTACTS}/${id}`);
        } else {
          history?.push(`${ROUTES?.PROPERTIES}/view${ROUTES?.CONTACTS}/${id}`);
        }
        break;

      default:
        break;
    }
  };
  return (
    <div className="card-container">
      <Tabs type="card" activeKey={activeKey} onChange={handleSelectTab}>
        <TabPane tab={infoTitle} key="INFORMATION">
          {children}
        </TabPane>
        {!location?.pathname?.includes('/add') && (
          <TabPane tab={infoTitleCustomer} key="CUSTOMER">
            {children}
          </TabPane>
        )}
        {!location?.pathname?.includes('/add') && (
          <TabPane tab={infoTitleContacts} key="CONTACTS">
            {children}
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default withRouter(PropertyTabs);
