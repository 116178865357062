import { useQuery } from '@apollo/client';
import { Button, Card, Empty } from 'antd';
import { map } from 'lodash';
import React, { useContext, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import { checkPermissions } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import GalleryComponent from '../../../components/GalleryComponent';
import GoBackButton from '../../../components/GoBackButton';
import { GET_ATTACHMENTS } from '../../../components/graphql/Query';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ProductTabs from '../components/ProductTabs';
import { GET_PRODUCT } from '../graphql/Queries';

const ProductGallery = (props) => {
  const [loading, setLoading] = useState(true);

  const {
    state: { currentUser, permissions }
  } = useContext(AppContext);
  const location = useLocation();

  const { match: { params: { id } = {} } = {}, history } = props;

  const { data: { product } = {} } = useQuery(GET_PRODUCT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  const { data: { attachments } = {}, refetch } = useQuery(GET_ATTACHMENTS, {
    variables: {
      filter: {
        type: 'IMAGE',
        referenceId: id,
        referenceKey: 'PRODUCT',
        skip: 0
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const files = map(attachments?.data, (item) => {
    return {
      id: item?.id,
      url: item?.url,
      label: item?.label
    };
  });

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PACKAGES} />
          {product && <span className="portal-header">{product?.title}</span>}
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_PRODUCT_UPDATE']}>
        {location?.pathname?.includes('/view') && (
          <Portal portalId="header-right-content">
            <Button
              className="common-button"
              icon={<img src={EditIcon} alt="save-icon" width={12} />}
              size="small"
              type="primary"
              onClick={() =>
                history?.push(
                  `${ROUTES?.PACKAGES}/edit${ROUTES?.GALLERY}/${id}`
                )
              }
            >
              Edit
            </Button>
          </Portal>
        )}
      </AccessControl>
      <ProductTabs>
        {loading ? (
          <LoaderComponent />
        ) : (
          <>
            {(location?.pathname?.includes('/view') ||
              !checkPermissions(permissions, ['FET_PRODUCT_UPDATE'])) &&
            files?.length < 1 ? (
              <div className="no-data-image">
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : (
              <GalleryComponent
                refetch={refetch}
                recordId={id}
                module="products"
                moduleKey="PRODUCT"
                fileListProps={files || []}
                uuid={product?.uuid}
                tenantId={currentUser?.tenantId}
                isEdit={
                  !location?.pathname?.includes('/view') &&
                  checkPermissions(permissions, ['FET_PRODUCT_UPDATE'])
                }
              />
            )}
          </>
        )}
      </ProductTabs>
    </Card>
  );
};

export default withRouter(ProductGallery);
