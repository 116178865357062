import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, message, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import CheckedIconComponent from '../../app/components/iconComponents/CheckedIconComponent';
import notChecked from '../../assets/images/notChecked.svg';
import { REGEX, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import InputComponent from '../../components/InputComponent';
import LoaderComponent from '../../components/LoaderComponent';
import './authModule.less';
import { RESET_PASSWORD, TOKEN_VALIDATION } from './graphql/Mutations';

const { required } = formValidatorRules;

const ChangePassword = (props) => {
  const [loading, setLoading] = useState(true);
  const { history, history: { location: { search } = {} } = {} } = props;
  const [displayErrorList, setDisplayErrorList] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lengthValidation, setLengthValidation] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);
  const [specialCharValidation, setSpecialCharValidation] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const getQuery = () => {
    return new URLSearchParams(search);
  };

  const token = getQuery()?.get('auth_token');
  const id = getQuery()?.get('uid');

  const [tokenValidationMutate] = useMutation(TOKEN_VALIDATION, {
    async onCompleted() {
      setLoading(false);
    },
    onError() {
      setLoading(false);
      history?.replace(ROUTES?.LOGIN);
    }
  });

  const [resetPasswordMutate] = useMutation(RESET_PASSWORD, {
    onCompleted() {
      history?.replace(ROUTES?.LOGIN);
    },
    onError() {
      setButtonLoading(false);
    }
  });

  useEffect(() => {
    if (token && id) {
      tokenValidationMutate({
        variables: {
          data: {
            id,
            token
          }
        }
      });
    } else {
      history?.replace(ROUTES?.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async ({ password }) => {
    setButtonLoading(true);
    if (
      numberValidation &&
      specialCharValidation &&
      upperCase &&
      lengthValidation
    ) {
      resetPasswordMutate({
        variables: { data: { id, token, password } }
      });
    } else {
      setButtonLoading(false);
      message?.error('Your Password Does Not Meet Requirements.');
    }
  };

  if (loading) {
    return (
      <div className="gx-login-container">
        <LoaderComponent spinning />
      </div>
    );
  }

  const handleChange = (e) => {
    const {
      target: { value }
    } = e;
    if (value?.match(REGEX?.UPPER_CASE)) {
      setUpperCase(true);
    } else {
      setUpperCase(false);
    }

    if (value?.length < 8 || value?.length > 16) {
      setLengthValidation(false);
    } else {
      setLengthValidation(true);
    }

    if (value?.match(REGEX?.SPECIAL_CHAR)) {
      setSpecialCharValidation(true);
    } else {
      setSpecialCharValidation(false);
    }

    if (value?.match(REGEX?.DIGIT)) {
      setNumberValidation(true);
    } else {
      setNumberValidation(false);
    }
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content change-password-width">
        <div className="gx-mb-4">
          <h2>Change Password</h2>
          <p>Enter a new password for your account</p>
        </div>
        <LoaderComponent spinning={false}>
          <Form
            name="change-password"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item
              name="password"
              rules={[required]}
              normalize={(value) => value?.split(' ')?.join('')}
            >
              <InputComponent.Password
                className="password-input"
                onChange={(e) => handleChange(e)}
                prefix={<LockOutlined />}
                placeholder="Password"
                onFocus={() => setDisplayErrorList(true)}
              />
            </Form.Item>
            {displayErrorList && (
              <Col md={24} lg={24} xl={24}>
                <div>
                  <p className="password-warning">
                    {upperCase ? (
                      <CheckedIconComponent className="primary-color font-size-22 mr-13" />
                    ) : (
                      <img
                        alt="password validation"
                        className="mr-13"
                        src={notChecked}
                        height="22px"
                        width="22px"
                      />
                    )}
                    1 or More Capital Characters
                  </p>
                  <p className="password-warning">
                    {lengthValidation ? (
                      <CheckedIconComponent className="primary-color font-size-22 mr-13" />
                    ) : (
                      <img
                        className="mr-13"
                        alt="password validation"
                        src={notChecked}
                        height="22px"
                        width="22px"
                      />
                    )}
                    length should be between 8 to 16 characters
                  </p>
                  <p className="password-warning">
                    {numberValidation ? (
                      <CheckedIconComponent className="primary-color font-size-22 mr-13" />
                    ) : (
                      <img
                        className="mr-13"
                        alt="password validation"
                        src={notChecked}
                        height="22px"
                        width="22px"
                      />
                    )}
                    1 or More Numbers
                  </p>
                  <p className="password-warning">
                    {specialCharValidation ? (
                      <CheckedIconComponent className="primary-color font-size-22 mr-13" />
                    ) : (
                      <img
                        className="mr-13"
                        alt="password validation"
                        src={notChecked}
                        height="22px"
                        width="22px"
                      />
                    )}
                    1 or More Special Characters (!@#$%^&*)
                  </p>
                </div>
              </Col>
            )}
            <Form.Item
              name="retype-password"
              normalize={(value) => value?.split(' ')?.join('')}
              rules={[
                required,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && value !== getFieldValue('password')) {
                      return Promise?.reject(
                        new Error('Passwords do not match')
                      );
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <InputComponent.Password
                className="password-input"
                prefix={<LockOutlined />}
                placeholder="Retype password"
                onFocus={() => setDisplayErrorList(false)}
              />
            </Form.Item>
            <div className="d-flex">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="primary"
                    className="mr-2 common-button font-size-14"
                    htmlType="submit"
                  >
                    Change Password
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="common-button discard-button font-size-14"
                    onClick={() => {
                      history?.push(ROUTES?.LOGIN);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </LoaderComponent>
      </div>
    </div>
  );
};

export default ChangePassword;
