import { Card } from 'antd';
import React from 'react';
import RegionForm from '../components/RegionForm';
import CardWrapper from '../components/RegionTabs';

function RegionEdit() {
  return (
    <Card className="full-height-card card-body-p-24">
      <CardWrapper>
        <RegionForm isEdit />
      </CardWrapper>
    </Card>
  );
}

export default RegionEdit;
