import { Card } from 'antd';
import React from 'react';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import RegionForm from '../components/RegionForm';
import CardWrapper from '../components/RegionTabs';

function RegionCreate() {
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.REGIONS} />
          <span className="portal-header">Add Region</span>
        </div>
      </Portal>
      <CardWrapper>
        <RegionForm />
      </CardWrapper>
    </Card>
  );
}

export default RegionCreate;
