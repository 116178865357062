import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ProductItemForm from '../components/ProductItemForm';
import CardWrapper from '../components/ProductItemTabs';
import { UPDATE_PRODUCT_ITEM } from '../graphql/Mutations';
import { GET_PRODUCT_ITEM } from '../graphql/Queries';

function ProductItemEdit(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [productItemData, setProductItemData] = useState({});
  const { data: { productItem } = {} } = useQuery(GET_PRODUCT_ITEM, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    setProductItemData({
      ...productItem,
      quoteDescription: productItem?.quoteDescription || '',
      productCategories: productItem?.productCategoryId,
      description: productItem?.description || ''
    });
  }, [productItem]);

  const [updateProductItem] = useMutation(UPDATE_PRODUCT_ITEM, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues,
      uuid: productItem?.uuid,
      productCategoryId: formValues?.productCategories || null,
      fuelSource: formValues?.fuelSource || null,
      ventType: formValues?.ventType || null,
      productType: formValues?.productType || null,
      capacity: formValues?.capacity || null,
      productItemWarranty: parseFloat(formValues?.productItemWarranty),
      labourWarranty: parseFloat(formValues?.labourWarranty),
      hsn: formValues?.hsn,
      stock: Number(formValues?.stock),
      heightInNumber: Number(formValues?.heightInNumber)
    };

    delete newFormValues?.productCategories;

    const variables = { data: newFormValues, where: { id: productItem?.id } };

    try {
      const response = await updateProductItem({
        variables: { ...variables }
      });
      if (response?.data?.updateProductItem) {
        setSubmitLoading(false);
        history?.push(ROUTES?.PRODUCTS);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.PRODUCTS} />;
  }

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRODUCTS} />
          {productItem && (
            <span className="portal-header">{productItem?.name}</span>
          )}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ProductItemForm
            isEdit
            productItemData={productItemData || {}}
            handleProductItemForm={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(ProductItemEdit);
