import React, { useState } from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import ScheduledAppointmentComponent from '../components/previewComponents/scheduledAppointment/components/ScheduledAppointmentComponent';
import ScheduledAppointmentForm from '../components/ScheduledAppointmentForm';
import CpqTemplate from './CpqTemplate';

const ScheduledAppointmentPage = () => {
  const [scheduledConfig, setScheduledConfig] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  return (
    <CpqTemplate
      centerContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ScheduledAppointmentComponent
            scheduledConfig={scheduledConfig}
            logoUrl={logoUrl}
          />
        )
      }
      rightSideContent={
        <ScheduledAppointmentForm
          scheduledConfig={scheduledConfig}
          setScheduledConfig={setScheduledConfig}
          loading={loading}
          setLoading={setLoading}
          setLogoUrl={setLogoUrl}
        />
      }
    />
  );
};

export default ScheduledAppointmentPage;
