import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PropertyFinishedBasementCreate from './pages/PropertyFinishedBasementCreate';
import PropertyFinishedBasementEdit from './pages/PropertyFinishedBasementEdit';
import PropertyFinishedBasementList from './pages/PropertyFinishedBasementList';
import './propertyFinishedBasementModule.less';

const PropertyFinishedBasementWrapper = () => {
  return (
    <div className="property-finished-basement-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PROPERTY_FINISHED_BASEMENTS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyFinishedBasementList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PROPERTY_FINISHED_BASEMENTS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <PropertyFinishedBasementCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PROPERTY_FINISHED_BASEMENTS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <PropertyFinishedBasementEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default PropertyFinishedBasementWrapper;
