import { CheckCircleTwoTone } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Empty,
  Menu,
  message,
  Row,
  Table,
  Tag
} from 'antd';
import { capitalize, debounce, filter, forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import { AppContext } from '../../../AppContext';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import api from '../../../common/api';
import { SKIP_RECORD } from '../../../common/constants';
import {
  dateFormatWithoutTime,
  formatDate,
  formatPrice,
  handleCsvDownload
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import PriceComponent from '../../../components/PriceComponent';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { BRAND_FILTER } from '../../myQuestionnaire/graphql/Queries';
import { SUB_AREA_FILTER } from '../../products/graphql/Queries';
import { REGION_FILTER } from '../../regions/graphql/Queries';
import { GET_QUOTE_VIEWS } from '../graphql/Queries';
import ExpandedDetails from './ExpandedDetails';

let scrollDebounce = null;

const QuoteViewerTable = () => {
  const {
    state: { pageSize, currentUser, filterData, globalDateFormat },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialQuoteViewerFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [quoteViewerFilter, setQuoteViewerFilter] = useState(
    initialQuoteViewerFilter
  );
  const [filters, setFilters] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  const [quoteViews, { loading, data }] = useLazyQuery(GET_QUOTE_VIEWS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.quoteViews?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [subAreaFilters] = useLazyQuery(SUB_AREA_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.label)
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.subAreaFilters?.data, (item) =>
          optionsCopy?.push(item?.label)
        );
        if (res?.subAreaFilters?.data?.length < SKIP_RECORD) {
          setFilterIsEnd(true);
        }
        setFilterList(optionsCopy);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [brandFilters] = useLazyQuery(BRAND_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push(item?.name)
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push(item?.name)
        );
        setFilterList(optionsCopy);
      }
      if (res?.questionnaireFilters?.data?.length < SKIP_RECORD) {
        setFilterIsEnd(true);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [regionFilters] = useLazyQuery(REGION_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.regionFilters?.data, (item) =>
          optionsCopy?.push(item?.name)
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.regionFilters?.data, (item) =>
          optionsCopy?.push(item?.name)
        );
        setFilterList(optionsCopy);
      }
      if (res?.regionFilters?.data?.length < SKIP_RECORD) {
        setFilterIsEnd(true);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    quoteViews({
      variables: {
        filter: quoteViewerFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm, dataIndex) => {
    if (dataIndex === 'amount') {
      if (
        !filtersCopyState?.amount?.min?.toString() ||
        !filtersCopyState?.amount?.max?.toString()
      ) {
        message?.destroy();
        message?.error('Please enter values');
        return;
      }
      if (filtersCopyState?.amount?.min < 0) {
        return message?.error('Min value can not be less than zero');
      }
      if (filtersCopyState?.amount?.max < 0) {
        return message?.error('Max value can not be less than zero');
      }

      if (filtersCopyState?.amount?.min >= filtersCopyState?.amount?.max) {
        message?.destroy();
        message?.error(
          'Min value can not be greater than or equal to max value'
        );
        return;
      }
    }
    quoteViews({
      variables: {
        filter: { ...quoteViewerFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setQuoteViewerFilter({
      ...quoteViewerFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'subArea':
          subAreaFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                getDBField: 'label'
              }
            }
          });
          break;
        case 'brand':
          brandFilters({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'name'
              }
            }
          });
          break;
        case 'region':
          regionFilters({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: 'name'
              }
            }
          });
          break;
        default:
          if (filterIndex === 'exportStatus') {
            const optionsCopy = [
              {
                key: 'SUCCESS',
                label: 'Success'
              },
              {
                key: 'PENDING',
                label: 'Pending'
              }
            ];
            setFilterList(optionsCopy);
          }
          setFilterLoading(false);
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'subArea':
            subAreaFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  getDBField: 'label'
                }
              }
            });
            break;
          case 'brand':
            brandFilters({
              variables: {
                filter: {
                  sortOn: 'name',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'name'
                }
              }
            });
            break;
          case 'region':
            regionFilters({
              variables: {
                filter: {
                  sortOn: 'name',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: 'name'
                }
              }
            });
            break;
          default:
            setFilterLoading(false);
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    }
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    if (dataIndex === 'createdAt' || dataIndex === 'amount') {
      delete filtersCopy?.[dataIndex];
    }
    setFilters(filtersCopy);
    quoteViews({
      variables: {
        filter: {
          ...quoteViewerFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setQuoteViewerFilter({
      ...quoteViewerFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setQuoteViewerFilter({
        ...quoteViewerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      quoteViews({
        variables: {
          filter: {
            ...quoteViewerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setQuoteViewerFilter({
        ...quoteViewerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      quoteViews({
        variables: {
          filter: {
            ...quoteViewerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'subArea':
        subAreaFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              getDBField: 'label'
            }
          }
        });
        break;
      case 'brand':
        brandFilters({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'name'
            }
          }
        });
        break;
      case 'region':
        regionFilters({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: 'name'
            }
          }
        });
        break;
      default:
        setFilterLoading(false);
        break;
    }
  };

  const handleChangeAmount = (value, input, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: { ...filtersCopyState?.[dataIndex], [input]: value }
    };
    setFiltersCopyState(filtersCopy);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: moment(values?.[0])?.startOf('day'),
      to: moment(values?.[1])?.endOf('day')
    };

    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj
    };
    if (!values?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              format={dateFormatWithoutTime(globalDateFormat)}
              value={
                filtersCopyState?.[dataIndex]
                  ? [
                      filtersCopyState?.[dataIndex]?.from,
                      filtersCopyState?.[dataIndex]?.to
                    ]
                  : []
              }
              onChange={(values) => onRangePickerChange(values, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="date-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      if (dataIndex === 'amount') {
        return (
          <div className="custom-filter-dropdown amount-filter-dropdown">
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.amount
                      ? filtersCopyState?.amount?.min
                      : false
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'min', dataIndex)
                  }
                  placeholder="min"
                />
              </Col>
              <Col span={12}>
                <PriceComponent
                  value={
                    filtersCopyState?.amount
                      ? filtersCopyState?.amount?.max
                      : false
                  }
                  onChange={(value) =>
                    handleChangeAmount(value, 'max', dataIndex)
                  }
                  placeholder="max"
                />
              </Col>
            </Row>
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="date-filter-ok"
                htmlType="submit"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'exportStatus' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => {
                  return (
                    <Tag
                      key={item?.toString()}
                      closable
                      onClose={() => handleDeleteFilter(item, dataIndex)}
                      className="filter-tag"
                    >
                      {dataIndex === 'exportStatus' ? (
                        <span
                          title={item === 'SUCCESS' ? 'Success' : 'Pending'}
                        >
                          {item === 'SUCCESS' ? 'Success' : 'Pending'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Tag>
                  );
                })}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => {
                  return (
                    <div
                      className="single-checkbox-div"
                      key={item?.key || item}
                    >
                      <Checkbox
                        value={item?.key || item}
                        checked={filtersCopyState?.[dataIndex]?.includes(
                          item?.key || item
                        )}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        {dataIndex === 'exportStatus' ? (
                          <span
                            title={
                              item?.key === 'SUCCESS' ? 'Success' : 'Pending'
                            }
                          >
                            {item?.key === 'SUCCESS' ? 'Success' : 'Pending'}
                          </span>
                        ) : (
                          <span title={item?.label || item?.toString()}>
                            {item?.label || item?.toString()}
                          </span>
                        )}
                      </Checkbox>
                    </div>
                  );
                })
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const columns = [
    Table?.SELECTION_COLUMN,
    Table?.EXPAND_COLUMN,
    {
      title: 'DATE & TIME',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 200,
      fixed: 'left',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      className: 'max-width-column date-column-min-width',
      ...filterPopup('createdAt'),
      render: (updatedAt) => {
        if (updatedAt) {
          return <span>{formatDate(updatedAt, globalDateFormat)}</span>;
        }
        return '-';
      }
    },
    {
      title: 'LEAD/OPPTY/CRM ID',
      dataIndex: 'leadId',
      key: 'leadId',
      ellipsis: true,
      width: 200,
      className: 'max-width-column'
    },
    {
      title: 'LINE OF BUSINESS & SUB AREA',
      dataIndex: 'subAreaId',
      key: 'subAreaId',
      sorter: true,
      className: 'max-width-column',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'subAreaId' && sortedInfo?.order,
      width: 250,
      ...filterPopup('subArea'),
      render: (subAreaId, record) => (
        <span
          title={`${record?.lineOfBusiness?.label} - ${record?.subArea?.label}`}
        >
          {record?.lineOfBusiness?.label} - {record?.subArea?.label}
        </span>
      )
    },
    {
      title: 'CUSTOMER NAME',
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      ellipsis: true,
      width: 200,
      sortOrder: sortedInfo?.columnKey === 'customerName' && sortedInfo?.order,
      render: (customerName) => <span>{customerName || '-'}</span>
    },
    {
      title: 'RETAILERS',
      dataIndex: 'brandId',
      key: 'brandId',
      sorter: true,
      width: 150,
      className: 'max-width-column',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'brandId' && sortedInfo?.order,
      ...filterPopup('brand'),
      render: (brandId, record) => {
        return (
          <>
            <img
              src={record?.brand?.logo?.url || placeholderImage}
              alt="brand-img"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
              className="list-img image-contain"
              width={24}
              height={24}
            />
            <span title={record?.brand?.name}>{record?.brand?.name}</span>
          </>
        );
      }
    },
    {
      title: 'REGION',
      dataIndex: 'regionId',
      key: 'regionId',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'regionId' && sortedInfo?.order,
      width: 120,
      ...filterPopup('region'),
      render: (regionId, record) => <span>{record?.region?.name}</span>
    },
    {
      title: 'QUOTE AMT.',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      align: 'right',
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'amount' && sortedInfo?.order,
      width: 150,
      ...filterPopup('amount'),
      render: (amount = null) => (
        <span title={formatPrice(amount)}>
          {amount ? formatPrice(amount) : '-'}
        </span>
      )
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: 170,
      ellipsis: true,
      render: (status) => {
        return capitalize(status);
      }
    },
    {
      title: 'EXPORT STATUS',
      dataIndex: 'exportStatus',
      key: 'exportStatus',
      fixed: 'right',
      width: 170,
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'exportStatus' && sortedInfo?.order,
      ...filterPopup('exportStatus'),
      render: (exportStatus) => {
        if (exportStatus === 'SUCCESS') {
          return (
            <div>
              <CheckCircleTwoTone twoToneColor="#52C41A" />
              <span className="success-color">Success</span>
            </div>
          );
        }

        return (
          <Badge color="#FF8845" text="Pending" className="pending-color" />
        );
      }
    }
  ];

  const onSearchChange = (value) => {
    setQuoteViewerFilter({
      ...quoteViewerFilter,
      skip: value
        ? 0
        : quoteViewerFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    quoteViews({
      variables: {
        filter: {
          ...quoteViewerFilter,
          skip: value
            ? 0
            : quoteViewerFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  const handleExport = (e) => {
    const { key } = e;
    if (key === 'SELECTED' && !selectedKeys?.length) {
      message?.destroy();
      message?.error('Please select records!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process?.env?.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module: 'QUOTE_VIEW',
        userId: currentUser?.id,
        filtersObj: {
          ...filters,
          id: key === 'SELECTED' ? selectedKeys : undefined,
          ...(key === 'PENDING' && {
            status: [key]
          })
        }
      }
    })
      .then((res) => {
        handleCsvDownload(res?.data, 'quote-viewer.csv');
        setExportLoading(false);
        quoteViews({
          variables: {
            filter: quoteViewerFilter,
            ...(filterData && { where: filterData })
          }
        });
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  const exportContent = (
    <Menu onClick={handleExport}>
      <Menu.Item key="SELECTED" id="selected-export">
        <span>Selected</span>
      </Menu.Item>
      <Menu.Item key="PENDING" id="pending-export">
        <span>Pending</span>
      </Menu.Item>
      <Menu.Item key="ALL" id="all-export">
        <span>All</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            className="list-search-box"
            id="search-container-id"
            placeholder="Search all details..."
            name="quoteViewers"
            getData={onSearchChange}
          />
        </div>
        <div className="export-btn-quote">
          <AccessControl allowedPermissions={['FET_QUOTE_VIEW_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              overlay={exportContent}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="quoteViewer-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl allowedPermissions={['FET_QUOTE_VIEW_LIST']}>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={loading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.quoteViews?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              expandable={{
                fixed: 'left',
                expandedRowRender: (record = null) => {
                  const upgradeArray = [];
                  if (record?.upgradeInfo?.upgradeChecks?.length > 0) {
                    upgradeArray?.push(...record?.upgradeInfo?.upgradeChecks);
                  }
                  if (record?.upgradeInfo?.otherDetails) {
                    upgradeArray?.push(record?.upgradeInfo?.otherDetails);
                  }
                  return (
                    <ExpandedDetails
                      record={record}
                      upgradeArray={upgradeArray}
                    />
                  );
                },
                columnWidth: 10,
                onExpandedRowsChange: (rows) => {
                  setExpandedRows(rows);
                }
              }}
              onRow={(record) => {
                return {
                  style: {
                    fontWeight: expandedRows?.includes(record?.id) && 500
                  }
                };
              }}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default QuoteViewerTable;
