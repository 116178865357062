import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PropertyTypeCreate from './pages/PropertyTypeCreate';
import PropertyTypeEdit from './pages/PropertyTypeEdit';
import PropertyTypeList from './pages/PropertyTypeList';
import './propertyTypeModule.less';

const PropertyTypeWrapper = () => {
  return (
    <div className="property-type-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PROPERTY_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyTypeList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PROPERTY_TYPES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <PropertyTypeCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PROPERTY_TYPES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <PropertyTypeEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default PropertyTypeWrapper;
