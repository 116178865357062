import { UpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Collapse, Form, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import { UPDATE_PROFILE } from '../../users/graphql/Mutations';
import ChangePasswordModal from './ChangePasswordModal';

const { Panel } = Collapse;

const { required, firstName, lastName, email } = formValidatorRules;

const MyAccountSettingForm = ({ initialValues }) => {
  const {
    state: { permissions },
    initializeAuth,
    getToken,
    dispatch
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'personal',
    'changePassword'
  ]);

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted: (res) => {
      dispatch({ type: 'SET_CURRENT_USER', data: res?.updateProfile?.data });
      initializeAuth(getToken(), res?.updateProfile?.data);
    },
    onError: () => {
      form?.setFieldsValue(initialValues);
      setLoading(false);
    }
  });

  const onValuesChange = () => {
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };
  const handleFinish = (values) => {
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setLoading(true);
    const userObj = {
      ...values,
      roles: initialValues?.roles
    };
    updateProfile({
      variables: {
        data: userObj
      }
    });
    setDisableBtn(true);
    setLoading(false);
  };

  return (
    <div className="content-header">
      <AccessControl allowedPermissions={['FET_MY_ACCOUNT_SETTINGS_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button save-btn"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="account-save-btn"
            loading={loading}
            disabled={disableBtn}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      {passwordVisible && (
        <ChangePasswordModal
          visible={passwordVisible}
          setVisible={setPasswordVisible}
        />
      )}
      <Form
        form={form}
        initialValues={initialValues}
        name="businessHoursForm"
        onValuesChange={onValuesChange}
        onFinish={handleFinish}
        validateTrigger="onChange"
        layout="vertical"
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_MY_ACCOUNT_SETTINGS_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(key) => setCollapseActiveKeys(key)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="PERSONAL" key="personal">
              <Row gutter={16}>
                <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                  <Form.Item
                    rules={[
                      { ...required, message: 'Please Enter First Name' },
                      firstName
                    ]}
                    label="First Name "
                    name="firstName"
                  >
                    <InputComponent placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                  <Form.Item
                    rules={[
                      { ...required, message: 'Please Enter Last Name' },
                      lastName
                    ]}
                    label="Last Name "
                    name="lastName"
                  >
                    <InputComponent placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                  <Form.Item
                    rules={[
                      { ...required, message: 'Please Enter Email' },
                      email
                    ]}
                    label="Email"
                    name="email"
                  >
                    <InputComponent placeholder="Email" />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel forceRender header="CHANGE PASSWORD" key="changePassword">
              <AccessControl
                allowedPermissions={['FET_MY_ACCOUNT_SETTINGS_UPDATE']}
              >
                <Button
                  className="common-button save-btn"
                  icon={<img src={SaveIcon} alt="save-icon" width={12} />}
                  size="small"
                  type="primary"
                  id="postal-save-btn"
                  onClick={() => setPasswordVisible(true)}
                >
                  Change Password
                </Button>
              </AccessControl>
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};

export default MyAccountSettingForm;
