import { gql } from '@apollo/client';

export const GET_PROPERTY_HEAT_SOURCES = gql`
  query propertyHeatSources(
    $filter: PropertyHeatSourceFilter!
    $where: PropertyHeatSourceWhereFilter
  ) {
    propertyHeatSources(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const GET_PROPERTY_HEAT_SOURCE = gql`
  query propertyHeatSource($id: ID!) {
    propertyHeatSource(where: { id: $id }) {
      id
      label
      key
      description
      order
      isActive
    }
  }
`;

export const PROPERTY_HEAT_SOURCE_FILTER = gql`
  query propertyHeatSourceFilters($filter: PropertyHeatSourceFilter!) {
    propertyHeatSourceFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
