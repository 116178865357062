import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './contactTypeModule.less';
import ContactTypeCreate from './pages/ContactTypeCreate';
import ContactTypeEdit from './pages/ContactTypeEdit';
import ContactTypeList from './pages/ContactTypeList';

const ContactTypeWrapper = () => {
  return (
    <div className="contact-type-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.CONTACT_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <ContactTypeList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.CONTACT_TYPES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <ContactTypeCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.CONTACT_TYPES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <ContactTypeEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default ContactTypeWrapper;
