import {
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
  UpOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Collapse, Form, message } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import api from '../../../common/api';
import {
  ALLOW_IMAGE_TYPES,
  CPQ_MAX_LIMIT,
  WRITE
} from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import { GET_SIGN_URL } from '../../../components/graphql/Mutation';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import { UPDATE_CPQ_NEW_LINE_OF_BUSINESS } from '../graphql/Mutations';
import { CPQ_NEW_LINE_OF_BUSINESS } from '../graphql/Queries';

const { Panel } = Collapse;
const { TextArea } = InputComponent;
const { Option } = SelectComponent;

const {
  required,
  requiredWhiteSpaceAllowed,
  url: urlCheck
} = formValidatorRules;

const NewProductForm = ({
  match: { params: { id } = {} } = {},
  loading = false,
  newProductConfig = null,
  setNewProductConfig,
  setLoading,
  setLogoUrl
}) => {
  const {
    state: { currentUser, cpqQuestionObject, permissions },
    dispatch
  } = useContext(AppContext);

  const [form] = Form?.useForm();
  const [initialValues, setInitialValues] = useState(null);

  const [validationTriggered, setValidationTriggered] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [valuesChanged, setValuesChanged] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'title',
    'image',
    'fuel_source',
    'capacity',
    'height',
    'tankless',
    'button',
    'footnote'
  ]);

  const [uploadFile] = useMutation(GET_SIGN_URL, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const [updateCpqNewLineOfBusiness] = useMutation(
    UPDATE_CPQ_NEW_LINE_OF_BUSINESS,
    {
      onError: () => {
        setSubmitLoading(false);
      }
    }
  );

  const [cpqNewLineOfBusiness, { data }] = useLazyQuery(
    CPQ_NEW_LINE_OF_BUSINESS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const initialValuesCopy = {
          title: res?.cpqNewLineOfBusiness?.title,
          image: res?.cpqNewLineOfBusiness?.image,
          fuelTitle: res?.cpqNewLineOfBusiness?.fuelSourceType?.title,
          fuelList: map(
            res?.cpqNewLineOfBusiness?.fuelSourceType?.fuelSource,
            (item) => {
              if (item?.checked) {
                return item?.key;
              }
            }
          ),
          ventList: map(
            res?.cpqNewLineOfBusiness?.fuelSourceType?.ventType,
            (item) => {
              if (item?.checked) {
                return item?.key;
              }
            }
          ),
          capacityTitle: res?.cpqNewLineOfBusiness?.capacity?.title,
          waterHeaterCapacity: map(
            res?.cpqNewLineOfBusiness?.capacity?.waterHeaterCapacity,
            (item) => {
              if (item?.checked) {
                return item?.key;
              }
            }
          ),
          heightTitle: res?.cpqNewLineOfBusiness?.height?.title,
          waterHeaterHeight: map(
            res?.cpqNewLineOfBusiness?.height?.waterHeaterHeight,
            (item) => {
              if (item?.checked) {
                return item?.key;
              }
            }
          ),
          showTankless: res?.cpqNewLineOfBusiness?.tankless?.enable,
          tanklessTitle: res?.cpqNewLineOfBusiness?.tanklessTitle,
          tanklessDescription: res?.cpqNewLineOfBusiness?.description,
          popupTitle: res?.cpqNewLineOfBusiness?.tankless?.title,
          bulletPoints: map(
            res?.cpqNewLineOfBusiness?.tankless?.bulletPoints,
            (item) => {
              return { bulletPoint: item };
            }
          ),
          videoUrl: res?.cpqNewLineOfBusiness?.tankless?.videoUrl,
          secondTitle: res?.cpqNewLineOfBusiness?.tankless?.secondTitle,
          button1: res?.cpqNewLineOfBusiness?.tankless?.button1,
          button2: res?.cpqNewLineOfBusiness?.tankless?.button2,
          linkTitle: res?.cpqNewLineOfBusiness?.tankless?.linkTitle,
          buttonLabel: res?.cpqNewLineOfBusiness?.buttonLabel,
          footNote: res?.cpqNewLineOfBusiness?.footNote
        };
        const newProductConfigCopy = {
          ...initialValuesCopy,
          imageUrl: res?.cpqNewLineOfBusiness?.image?.url,
          fuelList: map(
            res?.cpqNewLineOfBusiness?.fuelSourceType?.fuelSource,
            (item) => {
              if (item?.checked) {
                return item;
              }
            }
          ),
          ventList: map(
            res?.cpqNewLineOfBusiness?.fuelSourceType?.ventType,
            (item) => {
              if (item?.checked) {
                return item;
              }
            }
          ),
          waterHeaterCapacity: map(
            res?.cpqNewLineOfBusiness?.capacity?.waterHeaterCapacity,
            (item) => {
              if (item?.checked) {
                return item;
              }
            }
          ),
          waterHeaterHeight: map(
            res?.cpqNewLineOfBusiness?.height?.waterHeaterHeight,
            (item) => {
              if (item?.checked) {
                return item;
              }
            }
          )
        };
        if (res?.cpqNewLineOfBusiness?.image?.url && !fileList?.length) {
          setFileList([
            {
              url: res?.cpqNewLineOfBusiness?.image?.url,
              title: res?.cpqNewLineOfBusiness?.image?.name
            }
          ]);
        }
        setInitialValues(initialValuesCopy);
        setNewProductConfig(newProductConfigCopy);
        setLogoUrl(res?.cpqNewLineOfBusiness?.brandInfo?.logo?.url);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      }
    }
  );

  useEffect(() => {
    cpqNewLineOfBusiness({
      variables: {
        where: {
          questionnaireId: id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url }
    } = info;
    if (url) {
      setFileList([]);
      setNewProductConfig({ ...newProductConfig, imageUrl: null });
      form?.setFieldsValue({
        image: null
      });
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      if (info?.file?.status === 'removed') {
        form?.setFieldsValue({
          image: null
        });
        setNewProductConfig({ ...newProductConfig, imageUrl: null });
      } else {
        form?.setFieldsValue({
          image: info?.file
        });
        setNewProductConfig({
          ...newProductConfig,
          imageUrl: URL?.createObjectURL(info?.file)
        });
      }
      setFileList([...info?.fileList]);
    } else {
      setFileList([]);
      setNewProductConfig({ ...newProductConfig, imageUrl: null });
      form?.setFieldsValue({
        image: null
      });
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    let image = {};
    if (formValues?.image && !formValues?.image?.url) {
      const { name, type } = formValues?.image;
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const key = `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/new-product/${newFilename}`;
      const res = await uploadFile({
        variables: {
          action: WRITE,
          extension: `.${ext}`,
          contentType: type,
          key
        }
      });
      if (res?.data?.generateSignedUrl) {
        const { signedRequest, url: URL } = res?.data?.generateSignedUrl;
        // above url is send to the backend
        try {
          const response = await api(signedRequest, {
            method: 'PUT',
            data: formValues?.image,
            headers: {
              'Content-Type': type
            }
          });
          if (response) {
            image = {
              url: URL,
              name,
              key,
              extension: ext,
              contentType: type
            };
          }
        } catch (error) {
          setSubmitLoading(false);
          message?.error('got some problem');
        }
      }
    }
    const newFormValues = {
      title: formValues?.title,
      image: formValues?.image?.url
        ? { ...data?.cpqNewLineOfBusiness?.image, __typename: undefined }
        : image,
      fuelSourceType: {
        title: formValues?.fuelTitle,
        fuelSource: map(
          data?.cpqNewLineOfBusiness?.fuelSourceType?.fuelSource,
          (item) => {
            return {
              key: item?.key,
              checked: formValues?.fuelList?.includes(item?.key)
            };
          }
        ),
        ventType: map(
          data?.cpqNewLineOfBusiness?.fuelSourceType?.ventType,
          (item) => {
            return {
              key: item?.key,
              checked: formValues?.ventList?.includes(item?.key)
            };
          }
        )
      },
      capacity: {
        title: formValues?.capacityTitle,
        waterHeaterCapacity: map(
          data?.cpqNewLineOfBusiness?.capacity?.waterHeaterCapacity,
          (item) => {
            return {
              key: item?.key,
              checked: formValues?.waterHeaterCapacity?.includes(item?.key)
            };
          }
        )
      },
      description: formValues?.tanklessDescription,
      tanklessTitle: formValues?.tanklessTitle,
      tankless: {
        enable: formValues?.showTankless,
        title: formValues?.popupTitle,
        bulletPoints: map(
          formValues?.bulletPoints,
          (item) => item?.bulletPoint
        ),
        videoUrl: formValues?.videoUrl,
        secondTitle: formValues?.secondTitle,
        button1: formValues?.button1,
        button2: formValues?.button2,
        linkTitle: formValues?.linkTitle
      },
      height: {
        title: formValues?.heightTitle,
        waterHeaterHeight: map(
          data?.cpqNewLineOfBusiness?.height?.waterHeaterHeight,
          (item) => {
            return {
              key: item?.key,
              checked: formValues?.waterHeaterHeight?.includes(item?.key)
            };
          }
        )
      },
      buttonLabel: formValues?.buttonLabel,
      footNote: formValues?.footNote
    };

    const variables = {
      data: newFormValues,
      where: { questionnaireId: id }
    };

    try {
      const response = await updateCpqNewLineOfBusiness({
        variables: { ...variables }
      });
      if (response?.data?.updateCpqNewLineOfBusiness) {
        setValuesChanged(false);
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const handleOnChangeValue = (changedValues, allValues) => {
    const updatedValues = {
      ...allValues,
      fuelList: map(
        data?.cpqNewLineOfBusiness?.fuelSourceType?.fuelSource,
        (item) => {
          if (allValues?.fuelList?.includes(item?.key)) {
            return item;
          }
        }
      ),
      ventList: map(
        data?.cpqNewLineOfBusiness?.fuelSourceType?.ventType,
        (item) => {
          if (allValues?.ventList?.includes(item?.key)) {
            return item;
          }
        }
      ),
      waterHeaterCapacity: map(
        data?.cpqNewLineOfBusiness?.capacity?.waterHeaterCapacity,
        (item) => {
          if (allValues?.waterHeaterCapacity?.includes(item?.key)) {
            return item;
          }
        }
      ),
      waterHeaterHeight: map(
        data?.cpqNewLineOfBusiness?.height?.waterHeaterHeight,
        (item) => {
          if (allValues?.waterHeaterHeight?.includes(item?.key)) {
            return item;
          }
        }
      )
    };
    setNewProductConfig({ ...newProductConfig, ...updatedValues });
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
    setValuesChanged(true);
  };

  if (loading) {
    return <LoaderComponent setHeight="80" />;
  }

  return (
    <div className="new-product-form">
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="new-product-save-btn"
            loading={submitLoading}
            type="primary"
            disabled={!valuesChanged}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onValuesChange={handleOnChangeValue}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_QUESTIONNAIRE_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="Title" key="title">
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="title"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
            </Panel>
            <Panel forceRender header="Image" key="image">
              <Form.Item
                name="image"
                rules={[
                  { required, message: 'Please Upload Image', type: 'object' }
                ]}
              >
                <DraggerUploadComponent
                  fileList={fileList}
                  onChange={onChangeUpload}
                >
                  <p className="icon-header">
                    <DownloadOutlined />
                  </p>
                  <p className="upload-title">
                    Click or drag file to this area to upload
                  </p>
                </DraggerUploadComponent>
              </Form.Item>
            </Panel>
            <Panel forceRender header="Fuel Source" key="fuel_source">
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="fuelTitle"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
              {data?.cpqNewLineOfBusiness?.fuelSourceType?.fuelSource?.length >
                0 && (
                <Form.Item
                  dependencies={['ventList']}
                  name="fuelList"
                  rules={[
                    { required, message: 'Please Select Fuels', type: 'array' }
                  ]}
                  normalize={(value, preValue) => {
                    if (
                      preValue?.includes('NATURAL_GAS') &&
                      !value?.includes('NATURAL_GAS')
                    ) {
                      form?.setFieldsValue({ ventList: [] });
                    }
                    return value;
                  }}
                >
                  <Checkbox.Group className="fill-width">
                    {map(
                      data?.cpqNewLineOfBusiness?.fuelSourceType?.fuelSource,
                      (item) => {
                        return (
                          <div key={item?.key}>
                            <Checkbox
                              value={item?.key}
                              className="common-checkbox"
                            >
                              {item?.label}
                            </Checkbox>
                            {item?.key === 'NATURAL_GAS' &&
                              data?.cpqNewLineOfBusiness?.fuelSourceType
                                ?.ventType?.length > 0 && (
                                <Collapse
                                  bordered={false}
                                  defaultActiveKey={['vent_type']}
                                  expandIconPosition="right"
                                  expandIcon={({ isActive }) => (
                                    <UpOutlined rotate={isActive ? 0 : 180} />
                                  )}
                                  className="common-collapse inside-collapse"
                                >
                                  <Panel
                                    forceRender
                                    header="Vent Type"
                                    key="vent_type"
                                  >
                                    <Form.Item
                                      dependencies={['fuelList']}
                                      name="ventList"
                                      rules={[
                                        ({ getFieldValue }) => ({
                                          required: getFieldValue(
                                            'fuelList'
                                          )?.includes('NATURAL_GAS'),
                                          message: 'Please Select Vents',
                                          type: 'array'
                                        })
                                      ]}
                                      normalize={(
                                        value,
                                        preValue,
                                        allValues
                                      ) => {
                                        if (value?.length > 0) {
                                          form?.setFieldsValue({
                                            fuelList: [
                                              ...allValues?.fuelList,
                                              'NATURAL_GAS'
                                            ]
                                          });
                                        }
                                        return value;
                                      }}
                                    >
                                      <Checkbox.Group className="fill-width">
                                        {map(
                                          data?.cpqNewLineOfBusiness
                                            ?.fuelSourceType?.ventType,
                                          (singleVent) => {
                                            return (
                                              <div key={singleVent?.key}>
                                                <Checkbox
                                                  value={singleVent?.key}
                                                  className="common-checkbox"
                                                >
                                                  {singleVent?.label}
                                                </Checkbox>
                                              </div>
                                            );
                                          }
                                        )}
                                      </Checkbox.Group>
                                    </Form.Item>
                                  </Panel>
                                </Collapse>
                              )}
                          </div>
                        );
                      }
                    )}
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Panel>
            <Panel forceRender header="Capacity" key="capacity">
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="capacityTitle"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
              {data?.cpqNewLineOfBusiness?.capacity?.waterHeaterCapacity
                ?.length > 0 && (
                <Form.Item
                  rules={[
                    {
                      required,
                      message: 'Please Select Capacity',
                      type: 'array'
                    }
                  ]}
                  name="waterHeaterCapacity"
                >
                  <Checkbox.Group className="fill-width">
                    {map(
                      data?.cpqNewLineOfBusiness?.capacity?.waterHeaterCapacity,
                      (item) => {
                        return (
                          <div key={item?.key}>
                            <Checkbox
                              value={item?.key}
                              className="common-checkbox"
                            >
                              {item?.label}
                            </Checkbox>
                          </div>
                        );
                      }
                    )}
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Panel>
            <Panel forceRender header="Height" key="height">
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="heightTitle"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
              {data?.cpqNewLineOfBusiness?.height?.waterHeaterHeight?.length >
                0 && (
                <Form.Item
                  rules={[
                    { required, message: 'Please Select Height', type: 'array' }
                  ]}
                  name="waterHeaterHeight"
                >
                  <Checkbox.Group className="fill-width">
                    {map(
                      data?.cpqNewLineOfBusiness?.height?.waterHeaterHeight,
                      (item) => {
                        return (
                          <div key={item?.key}>
                            <Checkbox
                              value={item?.key}
                              className="common-checkbox"
                            >
                              {item?.label}
                            </Checkbox>
                          </div>
                        );
                      }
                    )}
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Panel>
            <Panel forceRender header="Why is Tankless better?" key="tankless">
              <Form.Item name="showTankless" valuePropName="checked">
                <Checkbox className="common-checkbox">
                  Show tankless section
                </Checkbox>
              </Form.Item>
              <Form.Item name="tanklessDescription" label="Description">
                <TextArea
                  className="common-textarea"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Enter Description"
                />
              </Form.Item>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="tanklessTitle"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
              <div className="pop-up-section">
                <span className="pop-up-text">Popup Model</span>
              </div>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Popup Title' }]}
                name="popupTitle"
                label="Popup Title"
              >
                <InputComponent allowClear placeholder="Enter Popup Title" />
              </Form.Item>
              <Form.Item name="videoUrl" label="Video URL" rules={[urlCheck]}>
                <InputComponent allowClear placeholder="Enter Video URL" />
              </Form.Item>
              <div className="bullet-section">
                <span className="bullet-text">Bullet point list</span>
                <Form.List name="bulletPoints">
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(
                        ({ key, name, fieldKey, ...restField }, index) => (
                          <div key={key} className="d-flex align-baseline">
                            <Form.Item
                              className="fill-width"
                              {...restField}
                              name={[name, 'bulletPoint']}
                              fieldKey={[fieldKey, 'bulletPoint']}
                              rules={[required]}
                            >
                              <InputComponent placeholder="Add point" />
                            </Form.Item>
                            {index > 1 && (
                              <DeleteOutlined
                                className="delete-icon"
                                onClick={() => remove(name)}
                              />
                            )}
                          </div>
                        )
                      )}
                      {fields?.length < CPQ_MAX_LIMIT && (
                        <Button
                          icon={<PlusOutlined />}
                          onClick={() => add()}
                          type="link"
                          className="add-link-btn"
                        >
                          Add
                        </Button>
                      )}
                    </>
                  )}
                </Form.List>
              </div>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="secondTitle"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Button 1 Label' }
                ]}
                name="button1"
                label="Button 1"
              >
                <InputComponent allowClear placeholder="Enter Button 1 Label" />
              </Form.Item>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Button 2 Label' }
                ]}
                name="button2"
                label="Button 2"
              >
                <InputComponent allowClear placeholder="Enter Button 2 Label" />
              </Form.Item>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Link Title' }]}
                name="linkTitle"
                label="Link Title"
              >
                <InputComponent allowClear placeholder="Enter Link Title" />
              </Form.Item>
            </Panel>
            <Panel forceRender header="Button" key="button">
              <Form.Item
                name="buttonLabel"
                label="Label"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Label'
                  }
                ]}
              >
                <SelectComponent placeholder="Select Label" allowClear>
                  <Option key="GET_QUOTES" value="Get Quotes">
                    Get Quotes
                  </Option>
                  <Option key="CONTINUE" value="Continue">
                    Continue
                  </Option>
                  <Option key="NEXT" value="Next">
                    Next
                  </Option>
                  <Option key="SUBMIT" value="Submit">
                    Submit
                  </Option>
                  <Option key="SEARCH_PRODUCTS" value="Search Products">
                    Search Products
                  </Option>
                  <Option key="FIND_PRODUCTS" value="Find Products">
                    Find Products
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Panel>
            <Panel forceRender header="Footnote" key="footnote">
              <Form.Item name="footNote" valuePropName="checked">
                <Checkbox className="common-checkbox">Location</Checkbox>
              </Form.Item>
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};

export default withRouter(NewProductForm);
