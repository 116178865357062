import { gql } from '@apollo/client';

export const GET_LINE_OF_BUSINESSES = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_SUB_AREAS = gql`
  query subAreas($filter: SubAreaFilter!, $where: SubAreasWhereFilter) {
    subAreas(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lineOfBusinessId
      }
    }
  }
`;

export const GET_PRODUCT_RANKING_REGIONS = gql`
  query regionsProductRankingConfigData(
    $filter: RegionFilter!
    $where: RegionsWhereFilter
  ) {
    regionsProductRankingConfigData(filter: $filter, where: $where) {
      count
      data {
        id
        name
        parentName
      }
    }
  }
`;

export const GET_BRANDS = gql`
  query brands($filter: BrandFilter!, $where: BrandsWhereFilter) {
    brands(filter: $filter, where: $where) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const GET_FUEL_SOURCES = gql`
  query fuelSources($filter: FuelSourceFilter!, $where: FuelSourceWhereFilter) {
    fuelSources(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_VENT_TYPES = gql`
  query ventTypes($filter: VentTypeFilter!, $where: VentTypesWhereFilter) {
    ventTypes(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_CAPACITIES = gql`
  query capacities($filter: CapacityFilter!, $where: CapacityWhereFilter) {
    capacities(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_HEIGHTS = gql`
  query heights($filter: HeightFilter!, $where: HeightWhereFilter) {
    heights(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const RANKING_QUALITY_LISTING = gql`
  query productRankingQualityListing($where: RankingConfigInput) {
    productRankingQualityListing(where: $where) {
      good {
        id
        sku
        stock
        manufacturer
        sellingPrice
        productItemWarranty
        title
        rank
        highlighted
        configId
        productQuality
        costPerDay
      }
      better {
        id
        sku
        stock
        manufacturer
        sellingPrice
        productItemWarranty
        title
        rank
        highlighted
        configId
        productQuality
        costPerDay
      }
      best {
        id
        sku
        stock
        manufacturer
        sellingPrice
        productItemWarranty
        title
        rank
        highlighted
        configId
        productQuality
        costPerDay
      }
    }
  }
`;

export const DEFAULT_CONFIG = gql`
  query defaultConfig {
    defaultConfig {
      lineOfBusiness {
        id
        label
        key
        all
        subAreas {
          id
          label
          key
        }
      }
      subArea {
        id
        label
        key
        lineOfBusinessId
      }
      region {
        id
        name
        zipCodes
        subRegions {
          id
          name
          zipCodes
        }
      }
      brand {
        id
        name
      }
      fuelSource {
        id
        label
        key
      }
      capacity {
        id
        label
        key
      }
      ventType {
        id
        label
        key
      }
      height {
        id
        label
        key
      }
    }
  }
`;
