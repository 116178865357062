import { gql } from '@apollo/client';

export const UPDATE_LOCATION_TYPE = gql`
  mutation updateLocationType(
    $where: LocationTypeWhereInput!
    $data: LocationTypeInput!
  ) {
    updateLocationType(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_LOCATION_TYPE = gql`
  mutation createLocationType($data: LocationTypeInput!) {
    createLocationType(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_LOCATION_TYPE = gql`
  mutation deleteLocationType($where: LocationTypeWhereInput!) {
    deleteLocationType(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
