import { RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import placeholderImage from '../../../../../assets/preview-product.png';
import { ROUTES } from '../../../../../common/constants';
import './styles/CommonProductDetail.less';

const CommonProductDetail = () => {
  const location = useLocation();
  const { pathname = '' } = location;
  const [productTitle] = useState('Package Title');
  const [productDescription] = useState(
    'Package description will be display here'
  );
  const [productPrice] = useState('$XXXX.XX');
  const [productImage] = useState('');

  return (
    <div className="common-product-card">
      <Row gutter={16} className="fill-width">
        <Col span={8}>
          <div className="image-section">
            <img
              src={productImage || placeholderImage}
              alt="product"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
            />
          </div>
        </Col>
        <Col span={16} className="product-detail-section">
          <span className="title">{productTitle}</span>
          <span className="price">{productPrice}</span>
          <span className="description">{productDescription}</span>
          {!pathname?.includes(ROUTES?.THANK_YOU) && (
            <span>
              <Button className="link" type="link">
                Select other solution <RightOutlined />
              </Button>
            </span>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CommonProductDetail;
