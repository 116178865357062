import { DownloadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, message, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import { ALLOW_IMAGE_TYPES, ROUTES, WRITE } from '../../../common/constants';
import { fetchStep, getLogo } from '../../../common/utils';
import { GET_SIGN_URL } from '../../../components/graphql/Mutation';
import history from '../../../historyData';
import { UPDATE_TENANT } from '../graphql/Mutations';

const { Dragger } = Upload;
const UploadLogo = (props) => {
  const { folder = 'unwanted' } = props;
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const [fileKey, setFileKey] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [logoObj, setLogoObj] = useState(null);

  const {
    dispatch,
    getOnboardingData,
    state: { logoUrl }
  } = useContext(AppContext);
  const data = getOnboardingData();

  const [updateTenant] = useMutation(UPDATE_TENANT, {
    onCompleted: async () => {
      setBtnLoading(false);
      await fetchStep({ dispatch, setLoading: false, changeRoute: false });
      if (data?.data?.brandLogo) {
        await getLogo({ logoObject: logoObj, dispatch, isBase64: true });
      }
      history?.push(`${ROUTES?.ONBOARDING}/color-palette`);
      setLogoObj(null);
    },
    onError: () => {
      setBtnLoading(false);
    }
  });

  useEffect(() => {
    if (logoUrl) {
      setFileList([{ url: logoUrl }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoUrl]);

  const [uploadFile] = useMutation(GET_SIGN_URL, {
    onCompleted: async (res) => {
      if (res && res?.generateSignedUrl) {
        const { signedRequest, url: URL } = res?.generateSignedUrl;
        // above url is send to the backend
        try {
          const response = await api(signedRequest, {
            method: 'PUT',
            data: file,
            headers: {
              'Content-Type': file.type
            }
          });
          if (response) {
            // For sending url to backend
            const ext = file.name?.substring(file.name?.lastIndexOf('.') + 1);
            const brandLogo = {
              url: URL,
              name: file.name,
              key: fileKey,
              extension: ext,
              contentType: file.type,
              isAgain: !!data?.data?.brandLogo
            };
            setLogoObj(brandLogo);
            updateTenant({
              variables: {
                data: {
                  tenantInput: {
                    brandLogo
                  }
                }
              }
            });
          }
        } catch (error) {
          setBtnLoading(false);
          message?.error('got some problem');
        }
      }
    },
    onError: () => {
      setBtnLoading(false);
    }
  });

  const onChange = (info) => {
    const {
      file: { name = '', url }
    } = info;
    if (url) {
      setFileList([]);
      setFile(null);
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      setFileList([...info?.fileList]);
      setFile(info?.file);
    } else {
      setFile(null);
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const handleUpload = () => {
    setBtnLoading(true);
    if (fileList?.[0]?.url) {
      setBtnLoading(false);
      return history?.push(`${ROUTES?.ONBOARDING}/color-palette`);
    }
    const { name, type } = file;
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    const timestamp = Date?.now();
    const filename = name?.split('.')?.slice(0, -1)?.join('.');
    const newFilename = `${timestamp}_${filename}.${ext}`;
    const key = `organization/onboarding/${folder}/${newFilename}`;
    setFileKey(key);
    // for Upload file on Bucket
    uploadFile({
      variables: {
        action: WRITE,
        extension: `.${ext}`,
        contentType: type,
        key
      }
    });
  };

  return (
    <div
      className={`upload-logo ${
        fileList?.length > 0 ? 'uploaded-file' : 'hide-border'
      }`}
    >
      <Dragger
        className="dragger-font"
        showUploadList={{ showPreviewIcon: false }}
        listType="picture-card"
        onPreview={() => {}}
        beforeUpload={() => {
          return false; // For stopping the default upload action of antd
        }}
        fileList={fileList}
        maxCount={1}
        onChange={onChange}
      >
        <p>
          <DownloadOutlined className="download-icon" />
        </p>
        <p className="ant-upload-text">Drag your Business Logo here</p>
        <p className="description-gray">...or click to browse your files</p>
        <p className="file-types description-gray">
          JPG, PNG, SVG are supported
        </p>
      </Dragger>
      {fileList?.length > 0 && (
        <Button
          type="primary"
          className="continue-button submit-btn"
          loading={btnLoading}
          onClick={handleUpload}
        >
          Continue to Color Palette
        </Button>
      )}
      <span className="bottom-text steps-content-description">
        You’ll be able to create multiple retailers in the Tenant Portal
      </span>
    </div>
  );
};

export default UploadLogo;
