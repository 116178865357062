import { UpOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Collapse, Form, message } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { ROUTES } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import { UPDATE_CPQ_SCHEDULE } from '../graphql/Mutations';
import { CPQ_SCHEDULE } from '../graphql/Queries';

const { Panel } = Collapse;
// section hide #DRF-5425
// const { RangePicker } = TimePicker;
const { TextArea } = InputComponent;
const { Option } = SelectComponent;

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const ScheduledAppointmentForm = ({
  match: { params: { id } = {} } = {},
  loading = false,
  setScheduledConfig,
  setLoading,
  setLogoUrl
}) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [initialValues, setInitialValues] = useState(null);

  const [validationTriggered, setValidationTriggered] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [valuesChanged, setValuesChanged] = useState(false);

  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'title',
    'countdown',
    'installationLocation',
    'appointment_time',
    'appointment_reservation',
    'email_me_instead',
    'contact_info'
  ]);

  const [updateCpqSchedule] = useMutation(UPDATE_CPQ_SCHEDULE, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const [cpqSchedule, { data }] = useLazyQuery(CPQ_SCHEDULE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const initialValuesCopy = {
        title: res?.cpqSchedule?.title,
        countdown: res?.cpqSchedule?.countDown?.checked,
        countDownLabel: res?.cpqSchedule?.countDown?.key,
        locationTitle: res?.cpqSchedule?.location?.title,
        locationDescription: res?.cpqSchedule?.location?.description,
        locationLabel: res?.cpqSchedule?.location?.label,
        appointmentTimeTitle: res?.cpqSchedule?.appointmentTime?.title,
        firstOfWeek: res?.cpqSchedule?.appointmentTime?.firstOfWeek,
        timeOfDayChecked: res?.cpqSchedule?.appointmentTime?.timeOfDayChecked,
        times: map(res?.cpqSchedule?.appointmentTime?.timeOfDay, (item) => {
          return { timeOfDay: [moment(item?.[0]), moment(item?.[1])] };
        }),
        appointmentResTitle: res?.cpqSchedule?.appointmentReservation?.title,
        creditCardEnabled:
          res?.cpqSchedule?.appointmentReservation?.creditCard?.enabled,
        creditCardTitle:
          res?.cpqSchedule?.appointmentReservation?.creditCard?.title,
        creditCardLabel:
          res?.cpqSchedule?.appointmentReservation?.creditCard?.buttonLabel,
        formDescription:
          res?.cpqSchedule?.appointmentReservation?.creditCard?.formDescription,
        formLabel:
          res?.cpqSchedule?.appointmentReservation?.creditCard?.formLabel,
        phoneCallEnabled:
          res?.cpqSchedule?.appointmentReservation?.phoneCall?.enabled,
        phoneCallTitle:
          res?.cpqSchedule?.appointmentReservation?.phoneCall?.title,
        phoneCallLabel:
          res?.cpqSchedule?.appointmentReservation?.phoneCall?.buttonLabel,
        emailMeTitle:
          res?.cpqSchedule?.appointmentReservation?.emailMe?.buttonLabel,
        emailMeDescription:
          res?.cpqSchedule?.appointmentReservation?.emailMe?.description,
        emailMeButtonLabel: res?.cpqSchedule?.emailMeButtonLabel,
        contactInfo: res?.cpqSchedule?.contactInfo,
        contactData: res?.cpqSchedule?.contactData
      };
      const scheduledConfigCopy = {
        ...initialValuesCopy,
        timesArray: map(
          res?.cpqSchedule?.appointmentTime?.timeOfDay,
          (item) => {
            return [moment(item?.[0]), moment(item?.[1])];
          }
        ),
        holidayList: res?.cpqSchedule?.holidayList
      };
      setInitialValues(initialValuesCopy);
      setScheduledConfig(scheduledConfigCopy);
      setLogoUrl(res?.cpqSchedule?.brandInfo?.logo?.url);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    cpqSchedule({
      variables: {
        where: {
          questionnaireId: id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleSubmit = async (formValues) => {
    if (!formValues?.creditCardEnabled && !formValues?.phoneCallEnabled) {
      return message?.error(
        'You can not disable both the reservation options; Either Credit Card or Phone Call option should be enabled.',
        5
      );
    }
    setSubmitLoading(true);
    const newFormValues = {
      title: formValues?.title,
      countDown: {
        key: formValues?.countDownLabel,
        checked: formValues?.countdown
      },
      location: {
        title: formValues?.locationTitle,
        description: formValues?.locationDescription,
        label: formValues?.locationLabel
      },
      appointmentTime: {
        title: formValues?.appointmentTimeTitle,
        firstOfWeek: formValues?.firstOfWeek
        // section hide #DRF-5425
        // timeOfDayChecked: formValues?.timeOfDayChecked,
        // timeOfDay: map(formValues?.times, (item) => [
        //   moment(item?.timeOfDay?.[0])?.format('YYYY-MM-DD HH:mm:ss'),
        //   moment(item?.timeOfDay?.[1])?.format('YYYY-MM-DD HH:mm:ss')
        // ])
      },
      appointmentReservation: {
        title: formValues?.title,
        creditCard: {
          enabled: formValues?.creditCardEnabled,
          title: formValues?.creditCardTitle,
          buttonLabel: formValues?.creditCardLabel,
          formDescription: formValues?.formDescription,
          formLabel: formValues?.formLabel
        },
        phoneCall: {
          enabled: formValues?.phoneCallEnabled,
          title: formValues?.phoneCallTitle,
          buttonLabel: formValues?.phoneCallLabel
        },
        emailMe: {
          buttonLabel: formValues?.emailMeTitle,
          description: formValues?.emailMeDescription
        }
      },
      emailMeButtonLabel: formValues?.emailMeButtonLabel,
      contactInfo: formValues?.contactInfo
    };

    const variables = {
      data: newFormValues,
      where: { questionnaireId: id }
    };

    try {
      const response = await updateCpqSchedule({
        variables: { ...variables }
      });
      if (response?.data?.updateCpqSchedule) {
        setValuesChanged(false);
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const handleOnChangeValue = (changedValues, allValues) => {
    const updatedConfig = {
      ...allValues,
      timesArray: map(allValues?.times, (item) => item?.timeOfDay),
      holidayList: data?.cpqSchedule?.holidayList
    };
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
    setScheduledConfig(updatedConfig);
    setValuesChanged(true);
  };

  const handleChangeRoute = (type) => {
    if (type === 'email') {
      // eslint-disable-next-line no-undef
      window
        ?.open(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.CONTACT_ME}/${id}?sp=false`,
          '_blank'
        )
        ?.focus();
    } else {
      // eslint-disable-next-line no-undef
      window?.open(ROUTES?.TENANT_SETTING, '_blank')?.focus();
    }
  };

  if (loading) {
    return <LoaderComponent setHeight="80" />;
  }

  return (
    <div className="new-product-form">
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="new-product-save-btn"
            loading={submitLoading}
            type="primary"
            disabled={!valuesChanged}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onValuesChange={handleOnChangeValue}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_QUESTIONNAIRE_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="Title" key="title">
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="title"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
            </Panel>

            <Panel forceRender header="CountDown" key="countdown">
              <Form.Item name="countdown" valuePropName="checked">
                <Checkbox className="common-checkbox">Countdown</Checkbox>
              </Form.Item>
            </Panel>

            <Panel
              forceRender
              header="Installation Location"
              key="installationLocation"
            >
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="locationTitle"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
              <Form.Item name="locationDescription" label="Description">
                <TextArea
                  className="common-textarea"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Enter Description"
                />
              </Form.Item>
              <Form.Item
                name="locationLabel"
                label="Label"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Label'
                  }
                ]}
              >
                <SelectComponent placeholder="Select Label" allowClear>
                  <Option key="REQUOTE_NOW" value="Requote Now">
                    Requote Now
                  </Option>
                  <Option key="REQUOTE" value="Requote">
                    Requote
                  </Option>
                  <Option key="GO_BACK" value="Go Back">
                    Go Back
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Panel>

            <Panel forceRender header="Appointment time" key="appointment_time">
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="appointmentTimeTitle"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
              <Form.Item
                name="firstOfWeek"
                label="First Day of the week"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select First Day of the week'
                  }
                ]}
              >
                <SelectComponent placeholder="Select Label" allowClear>
                  <Option key="SUNDAY" value="Sunday">
                    Sunday
                  </Option>
                  <Option key="MONDAY" value="Monday">
                    Monday
                  </Option>
                  <Option key="TUESDAY" value="Tuesday">
                    Tuesday
                  </Option>
                  <Option key="WEDNESDAY" value="Wednesday">
                    Wednesday
                  </Option>
                  <Option key="THURSDAY" value="Thursday">
                    Thursday
                  </Option>
                  <Option key="FRIDAY" value="Friday">
                    Friday
                  </Option>
                  <Option key="SATURDAY" value="Saturday">
                    Saturday
                  </Option>
                </SelectComponent>
              </Form.Item>
              {/* section hide #DRF-5425 */}
              {/* <Form.Item name="timeOfDayChecked" valuePropName="checked">
                <Checkbox className="common-checkbox">Time of Day</Checkbox>
              </Form.Item>
              <Form.List name="times">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <div key={key} className="d-flex align-baseline">
                          <Form.Item
                            className="fill-width"
                            {...restField}
                            name={[name, 'timeOfDay']}
                            fieldKey={[fieldKey, 'timeOfDay']}
                            rules={[
                              {
                                required,
                                message: 'Please Add Time',
                                type: 'array'
                              },

                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (index === 0 || !value) {
                                    return Promise?.resolve();
                                  }
                                  if (
                                    getFieldValue('times')[index - 1]?.timeOfDay
                                  ) {
                                    if (
                                      minutesOfDay(value?.[0]) >=
                                      minutesOfDay(
                                        getFieldValue('times')?.[index - 1]
                                          ?.timeOfDay?.[1]
                                      )
                                    ) {
                                      return Promise?.resolve();
                                    }
                                  }

                                  return Promise?.reject(
                                    new Error(
                                      'Start date can not be less than previous end date'
                                    )
                                  );
                                }
                              })
                            ]}
                          >
                            <RangePicker
                              className="common-range-picker fill-width"
                              popupClassName="common-range-picker"
                              use12Hours
                              minuteStep={15}
                              format="h:mm a"
                            />
                          </Form.Item>
                          {index > 2 && (
                            <DeleteOutlined
                              className="delete-icon"
                              onClick={() => remove(name)}
                            />
                          )}
                        </div>
                      )
                    )}
                    {fields?.length < CPQ_MAX_LIMIT && (
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                        type="link"
                        className="add-link-btn"
                      >
                        Add
                      </Button>
                    )}
                  </>
                )}
              </Form.List> */}
            </Panel>
            <Panel
              forceRender
              header="Appointment Reservation"
              key="appointment_reservation"
            >
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="appointmentResTitle"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
              <div className="min-indent-level">
                <div className="section-header">
                  <span className="section-title">Credit Card</span>
                </div>
                <Form.Item name="creditCardEnabled" valuePropName="checked">
                  <Checkbox className="common-checkbox">Credit Card</Checkbox>
                </Form.Item>
                <Form.Item
                  rules={[{ ...required, message: 'Please Enter Title' }]}
                  name="creditCardTitle"
                  label="Title"
                >
                  <InputComponent allowClear placeholder="Enter Title" />
                </Form.Item>
                <Form.Item
                  name="creditCardLabel"
                  label="Label"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Label'
                    }
                  ]}
                >
                  <SelectComponent placeholder="Select Label" allowClear>
                    <Option key="NEXT" value="Next">
                      Next
                    </Option>
                    <Option key="RESERVE" value="Reserve">
                      Reserve
                    </Option>
                  </SelectComponent>
                </Form.Item>
                <Form.Item name="formDescription" label="Form Description">
                  <TextArea
                    className="common-textarea"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder="Enter Form Description"
                  />
                </Form.Item>
                <Form.Item
                  name="formLabel"
                  label="Form Label"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Form Label'
                    }
                  ]}
                >
                  <SelectComponent placeholder="Select Form Label" allowClear>
                    <Option
                      key="RESERVE_APPOINTMENT"
                      value="Reserve Your Appointment"
                    >
                      Reserve Your Appointment
                    </Option>
                    <Option key="RESERVE" value="Reserve">
                      Reserve
                    </Option>
                    <Option key="SUBMIT" value="Submit">
                      Submit
                    </Option>
                  </SelectComponent>
                </Form.Item>
                <div className="section-header">
                  <span className="section-title">Contact Section</span>
                </div>
                <Form.Item name="phoneCallEnabled" valuePropName="checked">
                  <Checkbox className="common-checkbox">
                    Contact Section
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  rules={[{ ...required, message: 'Please Enter Title' }]}
                  name="phoneCallTitle"
                  label="Title"
                >
                  <InputComponent allowClear placeholder="Enter Title" />
                </Form.Item>
                <Form.Item
                  name="phoneCallLabel"
                  label="Label"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Label'
                    }
                  ]}
                >
                  <SelectComponent placeholder="Select Label" allowClear>
                    <Option key="NEXT" value="Next">
                      Next
                    </Option>
                    <Option key="RESERVE" value="Reserve">
                      Reserve
                    </Option>
                  </SelectComponent>
                </Form.Item>
              </div>
              <div className="max-indent-level">
                <div className="section-header">
                  <span className="section-title">Contact Information</span>
                </div>
                <Form.Item
                  rules={[{ ...required, message: 'Please Enter Title' }]}
                  name="emailMeTitle"
                  label="Title"
                >
                  <InputComponent allowClear placeholder="Enter Title" />
                </Form.Item>
                <Form.Item name="emailMeDescription" label="Description">
                  <TextArea
                    className="common-textarea"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder="Enter Description"
                  />
                </Form.Item>
              </div>
            </Panel>
            <Panel forceRender header="Email Me Instead" key="email_me_instead">
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Label' }]}
                name="emailMeButtonLabel"
                label="Label"
              >
                <InputComponent allowClear placeholder="Enter Label" />
              </Form.Item>
              <span className="font-size-12 ml-8">
                Go to{' '}
                <Button
                  className="p-0 font-size-12"
                  type="link"
                  onClick={() => handleChangeRoute('email')}
                >
                  Email Me Instead Page
                </Button>{' '}
                to change information.
              </span>
            </Panel>
            <Panel forceRender header="Contact Info" key="contact_info">
              <Form.Item name="contactInfo" valuePropName="checked">
                <Checkbox className="common-checkbox">Contact Info</Checkbox>
              </Form.Item>
              <span className="font-size-12 ml-8">
                Go to{' '}
                <Button
                  className="p-0 font-size-12"
                  type="link"
                  onClick={handleChangeRoute}
                >
                  Tenant Settings
                </Button>{' '}
                to change contact information.
              </span>
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};

export default withRouter(ScheduledAppointmentForm);
