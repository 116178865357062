import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoaderComponent from '../../../components/LoaderComponent';
import QuotesComponent from '../components/previewComponents/quotes/components/QuotesComponent';
import QuotesForm from '../components/QuotesForm';
import SettingModal from '../components/SettingModal';
import { CPQ_QUOTES } from '../graphql/Queries';
import CpqTemplate from './CpqTemplate';

const QuotesPage = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [quotesData, setQuotesData] = useState({});
  const [quotesConfig, setQuotesConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState('general');
  const [getQuotesData, { data }] = useLazyQuery(CPQ_QUOTES, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const tempObj = {
        showExpiration: res?.cpqQuote?.quoteExpiration?.checked,
        quoteExpirationTime: res?.cpqQuote?.quoteExpiration?.value,
        quoteExpiration: res?.cpqQuote?.quoteExpiration?.time,
        showCustomerPromotion:
          res?.cpqQuote?.marketingMessage?.customerPromotion?.checked,
        marketingTitle:
          res?.cpqQuote?.marketingMessage?.customerPromotion?.title,
        marketingDescription:
          res?.cpqQuote?.marketingMessage?.customerPromotion?.description,
        marketing_icon:
          res?.cpqQuote?.marketingMessage?.customerPromotion?.icon,
        price_icon: res?.cpqQuote?.marketingMessage?.price?.icon,
        priceDescription: res?.cpqQuote?.marketingMessage?.price?.description,
        priceFactorLabel: res?.cpqQuote?.marketingMessage?.priceFactors?.label,
        priceFactorDescription:
          res?.cpqQuote?.marketingMessage?.priceFactors?.description,
        showCountDown: res?.cpqQuote?.serviceCountDownLabel?.checked,
        countDownTime: res?.cpqQuote?.serviceCountDownLabel?.key,
        buttonLabel: res?.cpqQuote?.buttonLabel,
        backLabel: res?.cpqQuote?.backLabel,
        contactInfo: res?.cpqQuote?.contactInfo,
        contactData: res?.cpqQuote?.contactData,
        emailMeButtonLabel: res?.cpqQuote?.emailMeButtonLabel,
        showCostPerDay: res?.cpqQuote?.showCostPerDay
      };
      setQuotesData(tempObj);
      const quoteConfigCopy = {
        ...tempObj,
        lowCost: res?.cpqQuote?.quoteLabels?.lowCost,
        recommended: res?.cpqQuote?.quoteLabels?.recommended,
        highEnd: res?.cpqQuote?.quoteLabels?.highEnd
      };
      setQuotesConfig(quoteConfigCopy);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  const refetchData = () => {
    getQuotesData({
      variables: {
        where: {
          questionnaireId: id
        }
      }
    });
  };

  useEffect(() => {
    getQuotesData({
      variables: {
        where: {
          questionnaireId: id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {showModal && (
        <SettingModal
          showModal={showModal}
          refetchData={refetchData}
          setShowModal={setShowModal}
          activeKeyProps={activeKey}
          setActiveKeyProps={setActiveKey}
        />
      )}
      <CpqTemplate
        centerContent={
          loading ? (
            <LoaderComponent setHeight={80} />
          ) : (
            <QuotesComponent
              quotesConfig={quotesConfig}
              logoUrl={data?.cpqQuote?.brandInfo?.logo?.url}
            />
          )
        }
        rightSideContent={
          loading ? (
            <LoaderComponent setHeight={80} />
          ) : (
            <QuotesForm
              quotesData={quotesData}
              questionnaireId={id}
              quotesConfig={quotesConfig}
              setShowModal={setShowModal}
              setActiveKey={setActiveKey}
              setQuotesConfig={setQuotesConfig}
            />
          )
        }
      />
    </>
  );
};

export default QuotesPage;
