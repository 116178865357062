import { Tabs } from 'antd';
import React from 'react';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';

const { TabPane } = Tabs;

const ProductItemTypeTabs = ({ children }) => {
  const infoTitle = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">Information</span>
    </div>
  );
  return (
    <div className="card-container">
      <Tabs type="card" activeKey="INFORMATION">
        <TabPane tab={infoTitle} key="INFORMATION">
          {children}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ProductItemTypeTabs;
