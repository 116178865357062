import { Button, Divider } from 'antd';
import { map } from 'lodash';
import React from 'react';
import locationIcon from '../../../../../../assets/location-icon.svg';
import { generateEmbeddedUrl } from '../../../../../../common/utils';
import PreviewTagComponent from '../../previewTagComponent';
import '../customerGoal.less';

const CustomerGoalComponent = ({ customerGoalConfig = null, logo = null }) => {
  return (
    <div className="customer-goal-module">
      <PreviewTagComponent />
      <div className="logo-section">
        <img src={logo} alt="logo" />
      </div>
      <div className="customer-goal-title">
        <span>{customerGoalConfig?.title}</span>
      </div>
      {customerGoalConfig?.upgrade_replacement && (
        <div className="upgrade-replacement-section">
          <span className="upgrade-description">
            {customerGoalConfig?.upgradeDescription}
          </span>
          <ul className="bullet-points">
            {map(customerGoalConfig?.upgradeBulletPoints, (point, index) => {
              return <li key={index}>{point?.upgradeBulletPoint}</li>;
            })}
          </ul>
          <div className="button-section">
            <Button
              className="common-button-cpq update-replacement-button"
              type="primary"
              htmlType="submit"
            >
              {customerGoalConfig?.replaceBtnLabel}
            </Button>
            <Button
              className="common-button-cpq update-replacement-button upgrade-btn"
              type="primary"
            >
              {customerGoalConfig?.upgradeBtnLabel}
            </Button>
          </div>
        </div>
      )}
      {customerGoalConfig?.upgrade_replacement &&
        customerGoalConfig?.repair && (
          <Divider className="customer-goal-divider " />
        )}
      {customerGoalConfig?.repair && (
        <div className="repair-section">
          <span className="repair-section-sub-title">
            {customerGoalConfig?.repairDescription}
          </span>
          <ul className="repair-bullet-points ">
            {map(customerGoalConfig?.repairBulletPoints, (point, index) => {
              return <li key={index}>{point?.repairBulletPoint}</li>;
            })}
          </ul>
          <div className="video-section">
            <iframe
              title="videos"
              className="customer-goal-video"
              src={`${generateEmbeddedUrl(customerGoalConfig?.url)}`}
            />
          </div>
          <div className="repair-button-section">
            <Button
              type="primary"
              htmlType="submit"
              className="common-button-cpq repair-button"
            >
              {customerGoalConfig?.repairButtonLabel}
            </Button>
          </div>
        </div>
      )}

      {customerGoalConfig?.showLocation && (
        <div className="location-footer">
          <img src={locationIcon} alt="location-icon" />
          <span className="location-info">Irvine, CA</span>
        </div>
      )}
    </div>
  );
};

export default CustomerGoalComponent;
