import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './customerTypeModule.less';
import CustomerTypeCreate from './pages/CustomerTypeCreate';
import CustomerTypeEdit from './pages/CustomerTypeEdit';
import CustomerTypeList from './pages/CustomerTypeList';

const CustomerTypeWrapper = () => {
  return (
    <div className="customerType-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.CUSTOMER_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <CustomerTypeList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.CUSTOMER_TYPES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <CustomerTypeCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.CUSTOMER_TYPES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <CustomerTypeEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default CustomerTypeWrapper;
