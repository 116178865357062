import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import VentTypeCreate from './pages/VentTypeCreate';
import VentTypeEdit from './pages/VentTypeEdit';
import VentTypeList from './pages/VentTypeList';
import './ventTypeModule.less';

const VentTypeWrapper = () => {
  return (
    <div className="vent-type-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.VENT_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <VentTypeList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.VENT_TYPES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <VentTypeCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.VENT_TYPES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <VentTypeEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default VentTypeWrapper;
