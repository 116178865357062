import { UpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Col, Collapse, Form, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import TextAreaComponent from '../../../components/TextAreaComponent';
import { UPDATE_CPQ_POSTAL_CODE } from '../graphql/Mutations';

const { Panel } = Collapse;
const { required, requiredWhiteSpaceAllowed } = formValidatorRules;
const { Option } = SelectComponent;

const PostalCodeForm = ({ initialValue, questionnaireId, setPostalConfig }) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'title',
    'map',
    'button',
    'footnote'
  ]);
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const [validationTriggered, setValidationTriggered] = useState(false);

  const [updatePostalCodeData] = useMutation(UPDATE_CPQ_POSTAL_CODE, {
    onCompleted() {
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onFinish = (values) => {
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setDisableBtn(true);
    setLoading(true);
    const title = {
      thinLabel: values?.thinLabel,
      boldLabel: values?.boldLabel
    };
    const map = {
      checked: values?.showMap,
      key: values?.inputPlaceHolder
    };
    const tempObj = {
      title,
      map,
      buttonLabel: values?.label,
      footNote: values?.footNote
    };

    updatePostalCodeData({
      variables: {
        data: tempObj,
        where: {
          questionnaireId
        }
      }
    });
  };
  const onValuesChange = (changedValues, allValues) => {
    setPostalConfig({
      ...allValues
    });
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };
  return (
    <div>
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button save-btn"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="postal-save-btn"
            loading={loading}
            disabled={disableBtn}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        name="postalForm"
        form={form}
        initialValues={initialValue}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        className="postal-form"
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_QUESTIONNAIRE_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="TITLE" key="title">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Subtitle"
                    rules={[{ ...required, message: 'Please Enter Subtitle' }]}
                    name="thinLabel"
                  >
                    <InputComponent placeholder="Enter Subtitle" />
                  </Form.Item>
                  <Form.Item
                    label="Title"
                    rules={[{ ...required, message: 'Please Enter Title' }]}
                    name="boldLabel"
                  >
                    <InputComponent placeholder="Enter Title" />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel forceRender header="MAP" key="map">
              <Form.Item name="showMap" valuePropName="checked">
                <Checkbox className="common-checkbox show-map-checkbox">
                  Show Map
                </Checkbox>
              </Form.Item>
              <Form.Item
                label="Enter Placeholder Text"
                rules={[
                  { ...required, message: 'Please Enter Placeholder Text' }
                ]}
                name="inputPlaceHolder"
              >
                <InputComponent placeholder="Enter Placeholder Text" />
              </Form.Item>
            </Panel>
            <Panel forceRender header="BUTTON" key="button">
              <Form.Item
                label="Label"
                name="label"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Label'
                  }
                ]}
              >
                <SelectComponent>
                  <Option key="CONTINUE" value="Continue">
                    Continue
                  </Option>
                  <Option key="SAVE" value="Save">
                    Save
                  </Option>
                  <Option key="NEXT" value="Next">
                    Next
                  </Option>
                  <Option key="SUBMIT" value="Submit">
                    Submit
                  </Option>
                  <Option key="LETS_Go" value="Let's Go">
                    Let's Go
                  </Option>
                  <Option key="BEGIN" value="Begin">
                    Begin
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Panel>
            <Panel forceRender header="FOOTNOTE" key="footnote">
              <Form.Item
                label="Description"
                rules={[{ ...required, message: 'Please Enter Description' }]}
                name="footNote"
              >
                <TextAreaComponent
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="common-textarea"
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};

export default PostalCodeForm;
