import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import AppHeader from './AppHeader';

const HeaderWrapper = () => {
  return (
    <div>
      <Switch>
        <Route path={ROUTES?.NOTIFICATION} component={AppHeader} />
        <Route path={ROUTES?.USERS} component={AppHeader} />
        <Route path={ROUTES?.MANUFACTURERS} component={AppHeader} />
        <Route path={ROUTES?.CUSTOMERS} component={AppHeader} />
        <Route path={ROUTES?.IMPORTS} component={AppHeader} />
        <Route path={ROUTES?.PRODUCT_CATEGORIES} component={AppHeader} />
        <Route path={ROUTES?.LINE_OF_BUSINESSES} component={AppHeader} />
        <Route path={ROUTES?.ROLES} component={AppHeader} />
        <Route path={ROUTES?.PRODUCT_ITEM_TYPE} component={AppHeader} />
        <Route path={ROUTES?.RETAILERS} component={AppHeader} />
        <Route path={ROUTES?.REGIONS} component={AppHeader} />
        <Route path={ROUTES?.FUEL_SOURCES} component={AppHeader} />
        <Route path={ROUTES?.VENT_TYPES} component={AppHeader} />
        <Route path={ROUTES?.LOCATION_TYPES} component={AppHeader} />
        <Route path={ROUTES?.PRODUCTS} component={AppHeader} />
        <Route path={ROUTES?.PACKAGES} component={AppHeader} />
        <Route path={ROUTES?.PRICING_MODIFIERS} component={AppHeader} />
        <Route path={ROUTES?.QUESTIONNAIRES} component={AppHeader} />
        <Route path={ROUTES?.CAPACITIES} component={AppHeader} />
        <Route path={ROUTES?.TENANT_SETTING} component={AppHeader} />
        <Route path={ROUTES?.BUSINESS_INFORMATION} component={AppHeader} />
        <Route path={ROUTES?.HOURS_OF_OPERATION} component={AppHeader} />
        <Route path={ROUTES?.MY_ACCOUNT_SETTING} component={AppHeader} />
        <Route path={ROUTES?.PLAN_SETTING} component={AppHeader} />
        <Route path={ROUTES?.CONTACTS} component={AppHeader} />
        <Route path={ROUTES?.CUSTOMERS} component={AppHeader} />
        <Route path={ROUTES?.PROPERTIES} component={AppHeader} />
        <Route path={ROUTES?.CONTACT_TYPES} component={AppHeader} />
        <Route path={ROUTES?.CUSTOMER_TYPES} component={AppHeader} />
        <Route path={ROUTES?.HEIGHTS} component={AppHeader} />
        <Route path={ROUTES?.PREFERRED_CONTACT_METHODS} component={AppHeader} />
        <Route
          path={ROUTES?.PROPERTY_FINISHED_BASEMENTS}
          component={AppHeader}
        />
        <Route path={ROUTES?.PROPERTY_WATER_HOOK_UPS} component={AppHeader} />
        <Route path={ROUTES?.PROPERTY_TYPES} component={AppHeader} />
        <Route path={ROUTES?.PROPERTY_HEAT_SOURCE} component={AppHeader} />
        <Route
          path={ROUTES?.PROPERTY_FINISHED_BASEMENTS_CEILING}
          component={AppHeader}
        />
        <Route path={ROUTES?.QUOTE_VIEWER} component={AppHeader} />
        <Route
          path={ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION}
          component={AppHeader}
        />
        <Route path={ROUTES?.DASHBOARD} component={AppHeader} />
        <Route path={ROUTES?.API_SETTING} component={AppHeader} />
        <Route path={ROUTES?.PERMISSIONS} component={AppHeader} />
        <Route path={ROUTES?.PRODUCT_PRICING} component={AppHeader} />
      </Switch>
    </div>
  );
};

export default HeaderWrapper;
