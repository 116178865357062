import { gql } from '@apollo/client';

export const GET_VENT_TYPES = gql`
  query ventTypes($filter: VentTypeFilter!, $where: VentTypesWhereFilter) {
    ventTypes(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
        thumbnail {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const GET_VENT_TYPE = gql`
  query ventType($where: VentTypeWhereInput!) {
    ventType(where: $where) {
      id
      label
      key
      description
      order
      isActive
      logo {
        url
        key
        name
        extension
        contentType
      }
    }
  }
`;

export const VENT_TYPE_FILTER = gql`
  query ventTypeFilters($filter: VentTypeFilter!) {
    ventTypeFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
