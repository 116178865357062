import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Menu,
  message,
  Popconfirm,
  Popover,
  Tag
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import { AppContext } from '../../../AppContext';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import ImportIcon from '../../../assets/import.svg';
import api from '../../../common/api';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  checkPermissions,
  dateFormatWithoutTime,
  formatDate,
  handleCsvDownload
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import ImportModal from '../../imports/components/ImportModal';
import { DELETE_BRAND, UPDATE_BRAND } from '../graphql/Mutations';
import { BRAND_FILTER, GET_BRANDS } from '../graphql/Queries';

let scrollDebounce = null;

const BrandTable = () => {
  const {
    state: { pageSize, currentUser, filterData, globalDateFormat, permissions },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialBrandFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
    defaultBrand: false
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [brandFilter, setBrandFilter] = useState(initialBrandFilter);
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [updateBrandMutate, { loading: updateBrandLoading }] = useMutation(
    UPDATE_BRAND,
    {
      onError() {}
    }
  );

  const [deleteBrand, { loading: deleteBrandLoading }] = useMutation(
    DELETE_BRAND,
    {
      onError() {}
    }
  );

  const [fetchBrandData, { loading, data }] = useLazyQuery(GET_BRANDS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.brands?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [brandFilters] = useLazyQuery(BRAND_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.brandFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      if (res?.brandFilters?.data?.length < SKIP_RECORD) {
        setFilterIsEnd(true);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_BRAND_LIST']);
    if (permitted) {
      fetchBrandData({
        variables: {
          filter: brandFilter,
          ...(filterData && { where: filterData })
        }
      });
      setFilters(filterData);
      dispatch({ type: 'SET_FILTER_DATA', data: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchBrandData({
      variables: {
        filter: { ...brandFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setBrandFilter({
      ...brandFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'createdAt':
          // no api call for range filter;
          break;

        default:
          brandFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
                defaultBrand: false
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          default:
            brandFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                  defaultBrand: false
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    }
  };

  const handleReset = (clearFilters, dataIndex) => {
    let filtersCopy = {
      ...filters
    };
    if (dataIndex === 'createdAt') {
      delete filtersCopy?.createdAt;
    } else {
      filtersCopy = {
        ...filters,
        [dataIndex]: []
      };
    }

    setFilters(filtersCopy);
    fetchBrandData({
      variables: {
        filter: {
          ...brandFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setBrandFilter({
      ...brandFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditBrand = (record) => {
    if (record?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      history?.push(`${ROUTES?.RETAILERS}/edit/${record?.id}`);
    } else {
      history?.push(`${ROUTES?.RETAILERS}/add`);
    }
  };

  const handleViewBrand = (record) => {
    dispatch({ type: 'SET_FILTER_DATA', data: filters });
    history?.push(`${ROUTES?.RETAILERS}/view/${record?.id}`);
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setBrandFilter(initialBrandFilter);
    setSortedInfo({});
    fetchBrandData({
      variables: { filter: initialBrandFilter }
    });
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setBrandFilter({
        ...brandFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchBrandData({
        variables: {
          filter: {
            ...brandFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setBrandFilter({
        ...brandFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchBrandData({
        variables: {
          filter: {
            ...brandFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (filterIndex) {
      default:
        brandFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
              defaultBrand: false
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: moment(values?.[0])?.startOf('day'),
      to: moment(values?.[1])?.endOf('day')
    };

    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj
    };
    if (!values?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              format={dateFormatWithoutTime(globalDateFormat)}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                filtersCopyState?.[dataIndex]?.from
                  ? [
                      moment(filtersCopyState?.[dataIndex]?.from, 'DD/MM/YYYY'),
                      moment(filtersCopyState?.[dataIndex]?.to, 'DD/MM/YYYY')
                    ]
                  : []
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="date-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'isActive' && dataIndex !== 'createdAt' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => {
                  return (
                    <Tag
                      key={item?.toString()}
                      closable
                      onClose={() => handleDeleteFilter(item, dataIndex)}
                      className="filter-tag"
                    >
                      {dataIndex === 'createdAt' ? (
                        <span title={item?.label || item?.toString()}>
                          {moment(item?.label || item?.toString())?.format('L')}
                        </span>
                      ) : (
                        <>
                          {dataIndex === 'isActive' ? (
                            <span title={item === true ? 'Active' : 'Inactive'}>
                              {item === true ? 'Active' : 'Inactive'}
                            </span>
                          ) : (
                            <span title={item?.label || item?.toString()}>
                              {item?.label || item?.toString()}
                            </span>
                          )}
                        </>
                      )}
                    </Tag>
                  );
                })}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => {
                  return (
                    <div
                      className="single-checkbox-div"
                      key={item?.key || item}
                    >
                      <Checkbox
                        value={item?.key || item}
                        checked={filtersCopyState?.[dataIndex]?.includes(
                          item?.key || item
                        )}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        {dataIndex === 'createdAt' ? (
                          <span title={item?.label || item?.toString()}>
                            {moment(item?.label || item?.toString())?.format(
                              'L'
                            )}
                          </span>
                        ) : (
                          <>
                            {dataIndex === 'isActive' ? (
                              <span
                                title={item === true ? 'Active' : 'Inactive'}
                              >
                                {item === true ? 'Active' : 'Inactive'}
                              </span>
                            ) : (
                              <span title={item?.label || item?.toString()}>
                                {item?.label || item?.toString()}
                              </span>
                            )}
                          </>
                        )}
                      </Checkbox>
                    </div>
                  );
                })
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const handleDeleteBrand = async (editBrandData) => {
    const response = await deleteBrand({
      variables: { where: { id: editBrandData?.id } }
    });
    if (response?.data?.deleteBrand) {
      fetchBrandData({
        variables: { filter: brandFilter, ...(filters && { where: filters }) }
      });
    }
  };

  const handleBrandStatus = async (editBrandData) => {
    const editData = {
      ...editBrandData,
      logo: {
        ...editBrandData?.logo
      },
      isActive: !editBrandData?.isActive
    };
    // eslint-disable-next-line no-underscore-dangle
    delete editData?.__typename;
    // eslint-disable-next-line no-underscore-dangle
    delete editData?.logo.__typename;
    delete editData?.id;
    delete editData?.tenantId;
    delete editData?.refData;
    delete editData?.createdAt;
    const response = await updateBrandMutate({
      variables: { data: { ...editData }, where: { id: editBrandData?.id } }
    });
    if (response?.data?.updateBrand) {
      fetchBrandData({
        variables: { filter: brandFilter, ...(filters && { where: filters }) }
      });
    }
  };

  const renderActionButtons = (editBrandData) => {
    return (
      <div className="d-flex flex-vertical">
        <AccessControl
          allowedPermissions={['FET_BRAND_UPDATE', 'FET_BRAND_VIEW']}
        >
          <Button
            id="brand-table-edit-btn"
            className="b-0"
            onClick={() => handleAddEditBrand(editBrandData)}
          >
            Edit
          </Button>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_BRAND_VIEW']}>
          <Button
            id="brand-table-edit-btn"
            className="b-0"
            onClick={() => handleViewBrand(editBrandData)}
          >
            View
          </Button>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_BRAND_DELETE']}>
          <Popconfirm
            title="Line of business related to selected Retailer will be deleted as well, are you sure you want to continue?"
            onConfirm={() => handleDeleteBrand(editBrandData)}
            okText="Yes"
            cancelText="No"
          >
            <Button id="brand-table-status-btn" className="b-0">
              Remove
            </Button>
          </Popconfirm>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_BRAND_UPDATE']}>
          <Popconfirm
            title={`Line of business related to selected Retailer will be ${
              editBrandData?.isActive ? 'deactivated' : 'activated '
            } as well, are you sure you want to continue?`}
            onConfirm={() => handleBrandStatus(editBrandData)}
            okText="Yes"
            cancelText="No"
          >
            <Button id="brand-table-status-btn" className="b-0">
              {editBrandData?.isActive ? 'Mark Inactive' : 'Mark Active'}
            </Button>
          </Popconfirm>
        </AccessControl>
      </div>
    );
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (name, record) => {
        return (
          <>
            <img
              src={record?.logo?.url || placeholderImage}
              alt="brand-img"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
              className="list-img image-contain"
              width={24}
              height={24}
            />
            <span title={name}>{name}</span>
          </>
        );
      }
    },
    {
      title: 'ADDED ON',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      width: 140,
      ...filterPopup('createdAt'),
      render: (createdAt) => {
        return <span>{formatDate(createdAt, globalDateFormat)}</span>;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    checkPermissions(permissions, [
      'FET_BRAND_UPDATE',
      'FET_BRAND_DELETE',
      'FET_BRAND_VIEW'
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const onSearchChange = (value) => {
    setBrandFilter({
      ...brandFilter,
      skip: value ? 0 : brandFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchBrandData({
      variables: {
        filter: {
          ...brandFilter,
          skip: value ? 0 : brandFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  const handleExport = (e) => {
    const { key } = e;
    if (key === 'SELECTED' && !selectedKeys?.length) {
      message?.destroy();
      message?.error('Please select records!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process?.env?.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module: 'BRAND',
        userId: currentUser?.id,
        filtersObj: {
          ...filters,
          id: key === 'SELECTED' ? selectedKeys : undefined
        }
      }
    })
      .then((res) => {
        handleCsvDownload(res?.data, 'retailers.csv');
        setExportLoading(false);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  const exportContent = (
    <Menu onClick={handleExport}>
      <Menu.Item key="SELECTED" id="selected-export">
        <span>Selected</span>
      </Menu.Item>
      <Menu.Item key="ALL" id="all-export">
        <span>All</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="retailers"
        folder="BRAND"
      />
      <AccessControl allowedPermissions={['FET_BRAND_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="brand-table-add-btn"
            type="primary"
            onClick={handleAddEditBrand}
          >
            Add Retailer
          </Button>
        </Portal>
      </AccessControl>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_BRAND_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Retailer name or other detail.."
              name="Brands"
              getData={onSearchChange}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_BRAND_IMPORT']}>
            <Button
              size="small"
              className="common-button import-button"
              icon={<img src={ImportIcon} alt="import-icon" width={11} />}
              id="brand-table-import-btn"
              type="primary"
              onClick={() => handleShowImportModal(true)}
            >
              Import
            </Button>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_BRAND_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              overlay={exportContent}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="brand-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl allowedPermissions={['FET_BRAND_LIST']} showNoAccess>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={loading || updateBrandLoading || deleteBrandLoading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.brands?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default BrandTable;
