/* eslint-disable react/no-danger */
import { Col, Divider, Row } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { formatDate, formatPrice } from '../../../common/utils';
import ShowMoreComponent from '../../../components/ShowMoreComponent';

const quality = {
  GOOD: 'Good',
  BETTER: 'Better',
  BEST: 'Best'
};

const ProductDetails = ({ data = {} }) => {
  const {
    sku = '',
    productType = '',
    title = '',
    price = '',
    sellingPrice = '',
    productQuality = '',
    isActive = true,
    description = '',
    startData = '',
    endDate = '',
    productLob = {},
    productSubArea = {},
    productBrands = [],
    productRegions = []
  } = data;

  const {
    state: { globalDateFormat }
  } = useContext(AppContext);

  return (
    <div>
      <Row>
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">SKU *</span>
            <span className="field-value">{sku}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Package Type *</span>
            <span className="field-value">{productType}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="field-detail">
            <span className="field-label">Name *</span>
            <span className="field-value">{title}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Price *</span>
            <span className="field-value">{formatPrice(price)}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Line of Business *</span>
            <span className="field-value">{productLob?.label}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Sub Area *</span>
            <span className="field-value">{productSubArea?.label}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Status *</span>
            <span className="field-value">
              {isActive ? 'Active' : 'InActive'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Selling Price *</span>
            <span className="field-value">{formatPrice(sellingPrice)}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Product Quality*</span>
            <span className="field-value">{quality[productQuality]}</span>
          </div>
        </Col>
      </Row>
      <Row className="required-row">
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="field-detail">
            <span className="field-label">Retailers *</span>
            <span className="field-value">
              <ShowMoreComponent show={4} data={productBrands} />
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="field-detail">
            <span className="field-label">Regions *</span>
            <span className="field-value">
              <ShowMoreComponent show={4} data={productRegions} />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <span className="form-divider-text">OPTIONAL</span>
        <Divider className="form-divider" />
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Description</span>
            <div dangerouslySetInnerHTML={{ __html: description || '-' }} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Start Date</span>
            <span className="field-value">
              {formatDate(startData, globalDateFormat) || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Expiry Date</span>
            <span className="field-value">
              {formatDate(endDate, globalDateFormat) || '-'}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductDetails;
