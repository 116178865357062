import { useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Divider } from 'antd';
import { every, filter, forEach, map } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { fetchStep } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { UPDATE_SUB_AREAS } from '../graphql/Mutations';
import { GET_LINE_OF_BUSINESSES } from '../graphql/Queries';
import StepProcess from '../pages/StepProcess';

const CheckboxGroup = Checkbox.Group;

const Lob = () => {
  const { dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [checkBoxArray, setCheckBoxArray] = useState([]);

  const { data } = useQuery(GET_LINE_OF_BUSINESSES, {
    variables: {
      filter: {
        sortOn: 'label',
        sortBy: 'ASC',
        skip: 0,
        limit: 10,
        justShow: true
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const checkboxArray = [];

      forEach(res?.lineOfBusinesses?.data, (lob) => {
        checkboxArray?.push({
          indeterminate: !!(
            lob?.subAreas?.length !==
            filter(lob?.subAreas, (item) => item?.isActive)?.length
          ),
          checkedAll: !!(
            lob?.subAreas?.length ===
            filter(lob?.subAreas, (item) => item?.isActive)?.length
          ),
          checkedChildren: map(lob?.subAreas, (subArea) => {
            if (subArea?.isActive === true) {
              return subArea?.id;
            }
          }),
          id: lob?.id
        });
      });

      setCheckBoxArray(checkboxArray);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const [updateSubAreas, { loading: mutationLoading }] = useMutation(
    UPDATE_SUB_AREAS,
    {
      onCompleted: () => {
        fetchStep({ dispatch, setLoading: false, changeRoute: false });
        history?.push(`${ROUTES?.ONBOARDING}/region`);
      },
      onError: () => {}
    }
  );

  const onChange = (list, lob, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    if (list?.length > 0) {
      checkboxArrayCopy[index].checkedChildren = list;
    } else {
      checkboxArrayCopy[index].checkedChildren = [];
    }
    if (list?.length === lob.subAreas?.length) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = true;
    } else if (list?.length === 0) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = false;
    } else {
      checkboxArrayCopy[index].indeterminate = true;
      checkboxArrayCopy[index].checkedAll = false;
    }
    setCheckBoxArray(checkboxArrayCopy);
  };

  const onCheckAllChange = (e, lob, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    const checked = [];
    if (e?.target?.checked) {
      forEach(lob?.subAreas, (area) => checked?.push(area?.id));
    }
    checkboxArrayCopy[index].indeterminate = false;
    checkboxArrayCopy[index].checkedAll = e?.target?.checked;
    checkboxArrayCopy[index].checkedChildren = checked;
    setCheckBoxArray(checkboxArrayCopy);
  };

  const sendLob = () => {
    const sendData = [];
    forEach(checkBoxArray, (item) => {
      if (item?.checkedChildren?.length > 0) {
        sendData?.push({
          lineOfBusinessId: item?.id,
          subAreaId: item?.checkedChildren
        });
      }
    });
    updateSubAreas({
      variables: {
        data: {
          selectedLines: sendData
        }
      }
    });
  };

  return (
    <StepProcess>
      {loading ? (
        <LoaderComponent spinning={loading} />
      ) : (
        <div className="lob">
          <span className="steps-content-title">
            Select your Line(s) of Business
          </span>
          <span className="steps-content-description">
            Please select Line Of Business & Sub Areas which support for this
            brand.
          </span>
          {data?.lineOfBusinesses?.data?.length > 0 && (
            <div className="d-flex justify-center flex-wrap block-parent">
              {map(data?.lineOfBusinesses?.data, (lob, index) => {
                if (lob?.isActive) {
                  return (
                    <div className="lob-block" key={lob?.id}>
                      <Checkbox
                        className="lob-checkbox"
                        id={lob.id}
                        indeterminate={
                          checkBoxArray?.length > 0 &&
                          checkBoxArray?.[index]?.indeterminate
                        }
                        onChange={(e) => onCheckAllChange(e, lob, index)}
                        checked={
                          checkBoxArray?.length > 0 &&
                          checkBoxArray?.[index]?.checkedAll
                        }
                      >
                        <span className="bold-label">{lob?.label}</span>
                      </Checkbox>
                      <Divider />
                      <div className="d-flex">
                        <CheckboxGroup
                          options={map(lob.subAreas, (area) => {
                            return {
                              label: area?.label,
                              value: area?.id
                            };
                          })}
                          value={
                            checkBoxArray?.length > 0 &&
                            checkBoxArray?.[index]?.checkedChildren
                          }
                          onChange={(list) => onChange(list, lob, index)}
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <Button
            type="primary"
            className="lob-button submit-btn"
            loading={mutationLoading}
            disabled={every(
              checkBoxArray,
              (item) => item?.checkedChildren?.length < 1
            )}
            onClick={sendLob}
          >
            Continue to Region Creation
          </Button>
        </div>
      )}
    </StepProcess>
  );
};

export default Lob;
