/* eslint-disable react/no-danger */
import { Checkbox, Col, Divider, Popover, Row } from 'antd';
import React from 'react';
import CheckInfo from '../../../assets/check-info.svg';
import { formatPrice, formatUnits } from '../../../common/utils';

const ProductItemDetails = ({ data = {} }) => {
  const {
    name,
    description,
    quoteDescription,
    isActive,
    sku,
    lineOfBusiness,
    subArea,
    defaultPrice,
    hsn,
    btu,
    manufacturer,
    costPrice,
    unitForMeasure,
    isSellable,
    manageInventory,
    width,
    depth,
    weight,
    productItemModel,
    productItemMaterial,
    productItemWarranty,
    labourWarranty,
    ventTypeData,
    fuelSourceData,
    productTypeData,
    productCategory,
    capacityData,
    heightData,
    heightInNumber,
    energyEfficiency,
    style,
    softness,
    noXLevel,
    options,
    compostable,
    allowPriceChange,
    lowVoc,
    paintable,
    asset,
    stock = null
  } = data;

  const uniOfMeasureToolTip = (
    <div className="d-flex flex-vertical unit-of-measure-tooltip">
      <span className="single-unit">Meter (m)</span>
      <span className="single-unit">Inches (In)</span>
      <span className="single-unit">Kilometer (km)</span>
      <span className="single-unit">Yard (yd)</span>
      <span className="single-unit">Feet (ft)</span>
      <span className="single-unit">Mass - Gram (gm)</span>
      <span className="single-unit"> Mass - Pound (lbs)</span>
      <span className="single-unit">Mass - kilogram (kg)</span>
      <span className="single-unit">Pieces (pcs)</span>
    </div>
  );

  return (
    <div>
      <Row className="required-row">
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div className="field-detail">
            <span className="field-label">Name *</span>
            <span className="field-value">{name}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Manufacturer *</span>
            <span className="field-value">{manufacturer?.name}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">SKU *</span>
            <span className="field-value">{sku}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Line of Business *</span>
            <span className="field-value">{lineOfBusiness?.label}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Sub-Area *</span>
            <span className="field-value">{subArea?.label}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Cost *</span>
            <span className="field-value">{formatPrice(costPrice)}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Default Price *</span>
            <span className="field-value">{formatPrice(defaultPrice)}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Status *</span>
            <span className="field-value">
              {isActive ? 'Active' : 'InActive'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Stock *</span>
            <span className="field-value">{stock}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">
              Quote Description *{' '}
              <Popover
                placement="top"
                content="This will be shown on the quote page."
              >
                <img src={CheckInfo} alt="check-info" className="check-info" />
              </Popover>
            </span>
            <span className="field-value">{quoteDescription || '-'}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <span className="form-divider-text">OPTIONAL</span>
        <Divider className="form-divider" />
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Package Description</span>
            <div dangerouslySetInnerHTML={{ __html: description || '-' }} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Category</span>
            <span className="field-value">{productCategory?.title || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Model</span>
            <span className="field-value">{productItemModel || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Material</span>
            <span className="field-value">{productItemMaterial || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Type</span>
            <span className="field-value">
              {productTypeData?.label || '-'}{' '}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">HSN/SAC</span>
            <span className="field-value">{hsn || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">
              Unit of Measure{' '}
              <Popover placement="top" content={uniOfMeasureToolTip}>
                <img src={CheckInfo} alt="check-info" className="check-info" />
              </Popover>
            </span>
            <span className="field-value">{unitForMeasure || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">BTU</span>
            <span className="field-value">{btu || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Product Warranty</span>
            <span className="field-value">
              {productItemWarranty ? `${productItemWarranty} year` : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Labor Warranty</span>
            <span className="field-value">
              {labourWarranty ? `${labourWarranty} year` : '-'}{' '}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Width (in)</span>
            <span className="field-value">
              {width ? formatUnits(width) : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Depth/Diameter (in)</span>
            <span className="field-value">
              {depth ? formatUnits(depth) : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Height (in)</span>
            <span className="field-value">{heightInNumber || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Height Classification</span>
            <span className="field-value">{heightData?.label || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Weight (lbs)</span>
            <span className="field-value">
              {weight ? formatUnits(weight) : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Fuel Source</span>
            <span className="field-value">{fuelSourceData?.label || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Vent Type</span>
            <span className="field-value">{ventTypeData?.label || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Capacity</span>
            <span className="field-value">{capacityData?.label || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Energy Efficiency</span>
            <span className="field-value">
              {energyEfficiency ? formatUnits(energyEfficiency) : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Style</span>
            <span className="field-value">{style || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Softness</span>
            <span className="field-value">{softness || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">No X Level</span>
            <span className="field-value">{noXLevel || '-'}</span>
          </div>
        </Col>
      </Row>
      <span className="form-divider-text">ADDITIONAL</span>
      <Divider className="form-divider optional-divider" />
      <Row gutter={[16, 24]}>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={isSellable === true}
            className="product-item-checkbox"
          >
            Sellable
            <Popover content="If this option is checked then, This Product item will be available for sell.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={options === true}
            className="product-item-checkbox"
          >
            Options (EnergyStar)
            <Popover content="If this option is checked then, This product item supports Energy Star ratings.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={compostable === true}
            className="product-item-checkbox"
          >
            Compostable
            <Popover content="If this option is checked then, This item can be composted.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={lowVoc === true}
            className="product-item-checkbox"
          >
            Low VOC
            <Popover content="If this option is checked then, This product item has low VOC.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={allowPriceChange === true}
            className="product-item-checkbox"
          >
            Allow Price Change
            <Popover content="If this option is checked then, Technicians can change the price when they visit at customer place.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={manageInventory === true}
            className="product-item-checkbox"
          >
            Manage Inventory
            <Popover content="If this option is checked then, Inventory will be managed for this item.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={paintable === true}
            className="product-item-checkbox"
          >
            Paintable
            <Popover content="If this option is checked then, This item can be painted.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            disabled
            checked={asset === true}
            className="product-item-checkbox"
          >
            Asset
            <Popover content="If this option is checked then, This item will be considered as an Asset for the property where it is installed.">
              <img src={CheckInfo} alt="check-info" className="check-info" />
            </Popover>
          </Checkbox>
        </Col>
      </Row>
    </div>
  );
};

export default ProductItemDetails;
