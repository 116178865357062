import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './locationTypeModule.less';
import LocationTypeCreate from './pages/LocationTypeCreate';
import LocationTypeEdit from './pages/LocationTypeEdit';
import LocationTypeList from './pages/LocationTypeList';

const LocationTypeWrapper = () => {
  return (
    <div className="location-type-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.LOCATION_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <LocationTypeList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.LOCATION_TYPES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <LocationTypeCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.LOCATION_TYPES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <LocationTypeEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default LocationTypeWrapper;
