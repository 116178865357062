import { useMutation } from '@apollo/client';
import { Button, Checkbox, Col, DatePicker, Form, Row, TimePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import holidayMessagePreview from '../../../assets/images/holidayMessagePreview.jpg';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import ModalComponent from '../../../components/ModalComponent';
import { CREATE_HOLIDAY } from '../graphql/Mutations';
import { FETCH_HOLIDAYS } from '../graphql/Queries';

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;
const initialHolidayFilter = {
  skip: 0,
  limit: 10,
  sortBy: 'ASC',
  sortOn: 'date'
};
const HolidayModal = (props) => {
  const [form] = Form?.useForm();
  const { showModal, setShowModal } = props;
  const [allDayChecked, setAllDayChecked] = useState(false);
  const [cutOfTimeChecked, setCutOfTimeChecked] = useState(false);
  const [holidayMessage, setHolidayMessage] = useState(
    '* Your Holiday Message'
  );
  const [loading, setLoading] = useState(false);

  const [createHoliday] = useMutation(CREATE_HOLIDAY, {
    onError() {
      setLoading(false);
    },
    refetchQueries: [
      {
        query: FETCH_HOLIDAYS,
        variables: { filter: { ...initialHolidayFilter } }
      }
    ]
  });
  const handleAllDayCheckChange = (e) => {
    const {
      target: { checked }
    } = e;
    setAllDayChecked(checked);
  };

  const handleCutOfTimeCheckChange = (e) => {
    const {
      target: { checked }
    } = e;
    setCutOfTimeChecked(checked);
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await createHoliday({
        variables: {
          data: {
            ...values,
            open: values?.open
              ? moment(values?.open)?.format('HH:mm:ss')
              : null,
            close: values?.close
              ? moment(values?.close)?.format('HH:mm:ss')
              : null,
            cutOfTime: values?.cutOfTime
              ? moment(values?.cutOfTime)?.format('HH:mm:ss')
              : null
          }
        }
      });
      if (response) {
        setLoading(true);
      }
    } catch (error) {
      setLoading(false);
    }
    setShowModal(false);
  };

  const handleMessageChange = (e) => {
    const {
      target: { value }
    } = e;
    if (value?.length > 0) {
      setHolidayMessage(value);
    } else {
      setHolidayMessage('* Your Holiday Message');
    }
  };
  return (
    <ModalComponent
      width={464}
      title="Add Holiday"
      visible={showModal}
      footer={null}
      closable
      onCancel={() => setShowModal(false)}
      destroyOnClose
      wrapClassName="holiday-modal"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          allDayChecked: false,
          cutOfTimeChecked: false
        }}
        onFinish={(values) => handleSubmit(values)}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Holiday Name"
              rules={[{ ...required, message: 'Please Enter Holiday Name' }]}
            >
              <InputComponent allowClear placeholder="Enter Holiday Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Enter Date'
                }
              ]}
            >
              <DatePicker
                className="datepicker-component"
                format="MMM DD"
                allowClear
                placeholder="Add Date"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="allDayChecked" valuePropName="checked">
          <Checkbox
            onChange={handleAllDayCheckChange}
            className="holiday-modal-checkbox"
          >
            All Day
          </Checkbox>
        </Form.Item>
        {!allDayChecked && (
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                name="open"
                label="Open"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Open Time'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise?.resolve();
                      }
                      if (
                        getFieldValue('close') &&
                        value > getFieldValue('close')
                      ) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'Open time cannot be greater than close time!'
                        );
                      }

                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <TimePicker
                  format="h:mm a"
                  className="common-time-picker"
                  placeholder="Add Time"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="close"
                label="Close"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Close Time'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || !getFieldValue('open')) {
                        return Promise?.resolve();
                      }
                      if (
                        getFieldValue('open') &&
                        value < getFieldValue('open')
                      ) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'Close time cannot be less than open time!'
                        );
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <TimePicker
                  format="h:mm a"
                  className="common-time-picker"
                  placeholder="Add Time"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item name="cutOfTimeChecked" valuePropName="checked">
          <Checkbox
            className="holiday-modal-checkbox"
            onChange={handleCutOfTimeCheckChange}
          >
            Cut-off time
          </Checkbox>
        </Form.Item>
        {cutOfTimeChecked && (
          <Row>
            <Col span={8}>
              <Form.Item
                name="cutOfTime"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Cut-off time'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise?.resolve();
                      }
                      if (!getFieldValue('allDayChecked')) {
                        if (
                          getFieldValue('close') &&
                          value > getFieldValue('close')
                        ) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject('Please Enter Valid Time!');
                        }
                        if (
                          getFieldValue('open') &&
                          value < getFieldValue('open')
                        ) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject('Please Enter Valid Time!');
                        }
                      }

                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <TimePicker
                  format="h:mm a"
                  className="common-time-picker"
                  placeholder="Add Time"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item
          label="Holiday Message"
          name="holidayMessage"
          help=" This message informs your customers about this holiday as they are
          going through your questionnaire."
        >
          <InputComponent
            onChange={handleMessageChange}
            placeholder="Add Holiday Message"
          />
        </Form.Item>
        <div className="preview-section">
          <span className="description">
            Questionnaire Preview: Schedule Appointment Page
          </span>
          <div className="image-section">
            <img src={holidayMessagePreview} alt="message-preview" />
            <span className="message">{holidayMessage}</span>
          </div>
        </div>
        <Form.Item>
          <Button
            loading={loading}
            className="common-button save-btn"
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default HolidayModal;
