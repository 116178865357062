import { Button, Form } from 'antd';
import { forEach, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import locationIcon from '../../../../../../assets/location-icon.svg';
import SelectComponent from '../../globalComponents/SelectComponent';
import PreviewTagComponent from '../../previewTagComponent';
import '../newProduct.less';

const { Option } = SelectComponent;

const NewProductComponent = ({ newProductConfig = null, logoUrl = null }) => {
  const [form] = Form?.useForm();

  const [showVentType, setShowVentType] = useState(false);

  const [fuelSources, setFuelSource] = useState([]);
  const [ventTypes, setVentTypes] = useState([]);
  const [waterHeaterCapacities, setWaterHeaterCapacities] = useState([]);
  const [waterHeaterHeights, setWaterHeaterHeights] = useState([]);

  useEffect(() => {
    const fuelSourcesCopy = [];
    const ventTypesCopy = [];
    const waterHeaterCapacitiesCopy = [];
    const waterHeaterHeightsCopy = [];
    forEach(newProductConfig?.fuelList, (item) => {
      if (item) {
        fuelSourcesCopy?.push(item);
      }
    });

    forEach(newProductConfig?.ventList, (item) => {
      if (item) {
        ventTypesCopy?.push(item);
      }
    });

    forEach(newProductConfig?.waterHeaterCapacity, (item) => {
      if (item) {
        waterHeaterCapacitiesCopy?.push(item);
      }
    });
    forEach(newProductConfig?.waterHeaterHeight, (item) => {
      if (item) {
        waterHeaterHeightsCopy?.push(item);
      }
    });
    setFuelSource(fuelSourcesCopy);
    setVentTypes(ventTypesCopy);
    setWaterHeaterCapacities(waterHeaterCapacitiesCopy);
    setWaterHeaterHeights(waterHeaterHeightsCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProductConfig]);

  const handleFuelSourceChange = (value) => {
    if (value === 'NATURAL_GAS') {
      setShowVentType(true);
    } else {
      form?.setFieldsValue({ ventType: null });
      setShowVentType(false);
    }
  };

  return (
    <div className="new-product-module">
      <PreviewTagComponent />
      <div className="logo-section">
        <img alt="logo" src={logoUrl} />
      </div>
      <div className="new-product-title">
        <span>{newProductConfig?.title}</span>
      </div>
      <div className="image-section">
        {newProductConfig?.imageUrl ? (
          <img src={newProductConfig?.imageUrl} alt="product" />
        ) : (
          <span>Please Upload Image</span>
        )}
      </div>
      <Form form={form} layout="vertical">
        <div className="form-section">
          <Form.Item name="fuelSource">
            <SelectComponent
              className="fill-width"
              placeholder={newProductConfig?.fuelTitle}
              allowClear
              onChange={handleFuelSourceChange}
            >
              {map(fuelSources, (item) => (
                <Option key={item?.key} value={item?.key}>
                  {item?.label}
                </Option>
              ))}
            </SelectComponent>
          </Form.Item>
          {showVentType && (
            <Form.Item
              dependencies={['ventList']}
              name="ventType"
              normalize={(value, preValue) => {
                if (
                  preValue?.includes('NATURAL_GAS') &&
                  !value?.includes('NATURAL_GAS')
                ) {
                  form?.setFieldsValue({ ventList: [] });
                }
                return value;
              }}
            >
              <SelectComponent
                className="fill-width"
                placeholder="Vent Type"
                allowClear
              >
                {map(ventTypes, (item) => (
                  <Option key={item?.key} value={item?.key}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          )}
          <Form.Item name="capacity">
            <SelectComponent
              filterOption={(input, option) =>
                option?.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              className="fill-width "
              placeholder={newProductConfig?.capacityTitle}
              allowClear
            >
              {map(waterHeaterCapacities, (item) => (
                <Option key={item?.key} value={item?.key}>
                  <div key={item?.key} className="capacity-option">
                    <span className="option-title">{item?.label}</span>
                    <span className="option-description">
                      {item?.description}
                    </span>
                  </div>
                </Option>
              ))}
            </SelectComponent>
          </Form.Item>
          <Form.Item name="height">
            <SelectComponent
              className="fill-width"
              placeholder={newProductConfig?.heightTitle}
              allowClear
              showSearch={false}
            >
              {map(waterHeaterHeights, (item) => (
                <Option key={item?.key} value={item?.key}>
                  {item?.label}
                </Option>
              ))}
            </SelectComponent>
          </Form.Item>
          {newProductConfig?.showTankless && (
            <div className="tank-less-section">
              <span className="description">
                {newProductConfig?.tanklessDescription}
              </span>
              <Button className="tankless-link" type="link">
                {newProductConfig?.tanklessTitle}
              </Button>
            </div>
          )}
          <Form.Item>
            <div className="get-quotes-btn">
              <Button
                type="primary"
                htmlType="submit"
                className="common-button-cpq"
              >
                {newProductConfig?.buttonLabel}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
      {newProductConfig?.footNote && (
        <div className="location-footer">
          <img src={locationIcon} alt="location-icon" />
          <span className="location-info">Irvine, CA</span>
        </div>
      )}
    </div>
  );
};

export default NewProductComponent;
