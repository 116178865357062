import React, { useState } from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import NewProductForm from '../components/NewProductForm';
import NewProductComponent from '../components/previewComponents/newProduct/components/NewProductComponent';
import CpqTemplate from './CpqTemplate';

const NewProductPage = () => {
  const [newProductConfig, setNewProductConfig] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  return (
    <CpqTemplate
      centerContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <NewProductComponent
            newProductConfig={newProductConfig}
            logoUrl={logoUrl}
          />
        )
      }
      rightSideContent={
        <NewProductForm
          newProductConfig={newProductConfig}
          setNewProductConfig={setNewProductConfig}
          loading={loading}
          setLoading={setLoading}
          setLogoUrl={setLogoUrl}
        />
      }
    />
  );
};

export default NewProductPage;
