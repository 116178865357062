import { gql } from '@apollo/client';

export const GET_FUEL_SOURCES = gql`
  query fuelSources($filter: FuelSourceFilter!, $where: FuelSourceWhereFilter) {
    fuelSources(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        thumbnail {
          url
          key
          name
          extension
          contentType
        }
        order
        isActive
      }
    }
  }
`;

export const GET_FUEL_SOURCE = gql`
  query fuelSource($where: FuelSourceWhereInput!) {
    fuelSource(where: $where) {
      id
      label
      key
      description
      order
      isActive
      tenantId
      referenceId
      logo {
        url
        key
        name
        extension
        contentType
      }
    }
  }
`;

export const FUEL_SOURCE_FILTER = gql`
  query fuelSourceFilters($filter: FuelSourceFilter!) {
    fuelSourceFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const FUEL_SOURCE = gql`
  query fuelSources($filter: FuelSourceFilter!) {
    fuelSources(filter: $filter) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
