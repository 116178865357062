import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_API_SETTING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './apiSettingModule.less';
import ApiSettingPage from './pages/ApiSettingPage';

const ApiSettingWrapper = () => {
  return (
    <div className="api-setting-wrapper">
      <Switch>
        <Route
          exact
          path={ROUTES?.API_SETTING}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_API_SETTING}
              showNoAccess
            >
              <ApiSettingPage {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default ApiSettingWrapper;
