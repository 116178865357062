import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { MODULES } from '../../../common/constants';
import { objectWithoutKey } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import BrandSettingForm from '../components/BrandSettingForm';
import CardWrapper from '../components/BrandSettingTab';
import { GET_TENANT_BRAND } from '../graphql/Queries';

const BrandSettingPage = () => {
  const [loading, setLoading] = useState(true);
  const [brandData, setBrandData] = useState(null);
  const [logoObj, setLogoObj] = useState(null);

  const [fetchBrand] = useLazyQuery(GET_TENANT_BRAND, {
    onCompleted: (res) => {
      if (res?.fetchTenantBrand?.logo?.url) {
        setLogoObj(res?.fetchTenantBrand?.logo);
      }
      const tempBrand = {
        ...(res?.fetchTenantBrand && {
          ...objectWithoutKey(res?.fetchTenantBrand, 'logo')
        }),
        name: res?.fetchTenantBrand?.name,
        uuid: res?.fetchTenantBrand?.uuid,
        primaryColor: res?.fetchTenantBrand?.primaryColor || undefined,
        ...(res?.fetchTenantBrand?.logo?.url && {
          logo: { ...res?.fetchTenantBrand?.logo }
        }),
        secondaryColors: res?.fetchTenantBrand?.secondaryColors || undefined,
        tertiaryColors: res?.fetchTenantBrand?.tertiaryColors || undefined,
        description: res?.fetchTenantBrand?.description || '',
        waiverText: res?.fetchTenantBrand?.waiverText || '',
        priceGuarantee: res?.fetchTenantBrand?.priceGuarantee || '',
        offerText: res?.fetchTenantBrand?.offerText || '',
        productDetails: res?.fetchTenantBrand?.productDetails || '',
        isActive: res?.fetchTenantBrand?.isActive
      };
      setBrandData(tempBrand);

      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  useEffect(() => {
    fetchBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <span className="portal-header">{MODULES?.BUSINESS_INFORMATION}</span>
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <BrandSettingForm brandData={brandData} logoObj={logoObj} />
        )}
      </CardWrapper>
    </Card>
  );
};

export default BrandSettingPage;
