import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import BrandTable from '../components/BrandTable';

const BrandList = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <span className="portal-header">{MODULES?.RETAILERS}</span>
      </Portal>
      {pageSize && <BrandTable />}
    </Card>
  );
};

export default BrandList;
