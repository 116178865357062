import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Divider, Form, message, Row, Switch } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import copyIcon from '../../../assets/copy-dark.svg';
import { copyToClipboard } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import {
  ACTIVE_DEACTIVE_SERVICE,
  GENERATE_API_TOKEN
} from '../graphql/Mutation';
import { GET_API_TOKEN } from '../graphql/Queries';

const ApiSettingForm = () => {
  const [tokenData, setTokenData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [regenerateLoading, setRegenerateLoading] = useState(false);

  const [activeApi, setActiveApi] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const [getRestApiToken] = useLazyQuery(GET_API_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setLoading(false);
      setActiveApi(res?.getRestApiToken?.data?.status);
      setTokenData(res?.getRestApiToken?.data);
    }
  });

  const [generateApiToken] = useMutation(GENERATE_API_TOKEN, {
    onCompleted() {
      setRegenerateLoading(false);
      getRestApiToken();
    }
  });

  const [disableApi] = useMutation(ACTIVE_DEACTIVE_SERVICE, {
    onCompleted() {
      setStatusLoading(false);
      getRestApiToken();
    }
  });

  useEffect(() => {
    getRestApiToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnclick = () => {
    setRegenerateLoading(true);
    generateApiToken();
  };

  const handleChange = (checked) => {
    setActiveApi(!activeApi);
    setStatusLoading(true);
    disableApi({
      variables: {
        data: {
          status: checked
        }
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSwitchChange = useCallback(debounce(handleChange, 500), []);

  return (
    <div className="content-header">
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <>
          <div className="d-flex justify-between fill-width align-center ">
            <div>
              <span className="api-token-label">API Secret</span>
            </div>
            <AccessControl allowedPermissions={['FET_API_SETTINGS_UPDATE']}>
              <div>
                <Form initialValues={{ status: activeApi }}>
                  <Form.Item name="status" valuePropName="checked">
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      onChange={debounceSwitchChange}
                      loading={statusLoading}
                    />
                  </Form.Item>
                </Form>
              </div>
            </AccessControl>
          </div>
          <Divider />
          <Row gutter={16}>
            <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
              <InputComponent
                suffix={
                  <img
                    src={copyIcon}
                    alt="copy"
                    className="copy-icon"
                    onClick={() => {
                      copyToClipboard(tokenData?.token);
                      message?.success('Token copied to clipboard..!');
                    }}
                  />
                }
                value={tokenData?.token}
                allowClear={false}
                readOnly
                placeholder="Generate token"
              />
            </Col>
            <Col xs={10} sm={10} md={10} lg={2} xl={2} xxl={2}>
              <AccessControl allowedPermissions={['FET_API_SETTINGS_UPDATE']}>
                <Button
                  onClick={handleOnclick}
                  type="primary"
                  className="common-button"
                  loading={regenerateLoading}
                >
                  Regenerate
                </Button>
              </AccessControl>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ApiSettingForm;
