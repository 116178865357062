import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CHANGE_PRODUCT_RANKING = gql`
  mutation changeProductRanking(
    $data: ProductRankingData!
    $where: RankingConfigInput!
  ) {
    changeProductRanking(data: $data, where: $where) {
      message
      status
    }
  }
`;
