import { Col, Row } from 'antd';
import { camelCase, capitalize, join, map, startCase } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  dateFormatWithoutTime,
  formatDate,
  formatPhoneNumber
} from '../../../common/utils';

const showBooleanIfValue = (value) => {
  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }
  if (!value) {
    return '-';
  }
};

const ExpandedDetails = ({ record = null, upgradeArray = [] }) => {
  const {
    state: { globalDateFormat }
  } = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const recordData = [
      {
        title: 'Appointment Request',
        value: record?.appointmentTime
          ? `${formatDate(
              record?.appointmentTime,
              dateFormatWithoutTime(globalDateFormat)
            )}`
          : '-',
        description:
          record?.startTime?.length > 0 && record?.endTime?.length > 0
            ? `${record?.startTime} - ${record?.endTime}`
            : ''
      },
      {
        title: 'Type',
        value: capitalize(record?.flowType)
      },
      {
        title: 'Upgrade',
        value: upgradeArray?.length > 0 ? join(upgradeArray, ', ') : '-'
      },
      {
        title: 'Fuel Source',
        value: record?.fuelSource?.label
      },
      {
        title: 'Vent Type',
        value: record?.ventType?.label
      },
      {
        title: 'Capacity',
        value: record?.capacity?.label
      },
      {
        title: 'Height',
        value: record?.height?.label
      },
      {
        title: 'Urgent',
        value: record?.emailMeInfo?.urgent ? 'Yes' : 'No'
      },
      {
        title: 'Email Confirmation',
        value: record?.emailMeInfo?.emailConfirmation ? 'Yes' : 'No'
      },
      {
        title: 'Optional Comments',
        value: record?.comments
      },
      {
        title: 'Email',
        value: record?.email
      },
      {
        title: 'Phone number',
        value: formatPhoneNumber(record?.mobileNumber)
      },
      {
        title: 'Address',
        value: `${record?.customerLocation?.addressLine1 || ''} ${
          record?.customerLocation?.city
        }, ${
          record?.customerLocation?.county
            ? `${record?.customerLocation?.county},`
            : ''
        }  ${record?.customerLocation?.state} ${
          record?.customerLocation?.zipCode
        }`
      },
      {
        title: 'SKU',
        value: record?.sku
      },
      {
        title: 'Package',
        value: record?.packageTitle
      },
      {
        title: 'Package Quality',
        value: record?.quality
      },
      {
        title: 'Manufacturer (Primary Product)',
        value: record?.manufacturer?.name
      },
      {
        title: 'External Lead Number',
        value: record?.externalLeadNumber
      },
      {
        title: 'CRM Lead ID',
        value: record?.crmLeadId
      },
      {
        title: 'Work Phone',
        value: formatPhoneNumber(record?.workPhone)
      },
      {
        title: 'Home Phone',
        value: formatPhoneNumber(record?.homePhone)
      },
      {
        title: 'Text Opt In Approval',
        value: showBooleanIfValue(record?.textOptInApproval)
      },
      {
        title: 'Text Opt In Approval Date',
        value: `${
          record?.textOptInApprovalDate
            ? formatDate(
                record?.textOptInApprovalDate,
                dateFormatWithoutTime(globalDateFormat)
              )
            : '-'
        }`
      },
      {
        title: 'Email Verified',
        value: showBooleanIfValue(record?.emailVerified)
      },
      {
        title: 'Do Not Email',
        value: showBooleanIfValue(record?.contactPreferences?.doNotEmail)
      },
      {
        title: 'Do Not Phone',
        value: showBooleanIfValue(record?.contactPreferences?.doNotPhone)
      },
      {
        title: 'Do Not Text',
        value: showBooleanIfValue(record?.contactPreferences?.doNotText)
      },
      {
        title: 'Campaign ID',
        value: record?.campaignId
      },
      {
        title: 'CRM Campaign ID',
        value: record?.crmCampaignId
      },
      {
        title: 'Estimator',
        value: record?.estimator
      },
      {
        title: 'Contact Methods',
        value: map(record?.contactMethods, (item) =>
          startCase(camelCase(item))
        )?.join(', ')
      }
    ];
    setData(recordData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row gutter={10} className="detail-space">
      {map(data, (item) => (
        <Col push={1} span={4} key={item?.title}>
          <div className="field-detail">
            <span className="field-label">{item?.title}</span>
            <span className="field-value field-description">
              {item?.value || '-'}
            </span>
            <span className="field-value field-description">
              {item?.description}
            </span>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default ExpandedDetails;
