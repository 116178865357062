import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import SubAreaForm from '../components/SubAreaForm';
import CardWrapper from '../components/SubAreaTabs';
import { CREATE_SUB_AREA } from '../graphql/Mutations';

function SubAreaCreate(props) {
  const { match: { params: { lobId } = {} } = {}, history } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [createSubAreaMutate] = useMutation(CREATE_SUB_AREA, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = newFormValues;

    try {
      const response = await createSubAreaMutate({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createSubArea) {
        history?.push(
          `${ROUTES?.LINE_OF_BUSINESSES}/${lobId}${ROUTES?.SUB_AREAS}`
        );
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: '',
    lineOfBusinessId: '1'
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton
            customLink={`${ROUTES?.LINE_OF_BUSINESSES}/${lobId}${ROUTES?.SUB_AREAS}`}
          />
          <span className="portal-header">Add Sub Area</span>
        </div>
      </Portal>
      <CardWrapper>
        <SubAreaForm
          subAreaData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
}

export default withRouter(SubAreaCreate);
