import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import ProductItemTypeForm from '../components/ProductItemTypeForm';
import CardWrapper from '../components/ProductItemTypeTabs';
import { CREATE_PRODUCT_ITEM_TYPE } from '../graphql/Mutations';

function ProductItemTypeCreate() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [createProductItemTypeMutate] = useMutation(CREATE_PRODUCT_ITEM_TYPE, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = newFormValues;

    try {
      const response = await createProductItemTypeMutate({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createProductItemType) {
        history?.push(ROUTES?.PRODUCT_ITEM_TYPE);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: ''
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRODUCT_ITEM_TYPE} />
          <span className="portal-header">Add Product Type</span>
        </div>
      </Portal>
      <CardWrapper>
        <ProductItemTypeForm
          productItemTypeData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
}

export default withRouter(ProductItemTypeCreate);
