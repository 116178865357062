import { UpOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Collapse, Empty, Radio, Space } from 'antd';
import { debounce, find, get, map, some, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  MODULES,
  QUESTIONNAIRE_RESULT_VALIDATION
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import {
  DEFAULT_CONFIG,
  GET_BRANDS,
  GET_CAPACITIES,
  GET_FUEL_SOURCES,
  GET_HEIGHTS,
  GET_LINE_OF_BUSINESSES,
  GET_PRODUCT_RANKING_REGIONS,
  GET_SUB_AREAS,
  GET_VENT_TYPES
} from '../graphql/Queries';

const { Panel } = Collapse;

const LIMIT = 50;

let lineOfBusinessScrollDebounceJob;
let subAreaScrollDebounceJob;
let regionScrollDebounceJob;
let brandScrollDebounceJob;
let fuelSourceScrollDebounceJob;
let ventTypeScrollDebounceJob;
let capacityScrollDebounceJob;
let heightScrollDebounceJob;

const lineOfBusinessFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true
};

const subAreaFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true
};

const regionFilter = {
  skip: 0,
  limit: LIMIT
};

const brandFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: LIMIT
};

const fuelSourceFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  getDBField: []
};

const ventTypeFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  getDBField: []
};

const capacityFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  getDBField: []
};

const heightFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  getDBField: []
};

const updateData = (data = null) => {
  // eslint-disable-next-line no-undef
  localStorage.setItem(QUESTIONNAIRE_RESULT_VALIDATION, JSON?.stringify(data));
};

const getData = (key = '') => {
  const data =
    // eslint-disable-next-line no-undef
    JSON?.parse(localStorage?.getItem(QUESTIONNAIRE_RESULT_VALIDATION)) || null;
  if (key?.length > 0) {
    return get(data, key);
  }
  return data;
};

const ProductRankingPanelComponent = () => {
  const {
    dispatch,
    state: { productRankingConfig, showNotificationWarning }
  } = useContext(AppContext);

  const [lineOfBusinessesLoading, setLineOfBusinessesLoading] = useState(false);
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessesIsEnd, setLineOfBusinessesIsEnd] = useState(false);
  const [
    lineOfBusinessesInitialCall,
    setLineOfBusinessesInitialCall
  ] = useState(true);
  const [
    lineOfBusinessesDebounceCall,
    setLineOfBusinessesDebounceCall
  ] = useState(0);

  const [subAreasLoading, setSubAreasLoading] = useState(false);
  const [subAreasData, setSubAreasData] = useState([]);
  const [subAreasIsEnd, setSubAreasIsEnd] = useState(false);
  const [subAreasInitialCall, setSubAreasInitialCall] = useState(true);
  const [subAreasDebounceCall, setSubAreasDebounceCall] = useState(0);

  const [regionsLoading, setRegionsLoading] = useState(false);
  const [regionsData, setRegionsData] = useState([]);
  const [regionsIsEnd, setRegionsIsEnd] = useState(false);
  const [regionsInitialCall, setRegionsInitialCall] = useState(true);
  const [regionsDebounceCall, setRegionsDebounceCall] = useState(0);

  const [brandsLoading, setBrandsLoading] = useState(false);
  const [brandsData, setBrandsData] = useState([]);
  const [brandsIsEnd, setBrandsIsEnd] = useState(false);
  const [brandsInitialCall, setBrandsInitialCall] = useState(true);
  const [brandsDebounceCall, setBrandsDebounceCall] = useState(0);

  const [fuelSourcesLoading, setFuelSourcesLoading] = useState(false);
  const [fuelSourcesData, setFuelSourcesData] = useState([]);
  const [fuelSourcesIsEnd, setFuelSourcesIsEnd] = useState(false);
  const [fuelSourcesInitialCall, setFuelSourcesInitialCall] = useState(true);
  const [fuelSourcesDebounceCall, setFuelSourcesDebounceCall] = useState(0);

  const [ventTypesLoading, setVentTypesLoading] = useState(false);
  const [ventTypesData, setVentTypesData] = useState([]);
  const [ventTypesIsEnd, setVentTypesIsEnd] = useState(false);
  const [ventTypesInitialCall, setVentTypesInitialCall] = useState(true);
  const [ventTypesDebounceCall, setVentTypesDebounceCall] = useState(0);

  const [capacitiesLoading, setCapacitiesLoading] = useState(false);
  const [capacitiesData, setCapacitiesData] = useState([]);
  const [capacitiesIsEnd, setCapacitiesIsEnd] = useState(false);
  const [capacitiesInitialCall, setCapacitiesInitialCall] = useState(true);
  const [capacitiesDebounceCall, setCapacitiesDebounceCall] = useState(0);

  const [heightsLoading, setHeightsLoading] = useState(false);
  const [heightsData, setHeightsData] = useState([]);
  const [heightsIsEnd, setHeightsIsEnd] = useState(false);
  const [heightsInitialCall, setHeightsInitialCall] = useState(true);
  const [heightsDebounceCall, setHeightsDebounceCall] = useState(0);

  const [lineOfBusinesses] = useLazyQuery(GET_LINE_OF_BUSINESSES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.lineOfBusinesses?.data?.length < LIMIT) {
        setLineOfBusinessesIsEnd(true);
      }
      if (lineOfBusinessesInitialCall) {
        const lineOfBusinessData = getData('lineOfBusiness');
        if (lineOfBusinessData) {
          setLineOfBusinessesData(
            uniqBy([lineOfBusinessData, ...res?.lineOfBusinesses?.data], 'id')
          );
        } else {
          setLineOfBusinessesData(uniqBy(res?.lineOfBusinesses?.data, 'id'));
        }
        setLineOfBusinessesInitialCall(false);
      } else {
        setLineOfBusinessesData(
          uniqBy(
            [...lineOfBusinessesData, ...res?.lineOfBusinesses?.data],
            'id'
          )
        );
      }
      setLineOfBusinessesLoading(false);
    },
    onError() {
      setLineOfBusinessesLoading(false);
    }
  });

  const [subAreas] = useLazyQuery(GET_SUB_AREAS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.subAreas?.data?.length < LIMIT) {
        setSubAreasIsEnd(true);
      }
      if (subAreasInitialCall) {
        const subAreaData = getData('subArea');
        if (subAreaData) {
          setSubAreasData(uniqBy([subAreaData, ...res?.subAreas?.data], 'id'));
        } else {
          setSubAreasData(uniqBy(res?.subAreas?.data, 'id'));
        }
        setSubAreasInitialCall(false);
      } else {
        setSubAreasData(
          uniqBy([...subAreasData, ...res?.subAreas?.data], 'id')
        );
      }
      setSubAreasLoading(false);
    },
    onError() {
      setSubAreasLoading(false);
    }
  });

  const [regionsProductRankingConfigData] = useLazyQuery(
    GET_PRODUCT_RANKING_REGIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (res?.regionsProductRankingConfigData?.data?.length < LIMIT) {
          setRegionsIsEnd(true);
        }
        if (regionsInitialCall) {
          const regionData = getData('region');
          if (regionData) {
            setRegionsData(
              uniqBy(
                [regionData, ...res?.regionsProductRankingConfigData?.data],
                'id'
              )
            );
          } else {
            setRegionsData(
              uniqBy(res?.regionsProductRankingConfigData?.data, 'id')
            );
          }
          setRegionsInitialCall(false);
        } else {
          setRegionsData(
            uniqBy(
              [...regionsData, ...res?.regionsProductRankingConfigData?.data],
              'id'
            )
          );
        }
        setRegionsLoading(false);
      },
      onError() {
        setRegionsLoading(false);
      }
    }
  );

  const [brands] = useLazyQuery(GET_BRANDS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.brands?.data?.length < LIMIT) {
        setBrandsIsEnd(true);
      }
      if (brandsInitialCall) {
        const brandData = getData('brand');
        if (brandData) {
          setBrandsData(uniqBy([brandData, ...res?.brands?.data], 'id'));
        } else {
          setBrandsData(uniqBy(res?.brands?.data, 'id'));
        }
        setBrandsInitialCall(false);
      } else {
        setBrandsData(uniqBy([...brandsData, ...res?.brands?.data], 'id'));
      }
      setBrandsLoading(false);
    },
    onError() {
      setBrandsLoading(false);
    }
  });

  const [fuelSources] = useLazyQuery(GET_FUEL_SOURCES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.fuelSources?.data?.length < LIMIT) {
        setFuelSourcesIsEnd(true);
      }
      if (fuelSourcesInitialCall) {
        const fuelSourceData = getData('fuelSource');
        if (fuelSourceData) {
          setFuelSourcesData(
            uniqBy([fuelSourceData, ...res?.fuelSources?.data], 'key')
          );
        } else {
          setFuelSourcesData(uniqBy(res?.fuelSources?.data, 'key'));
        }
        setFuelSourcesInitialCall(false);
      } else {
        setFuelSourcesData(
          uniqBy([...fuelSourcesData, ...res?.fuelSources?.data], 'key')
        );
      }
      setFuelSourcesLoading(false);
    },
    onError() {
      setFuelSourcesLoading(false);
    }
  });

  const [ventTypes] = useLazyQuery(GET_VENT_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.ventTypes?.data?.length < LIMIT) {
        setVentTypesIsEnd(true);
      }
      if (ventTypesInitialCall) {
        const ventTypeData = getData('ventType');
        if (ventTypeData) {
          setVentTypesData(
            uniqBy([ventTypeData, ...res?.ventTypes?.data], 'key')
          );
        } else {
          setVentTypesData(uniqBy(res?.ventTypes?.data, 'key'));
        }
        setVentTypesInitialCall(false);
      } else {
        setVentTypesData(
          uniqBy([...ventTypesData, ...res?.ventTypes?.data], 'key')
        );
      }
      setVentTypesLoading(false);
    },
    onError() {
      setVentTypesLoading(false);
    }
  });

  const [capacities] = useLazyQuery(GET_CAPACITIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.capacities?.data?.length < LIMIT) {
        setCapacitiesIsEnd(true);
      }
      if (capacitiesInitialCall) {
        const capacityData = getData('capacity');
        if (capacityData) {
          setCapacitiesData(
            uniqBy([capacityData, ...res?.capacities?.data], 'key')
          );
        } else {
          setCapacitiesData(uniqBy(res?.capacities?.data, 'key'));
        }
        setCapacitiesInitialCall(false);
      } else {
        setCapacitiesData(
          uniqBy([...capacitiesData, ...res?.capacities?.data], 'key')
        );
      }
      setCapacitiesLoading(false);
    },
    onError() {
      setCapacitiesLoading(false);
    }
  });

  const [heights] = useLazyQuery(GET_HEIGHTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.heights?.data?.length < LIMIT) {
        setHeightsIsEnd(true);
      }
      if (heightsInitialCall) {
        const heightData = getData('height');
        if (heightData) {
          setHeightsData(uniqBy([heightData, ...res?.heights?.data], 'key'));
        } else {
          setHeightsData(uniqBy(res?.heights?.data, 'key'));
        }
        setHeightsInitialCall(false);
      } else {
        setHeightsData(uniqBy([...heightsData, ...res?.heights?.data], 'key'));
      }
      setHeightsLoading(false);
    },
    onError() {
      setHeightsLoading(false);
    }
  });

  const [defaultConfig, { loading }] = useLazyQuery(DEFAULT_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const configCopy = {
        lineOfBusiness: {
          id: res?.defaultConfig?.lineOfBusiness?.[0]?.id,
          label: res?.defaultConfig?.lineOfBusiness?.[0]?.label
        },
        subArea: {
          id: res?.defaultConfig?.subArea?.[0]?.id,
          label: res?.defaultConfig?.subArea?.[0]?.label,
          lineOfBusinessId: res?.defaultConfig?.subArea?.[0]?.lineOfBusinessId
        },
        brand: {
          id: res?.defaultConfig?.brand?.[0]?.id,
          name: res?.defaultConfig?.brand?.[0]?.name
        },
        region: {
          id: res?.defaultConfig?.region?.[0]?.id,
          name: res?.defaultConfig?.region?.[0]?.parentName
            ? `${res?.defaultConfig?.region?.[0]?.parentName} / ${res?.defaultConfig?.region?.[0]?.name}`
            : res?.defaultConfig?.region?.[0]?.name
        },
        fuelSource: {
          key: res?.defaultConfig?.fuelSource?.[0]?.key,
          label: res?.defaultConfig?.fuelSource?.[0]?.label
        },
        capacity: {
          key: res?.defaultConfig?.capacity?.[0]?.key,
          label: res?.defaultConfig?.capacity?.[0]?.label
        },
        ventType: {
          key: res?.defaultConfig?.ventType?.[0]?.key,
          label: res?.defaultConfig?.ventType?.[0]?.label
        },
        height: {
          key: res?.defaultConfig?.height?.[0]?.key,
          label: res?.defaultConfig?.height?.[0]?.label
        }
      };
      dispatch({
        type: 'SET_PRODUCT_RANKING_CONFIG',
        data: configCopy
      });
      updateData(configCopy);
    },
    onError() {}
  });

  useEffect(() => {
    const localStorageData = getData();
    if (!localStorageData && !showNotificationWarning) {
      defaultConfig();
    } else {
      dispatch({
        type: 'SET_PRODUCT_RANKING_CONFIG',
        data: localStorageData
      });
    }
    lineOfBusinesses({
      variables: {
        filter: lineOfBusinessFilter,
        where: { isActive: true }
      }
    });
    subAreas({
      variables: {
        filter: subAreaFilter,
        where: { isActive: true }
      }
    });
    regionsProductRankingConfigData({
      variables: {
        filter: regionFilter
      }
    });
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true }
      }
    });
    fuelSources({
      variables: {
        filter: fuelSourceFilter,
        where: { isActive: true }
      }
    });
    ventTypes({
      variables: {
        filter: ventTypeFilter,
        where: { isActive: true }
      }
    });
    capacities({
      variables: {
        filter: capacityFilter,
        where: { isActive: true }
      }
    });
    heights({
      variables: {
        filter: heightFilter,
        where: { isActive: true }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLineOfBusinessScroll = (event) => {
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessesIsEnd) {
        setLineOfBusinessesLoading(true);
        setLineOfBusinessesDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              skip: (lineOfBusinessesDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    lineOfBusinessScrollDebounceJob();
  };

  const onSubAreaScroll = (event) => {
    if (subAreaScrollDebounceJob) {
      subAreaScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    subAreaScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !subAreasIsEnd) {
        setSubAreasLoading(true);
        setSubAreasDebounceCall((prevState) => prevState + 1);
        subAreas({
          variables: {
            filter: {
              ...subAreaFilter,
              skip: (subAreasDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    subAreaScrollDebounceJob();
  };

  const onRegionScroll = (event) => {
    if (regionScrollDebounceJob) {
      regionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    regionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !regionsIsEnd) {
        setRegionsLoading(true);
        setRegionsDebounceCall((prevState) => prevState + 1);
        regionsProductRankingConfigData({
          variables: {
            filter: {
              ...regionFilter,
              skip: (regionsDebounceCall + 1) * LIMIT
            }
          }
        });
      }
    }, 500);

    regionScrollDebounceJob();
  };

  const onBrandScroll = (event) => {
    if (brandScrollDebounceJob) {
      brandScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    brandScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !brandsIsEnd) {
        setBrandsLoading(true);
        setBrandsDebounceCall((prevState) => prevState + 1);
        brands({
          variables: {
            filter: {
              ...brandFilter,
              skip: (brandsDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    brandScrollDebounceJob();
  };

  const onFuelSourceScroll = (event) => {
    if (fuelSourceScrollDebounceJob) {
      fuelSourceScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    fuelSourceScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !fuelSourcesIsEnd) {
        setFuelSourcesLoading(true);
        setFuelSourcesDebounceCall((prevState) => prevState + 1);
        fuelSources({
          variables: {
            filter: {
              ...fuelSourceFilter,
              skip: (fuelSourcesDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    fuelSourceScrollDebounceJob();
  };

  const onVentTypeScroll = (event) => {
    if (ventTypeScrollDebounceJob) {
      ventTypeScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    ventTypeScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !ventTypesIsEnd) {
        setVentTypesLoading(true);
        setVentTypesDebounceCall((prevState) => prevState + 1);
        ventTypes({
          variables: {
            filter: {
              ...ventTypeFilter,
              skip: (ventTypesDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    ventTypeScrollDebounceJob();
  };

  const onCapacityScroll = (event) => {
    if (capacityScrollDebounceJob) {
      capacityScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    capacityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !capacitiesIsEnd) {
        setCapacitiesLoading(true);
        setCapacitiesDebounceCall((prevState) => prevState + 1);
        capacities({
          variables: {
            filter: {
              ...capacityFilter,
              skip: (capacitiesDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    capacityScrollDebounceJob();
  };

  const onHeightScroll = (event) => {
    if (heightScrollDebounceJob) {
      heightScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    heightScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !heightsIsEnd) {
        setHeightsLoading(true);
        setHeightsDebounceCall((prevState) => prevState + 1);
        heights({
          variables: {
            filter: {
              ...heightFilter,
              skip: (heightsDebounceCall + 1) * LIMIT
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    heightScrollDebounceJob();
  };

  const handleChangeFilter = (event, objKey) => {
    let configCopy;
    const localStorageData = getData();
    switch (objKey) {
      case 'lineOfBusiness':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: event?.target?.value,
            label: find(
              lineOfBusinessesData,
              (item) => item?.id === event?.target?.value
            )?.label
          }
        };
        break;
      case 'subArea':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: event?.target?.value,
            label: find(
              subAreasData,
              (item) => item?.id === event?.target?.value
            )?.label,
            lineOfBusinessId: find(
              subAreasData,
              (item) => item?.id === event?.target?.value
            )?.lineOfBusinessId
          }
        };
        break;
      case 'region':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: event?.target?.value,
            name: find(regionsData, (item) => item?.id === event?.target?.value)
              ?.name,
            ...(find(regionsData, (item) => item?.id === event?.target?.value)
              ?.parentName && {
              parentName: find(
                regionsData,
                (item) => item?.id === event?.target?.value
              )?.parentName
            })
          }
        };
        break;
      case 'brand':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: event?.target?.value,
            name: find(brandsData, (item) => item?.id === event?.target?.value)
              ?.name
          }
        };
        break;
      case 'fuelSource':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            key: event?.target?.value,
            label: find(
              fuelSourcesData,
              (item) => item?.key === event?.target?.value
            )?.label
          }
        };
        break;
      case 'ventType':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            key: event?.target?.value,
            label: find(
              ventTypesData,
              (item) => item?.key === event?.target?.value
            )?.label
          }
        };
        break;
      case 'capacity':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            key: event?.target?.value,
            label: find(
              capacitiesData,
              (item) => item?.key === event?.target?.value
            )?.label
          }
        };
        break;
      case 'height':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            key: event?.target?.value,
            label: find(
              heightsData,
              (item) => item?.key === event?.target?.value
            )?.label
          }
        };
        break;

      default:
        break;
    }
    dispatch({
      type: 'SET_PRODUCT_RANKING_CONFIG',
      data: configCopy
    });
    updateData(configCopy);
  };

  const panelHeader = (title = '', description = '') => {
    return (
      <div className="d-flex flex-vertical">
        <span>{title}</span>
        <span className="panel-description">{description}</span>
      </div>
    );
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="product-ranking-panel-component">
      <Collapse
        bordered={false}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        className="common-collapse"
      >
        <Panel
          forceRender
          header={panelHeader(
            'Line of Business',
            productRankingConfig?.lineOfBusiness?.label
          )}
          key="LOB"
        >
          {lineOfBusinessesData?.length > 0 ? (
            <div className="panel-section" onScroll={onLineOfBusinessScroll}>
              <LoaderComponent spinning={lineOfBusinessesLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) =>
                    handleChangeFilter(event, 'lineOfBusiness')
                  }
                  value={productRankingConfig?.lineOfBusiness?.id}
                >
                  <Space direction="vertical">
                    {map(lineOfBusinessesData, (item) => (
                      <Radio key={item?.id} value={item?.id}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Panel>
        <Panel
          forceRender
          header={panelHeader('Sub Area', productRankingConfig?.subArea?.label)}
          key="SUBAREA"
        >
          {subAreasData?.length > 0 &&
          some(
            subAreasData,
            (item) =>
              item?.lineOfBusinessId?.toString() ===
              productRankingConfig?.lineOfBusiness?.id?.toString()
          ) ? (
            <div className="panel-section" onScroll={onSubAreaScroll}>
              <LoaderComponent spinning={subAreasLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) => handleChangeFilter(event, 'subArea')}
                  value={productRankingConfig?.subArea?.id}
                >
                  <Space direction="vertical">
                    {map(subAreasData, (item) => {
                      if (
                        item?.lineOfBusinessId?.toString() ===
                        productRankingConfig?.lineOfBusiness?.id?.toString()
                      ) {
                        return (
                          <Radio key={item?.id} value={item?.id}>
                            {item?.label}
                          </Radio>
                        );
                      }
                    })}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Panel>
        <Panel
          header={panelHeader(
            'Region',
            productRankingConfig?.region?.parentName
              ? `${productRankingConfig?.region?.parentName} / ${productRankingConfig?.region?.name}`
              : productRankingConfig?.region?.name
          )}
          forceRender
          key="REGION"
        >
          {regionsData?.length > 0 ? (
            <div className="panel-section" onScroll={onRegionScroll}>
              <LoaderComponent spinning={regionsLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) => handleChangeFilter(event, 'region')}
                  value={productRankingConfig?.region?.id}
                >
                  <Space direction="vertical">
                    {map(regionsData, (item) => (
                      <Radio key={item?.id} value={item?.id}>
                        {item?.parentName
                          ? `${item?.parentName} / ${item?.name}`
                          : item?.name}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Panel>
        <Panel
          header={panelHeader(
            `${MODULES?.RETAILERS}`,
            productRankingConfig?.brand?.name
          )}
          forceRender
          key="BRAND"
        >
          {brandsData?.length > 0 ? (
            <div className="panel-section" onScroll={onBrandScroll}>
              <LoaderComponent spinning={brandsLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) => handleChangeFilter(event, 'brand')}
                  value={productRankingConfig?.brand?.id}
                >
                  <Space direction="vertical">
                    <>
                      {map(brandsData, (item) => (
                        <Radio key={item?.id} value={item?.id}>
                          {item?.name}
                        </Radio>
                      ))}
                    </>
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Panel>
        <Panel
          header={panelHeader(
            'Fuel Source',
            productRankingConfig?.fuelSource?.label
          )}
          forceRender
          key="FUEL_SOURCE"
        >
          {fuelSourcesData?.length > 0 ? (
            <div className="panel-section" onScroll={onFuelSourceScroll}>
              <LoaderComponent spinning={fuelSourcesLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) => handleChangeFilter(event, 'fuelSource')}
                  value={productRankingConfig?.fuelSource?.key}
                >
                  <Space direction="vertical">
                    {map(fuelSourcesData, (item) => (
                      <Radio key={item?.key} value={item?.key}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Panel>
        {productRankingConfig?.fuelSource?.key === 'NATURAL_GAS' && (
          <Panel
            header={panelHeader(
              'Vent Type',
              productRankingConfig?.ventType?.label
            )}
            forceRender
            key="VENT_TYPE"
          >
            {ventTypesData?.length > 0 ? (
              <div className="panel-section" onScroll={onVentTypeScroll}>
                <LoaderComponent spinning={ventTypesLoading}>
                  <Radio.Group
                    className="common-radio"
                    onChange={(event) => handleChangeFilter(event, 'ventType')}
                    value={productRankingConfig?.ventType?.key}
                  >
                    <Space direction="vertical">
                      {map(ventTypesData, (item) => (
                        <Radio key={item?.key} value={item?.key}>
                          {item?.label}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </LoaderComponent>
              </div>
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Panel>
        )}
        <Panel
          header={panelHeader(
            'Capacity',
            productRankingConfig?.capacity?.label
          )}
          forceRender
          key="CAPACITY"
        >
          {capacitiesData?.length > 0 ? (
            <div className="panel-section" onScroll={onCapacityScroll}>
              <LoaderComponent spinning={capacitiesLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) => handleChangeFilter(event, 'capacity')}
                  value={productRankingConfig?.capacity?.key}
                >
                  <Space direction="vertical">
                    {map(capacitiesData, (item) => (
                      <Radio key={item?.key} value={item?.key}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Panel>
        <Panel
          header={panelHeader('Height', productRankingConfig?.height?.label)}
          forceRender
          key="HEIGHT"
        >
          {heightsData?.length > 0 ? (
            <div className="panel-section" onScroll={onHeightScroll}>
              <LoaderComponent spinning={heightsLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) => handleChangeFilter(event, 'height')}
                  value={productRankingConfig?.height?.key}
                >
                  <Space direction="vertical">
                    {map(heightsData, (item) => (
                      <Radio key={item?.key} value={item?.key}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default ProductRankingPanelComponent;
