import { gql } from '@apollo/client';

export const UPDATE_HEIGHT = gql`
  mutation updateHeight($where: HeightWhereInput!, $data: HeightInput!) {
    updateHeight(where: $where, data: $data) {
      data {
        id
        label
        refData
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_HEIGHT = gql`
  mutation createHeight($data: HeightInput!) {
    createHeight(data: $data) {
      data {
        id
        label
        refData
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_HEIGHT = gql`
  mutation deleteHeight($where: HeightWhereInput!) {
    deleteHeight(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
