import { gql } from '@apollo/client';

export const GET_PROPERTY_FINISHED_BASEMENTS_CEILING = gql`
  query propertyFinishedBasementCeilings(
    $filter: PropertyFinishedBasementCeilingFilter!
    $where: PropertyFinishedBasementCeilingWhereFilter
  ) {
    propertyFinishedBasementCeilings(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const GET_PROPERTY_FINISHED_BASEMENT_CEILING = gql`
  query propertyFinishedBasementCeiling($id: ID!) {
    propertyFinishedBasementCeiling(where: { id: $id }) {
      id
      label
      key
      description
      order
      isActive
    }
  }
`;

export const PROPERTY_FINISHED_BASEMENT_CEILING_FILTER = gql`
  query propertyFinishedBasementCeilingFilters(
    $filter: PropertyFinishedBasementCeilingFilter!
  ) {
    propertyFinishedBasementCeilingFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
