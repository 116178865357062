import React, { useState } from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import CurrentProductForm from '../components/CurrentProductForm';
import CurrentProductComponent from '../components/previewComponents/currentProduct/components/CurrentProductComponent';
import CpqTemplate from './CpqTemplate';

const CurrentProductPage = () => {
  const [currentProductConfig, setCurrentProductConfig] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  return (
    <CpqTemplate
      centerContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <CurrentProductComponent
            currentProductConfig={currentProductConfig}
            logoUrl={logoUrl}
          />
        )
      }
      rightSideContent={
        <CurrentProductForm
          currentProductConfig={currentProductConfig}
          setCurrentProductConfig={setCurrentProductConfig}
          loading={loading}
          setLoading={setLoading}
          setLogoUrl={setLogoUrl}
        />
      }
    />
  );
};

export default CurrentProductPage;
