import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import BackIconComponent from '../app/components/iconComponents/BackIconComponent';
import './styles/GoBackButton.less';

const GoBackButton = ({ customLink = '' }) => {
  const history = useHistory();
  if (customLink) {
    return (
      <div className="back-button">
        <Button
          className="b-0 bs-none primary-color font-size-24"
          onClick={() => history?.push(customLink)}
        >
          <BackIconComponent />
        </Button>
      </div>
    );
  }
  return (
    <div className="back-button">
      <Button
        className="b-0 bs-none primary-color font-size-24"
        onClick={() => history?.goBack()}
      >
        <BackIconComponent />
      </Button>
    </div>
  );
};

export default GoBackButton;
