import {
  CarryOutOutlined,
  DollarOutlined,
  HistoryOutlined,
  HourglassOutlined,
  LikeOutlined,
  RocketOutlined,
  UpOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Col, Collapse, Form, Row } from 'antd';
import { map } from 'lodash';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { ROUTES } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import TextAreaComponent from '../../../components/TextAreaComponent';
import { UPDATE_CPQ_QUOTES } from '../graphql/Mutations';

const { Panel } = Collapse;
const { required, number, requiredWhiteSpaceAllowed } = formValidatorRules;
const { Option } = SelectComponent;

const QuotesForm = ({
  setShowModal,
  setActiveKey,
  quotesData,
  quotesConfig = null,
  questionnaireId,
  setQuotesConfig,
  match: { params: { id } = {} }
}) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [disableBtn, setDisableBtn] = useState(true);

  const [loading, setLoading] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'tab_settings',
    'marketing_message',
    'expiration',
    'price',
    'price_factors',
    'email_me_instead',
    'contact_info',
    'service_day_countdown',
    'button',
    'back'
  ]);

  const quotesIcons = [
    {
      key: 'carryOutLined',
      icon: <CarryOutOutlined />
    },
    {
      key: 'hourGlassOutLined',
      icon: <HourglassOutlined />
    },
    {
      key: 'likeOutlined',
      icon: <LikeOutlined />
    },
    {
      key: 'rocketOutLined',
      icon: <RocketOutlined />
    },
    {
      key: 'dollarOutLine',
      icon: <DollarOutlined />
    },
    {
      key: 'historyOutLined',
      icon: <HistoryOutlined />
    }
  ];

  const [updateQuotesFormData] = useMutation(UPDATE_CPQ_QUOTES, {
    onCompleted() {
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleShowModal = (setCutOffActiveKey = false) => {
    if (setCutOffActiveKey) {
      setActiveKey('cut-off-time');
    }
    setShowModal(true);
  };

  const handleChangeRoute = (type) => {
    if (type === 'email') {
      // eslint-disable-next-line no-undef
      window
        ?.open(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.CONTACT_ME}/${id}?sp=false`,
          '_blank'
        )
        ?.focus();
    } else {
      // eslint-disable-next-line no-undef
      window?.open(ROUTES?.TENANT_SETTING, '_blank')?.focus();
    }
  };

  const onFinish = (values) => {
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setDisableBtn(true);
    const tempObj = {
      marketingMessage: {
        customerPromotion: {
          icon: values?.marketing_icon,
          title: values?.marketingTitle,
          description: values?.marketingDescription,
          checked: values?.showCustomerPromotion
        },
        price: {
          icon: values?.price_icon,
          title: values?.priceTitle,
          description: values?.priceDescription
        },
        priceFactors: {
          label: values?.priceFactorLabel,
          description: values?.priceFactorDescription
        }
      },
      quoteExpiration: {
        checked: values?.showExpiration,
        time: values?.quoteExpiration,
        value: parseInt(values?.quoteExpirationTime, 10)
      },
      backLabel: values?.backLabel,
      buttonLabel: values?.buttonLabel,
      serviceCountDownLabel: {
        key: values?.countDownTime,
        checked: values?.showCountDown
      },
      contactInfo: values?.contactInfo,
      emailMeButtonLabel: values?.emailMeButtonLabel,
      showCostPerDay: values?.showCostPerDay
    };

    updateQuotesFormData({
      variables: {
        data: tempObj,
        where: {
          questionnaireId
        }
      }
    });
  };

  const onValuesChange = (changedValues, allValues) => {
    setQuotesConfig({ ...quotesConfig, ...allValues });
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };

  return (
    <div>
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button save-btn"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="quote-save-btn"
            disabled={disableBtn}
            loading={loading}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        name="quotesForm"
        form={form}
        initialValues={quotesData}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        className="quotes-form"
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_QUESTIONNAIRE_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="TAB SETTINGS" key="tab_settings">
              <Button
                onClick={() => handleShowModal(false)}
                className="tab-setting-btn"
                type="link"
              >
                Offering Configuration
              </Button>
            </Panel>
            <Panel forceRender header="EXPIRATION" key="expiration">
              <Form.Item name="showExpiration" valuePropName="checked">
                <Checkbox className="common-checkbox quote-checkbox">
                  Expiration: the quote will be expired after a set number of
                  days.
                </Checkbox>
              </Form.Item>
              <span className="quote-label">This quotes Expires in</span>
              <Row gutter={6}>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        ...requiredWhiteSpaceAllowed,
                        message: 'Please Enter Expiration Time'
                      },
                      number
                    ]}
                    name="quoteExpirationTime"
                  >
                    <NumberComponent min={1} placeholder="Enter Expire Time" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        ...requiredWhiteSpaceAllowed,
                        message: 'Please Select Expire Time'
                      }
                    ]}
                    name="quoteExpiration"
                  >
                    <SelectComponent>
                      <Option key="DAY" value="Days">
                        Days
                      </Option>
                      <Option key="MONTH" value="Months">
                        Month
                      </Option>
                      <Option key="YEAR" value="Years">
                        Year
                      </Option>
                    </SelectComponent>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel forceRender header="PRICE" key="price">
              <Form.Item name="showCostPerDay" valuePropName="checked">
                <Checkbox className="common-checkbox quote-checkbox">
                  Cost Per Day
                </Checkbox>
              </Form.Item>
              <Form.Item
                label="icon"
                name="price_icon"
                rules={[{ ...required, message: 'Please Select Icon' }]}
              >
                <SelectComponent
                  showSearch={false}
                  allowClear={false}
                  className="icon-select"
                >
                  {map(quotesIcons, (icon) => {
                    return <Option key={icon?.key}>{icon?.icon}</Option>;
                  })}
                </SelectComponent>
              </Form.Item>
              <Form.Item
                label="Description"
                rules={[{ ...required, message: 'Please Enter Description' }]}
                name="priceDescription"
              >
                <TextAreaComponent
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="common-textarea"
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Panel>
            <Panel
              forceRender
              header="MARKETING MESSAGE"
              key="marketing_message"
            >
              <Form.Item name="showCustomerPromotion" valuePropName="checked">
                <Checkbox className="common-checkbox quote-checkbox">
                  Customer Promotion
                </Checkbox>
              </Form.Item>
              <Form.Item
                label="icon"
                name="marketing_icon"
                rules={[{ ...required, message: 'Please Select Icon' }]}
              >
                <SelectComponent
                  showSearch={false}
                  allowClear={false}
                  className="icon-select"
                >
                  {map(quotesIcons, (icon) => {
                    return <Option key={icon?.key}>{icon?.icon}</Option>;
                  })}
                </SelectComponent>
              </Form.Item>
              <Form.Item
                label="Title"
                rules={[
                  { ...required, message: 'Please  Marketing Message Title' }
                ]}
                name="marketingTitle"
              >
                <InputComponent placeholder="Enter Marketing Message Title" />
              </Form.Item>
              <Form.Item
                label="Description"
                rules={[
                  {
                    ...required,
                    message: 'Please Enter Marketing Message Description'
                  }
                ]}
                name="marketingDescription"
              >
                <TextAreaComponent
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="common-textarea"
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Panel>
            <Panel forceRender header="PRICE FACTORS" key="price_factors">
              <Form.Item
                label="Label"
                name="priceFactorLabel"
                rules={[
                  { ...required, message: 'Please Enter Price Factor Label' }
                ]}
                normalize={(value) => {
                  if (value) {
                    return value?.toString();
                  }
                  return '';
                }}
              >
                <InputComponent placeholder="Enter Price" />
              </Form.Item>
              <Form.Item
                label="Description"
                rules={[{ ...required, message: 'Please Enter Description' }]}
                name="priceFactorDescription"
              >
                <TextAreaComponent
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="common-textarea"
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Panel>
            <Panel forceRender header="Email Me Instead" key="email_me_instead">
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Label' }]}
                name="emailMeButtonLabel"
                label="Label"
              >
                <InputComponent allowClear placeholder="Enter Label" />
              </Form.Item>
              <span className="font-size-12 ml-8">
                Go to{' '}
                <Button
                  className="p-0 font-size-12"
                  type="link"
                  onClick={() => handleChangeRoute('email')}
                >
                  Email Me Instead Page
                </Button>{' '}
                to change information.
              </span>
            </Panel>
            <Panel forceRender header="Contact Info" key="contact_info">
              <Form.Item name="contactInfo" valuePropName="checked">
                <Checkbox className="common-checkbox">Contact Info</Checkbox>
              </Form.Item>
              <span className="font-size-12 ml-8">
                Go to{' '}
                <Button
                  className="p-0 font-size-12"
                  type="link"
                  onClick={handleChangeRoute}
                >
                  Tenant Settings
                </Button>{' '}
                to change contact information.
              </span>
            </Panel>
            <Panel
              forceRender
              header="SERVICE DAY COUNTDOWN"
              key="service_day_countdown"
            >
              <Form.Item name="showCountDown" valuePropName="checked">
                <Checkbox className="common-checkbox quote-checkbox">
                  Service Day Countdown.
                </Checkbox>
              </Form.Item>
              <span className="font-size-12 ml-8">
                Go to{' '}
                <Button
                  className="p-0 font-size-12"
                  type="link"
                  onClick={() => handleShowModal(true)}
                >
                  Cut Off Time Settings
                </Button>{' '}
                to change information.
              </span>
            </Panel>
            <Panel forceRender header="Button" key="button">
              <Form.Item
                label="Label"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Button Label'
                  }
                ]}
                name="buttonLabel"
              >
                <SelectComponent>
                  <Option key="CONTINUE" value="Continue">
                    Continue
                  </Option>
                  <Option key="SELECT" value="Select">
                    Select
                  </Option>
                  <Option key="NEXT" value="Next">
                    Next
                  </Option>
                  <Option key="SUBMIT" value="Submit">
                    Submit
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Panel>
            <Panel forceRender header="BACK" key="back">
              <Form.Item
                label="Label"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Enter Back Button Label'
                  }
                ]}
                name="backLabel"
              >
                <SelectComponent>
                  <Option key="BACK" value="Back">
                    Back
                  </Option>
                  <Option key="PREVIOUS" value="Previous">
                    Previous
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};

export default withRouter(QuotesForm);
