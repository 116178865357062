import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import RoleForm from '../components/RoleForm';
import CardWrapper from '../components/RoleTabs';
import { UPDATE_ROLE } from '../graphql/Mutations';
import { GET_ROLE } from '../graphql/Queries';

function RoleEdit(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { role } = {} } = useQuery(GET_ROLE, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateRole] = useMutation(UPDATE_ROLE, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = { data: newFormValues, where: { id: role?.id } };

    try {
      const response = await updateRole({
        variables: { ...variables }
      });
      if (response?.data?.updateRole) {
        setSubmitLoading(false);
        history?.push(ROUTES?.ROLES);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.ROLES} />;
  }

  const initialValues = {
    ...role,
    description: role?.description || '',
    isActive: role?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.ROLES} />
          {role && <span className="portal-header">{role?.label}</span>}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <RoleForm
            isEdit
            roleData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(RoleEdit);
