import { Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { TABLE_HEIGHT, TABLE_HEIGHT_SEARCH } from '../common/constants';
import './styles/TableComponent.less';

const TableComponent = (props) => {
  const {
    state: { pageSize }
  } = useContext(AppContext);
  const {
    columns,
    data = [],
    onChange,
    isSearch = true, // need to pass false when there is no search for table, it set height from this.
    setHeight = !isSearch ? TABLE_HEIGHT : TABLE_HEIGHT_SEARCH,
    loadingData = false,
    fullHeight = true, // To enable scroll inside table
    paginationConfig, // required for showing pagination
    ...rest
  } = props;

  let tableClassName = '';

  if (!data?.length) {
    tableClassName = 'no-scroll-no-data';
  } else if (fullHeight && !paginationConfig) {
    tableClassName = 'no-scroll-data';
  } else if (fullHeight && isSearch) {
    tableClassName = 'has-scroll-data-search';
  } else {
    tableClassName = 'has-scroll-data';
  }
  const [paginationProps, setPaginationProps] = useState({
    pageSizeOptions: [10, 15, 20, 50, 100],
    defaultPageSize: fullHeight ? pageSize : 10,
    size: 'small',
    responsive: true,
    showSizeChanger: true,
    position: ['bottomCenter']
  });

  useEffect(() => {
    setPaginationProps({ ...paginationProps, ...paginationConfig });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  return (
    <Table
      columns={[...columns?.filter((item) => item !== false)]}
      bordered={false} // by default false if want then pass true from props
      dataSource={data}
      className={tableClassName}
      tableLayout={fullHeight && data?.length > 0 ? 'fixed' : 'auto'}
      {...(fullHeight && data?.length > 0
        ? {
            scroll: {
              x: 'max-content',
              y: `calc(100vh - ${setHeight}px)`
            }
          }
        : {})} // pass setHeight prop to change table height && need fullHeight or not.
      onChange={onChange} // for getting pagination,sorting and filter data
      pagination={paginationConfig ? paginationProps : false} // for server side or client side pagination
      loading={{
        spinning: loadingData, // keep it true to set loader
        size: 'large' // currently kept large loader
      }}
      {...rest}
    />
  );
};

export default TableComponent;
