import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { getLogo, objectWithoutKey, uploadLogo } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import CapacityForm from '../components/CapacityForm';
import CardWrapper from '../components/CapacityTabs';
import { UPDATE_CAPACITY } from '../graphql/Mutations';
import { GET_CAPACITY } from '../graphql/Queries';

function CapacityEdit(props) {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const { match: { params: { id } = {} } = {}, history } = props;

  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { capacity } = {} } = useQuery(GET_CAPACITY, {
    variables: { id },
    onCompleted: (res) => {
      if (!res?.capacity?.logo?.url) {
        setLoading(false);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateCapacity] = useMutation(UPDATE_CAPACITY, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    if (capacity?.logo?.url) {
      getLogo({
        logoObject: capacity?.logo,
        setDataState: setLogoUrl,
        setLoadingState: setLoading
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacity]);

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const uuid = capacity?.uuid || uuidv4();
    let logo = null;
    if (formValues?.logo && !formValues?.logo?.url) {
      logo = await uploadLogo({
        logoObject: formValues?.logo,
        currentUser,
        uuid,
        folder: 'capacity'
      });
    }

    const newFormValues = {
      ...formValues,
      ...(formValues?.logo?.url
        ? {
            logo: {
              ...capacity?.logo,
              __typename: undefined
            }
          }
        : {
            ...(logo !== null && { logo })
          }),
      uuid
    };

    const variables = { data: newFormValues, where: { id: capacity?.id } };

    try {
      const response = await updateCapacity({
        variables: { ...variables }
      });
      if (response?.data?.updateCapacity) {
        setSubmitLoading(false);
        history?.push(ROUTES?.CAPACITIES);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.CAPACITIES} />;
  }

  const initialValues = {
    ...(capacity && { ...objectWithoutKey(capacity, 'logo') }),
    ...(capacity?.logo?.url && {
      logo: { ...capacity?.logo, ...logoUrl }
    }),
    description: capacity?.description || '',
    isActive: capacity?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CAPACITIES} />
          {capacity && <span className="portal-header">{capacity?.label}</span>}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={75} />
        ) : (
          <CapacityForm
            capacityData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
            isEdit
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(CapacityEdit);
