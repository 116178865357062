import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { getLogo, objectWithoutKey, uploadLogo } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import VentTypeForm from '../components/VentTypeForm';
import CardWrapper from '../components/VentTypeTabs';
import { UPDATE_VENT_TYPE } from '../graphql/Mutations';
import { GET_VENT_TYPE } from '../graphql/Queries';

function VentTypeEdit(props) {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const { match: { params: { id } = {} } = {}, history } = props;

  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { ventType } = {} } = useQuery(GET_VENT_TYPE, {
    variables: {
      where: {
        id
      }
    },
    onCompleted: (res) => {
      if (!res?.ventType?.logo?.url) {
        setLoading(false);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateVentType] = useMutation(UPDATE_VENT_TYPE, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    if (ventType?.logo?.url) {
      getLogo({
        logoObject: ventType?.logo,
        setDataState: setLogoUrl,
        setLoadingState: setLoading
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventType]);

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const uuid = ventType?.uuid || uuidv4();
    let logo = null;
    if (formValues?.logo && !formValues?.logo?.url) {
      logo = await uploadLogo({
        logoObject: formValues?.logo,
        currentUser,
        uuid,
        folder: 'vent-type'
      });
    }

    const newFormValues = {
      ...formValues,
      ...(formValues?.logo?.url
        ? {
            logo: {
              ...ventType?.logo,
              __typename: undefined
            }
          }
        : {
            ...(logo !== null && { logo })
          }),
      uuid
    };

    const variables = { data: newFormValues, where: { id: ventType?.id } };

    try {
      const response = await updateVentType({
        variables: { ...variables }
      });
      if (response?.data?.updateVentType) {
        setSubmitLoading(false);
        history?.push(ROUTES?.VENT_TYPES);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.VENT_TYPES} />;
  }

  const initialValues = {
    ...(ventType && { ...objectWithoutKey(ventType, 'logo') }),
    ...(ventType?.logo?.url && {
      logo: { ...ventType?.logo, ...logoUrl }
    }),
    description: ventType?.description || '',
    isActive: ventType?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.VENT_TYPES} />
          {ventType && <span className="portal-header">{ventType?.label}</span>}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={75} />
        ) : (
          <VentTypeForm
            isEdit
            ventTypeData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(VentTypeEdit);
