import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_QUOTE_VIEWS = gql`
  query quoteViews($filter: QuoteFilter!, $where: QuoteViewWhere) {
    quoteViews(filter: $filter, where: $where) {
      count
      data {
        id
        questionnaireId
        leadId
        packageTitle
        sku
        quality
        comments
        flowType
        upgradeInfo {
          upgradeChecks
          otherDetails
        }
        emailMeInfo {
          urgent
          emailConfirmation
        }
        contactMethods
        externalId
        lineOfBusiness {
          id
          key
          label
        }
        subArea {
          id
          key
          label
        }
        customerName
        status
        exportStatus
        externalLeadNumber
        crmLeadId
        workPhone
        homePhone
        textOptInApproval
        textOptInApprovalDate
        emailVerified
        contactPreferences {
          doNotEmail
          doNotPhone
          doNotText
        }
        campaignId
        crmCampaignId
        estimator
        brand {
          name
          logo {
            url
            key
            name
            extension
            contentType
          }
        }
        region {
          name
        }
        customerLocation {
          addressLine1
          addressLine2
          addressLine3
          city
          county
          state
          country
          zipCode
        }
        email
        mobileNumber
        amount
        appointmentTime
        startTime
        endTime
        manufacturer {
          id
          name
        }
        fuelSource {
          id
          key
          label
        }
        ventType {
          id
          key
          label
        }
        capacity {
          id
          key
          label
        }
        height {
          id
          key
          label
        }
        createdAt
      }
    }
  }
`;
