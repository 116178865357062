import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Portal from '../../../components/Portal';
import RoleTable from '../components/RoleTable';

const RoleList = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <span className="portal-header">Roles</span>
      </Portal>
      {pageSize && <RoleTable />}
    </Card>
  );
};

export default RoleList;
