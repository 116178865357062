import {
  CarryOutOutlined,
  DollarOutlined,
  HistoryOutlined,
  HourglassOutlined,
  LeftOutlined,
  LikeOutlined,
  RightOutlined,
  RocketOutlined
} from '@ant-design/icons';
import { Button, Carousel, List, Popover, Tabs } from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import placeholderImage from '../../../../../../assets/preview-product.png';
import timerIcon from '../../../../../../assets/timer.svg';
import { SAME_DAY_TIME } from '../../../../../../common/constants';
import PreviewTagComponent from '../../previewTagComponent';
import '../quotes.less';
import ContactSection from './ContactSection';

const quotesIcons = [
  {
    key: 'carryOutLined',
    icon: <CarryOutOutlined />
  },
  {
    key: 'hourGlassOutLined',
    icon: <HourglassOutlined />
  },
  {
    key: 'likeOutlined',
    icon: <LikeOutlined />
  },
  {
    key: 'rocketOutLined',
    icon: <RocketOutlined />
  },
  {
    key: 'dollarOutLine',
    icon: <DollarOutlined />
  },
  {
    key: 'historyOutLined',
    icon: <HistoryOutlined />
  }
];

const { TabPane } = Tabs;

const ListDataSource = [
  {
    name: 'Water Heater',
    quantity: 1
  },
  {
    name: 'Installation',
    quantity: 1
  },
  {
    name: 'Removal and disposal of your old water heater',
    quantity: 1
  }
];

const QuotesComponent = ({ quotesConfig = null, logoUrl = null }) => {
  const [selectedTab] = useState('recommended');

  return (
    <div className="quotes-page">
      <PreviewTagComponent />
      <div className="logo-section">
        <img alt="logo" src={logoUrl} />
      </div>
      <div className="tab-section">
        <Tabs
          className="product-tabs"
          activeKey={selectedTab}
          size="large"
          centered
        >
          <TabPane tab={quotesConfig?.lowCost} key="lowCost" />
          <TabPane tab={quotesConfig?.recommended} key="recommended">
            <Carousel
              className="common-carousel"
              dots={false}
              arrows
              prevArrow={<LeftOutlined />}
              nextArrow={<RightOutlined />}
            >
              <div className="product-image">
                <img alt="no-product" src={placeholderImage} />
              </div>
            </Carousel>
            <div className="product-label">Package Title</div>
            <div className="package-description">
              Package description will be display here
            </div>
            <List
              dataSource={ListDataSource}
              renderItem={(item) => (
                <List.Item>
                  <span className="quantity">{item?.quantity}x</span>
                  <span className="product-name">{item?.name}</span>
                </List.Item>
              )}
            />
            <div className="price-card">
              <div className="price-section">
                <div className="price-row">
                  {map(quotesIcons, (item) => {
                    if (item?.key === quotesConfig?.price_icon) {
                      return (
                        <span key={item?.key} className="price-icon">
                          {item?.icon}
                        </span>
                      );
                    }
                  })}
                  <span className="price">$XXXX.XX</span>
                </div>
                {quotesConfig?.showCostPerDay && (
                  <div>
                    <span className="cost-per-day">Cost per day: $XX.XX</span>
                  </div>
                )}
              </div>
            </div>
          </TabPane>
          <TabPane tab={quotesConfig?.highEnd} key="highEnd" />
        </Tabs>
      </div>
      <span className="price-description">
        {quotesConfig?.marketingMessage?.price?.description}{' '}
        <div className="price-factor">
          <Popover
            overlayClassName="overlay-text"
            placement="topLeft"
            content={
              <span>
                {quotesConfig?.marketingMessage?.priceFactors?.description}
              </span>
            }
          >
            <span>{quotesConfig?.marketingMessage?.priceFactors?.label}</span>
          </Popover>
        </div>
      </span>
      <div className="marketing-section">
        {quotesConfig?.showCustomerPromotion && (
          <div className="marketing-card customer-card">
            {map(quotesIcons, (item) => {
              if (item?.key === quotesConfig?.marketing_icon) {
                return (
                  <span key={item?.key} className="marketing-icon">
                    {item?.icon}
                  </span>
                );
              }
            })}
            <span className="marketing-title">
              {quotesConfig?.marketingTitle}
            </span>
            <span className="marketing-description">
              {quotesConfig?.marketingDescription}
            </span>
          </div>
        )}
      </div>
      <ContactSection
        phoneNo={quotesConfig?.contactData?.phoneNo}
        email={quotesConfig?.contactData?.email}
        contactInfo={quotesConfig?.contactInfo}
        emailMeButtonLabel={quotesConfig?.emailMeButtonLabel}
        liveChat={quotesConfig?.contactData?.liveChat}
        helpPage={quotesConfig?.contactData?.helpPage}
      />
      <div className="service-section">
        <div className="service-card">
          {quotesConfig?.showCountDown && (
            <div className="countdown-text">
              <img src={timerIcon} alt="timer-icon" />
              <span>00:13:47 {SAME_DAY_TIME}</span>
            </div>
          )}
          <Button
            className="common-button-cpq"
            htmlType="submit"
            id="quotes-select-btn"
            type="primary"
          >
            {quotesConfig?.buttonLabel}
          </Button>
          <Button
            className="common-button-cpq back-button"
            id="quotes-back-btn"
            type="link"
          >
            {quotesConfig?.backLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuotesComponent;
