import { DownloadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { TinyColor } from '@ctrl/tinycolor';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Radio,
  Row
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import api from '../../../common/api';
import { ALLOW_IMAGE_TYPES, WRITE } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import ColorInputComponent from '../../../components/ColorInputComponent';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import EditorComponent from '../../../components/EditorComponent';
import { GET_SIGN_URL } from '../../../components/graphql/Mutation';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import { UPDATE_DEFAULT_BRAND } from '../graphql/Mutations';

const {
  required,

  requiredWhiteSpaceAllowed,
  color
} = formValidatorRules;

const { Option } = SelectComponent;

const BrandSettingForm = ({ brandData, logoObj }) => {
  const [fileList, setFileList] = useState([]);
  const {
    state: { currentUser, permissions },
    dispatch
  } = useContext(AppContext);
  const [secondaryColor, setSecondaryColor] = useState('');
  const [tertiaryColor, setTertiaryColor] = useState('');
  const [colorPalletValue, setColorPalletValue] = useState('');
  const [disabled, setDisable] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const [disableColorPalletSection, setDisableColorPalletSection] = useState(
    true
  );
  const [loading, setLoading] = useState(false);

  const [selectedColor, setSelectedColor] = useState('');

  const [form] = Form?.useForm();
  const colorPalleteOptions = [
    {
      key: 'PRIMARY',
      value: 'Just Primary Color'
    },
    {
      key: 'CUSTOM',
      value: 'Custom'
    },
    {
      key: 'CONSERVATIVE',
      value: 'Conservative'
    },
    {
      key: 'MIDDLE_GROUND',
      value: 'Middle Ground'
    },
    {
      key: 'COLORFUL',
      value: 'Colorful'
    }
  ];

  const [updateDefaultBrand] = useMutation(UPDATE_DEFAULT_BRAND, {
    onCompleted: () => {},
    onError: () => {
      setLoading(false);
    }
  });

  const [uploadFile] = useMutation(GET_SIGN_URL, {
    onCompleted: () => {},
    onError: () => {
      setLoading(false);
    }
  });
  useEffect(() => {
    if (brandData?.logo) {
      setFileList([brandData?.logo]);
    }
    if (brandData?.primaryColor) {
      setDisableColorPalletSection(false);
    }

    if (brandData?.colorPallete === 'PRIMARY') {
      setDisable(true);
    }

    if (brandData?.secondaryColors) {
      setSecondaryColor(brandData?.secondaryColors);
    }
    if (brandData?.tertiaryColors) {
      setTertiaryColor(brandData?.tertiaryColors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedColor || brandData?.primaryColor) {
      if (colorPalletValue === 'CUSTOM') {
        setDisable(false);
        setSecondaryColor('');
        setTertiaryColor('');
        form?.setFieldsValue({ tertiaryColors: '' });
        form?.setFieldsValue({
          secondaryColors: ''
        });
      }
      if (colorPalletValue === 'PRIMARY') {
        setDisable(true);
        form?.setFieldsValue({ tertiaryColors: '' });
        form?.setFieldsValue({
          secondaryColors: ''
        });
        setSecondaryColor('');
        setTertiaryColor('');
      }
      if (colorPalletValue === 'CONSERVATIVE') {
        setDisable(false);
        const monoChromatic = new TinyColor(
          selectedColor || brandData?.primaryColor
        )
          ?.monochromatic(2)
          ?.map((t) => t?.toHexString());
        setSecondaryColor(monoChromatic?.[1]);
        setTertiaryColor('');
        form?.setFieldsValue({
          secondaryColors: monoChromatic?.[1]
        });
        form?.setFieldsValue({ tertiaryColors: '' });
      }

      if (colorPalletValue === 'MIDDLE_GROUND') {
        setDisable(false);
        const analogous = new TinyColor(
          selectedColor || brandData?.primaryColor
        )
          ?.analogous(3)
          ?.map((t) => t?.toHexString());
        setSecondaryColor(analogous?.[1]);
        setTertiaryColor(analogous?.[2]);
        form?.setFieldsValue({
          secondaryColors: analogous?.[1]
        });
        form?.setFieldsValue({ tertiaryColors: analogous?.[2] });
      }
      if (colorPalletValue === 'COLORFUL') {
        const triadic = new TinyColor(selectedColor || brandData?.primaryColor)
          ?.triad()
          ?.map((t) => t?.toHexString());
        setSecondaryColor(triadic?.[1]);
        setTertiaryColor(triadic?.[2]);
        form?.setFieldsValue({
          secondaryColors: triadic?.[1]
        });
        form?.setFieldsValue({ tertiaryColors: triadic?.[2] });
      }
    } else {
      setSelectedColor('');
      setSecondaryColor('');
      setTertiaryColor('');
      form?.setFieldsValue({
        secondaryColors: ''
      });
      form?.setFieldsValue({ tertiaryColors: '' });
      form?.setFieldsValue({ colorPallete: null });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColor, colorPalletValue, brandData?.primaryColor]);

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url }
    } = info;
    if (url) {
      setFileList([]);
      form?.setFieldsValue({
        logo: null
      });
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      if (info?.file?.status === 'removed') {
        form?.setFieldsValue({
          logo: null
        });
      } else {
        form?.setFieldsValue({
          logo: info?.file
        });
      }
      setFileList([...info?.fileList]);
    } else {
      setFileList([]);
      form?.setFieldsValue({
        logo: null
      });
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const handlePickColor = (pickerColor) => {
    setSelectedColor(pickerColor);
    setDisableColorPalletSection(false);
  };

  const handleColorPalateChange = (value) => {
    setColorPalletValue(value);
  };

  const handleColorPaletteClear = () => {
    setSecondaryColor('');
    setTertiaryColor('');
    form?.setFieldsValue({
      secondaryColors: null
    });
    form?.setFieldsValue({ tertiaryColors: null });
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    let logo = {};
    if (values?.logo && !values?.logo?.url) {
      const { name, type } = values?.logo;
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const key = `organization/${currentUser?.tenantId}/${brandData?.uuid}/logo/${newFilename}`;
      const res = await uploadFile({
        variables: {
          action: WRITE,
          extension: `.${ext}`,
          contentType: type,
          key
        }
      });
      if (res?.data?.generateSignedUrl) {
        const { signedRequest, url: URL } = res?.data?.generateSignedUrl;
        // above url is send to the backend
        try {
          const response = await api(signedRequest, {
            method: 'PUT',
            data: values?.logo,
            headers: {
              'Content-Type': type
            }
          });
          if (response) {
            logo = {
              url: URL,
              name,
              key,
              extension: ext,
              contentType: type
            };
          }
        } catch (error) {
          setLoading(false);
          message?.error('got some problem');
        }
      }
    }

    const defaultBrandLogo = {
      url: logoObj?.url,
      name: logoObj?.name,
      key: logoObj?.key,
      extension: logoObj?.ext,
      contentType: logoObj?.type
    };
    const newFormValues = {
      ...values,
      uuid: brandData?.uuid,
      logo: values?.logo?.url ? defaultBrandLogo : logo
    };

    const variables = { data: newFormValues, where: { id: brandData?.id } };
    try {
      const response = await updateDefaultBrand({
        variables: { ...variables }
      });
      if (response?.data?.updateDefaultBrand) {
        setDisableBtn(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const onValuesChange = () => {
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };

  return (
    <div>
      <style>
        {`.ant-checkbox-checked .ant-checkbox-inner { background-color:${
          selectedColor || brandData?.primaryColor
        };border-color:${selectedColor || brandData?.primaryColor}; }
        .common-radio .ant-radio-checked .ant-radio-inner { border: 9px solid ${
          selectedColor || brandData?.primaryColor
        }; }
      }\
          `}
      </style>
      <AccessControl allowedPermissions={['FET_RETAILER_SETTINGS_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="brand-table-save-btn"
            disabled={disableBtn}
            loading={loading}
            type="primary"
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        name="brandSettingForm"
        initialValues={brandData}
        layout="vertical"
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onValuesChange={onValuesChange}
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_RETAILER_SETTINGS_UPDATE'])
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider " />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="logo"
                label="Logo"
                rules={[
                  { required, message: 'Please Upload Logo', type: 'object' }
                ]}
              >
                <DraggerUploadComponent
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                    showRemoveIcon: checkPermissions(permissions, [
                      'FET_RETAILER_SETTINGS_UPDATE'
                    ])
                  }}
                  fileList={fileList}
                  onChange={onChangeUpload}
                >
                  <p className="icon-header">
                    <DownloadOutlined />
                  </p>
                  <p className="upload-title">
                    Drag the retailer's logo here or browse your files
                  </p>
                  <p className="upload-description">
                    200x200 px minimum | JPG, PNG, SVG
                  </p>
                </DraggerUploadComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Name' }]}
                name="name"
                label="Name"
              >
                <InputComponent allowClear placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status'
                  }
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="primaryColor"
                label="Primary Color"
                rules={[
                  { ...required, message: 'Please Select Primary Color' },
                  color
                ]}
              >
                <ColorInputComponent
                  placeholder="Select Primary Colour"
                  onChange={handlePickColor}
                  externalStateUpdate={colorPalletValue}
                  color={selectedColor || brandData?.primaryColor}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="preview-label">Example Area</div>
          <Row gutter={16} className="required-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <div className="preview-div">
                <div className="d-flex justify-between">
                  <div>
                    <div
                      className="preview-blocks"
                      style={{
                        backgroundColor:
                          selectedColor || brandData?.primaryColor,
                        color: '#FFFFFF'
                      }}
                    >
                      <span>Primary Button</span>
                    </div>
                    <div
                      className="preview-blocks"
                      style={{
                        backgroundColor: '#F1F3F7',
                        color: selectedColor || brandData?.primaryColor
                      }}
                    >
                      <span>Secondary Button</span>
                    </div>
                  </div>
                  <div
                    className="preview-link"
                    style={{ color: selectedColor || brandData?.primaryColor }}
                  >
                    <span>Tertiary Action</span>
                  </div>
                  <div>
                    <div className="brand-text-left">
                      <Checkbox disabled />
                      <Checkbox checked>Checkbox</Checkbox>
                    </div>
                    <div className="brand-text-left">
                      <Radio disabled />
                      <Radio className="common-radio" checked>
                        Radio Button
                      </Radio>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <span className="form-divider-text">OPTIONAL</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="colorPallete" label="Color Palette">
                <SelectComponent
                  allowClear
                  onChange={handleColorPalateChange}
                  placeholder=" Select Color Palette"
                  onClear={handleColorPaletteClear}
                  disabled={disableColorPalletSection}
                >
                  {colorPalleteOptions?.map((option) => (
                    <Option key={option?.key} value={option?.key}>
                      {option?.value}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="secondaryColors"
                label="Secondary Color"
                rules={[color]}
              >
                <ColorInputComponent
                  placeholder="Select Secondary Color"
                  color={secondaryColor}
                  externalStateUpdate={colorPalletValue}
                  disabled={disabled || disableColorPalletSection}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="tertiaryColors"
                label="Tertiary Color"
                rules={[color]}
              >
                <ColorInputComponent
                  placeholder="Select Tertiary Color"
                  externalStateUpdate={colorPalletValue}
                  color={tertiaryColor}
                  disabled={disabled || disableColorPalletSection}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" label="Description">
                <EditorComponent placeholder="Enter Description" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="waiverText" label="Waiver Text">
                <EditorComponent placeholder="Enter Waiver Text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="offerText" label="Offer Text">
                <EditorComponent placeholder="Enter Offer Text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="priceGuarantee" label="Price Guarantee">
                <EditorComponent placeholder="Enter Price Guarantee" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="productDetails" label="Product Details">
                <EditorComponent placeholder="Enter Product Details" />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default BrandSettingForm;
