import { useLoadScript } from '@react-google-maps/api';
import { Button, Form } from 'antd';
import React, { useEffect } from 'react';
import MarkerIcon from '../../../../../../assets/marker-icon.svg';
import GoogleMapComponent from '../../globalComponents/GoogleMapComponent';
import NumberComponent from '../../globalComponents/NumberComponent';
import PreviewTagComponent from '../../previewTagComponent';
import '../postalCode.less';

const PostalCodeComponent = ({ postalConfig = null, logoUrl = null }) => {
  const [form] = Form?.useForm();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process?.env?.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const updateHeight = () => {
    // eslint-disable-next-line no-undef
    const mapComponent = document?.getElementsByClassName(
      'common-map-cpq'
    )?.[0];
    if (mapComponent) {
      mapComponent.style.height = `${mapComponent?.clientWidth}px`;
    }
  };

  useEffect(() => {
    updateHeight();
  }, [isLoaded]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('resize', updateHeight);
    // eslint-disable-next-line no-undef
    return () => window?.removeEventListener('resize', updateHeight);
  });

  return (
    <div className="postal-code-preview">
      <PreviewTagComponent />
      <div className="logo-section">
        <img alt="logo" src={logoUrl} />
      </div>
      <div className="title-section">
        <span className="thin-label">{postalConfig?.thinLabel}</span>
        <span className="bold-label">{postalConfig?.boldLabel}</span>
      </div>
      {postalConfig?.showMap && (
        <div className="map-section" id="map-area">
          {isLoaded && <GoogleMapComponent loadError={loadError} />}
        </div>
      )}
      <div className="zip-section">
        <Form form={form} layout="vertical">
          <Form.Item name="zipCode">
            <NumberComponent
              allowClear
              readOnly
              placeholder={postalConfig?.inputPlaceHolder}
              suffix={
                <Button
                  className="marker-btn"
                  type="primary"
                  icon={
                    <img
                      src={MarkerIcon}
                      alt="import-icon"
                      width={11}
                      height={13}
                    />
                  }
                />
              }
            />
          </Form.Item>
        </Form>
        <Button
          className="common-button-cpq"
          htmlType="submit"
          id="zip-code-save-btn"
          type="primary"
        >
          {postalConfig?.label}
        </Button>
      </div>
      <div className="footnote-section">
        <span className="footnote-text">{postalConfig?.footNote}</span>
      </div>
    </div>
  );
};

export default PostalCodeComponent;
