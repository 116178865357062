import { forEach } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppContext } from '../AppContext';
import {
  PERMISSION_API_SETTING,
  PERMISSION_DASHBOARD,
  PERMISSION_HOURS_OF_OPERATION_SETTING,
  PERMISSION_LOB,
  PERMISSION_MANUFACTURER,
  PERMISSION_MASTER,
  PERMISSION_MY_ACCOUNT_SETTING,
  PERMISSION_PLAN_SETTING,
  PERMISSION_PRICING_MODIFIER,
  PERMISSION_PRODUCT,
  PERMISSION_PRODUCT_CATEGORY,
  PERMISSION_PRODUCT_ITEM,
  PERMISSION_PRODUCT_PRICING,
  PERMISSION_PRODUCT_RANKING,
  PERMISSION_QUESTIONNAIRE,
  PERMISSION_QUOTE_VIEW,
  PERMISSION_REGION,
  PERMISSION_RETAILER,
  PERMISSION_RETAILER_SETTING,
  PERMISSION_ROLE,
  PERMISSION_TENANT_SETTING,
  PERMISSION_USER,
  PERMISSION_USER_PERMISSION,
  ROUTES
} from '../common/constants';
import { checkPermissions } from '../common/utils';
import AccessControl from '../components/AccessControl';
import LoaderComponent from '../components/LoaderComponent';
import Error404 from '../Error404';
import ApiSettingWrapper from '../modules/apiSetting';
import BrandWrapper from '../modules/brands';
import BrandSettingWrapper from '../modules/brandSetting';
import CapacityWrapper from '../modules/capacities';
import ContactWrapper from '../modules/contacts';
import ContactTypeWrapper from '../modules/contactTypes';
import CustomerWrapper from '../modules/customers';
import CustomerTypeWrapper from '../modules/customerTypes';
import DashboardWrapper from '../modules/dashboard';
import FuelSourceWrapper from '../modules/fuelSources';
import HeightWrapper from '../modules/heights';
import HoursOfOperationWrapper from '../modules/hoursOfOperation';
import ImportWrapper from '../modules/imports';
import JobWrapper from '../modules/jobs';
import LOBWrapper from '../modules/lineOfBusinesses';
import LocationTypeWrapper from '../modules/locationTypes';
import ManufacturerWrapper from '../modules/manufacturers';
import MyAccountSettingWrapper from '../modules/myAccountSetting';
import MyQuestionnaireWrapper from '../modules/myQuestionnaire';
import NotificationWrapper from '../modules/notification';
import OnBoardingWrapper from '../modules/onboarding';
import PermissionWrapper from '../modules/permissions';
import PlanSettingWrapper from '../modules/planSetting';
import PreferredContactMethodWrapper from '../modules/preferredContactMethods';
import PricingModifierWrapper from '../modules/pricingModifiers';
import ProductCategoryWrapper from '../modules/productCategories';
import ProductItemWrapper from '../modules/productItems';
import ProductItemTypeWrapper from '../modules/productItemTypes';
import ProductPricingWrapper from '../modules/productPricing';
import ProductRankingWrapper from '../modules/productRanking';
import ProductWrapper from '../modules/products';
import PropertyWrapper from '../modules/properties';
import PropertyFinishedBasementCeilingWrapper from '../modules/propertyFinishedBasementCeiling';
import PropertyFinishedBasementWrapper from '../modules/propertyFinishedBasements';
import PropertyHeatSourceWrapper from '../modules/propertyHeatSource';
import PropertyTypeWrapper from '../modules/propertyTypes';
import PropertyWaterHookUpWrapper from '../modules/propertyWaterHookUps';
import QuoteViewerWrapper from '../modules/quoteViewer';
import RegionWrapper from '../modules/regions';
import RolesWrapper from '../modules/roles';
import TenantSettingWrapper from '../modules/tenantSetting';
import UserWrapper from '../modules/users';
import VentTypeWrapper from '../modules/ventTypes';
import sideMenuConfig from './components/sidebar/sideMenuConfig';
import SidePanel from './components/sidepanel';

const ContentRoutes = () => {
  const [loading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState([]);
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);

  useEffect(() => {
    const redirectArray = [];
    forEach(sideMenuConfig, (item, index) => {
      if (item?.children?.length > 0) {
        return forEach(item?.children, (child, childIndex) => {
          if (
            permissions?.length > 0 &&
            child?.permissions?.length > 0 &&
            checkPermissions(permissions, child?.permissions)
          ) {
            redirectArray?.push(child?.key);
            if (childIndex === item?.children?.length - 1) {
              if (index === sideMenuConfig?.length - 1) {
                setLoading(false);
              }
            }
          }
        });
      }
      if (checkPermissions(permissions, item?.permissions)) {
        redirectArray?.push(item?.key);
        if (index === sideMenuConfig?.length - 1) {
          setLoading(false);
        }
      }
    });
    dispatch({ type: 'SET_PERMISSION_REDIRECT', data: redirectArray?.[0] });
    if (!redirectArray?.length) {
      setLoading(false);
    }
    setRedirectTo(redirectArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return (
    <>
      <Switch>
        <Route path="/:type/edit/:id?" component={SidePanel} />
        <Route path="/:type?" component={SidePanel} />
      </Switch>
      <Switch>
        <Route
          exact
          path={ROUTES?.MAIN}
          render={() => <Redirect to={redirectTo?.[0]} />}
        />
        <Route
          path={ROUTES?.DASHBOARD}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_DASHBOARD}
              showNoAccess
            >
              <DashboardWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.QUESTIONNAIRES}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_QUESTIONNAIRE}
              showNoAccess
            >
              <MyQuestionnaireWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.CONTACTS}
          render={(props) => (
            <AccessControl
              allowedPermissions={['PERMISSION_CONTACT']}
              showNoAccess
            >
              <ContactWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.CUSTOMERS}
          render={(props) => (
            <AccessControl
              allowedPermissions={['PERMISSION_CUSTOMER']}
              showNoAccess
            >
              <CustomerWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PROPERTIES}
          render={(props) => (
            <AccessControl
              allowedPermissions={['PERMISSION_PROPERTY']}
              showNoAccess
            >
              <PropertyWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PRODUCTS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_ITEM}
              showNoAccess
            >
              <ProductItemWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PACKAGES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_PRODUCT} showNoAccess>
              <ProductWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PRODUCT_PRICING}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_PRICING}
              showNoAccess
            >
              <ProductPricingWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_RANKING}
              showNoAccess
            >
              <ProductRankingWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PRICING_MODIFIERS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRICING_MODIFIER}
              showNoAccess
            >
              <PricingModifierWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PRODUCT_CATEGORIES}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_CATEGORY}
              showNoAccess
            >
              <ProductCategoryWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.MANUFACTURERS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_MANUFACTURER}
              showNoAccess
            >
              <ManufacturerWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.REGIONS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_REGION} showNoAccess>
              <RegionWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.RETAILERS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_RETAILER}
              showNoAccess
            >
              <BrandWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.USERS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_USER} showNoAccess>
              <UserWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.ROLES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_ROLE} showNoAccess>
              <RolesWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PERMISSIONS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_USER_PERMISSION}
              showNoAccess
            >
              <PermissionWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.LINE_OF_BUSINESSES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_LOB} showNoAccess>
              <LOBWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PRODUCT_ITEM_TYPE}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <ProductItemTypeWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.FUEL_SOURCES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <FuelSourceWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.VENT_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <VentTypeWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.LOCATION_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <LocationTypeWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.CAPACITIES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <CapacityWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.CONTACT_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <ContactTypeWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.CUSTOMER_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <CustomerTypeWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.HEIGHTS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <HeightWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PREFERRED_CONTACT_METHODS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PreferredContactMethodWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PROPERTY_FINISHED_BASEMENTS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyFinishedBasementWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PROPERTY_TYPES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyTypeWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PROPERTY_WATER_HOOK_UPS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyWaterHookUpWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PROPERTY_HEAT_SOURCE}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyHeatSourceWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PROPERTY_FINISHED_BASEMENTS_CEILING}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyFinishedBasementCeilingWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.TENANT_SETTING}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_TENANT_SETTING}
              showNoAccess
            >
              <TenantSettingWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.API_SETTING}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_API_SETTING}
              showNoAccess
            >
              <ApiSettingWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.HOURS_OF_OPERATION}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_HOURS_OF_OPERATION_SETTING}
              showNoAccess
            >
              <HoursOfOperationWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.QUOTE_VIEWER}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_QUOTE_VIEW}
              showNoAccess
            >
              <QuoteViewerWrapper {...props} />
            </AccessControl>
          )}
        />

        <Route
          path={ROUTES?.BUSINESS_INFORMATION}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_RETAILER_SETTING}
              showNoAccess
            >
              <BrandSettingWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.PLAN_SETTING}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_PLAN_SETTING}
              showNoAccess
            >
              <PlanSettingWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.MY_ACCOUNT_SETTING}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_MY_ACCOUNT_SETTING}
              showNoAccess
            >
              <MyAccountSettingWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.JOBS}
          render={(props) => (
            <AccessControl allowedPermissions={['SHOW_JOB']} showNoAccess>
              <JobWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={ROUTES?.IMPORTS}
          render={(props) => (
            <AccessControl allowedPermissions={['SHOW_IMPORT']} showNoAccess>
              <ImportWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route path={ROUTES?.ONBOARDING} component={OnBoardingWrapper} />
        <Route
          path={ROUTES?.NOTIFICATION}
          render={(props) => (
            <AccessControl
              allowedPermissions={['SHOW_NOTIFICATION']}
              showNoAccess
            >
              <NotificationWrapper {...props} />
            </AccessControl>
          )}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
