import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, message, Row } from 'antd';
import generator from 'generate-password';
import { forEach, startCase } from 'lodash';
import React, { useContext, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import CheckedIconComponent from '../../../app/components/iconComponents/CheckedIconComponent';
import { AppContext } from '../../../AppContext';
import notChecked from '../../../assets/images/notChecked.svg';
import { REGEX, ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import history from '../../../historyData';
import { ADD_ADMINISTRATOR_INFO } from '../graphql/Mutation';
import Signup from '../pages/Signup';

const layout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  }
};
const {
  email,
  firstName,
  lastName,
  requiredWithoutMessage
} = formValidatorRules;

const Administrator = () => {
  const { signUpAuth } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [displayErrorList, setDisplayErrorList] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lengthValidation, setLengthValidation] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);
  const [specialCharValidation, setSpecialCharValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validateTrigger, setValidateTrigger] = useState('onSubmit');
  const [disabled, setDisabled] = useState(true);

  const [addAdministratorInfo] = useMutation(ADD_ADMINISTRATOR_INFO, {
    onError() {}
  });
  function successCallback(accessToken, userData) {
    signUpAuth(accessToken, userData);
    history?.push(`${ROUTES?.SIGNUP}/business-info`);
  }

  const onFinish = async (values) => {
    setValidateTrigger('onChange');
    setErrorMessage('');
    setButtonLoading(true);
    try {
      if (
        numberValidation &&
        specialCharValidation &&
        upperCase &&
        lengthValidation
      ) {
        const response = await addAdministratorInfo({
          variables: { data: { ...values, roles: ['TENANT_ADMIN'] } }
        });
        if (
          response?.data?.webRegistration?.authToken &&
          response?.data?.webRegistration?.user
        ) {
          const accessToken = response?.data?.webRegistration?.authToken;
          const userData = response?.data?.webRegistration?.user;
          if (successCallback) {
            successCallback(accessToken, userData);
          }
        } else {
          setButtonLoading(false);
        }
      } else {
        setButtonLoading(false);
        message?.error('Your Password Does Not Meet Requirements.');
      }
    } catch (errors) {
      setButtonLoading(false);
    }
  };

  const onFinishFailed = (values) => {
    const requiredFields = [];
    setValidateTrigger('onChange');
    if (values?.errorFields) {
      forEach(values?.errorFields, (item) => {
        requiredFields?.push(startCase(item?.name?.[0]));
      });
      setErrorMessage(`Enter your valid ${requiredFields?.join(' , ')}`);
    } else {
      setErrorMessage('');
    }
  };

  const onFormChange = (changedValues, allValues) => {
    if (
      allValues?.firstName?.length > 0 &&
      allValues?.lastName?.length > 0 &&
      allValues?.email?.length > 0 &&
      allValues?.password?.length > 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (validateTrigger === 'onChange') {
      const requiredFields = [];

      forEach(allValues, (value, key) => {
        if (key === 'email') {
          if (value && !REGEX?.EMAIL?.test(value)) {
            requiredFields?.push(startCase(key));
          }
        }
        if (!value) {
          requiredFields?.push(startCase(key));
        }
      });

      setErrorMessage(`Enter your valid ${requiredFields?.join(' , ')}`);

      if (!Object?.values(allValues)?.includes(undefined)) {
        setErrorMessage(null);
      }
    }
  };

  const handleChange = (e) => {
    const {
      target: { value }
    } = e;
    if (value?.match(REGEX?.UPPER_CASE)) {
      setUpperCase(true);
    } else {
      setUpperCase(false);
    }

    if (value?.length < 8 || value?.length > 16) {
      setLengthValidation(false);
    } else {
      setLengthValidation(true);
    }

    if (value?.match(REGEX?.SPECIAL_CHAR)) {
      setSpecialCharValidation(true);
    } else {
      setSpecialCharValidation(false);
    }

    if (value?.match(REGEX?.DIGIT)) {
      setNumberValidation(true);
    } else {
      setNumberValidation(false);
    }
  };

  const handleGeneratePassword = () => {
    if (
      form?.getFieldValue('firstName')?.length > 0 &&
      form?.getFieldValue('lastName')?.length > 0 &&
      form?.getFieldValue('email')?.length > 0
    ) {
      setDisabled(false);
    }
    if (errorMessage) {
      if (
        errorMessage?.split(' ')?.length === 3 &&
        errorMessage?.includes('Password')
      ) {
        return setErrorMessage('');
      }
      const newMsg = errorMessage
        ?.replace(', Password', ' ')
        ?.replace('Password', ' ');

      setErrorMessage(newMsg);
    }

    const password = generator?.generate({
      length: 8,
      numbers: true,
      uppercase: true,
      symbols: true,
      strict: true,
      exclude: '<>.?`~"",+-|;:=_()[]{}/'
    });
    form?.setFieldsValue({
      password
    });
    setDisplayErrorList(false);
    setUpperCase(true);
    setLengthValidation(true);
    setSpecialCharValidation(true);
    setNumberValidation(true);
  };
  return (
    <Signup>
      <Form
        {...layout}
        form={form}
        name="tenant-add-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onFormChange}
        autoComplete="off"
        validateTrigger="onChange"
      >
        <Row gutter={16} className="administrator-form">
          <Col md={24} lg={24} xl={12}>
            <Form.Item
              name="firstName"
              rules={[requiredWithoutMessage, firstName]}
            >
              <InputComponent
                autoFocus
                onFocus={() => setDisplayErrorList(false)}
                placeholder="First Name"
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={12}>
            <Form.Item
              name="lastName"
              rules={[requiredWithoutMessage, lastName]}
            >
              <InputComponent
                onFocus={() => setDisplayErrorList(false)}
                placeholder="Last Name"
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={24}>
            <Form.Item name="email" rules={[requiredWithoutMessage, email]}>
              <InputComponent
                onFocus={() => setDisplayErrorList(false)}
                placeholder="Email"
                id="admin-email-input"
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={24}>
            <Form.Item
              name="password"
              className="password-form-item"
              rules={[requiredWithoutMessage]}
              normalize={(value) => value?.split(' ')?.join('')}
            >
              <InputComponent.Password
                className="password-input"
                onChange={(e) => handleChange(e)}
                onFocus={() => setDisplayErrorList(true)}
                prefix={<LockOutlined />}
                placeholder="Password"
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={24}>
            <Form.Item className="gx-text-center">
              <Button
                type="link"
                id="btn-generate-password"
                onClick={() => handleGeneratePassword()}
              >
                Generate strong password
              </Button>
            </Form.Item>
          </Col>
          {displayErrorList && (
            <Col md={24} lg={24} xl={24}>
              <div>
                <p className="password-warning">
                  {upperCase ? (
                    <CheckedIconComponent className="primary-color font-size-22 mr-13" />
                  ) : (
                    <img
                      alt="password validation"
                      className="mr-13"
                      src={notChecked}
                      height="22px"
                      width="22px"
                    />
                  )}
                  1 or More Capital Characters
                </p>
                <p className="password-warning">
                  {lengthValidation ? (
                    <CheckedIconComponent className="primary-color font-size-22 mr-13" />
                  ) : (
                    <img
                      className="mr-13"
                      alt="password validation"
                      src={notChecked}
                      height="22px"
                      width="22px"
                    />
                  )}
                  Password length should be between 8 to 16 characters
                </p>
                <p className="password-warning">
                  {numberValidation ? (
                    <CheckedIconComponent className="primary-color font-size-22 mr-13" />
                  ) : (
                    <img
                      className="mr-13"
                      alt="password validation"
                      src={notChecked}
                      height="22px"
                      width="22px"
                    />
                  )}
                  1 or More Numbers
                </p>
                <p className="password-warning">
                  {specialCharValidation ? (
                    <CheckedIconComponent className="primary-color font-size-22 mr-13" />
                  ) : (
                    <img
                      className="mr-13"
                      alt="password validation"
                      src={notChecked}
                      height="22px"
                      width="22px"
                    />
                  )}
                  1 or More Special Characters (!@#$%^&*)
                </p>
              </div>
            </Col>
          )}
          <Col md={24} lg={24} xl={24}>
            <Form.Item>
              <Button
                type="primary"
                id="btn-administrator"
                htmlType="submit"
                className="fill-width onboarding-btn"
                disabled={disabled}
                loading={buttonLoading}
              >
                Continue to Business Information
              </Button>
            </Form.Item>
          </Col>
          <div className="fill-width d-flex justify-center">
            Have an account already?
            <Link className="ml-8" to={ROUTES?.LOGIN}>
              Click Here
            </Link>
          </div>
          {errorMessage && (
            <Col md={24} lg={24} xl={24}>
              <p className="error">{errorMessage}</p>
            </Col>
          )}
        </Row>
      </Form>
    </Signup>
  );
};

export default withRouter(Administrator);
