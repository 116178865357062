import { UpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Col, Collapse, Form, Row } from 'antd';
import { map } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import { UPDATE_CPQ_CONTACT_ME } from '../graphql/Mutations';

const { Panel } = Collapse;
const { required, requiredWhiteSpaceAllowed, email } = formValidatorRules;
const { Option } = SelectComponent;

const ContactMeForm = ({
  initialValue,
  questionnaireId,
  setContactMeConfig,
  contactMeConfig = null
}) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'title',
    'countdown',
    'optional_comment_placeholder',
    'additional_checkbox_option',
    'button'
  ]);
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const [validationTriggered, setValidationTriggered] = useState(false);

  const checkBoxTitle = {
    URGENT: 'It’s urgent, contact me today',
    CONFIRMATION: 'Send confirmation to my email'
  };

  const [updateContactMeFormData] = useMutation(UPDATE_CPQ_CONTACT_ME, {
    onCompleted() {
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  const onValuesChange = (changedValues, allValues) => {
    setContactMeConfig({
      ...contactMeConfig,
      ...allValues
    });
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onFinish = (values) => {
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setLoading(true);
    setDisableBtn(true);

    const title = {
      thinLabel: values?.thinLabel,
      boldLabel: values?.boldLabel
    };
    const countDown = {
      key: values?.countDownTime,
      checked: values?.showCountDown
    };

    const optionalCommentsPlaceholder = {
      key: values?.commentPlaceHolder,
      checked: values?.showComment
    };

    const tempObj = {
      title,
      countDown,
      optionalCommentsPlaceholder,
      additionalCheckBoxOptions: map(
        initialValue?.additionalCheckBoxOptions,
        (item) => {
          return {
            key: item?.key,
            checked: values?.additionalOptions?.includes(item?.key)
          };
        }
      ),
      email: values?.email,
      buttonLabel: values?.label
    };
    updateContactMeFormData({
      variables: {
        data: tempObj,
        where: {
          questionnaireId
        }
      }
    });
  };
  return (
    <div>
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="contact-me-save-btn"
            loading={loading}
            disabled={disableBtn}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        name="contactForm"
        form={form}
        initialValues={initialValue}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        className="contact-form"
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_QUESTIONNAIRE_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="TITLE" key="title">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Thin Label"
                    rules={[
                      { ...required, message: 'Please Enter Thin Label' }
                    ]}
                    name="thinLabel"
                  >
                    <InputComponent placeholder="Enter Thin Label" />
                  </Form.Item>
                  <Form.Item
                    label="Bold Label"
                    rules={[
                      { ...required, message: 'Please Enter Bold Label' }
                    ]}
                    name="boldLabel"
                  >
                    <InputComponent placeholder="Enter Bold Label" />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel forceRender header="COUNTDOWN" key="countdown">
              <Form.Item name="showCountDown" valuePropName="checked">
                <Checkbox className="common-checkbox contact-checkbox">
                  Countdown
                </Checkbox>
              </Form.Item>
            </Panel>
            <Panel
              forceRender
              header="OPTIONAL COMMENT PLACEHOLDER"
              key="optional_comment_placeholder"
            >
              <Form.Item name="showComment" valuePropName="checked">
                <Checkbox className="common-checkbox contact-checkbox">
                  Optional Comment Placeholder
                </Checkbox>
              </Form.Item>
              <Form.Item
                label="Label"
                rules={[
                  {
                    ...required,
                    message: 'Please Enter CountDown Time'
                  }
                ]}
                name="commentPlaceHolder"
              >
                <InputComponent placeholder="Enter CountDown Time" />
              </Form.Item>
            </Panel>
            <Panel
              forceRender
              header="ADDITIONAL CHECKBOX OPTION"
              key="additional_checkbox_option"
            >
              <Form.Item name="additionalOptions">
                <Checkbox.Group
                  className="fill-width d-flex flex-vertical"
                  value={map(
                    initialValue?.additionalCheckBoxOptions,
                    (item) => item?.key
                  )}
                >
                  {map(
                    initialValue?.additionalCheckBoxOptions,
                    (singleCheckbox) => {
                      return (
                        <Checkbox
                          key={singleCheckbox?.key}
                          value={singleCheckbox?.key}
                          className="common-checkbox additional-checkbox"
                        >
                          {checkBoxTitle[singleCheckbox?.key]}
                        </Checkbox>
                      );
                    }
                  )}
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                name="email"
                label="E-mail PlaceHolder"
                rules={[required, email]}
              >
                <InputComponent placeholder="Email" />
              </Form.Item>
            </Panel>
            <Panel
              forceRender
              header="BUTTON"
              key="button"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Label'
                }
              ]}
            >
              <Form.Item label="Label" name="label">
                <SelectComponent>
                  <Option key="SAVE" value="Save">
                    Save
                  </Option>
                  <Option key="SUBMIT" value="Submit">
                    Submit
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};

export default ContactMeForm;
