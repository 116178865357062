import { gql } from '@apollo/client';

export const UPDATE_FUEL_SOURCE = gql`
  mutation updateFuelSource(
    $where: FuelSourceWhereInput!
    $data: FuelSourceInput!
  ) {
    updateFuelSource(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_FUEL_SOURCE = gql`
  mutation createFuelSource($data: FuelSourceInput!) {
    createFuelSource(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_FUEL_SOURCE = gql`
  mutation deleteFuelSource($where: FuelSourceWhereInput!) {
    deleteFuelSource(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
