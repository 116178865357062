import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Card, Checkbox, Divider, message } from 'antd';
import { filter, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { AppContext } from '../../../AppContext';
import EditIcon from '../../../assets/edit.svg';
import SaveIcon from '../../../assets/save.svg';
import { MODULES, ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import CardWrapper from '../components/BrandTabs';
import { CREATE_BRAND_LOB, UPDATE_BRAND_LOB } from '../graphql/Mutations';
import { GET_BRAND, GET_BRAND_LOB, GET_LOB_LIST } from '../graphql/Queries';

const CheckboxGroup = Checkbox.Group;

const BrandsLobCreate = (props) => {
  const [loading, setLoading] = useState(true);
  const [lobList, setLobList] = useState([]);
  const [defaultLobList, setDefaultLobList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const { match: { params: { id } = {} } = {} } = props;
  const {
    state: { pageSize }
  } = useContext(AppContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [checkBoxArray, setCheckBoxArray] = useState([]);
  const [brandName, setBrandName] = useState('');
  const [showName, setShowName] = useState(false);
  const [checkBoxDisable, setCheckBoxDisable] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setCheckBoxArray((prevArray) => [...prevArray, ...lobList]);
  }, [lobList]);

  useEffect(() => {
    if (location?.pathname?.includes('/view')) {
      setCheckBoxDisable(true);
    } else {
      setCheckBoxDisable(false);
    }
    if (
      location?.pathname?.includes('/view') ||
      location?.pathname?.includes('/edit')
    ) {
      setShowName(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [createBrandLob] = useMutation(CREATE_BRAND_LOB, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const [updateBrandLob] = useMutation(UPDATE_BRAND_LOB, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const { loading: brandLoading } = useQuery(GET_BRAND, {
    variables: { id },
    onCompleted: (res) => {
      if (res?.brand) {
        setBrandName(res?.brand?.name);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const initialBrandLobFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
    justShow: true
  };

  const onChange = (list, lob, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    if (list?.length > 0) {
      checkboxArrayCopy[index].checkedChildren = list;
    } else {
      checkboxArrayCopy[index].checkedChildren = [];
    }
    if (list?.length === lob?.subAreas?.length) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = true;
    } else if (list?.length === 0) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = false;
    } else {
      checkboxArrayCopy[index].indeterminate = true;
      checkboxArrayCopy[index].checkedAll = false;
    }

    setCheckBoxArray(checkboxArrayCopy);
  };

  const onCheckAllChange = (e, lob, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    const checked = [];
    if (e?.target?.checked) {
      forEach(lob?.subAreas, (area) => checked?.push(area?.id));
    }
    if (checkboxArrayCopy?.[index]) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = e?.target?.checked;
      checkboxArrayCopy[index].checkedChildren = checked;
      setCheckBoxArray(checkboxArrayCopy);
    }
  };

  const saveBrandLob = async () => {
    setSubmitLoading(true);
    const sendData = [];

    forEach(checkBoxArray, (item) => {
      if (item?.checkedChildren?.length > 0) {
        sendData?.push({
          lineOfBusinessId: item?.id,
          subAreaId: item?.checkedChildren
        });
      }
    });

    if (sendData?.length > 0) {
      if (isUpdate) {
        const response = await updateBrandLob({
          variables: {
            data: sendData,
            where: {
              id
            }
          }
        });
        if (response?.data?.updateBrandLineOfBusiness) {
          setSubmitLoading(false);
          history?.push(ROUTES?.RETAILERS);
        }
      } else {
        const response = await createBrandLob({
          variables: {
            data: sendData,
            where: {
              id
            }
          }
        });
        if (response?.data?.createBrandLineOfBusiness) {
          setSubmitLoading(false);
          history?.push(ROUTES?.RETAILERS);
        }
      }
    } else {
      setSubmitLoading(false);
      message?.destroy();
      message?.error(`Please select Line Of Business and Subareas.`);
    }
  };

  const createLobArray = (brandRes) => {
    const tempArray = [];

    if (brandRes?.brandLineOfBusinesses?.length > 0) {
      setIsUpdate(true);
      forEach(defaultLobList?.lineOfBusinesses?.data, (lob) => {
        tempArray?.push({
          indeterminate: !!(
            brandRes?.brandLineOfBusinesses?.length > 0 &&
            filter(
              brandRes?.brandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            )?.[0]?.subAreaIds?.length > 0 &&
            filter(
              brandRes?.brandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            )?.[0].subAreaIds?.length !== lob?.subAreas?.length
          ),
          checkedAll: !!(
            brandRes?.brandLineOfBusinesses?.length > 0 &&
            filter(
              brandRes?.brandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            ) &&
            filter(
              brandRes?.brandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            )?.[0]?.subAreaIds?.length === lob?.subAreas?.length
          ),
          checkedChildren:
            brandRes?.brandLineOfBusinesses?.length > 0 &&
            filter(
              brandRes?.brandLineOfBusinesses,
              (item) => item?.lineOfBusinessId === lob?.id
            )?.length > 0
              ? filter(
                  brandRes?.brandLineOfBusinesses,
                  (item) => item?.lineOfBusinessId === lob?.id
                )?.[0]?.subAreaIds
              : [],
          id: lob?.id
        });
      });
      setLobList(tempArray);
    } else {
      setIsUpdate(false);
      forEach(defaultLobList?.lineOfBusinesses?.data, (lob) => {
        tempArray?.push({
          indeterminate: false,
          checkedAll: false,
          checkedChildren: [],
          id: lob?.id
        });
      });
      setLobList(tempArray);
    }
  };

  const [brandsLob] = useLazyQuery(GET_BRAND_LOB, {
    fetchPolicy: 'network-only',
    onCompleted: (brandRes) => {
      setLoading(false);
      createLobArray(brandRes);
    },
    onError: () => {
      setLoading(false);
    }
  });

  const { loading: lobLoading, data: defaultLob } = useQuery(GET_LOB_LIST, {
    variables: {
      filter: initialBrandLobFilter,
      where: {
        isActive: [true]
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLoading(false);
      setDefaultLobList(res);
      brandsLob({
        variables: {
          id
        }
      });
    },
    onError: () => {
      setLoading(false);
    }
  });

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.RETAILERS} />
          {showName ? (
            <span className="portal-header">{brandName}</span>
          ) : (
            <span className="portal-header">Add {MODULES?.RETAILERS}</span>
          )}
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_BRAND_UPDATE']}>
        {location?.pathname?.includes('/view') ? (
          <Portal portalId="header-right-content">
            <Button
              className="common-button"
              size="small"
              htmlType="submit"
              id="manufacturer-table-save-btn"
              type="primary"
              icon={<img src={EditIcon} alt="save-icon" width={12} />}
              onClick={() =>
                history?.push(`${ROUTES?.RETAILERS}/edit/lob/${id}`)
              }
            >
              Edit
            </Button>
          </Portal>
        ) : (
          <Portal portalId="header-right-content">
            <Button
              className="common-button discard-button"
              icon={<DeleteIconComponent />}
              size="small"
              id="brand-table-discard-btn"
              onClick={() => history?.push(ROUTES?.RETAILERS)}
            >
              Discard Changes
            </Button>
            <Button
              className="common-button"
              icon={<img src={SaveIcon} alt="save-icon" width={12} />}
              size="small"
              htmlType="submit"
              id="brand-table-save-btn"
              loading={submitLoading}
              type="primary"
              onClick={saveBrandLob}
            >
              Save
            </Button>
          </Portal>
        )}
      </AccessControl>
      <CardWrapper>
        {lobLoading && loading && brandLoading ? (
          <LoaderComponent />
        ) : (
          <div>
            <div className="lob">
              <div className="d-flex justify-between flex-wrap block-parent">
                {map(defaultLob?.lineOfBusinesses?.data, (lob, index) => {
                  return (
                    <div className="lob-block" key={index}>
                      <Checkbox
                        className="lob-checkbox"
                        id={lob?.id}
                        indeterminate={
                          checkBoxArray?.length > 0 &&
                          checkBoxArray?.[index]?.indeterminate
                        }
                        onChange={(e) => onCheckAllChange(e, lob, index)}
                        checked={
                          checkBoxArray?.length > 0 &&
                          checkBoxArray?.[index]?.checkedAll
                        }
                        disabled={checkBoxDisable}
                      >
                        <span className="bold-label">{lob?.label}</span>
                      </Checkbox>
                      <Divider />
                      <div className="d-flex">
                        <CheckboxGroup
                          disabled={checkBoxDisable}
                          options={map(lob?.subAreas, (area) => {
                            return {
                              label: area?.label,
                              value: area?.id
                            };
                          })}
                          value={
                            checkBoxArray?.length > 0 &&
                            checkBoxArray?.[index]?.checkedChildren
                          }
                          onChange={(list) => onChange(list, lob, index)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </CardWrapper>
    </Card>
  );
};

export default withRouter(BrandsLobCreate);
