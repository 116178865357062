import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import UserForm from '../components/UserForm';
import UserTabs from '../components/UserTabs';
import { UPDATE_USER } from '../graphql/Mutations';
import { GET_USER } from '../graphql/Queries';

function UserEdit(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { user } = {} } = useQuery(GET_USER, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateUserMutate] = useMutation(UPDATE_USER, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const locations = [];
    if (
      formValues?.addressLine1 ||
      formValues?.city ||
      formValues?.state ||
      formValues?.country ||
      formValues?.zipCode
    ) {
      locations?.push({
        addressLine1: formValues?.addressLine1 || null,
        city: formValues?.city || null,
        state: formValues?.state || null,
        country: formValues?.country || null,
        zipCode: formValues?.zipCode || null
      });
    }
    const newFormValues = {
      ...formValues,
      phoneNo: formatPhoneNumberWithoutMask(formValues?.phoneNo),
      locations
    };
    delete newFormValues?.addressLine1;
    delete newFormValues?.city;
    delete newFormValues?.state;
    delete newFormValues?.country;
    delete newFormValues?.zipCode;

    const variables = { data: newFormValues, where: { id: user?.id } };

    try {
      const response = await updateUserMutate({
        variables: { ...variables }
      });
      if (response?.data?.updateUser) {
        setSubmitLoading(false);
        history?.push(ROUTES?.USERS);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.USERS} />;
  }

  const initialValues = {
    ...user,
    ...user?.locations?.[0],
    isActive: user?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.USERS} />
          {user && (
            <span className="portal-header">
              {`${user?.firstName} ${user?.lastName}`}
            </span>
          )}
        </div>
      </Portal>
      <UserTabs>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <UserForm
            isEdit
            userData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </UserTabs>
    </Card>
  );
}

export default withRouter(UserEdit);
