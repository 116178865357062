import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PropertyWaterHookUpCreate from './pages/PropertyWaterHookUpCreate';
import PropertyWaterHookUpEdit from './pages/PropertyWaterHookUpEdit';
import PropertyWaterHookUpList from './pages/PropertyWaterHookUpList';
import './propertyWaterHookUpModule.less';

const PropertyWaterHookUpWrapper = () => {
  return (
    <div className="property-water-hook-up-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PROPERTY_WATER_HOOK_UPS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyWaterHookUpList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PROPERTY_WATER_HOOK_UPS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <PropertyWaterHookUpCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PROPERTY_WATER_HOOK_UPS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <PropertyWaterHookUpEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default PropertyWaterHookUpWrapper;
