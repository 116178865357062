import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import DocumentIconComponent from '../../../app/components/iconComponents/DocumentIconComponent';
import GalleryIconComponent from '../../../app/components/iconComponents/GalleryIconComponent';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import { ROUTES } from '../../../common/constants';
import history from '../../../historyData';

const { TabPane } = Tabs;

const ProductItemTabs = ({ match: { params: { id } = {} } = {}, children }) => {
  const [activeKey, setActiveKey] = useState();
  const location = useLocation();

  useEffect(() => {
    if (
      location?.pathname?.includes('/edit') ||
      location?.pathname?.includes('/view') ||
      location?.pathname?.includes('/add')
    ) {
      setActiveKey('INFORMATION');
    }
    if (location?.pathname?.includes('gallery')) {
      setActiveKey('GALLERY');
    }
    if (location?.pathname?.includes('document')) {
      setActiveKey('DOCUMENT');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTab = (key) => {
    switch (key) {
      case 'INFORMATION':
        if (location?.pathname?.includes('/add')) {
          history?.push(`${ROUTES?.PRODUCTS}/add`);
        } else if (location?.pathname?.includes('/view')) {
          history?.push(`${ROUTES?.PRODUCTS}/view/${id}`);
        } else {
          history?.push(`${ROUTES?.PRODUCTS}/edit/${id}`);
        }
        break;

      case 'GALLERY':
        if (location?.pathname?.includes('/view')) {
          history?.push(`${ROUTES?.PRODUCTS}/view${ROUTES?.GALLERY}/${id}`);
        } else {
          history?.push(`${ROUTES?.PRODUCTS}/edit${ROUTES?.GALLERY}/${id}`);
        }
        break;

      case 'DOCUMENT':
        if (location?.pathname?.includes('/view')) {
          history?.push(`${ROUTES?.PRODUCTS}/view/document/${id}`);
        } else {
          history?.push(`${ROUTES?.PRODUCTS}/edit/document/${id}`);
        }
        break;

      default:
        break;
    }
  };
  const infoTitle = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">Information</span>
    </div>
  );
  const galleryTitle = (
    <div>
      <GalleryIconComponent className="tab-icons" />
      <span className="card-title">Gallery</span>
    </div>
  );

  const documentTitle = (
    <div>
      <DocumentIconComponent className="tab-icons" />
      <span className="card-title">Document</span>
    </div>
  );
  return (
    <div className="card-container">
      <Tabs type="card" activeKey={activeKey} onChange={handleSelectTab}>
        <TabPane tab={infoTitle} key="INFORMATION">
          {children}
        </TabPane>
        {!location?.pathname?.includes('/add') && (
          <TabPane tab={galleryTitle} key="GALLERY">
            {children}
          </TabPane>
        )}
        {!location?.pathname?.includes('/add') && (
          <TabPane tab={documentTitle} key="DOCUMENT">
            {children}
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default withRouter(ProductItemTabs);
