import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Popover,
  Row
} from 'antd';
import { debounce, map, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { AppContext } from '../../../AppContext';
import CheckInfo from '../../../assets/check-info.svg';
import SaveIcon from '../../../assets/save.svg';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import EditorComponent from '../../../components/EditorComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { GET_CAPACITIES } from '../../capacities/graphql/Queries';
import { GET_FUEL_SOURCES } from '../../fuelSources/graphql/Queries';
import { GET_PRODUCT_ITEM_TYPES } from '../../productItemTypes/graphql/Queries';
import { BRAND, REGION } from '../../products/graphql/Queries';
import { GET_VENT_TYPES } from '../../ventTypes/graphql/Queries';
import {
  FETCH_HEIGHTS,
  LINE_OF_BUSINESS,
  MANUFACTURER,
  PRODUCT_CATEGORY,
  SUB_AREA
} from '../graphql/Queries';

let lineOfBusinessScrollDebounceJob;
let subAreaScrollDebounceJob;
let manufacturerScrollDebounceJob;
let productCategoryScrollDebounceJob;
let ventTypeDebounceJob;
let fuelSourceDebounceJob;
let productTypeDebounceJob;
let brandScrollDebounceJob;
let regionScrollDebounceJob;
let capacityScrollDebounceJob;
let heightScrollDebounceJob;

const { required, requiredWhiteSpaceAllowed, number } = formValidatorRules;

const lineOfBusinessFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true
};
const subAreaFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true
};
const manufacturerFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: ''
};
const productCategoryFilter = {
  sortOn: 'title',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: ''
};

const initialVentTypeFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const initialFuelSourceFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const initialProductTypeFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const initialCapacityFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const initialHeightFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const brandFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  defaultBrand: true
};

const regionFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: ''
};

const WORD_LIMIT = 70;

const { Option } = SelectComponent;

const ProductItemForm = (props) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const {
    productItemData = {},
    isSubmit,
    isEdit = false,
    showCreateProductModal = false,
    handleCreateProduct,
    handleCreateProductCancel,
    handleCreateProductForm,
    handleProductItemForm
  } = props;

  const [validationTriggered, setValidationTriggered] = useState(false);

  const [lineOfBusinessLoading, setLineOfBusinessLoading] = useState(false);
  const [lineOfBusinessSearchFlag, setLineOfBusinessSearchFlag] = useState(
    false
  );
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessIsEnd, setLineOfBusinessIsEnd] = useState(false);
  const [lineOfBusinessDebounceCall, setLineOfBusinessDebounceCall] = useState(
    0
  );

  const [subAreaLoading, setSubAreaLoading] = useState(false);
  const [subAreaSearchFlag, setSubAreaSearchFlag] = useState(false);
  const [subAreasData, setSubAreasData] = useState([]);
  const [disableSubArea, setDisableSubArea] = useState(true);
  const [subAreaIsEnd, setSubAreaIsEnd] = useState(false);
  const [subAreaDebounceCall, setSubAreaDebounceCall] = useState(0);

  const [manufacturerLoading, setManufacturerLoading] = useState(false);
  const [manufacturerSearchFlag, setManufacturerSearchFlag] = useState(false);
  const [manufacturersData, setManufacturersData] = useState([]);
  const [manufacturerIsEnd, setManufacturerIsEnd] = useState(false);
  const [manufactureDebounceCall, setManufactureDebounceCall] = useState(0);

  const [productCategoriesData, setProductCategoriesData] = useState([]);
  const [productCategoryLoading, setProductCategoryLoading] = useState(false);
  const [productCategorySearchFlag, setProductCategorySearchFlag] = useState(
    false
  );
  const [productCategoryIsEnd, setProductCategoryIsEnd] = useState(false);
  const [
    productCategoryDebounceCall,
    setProductCategoryDebounceCall
  ] = useState(0);

  const [searchValue, setSearchValue] = useState('');

  const [ventType, setVentType] = useState([]);
  const [ventTypeLoading, setVentTypeLoading] = useState(false);
  const [ventTypeSearchFlag, setVentTypeSearchFlag] = useState(false);
  const [ventTypeIsEnd, setVentTypeIsEnd] = useState(false);
  const [ventTypeDebounceCall, setVentTypeDebounceCall] = useState(0);

  const [fuelSource, setFuelSource] = useState([]);
  const [fuelSourceLoading, setFuelSourceLoading] = useState(false);
  const [fuelSourceSearchFlag, setFuelSourceSearchFlag] = useState(false);
  const [fuelSourceIsEnd, setFuelSourceIsEnd] = useState(false);
  const [fuelSourceDebounceCall, setFuelSourceDebounceCall] = useState(0);

  const [capacityData, setCapacityData] = useState([]);
  const [capacityLoading, setCapacityLoading] = useState(false);
  const [capacitySearchFlag, setCapacitySearchFlag] = useState(false);
  const [capacityIsEnd, setCapacityIsEnd] = useState(false);
  const [capacityDebounceCall, setCapacityDebounceCall] = useState(0);

  const [productType, setProductType] = useState([]);
  const [productTypeLoading, setProductTypeLoading] = useState(false);
  const [productTypeSearchFlag, setProductTypeSearchFlag] = useState(false);
  const [productTypeIsEnd, setProductTypeIsEnd] = useState(false);
  const [productTypeDebounceCall, setProductTypeDebounceCall] = useState(0);

  const [heightData, setHeightData] = useState([]);
  const [heightLoading, setHeightLoading] = useState(false);
  const [heightSearchFlag, setHeightSearchFlag] = useState(false);
  const [heightIsEnd, setHeightIsEnd] = useState(false);
  const [heightDebounceCall, setHeightDebounceCall] = useState(0);

  const [brandLoading, setBrandLoading] = useState(false);
  const [brandSearchFlag, setBrandSearchFlag] = useState(false);
  const [brandsData, setBrandsData] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [debounceCall, setDebounceCall] = useState(0);

  const [regionLoading, setRegionLoading] = useState(false);
  const [regionSearchFlag, setRegionSearchFlag] = useState(false);
  const [regionsData, setRegionsData] = useState([]);
  const [regionIsEnd, setRegionIsEnd] = useState(false);
  const [regionDebounceCall, setRegionDebounceCall] = useState(0);

  const [form] = Form?.useForm();
  const location = useLocation();

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const [brands] = useLazyQuery(BRAND, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.brands?.data?.length < SKIP_RECORD) {
        setIsEnd(true);
      }
      if (brandSearchFlag) {
        setBrandsData([...res?.brands?.data]);
      } else {
        setBrandsData([...brandsData, ...res?.brands?.data]);
      }
      setBrandLoading(false);
    },
    onError() {
      setBrandLoading(false);
    }
  });

  const onBrandScroll = (event) => {
    setBrandSearchFlag(false);
    if (brandScrollDebounceJob) {
      brandScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    brandScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setBrandLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        brands({
          variables: {
            filter: {
              ...brandFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    brandScrollDebounceJob();
  };

  const handleBrandChange = (value) => {
    setBrandSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setBrandLoading(true);
      brands({
        variables: {
          filter: {
            ...brandFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    } else {
      setBrandLoading(true);
      brands({
        variables: {
          filter: {
            ...brandFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedBrandHandler = useCallback(
    debounce(handleBrandChange, 500),
    []
  );

  const handleBrandClear = () => {
    form?.setFieldsValue({
      brands: null
    });
    setBrandsData([]);
    brands({ variables: { filter: brandFilter, where: { isActive: true } } });
  };

  const handleBrandBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
    setBrandSearchFlag(true);
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true }
      }
    });
  };

  const [regions] = useLazyQuery(REGION, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.regions?.data?.length < SKIP_RECORD) {
        setRegionIsEnd(true);
      }
      if (regionSearchFlag) {
        setRegionsData([...res?.regions?.data]);
      } else {
        setRegionsData([...regionsData, ...res?.regions?.data]);
      }
      setRegionLoading(false);
    },
    onError() {
      setRegionLoading(false);
    }
  });

  const onRegionScroll = (event) => {
    setRegionSearchFlag(false);
    if (regionScrollDebounceJob) {
      regionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    regionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !regionIsEnd) {
        setRegionLoading(true);
        setRegionDebounceCall((prevState) => prevState + 1);
        regions({
          variables: {
            filter: {
              ...regionFilter,
              skip: (regionDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    regionScrollDebounceJob();
  };

  const handleRegionChange = (value) => {
    setRegionSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setRegionLoading(true);
      regions({
        variables: {
          filter: {
            ...regionFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    } else {
      setRegionLoading(true);
      regions({
        variables: {
          filter: {
            ...regionFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRegionHandler = useCallback(
    debounce(handleRegionChange, 500),
    []
  );

  const handleRegionClear = () => {
    form?.setFieldsValue({
      regions: null
    });
    setRegionsData([]);
    regions({ variables: { filter: regionFilter, where: { isActive: true } } });
  };

  const handleRegionBlur = () => {
    setSearchValue('');
    setRegionDebounceCall(0);
    setRegionIsEnd(false);
    setRegionSearchFlag(true);
    regions({
      variables: {
        filter: regionFilter,
        where: { isActive: true }
      }
    });
  };

  const [fetchProductItemTypeData] = useLazyQuery(GET_PRODUCT_ITEM_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.productItemTypes?.data?.length < SKIP_RECORD) {
        setProductTypeIsEnd(true);
      }
      if (productTypeSearchFlag) {
        setProductType([...res?.productItemTypes?.data]);
      } else {
        if (isEdit && productItemData?.productTypeData) {
          const tempProductItemType = [
            productItemData?.productTypeData,
            ...res?.productItemTypes?.data
          ];
          setProductType(
            uniqBy([...productType, ...tempProductItemType], 'id')
          );
          return;
        }
        setProductType([...productType, ...res?.productItemTypes?.data]);
      }
      setProductTypeLoading(false);
    },
    onError() {
      setProductTypeLoading(false);
    }
  });

  const onProductTypeScroll = (event) => {
    setProductTypeSearchFlag(false);
    if (productTypeDebounceJob) {
      productTypeDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    productTypeDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;

      if (scrolledToBottom && !productTypeIsEnd) {
        setProductTypeLoading(true);
        setProductTypeDebounceCall((prevState) => prevState + 1);
        fetchProductItemTypeData({
          variables: {
            filter: {
              ...initialProductTypeFilter,
              skip: (productTypeDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);
    productTypeDebounceJob();
  };

  const handleProductTypeChange = (value) => {
    setProductTypeSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setProductTypeLoading(true);
      fetchProductItemTypeData({
        variables: {
          filter: {
            ...initialProductTypeFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setProductTypeLoading(false);
      fetchProductItemTypeData({
        variables: {
          filter: {
            ...initialProductTypeFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProductTypeHandler = useCallback(
    debounce(handleProductTypeChange, 500),
    []
  );

  const handleProductTypeBlur = () => {
    setSearchValue('');
    setProductTypeIsEnd(false);
    setProductTypeDebounceCall(0);
  };

  const handleProductTypeClear = () => {
    form?.setFieldsValue({
      productType: null
    });
    setProductType([]);
    fetchProductItemTypeData({
      variables: { filter: initialProductTypeFilter },
      where: {
        isActive: true
      }
    });
  };

  const [fetchVentTypeData] = useLazyQuery(GET_VENT_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.ventTypes?.data?.length < SKIP_RECORD) {
        setVentTypeIsEnd(true);
      }
      if (ventTypeSearchFlag) {
        setVentType([...res?.ventTypes?.data]);
      } else {
        if (isEdit && productItemData?.ventTypeData) {
          const tempVentTypes = [
            productItemData?.ventTypeData,
            ...res?.ventTypes?.data
          ];
          setVentType(uniqBy([...ventType, ...tempVentTypes], 'id'));
          return;
        }
        setVentType([...ventType, ...res?.ventTypes?.data]);
      }
      setVentTypeLoading(false);
    },
    onError() {
      setVentTypeLoading(false);
    }
  });

  const onVentTypeScroll = (event) => {
    setVentTypeSearchFlag(false);
    if (ventTypeDebounceJob) {
      ventTypeDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    ventTypeDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !ventTypeIsEnd) {
        setVentTypeLoading(true);
        setVentTypeDebounceCall((prevState) => prevState + 1);
        fetchVentTypeData({
          variables: {
            filter: {
              ...initialVentTypeFilter,
              skip: (ventTypeDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);
    ventTypeDebounceJob();
  };

  const handleVentTypeChange = (value) => {
    setVentTypeSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setVentTypeLoading(true);
      fetchVentTypeData({
        variables: {
          filter: {
            ...initialVentTypeFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setVentTypeLoading(false);
      fetchVentTypeData({
        variables: {
          filter: {
            ...initialVentTypeFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedVentTypeHandler = useCallback(
    debounce(handleVentTypeChange, 500),
    []
  );

  const handleVentTypeBlur = () => {
    setSearchValue('');
    setVentTypeIsEnd(false);
    setVentTypeDebounceCall(0);
  };

  const handleVentTypeClear = () => {
    form?.setFieldsValue({
      ventType: null
    });
    setVentType([]);
    fetchVentTypeData({
      variables: { filter: initialVentTypeFilter },
      where: {
        isActive: true
      }
    });
  };

  const [fetchCapacityData] = useLazyQuery(GET_CAPACITIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.capacities?.data?.length < SKIP_RECORD) {
        setCapacityIsEnd(true);
      }

      if (capacitySearchFlag) {
        setCapacityData([...res?.capacities?.data]);
      } else {
        if (isEdit && productItemData?.capacityData) {
          const tempCapacity = [
            productItemData?.capacityData,
            ...res?.capacities?.data
          ];
          setCapacityData(uniqBy([...capacityData, ...tempCapacity], 'id'));
          return;
        }
        setCapacityData([...capacityData, ...res?.capacities?.data]);
      }
      setCapacityLoading(false);
    },
    onError() {
      setCapacityLoading(false);
    }
  });

  const onCapacityScroll = (event) => {
    setCapacitySearchFlag(false);
    if (capacityScrollDebounceJob) {
      capacityScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    capacityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !capacityIsEnd) {
        setCapacityLoading(true);
        setCapacityDebounceCall((prevState) => prevState + 1);
        fetchCapacityData({
          variables: {
            filter: {
              ...initialCapacityFilter,
              skip: (capacityDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);
    capacityScrollDebounceJob();
  };

  const handleCapacityChange = (value) => {
    setCapacitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCapacityLoading(true);
      fetchCapacityData({
        variables: {
          filter: {
            ...initialCapacityFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setCapacityLoading(false);
      fetchCapacityData({
        variables: {
          filter: {
            ...initialCapacityFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCapacityHandler = useCallback(
    debounce(handleCapacityChange, 500),
    []
  );

  const handleCapacityBlur = () => {
    setSearchValue('');
    setCapacityIsEnd(false);
    setCapacityDebounceCall(0);
  };

  const handleCapacityClear = () => {
    form?.setFieldsValue({
      capacity: null
    });
    setCapacityData([]);
    fetchCapacityData({
      variables: { filter: initialCapacityFilter },
      where: {
        isActive: true
      }
    });
  };

  const [fetchFuelSourceData] = useLazyQuery(GET_FUEL_SOURCES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.fuelSources?.data?.length < SKIP_RECORD) {
        setFuelSourceIsEnd(true);
      }
      if (fuelSourceSearchFlag) {
        setFuelSource([...res?.fuelSources?.data]);
      } else {
        if (isEdit && productItemData?.fuelSourceData) {
          const tempFuelSource = [
            productItemData?.fuelSourceData,
            ...res?.fuelSources?.data
          ];
          setFuelSource(uniqBy([...fuelSource, ...tempFuelSource], 'id'));
          return;
        }
        setFuelSource([...fuelSource, ...res?.fuelSources?.data]);
      }
      setFuelSourceLoading(false);
    },
    onError() {
      setFuelSourceLoading(false);
    }
  });

  const onFuelSourceScroll = (event) => {
    setFuelSourceSearchFlag(false);
    if (fuelSourceDebounceJob) {
      fuelSourceDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    fuelSourceDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !fuelSourceIsEnd) {
        setFuelSourceLoading(true);
        setFuelSourceDebounceCall((prevState) => prevState + 1);
        fetchFuelSourceData({
          variables: {
            filter: {
              ...initialFuelSourceFilter,
              skip: (fuelSourceDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);
    fuelSourceDebounceJob();
  };

  const handleFuelSourceChange = (value) => {
    setFuelSourceSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setFuelSourceLoading(true);
      fetchFuelSourceData({
        variables: {
          filter: {
            ...initialFuelSourceFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setFuelSourceLoading(false);
      fetchFuelSourceData({
        variables: {
          filter: {
            ...initialFuelSourceFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFuelSourceHandler = useCallback(
    debounce(handleFuelSourceChange, 500),
    []
  );

  const handleFuelSourceBlur = () => {
    setSearchValue('');
    setFuelSourceIsEnd(false);
    setFuelSourceDebounceCall(0);
  };

  const handleFuelSourceClear = () => {
    form?.setFieldsValue({
      fuelSource: null
    });
    setFuelSource([]);
    fetchFuelSourceData({
      variables: { filter: initialFuelSourceFilter },
      where: {
        isActive: true
      }
    });
  };

  const [lineOfBusinesses] = useLazyQuery(LINE_OF_BUSINESS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.lineOfBusinesses?.data?.length < SKIP_RECORD) {
        setLineOfBusinessIsEnd(true);
      }
      if (lineOfBusinessSearchFlag) {
        setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
      } else {
        if (isEdit) {
          const tempLineOfBusiness = [
            productItemData?.lineOfBusiness,
            ...res?.lineOfBusinesses?.data
          ];
          setLineOfBusinessesData(
            uniqBy([...lineOfBusinessesData, ...tempLineOfBusiness], 'id')
          );
          return;
        }
        setLineOfBusinessesData([
          ...lineOfBusinessesData,
          ...res?.lineOfBusinesses?.data
        ]);
      }
      setLineOfBusinessLoading(false);
    },
    onError() {
      setLineOfBusinessLoading(false);
    }
  });

  const onLineOfBusinessScroll = (event) => {
    setLineOfBusinessSearchFlag(false);
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessIsEnd) {
        setLineOfBusinessLoading(true);
        setLineOfBusinessDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              skip: (lineOfBusinessDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    lineOfBusinessScrollDebounceJob();
  };

  const handleLineOfBusinessChange = (value) => {
    setLineOfBusinessSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setLineOfBusinessLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setLineOfBusinessLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLineOfBusinessHandler = useCallback(
    debounce(handleLineOfBusinessChange, 500),
    []
  );

  const handleLineOfBusinessClear = () => {
    form?.setFieldsValue({
      lineOfBusinesses: null,
      subAreas: null
    });
    setDisableSubArea(true);
    setSubAreaSearchFlag(true);
    setLineOfBusinessesData([]);
    lineOfBusinesses({
      variables: {
        filter: lineOfBusinessFilter,
        where: {
          isActive: true
        }
      }
    });
  };

  const handleLineOfBusinessBlur = () => {
    setSearchValue('');
    setLineOfBusinessDebounceCall(0);
    setLineOfBusinessIsEnd(false);
  };

  const [subAreas] = useLazyQuery(SUB_AREA, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.subAreas?.data?.length < SKIP_RECORD) {
        setSubAreaIsEnd(true);
      }
      if (subAreaSearchFlag) {
        setSubAreasData([...res?.subAreas?.data]);
      } else {
        if (isEdit) {
          const tempSubAreas = [
            productItemData?.subArea,
            ...res?.subAreas?.data
          ];
          setSubAreasData(uniqBy([...subAreasData, ...tempSubAreas], 'id'));
          return;
        }
        setSubAreasData([...subAreasData, ...res?.subAreas?.data]);
      }
      setSubAreaLoading(false);
    },
    onError() {
      setSubAreaLoading(false);
    }
  });

  const handleLobSelect = (value) => {
    setDisableSubArea(false);
    subAreas({
      variables: {
        filter: { ...subAreaFilter, lobId: value },
        where: {
          isActive: true
        }
      }
    });
  };

  const onSubAreaScroll = (event) => {
    setSubAreaSearchFlag(false);
    if (subAreaScrollDebounceJob) {
      subAreaScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    subAreaScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !subAreaIsEnd) {
        setSubAreaLoading(true);
        setSubAreaDebounceCall((prevState) => prevState + 1);
        subAreas({
          variables: {
            filter: {
              ...subAreaFilter,
              skip: (subAreaDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    subAreaScrollDebounceJob();
  };

  const handleSubAreaChange = (value) => {
    setSubAreaSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setSubAreaLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setSubAreaLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubAreaHandler = useCallback(
    debounce(handleSubAreaChange, 500),
    []
  );

  const handleSubAreaClear = () => {
    form?.setFieldsValue({
      subAreas: null
    });
    setSubAreasData([]);
    subAreas({
      variables: { filter: subAreaFilter, where: { isActive: true } }
    });
  };

  const handleSubAreaBlur = () => {
    setSearchValue('');
    setSubAreaDebounceCall(0);
    setSubAreaIsEnd(false);
  };

  const [manufacturers] = useLazyQuery(MANUFACTURER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.manufacturers?.data?.length < SKIP_RECORD) {
        setManufacturerIsEnd(true);
      }
      if (manufacturerSearchFlag) {
        setManufacturersData([...res?.manufacturers?.data]);
      } else {
        if (isEdit) {
          const tempManufacturer = [
            productItemData?.manufacturer,
            ...res?.manufacturers?.data
          ];
          setManufacturersData(
            uniqBy([...manufacturersData, ...tempManufacturer], 'id')
          );
          return;
        }
        setManufacturersData([
          ...manufacturersData,
          ...res?.manufacturers?.data
        ]);
      }
      setManufacturerLoading(false);
    },
    onError() {
      setManufacturerLoading(false);
    }
  });

  const onManufacturerScroll = (event) => {
    setManufacturerSearchFlag(false);
    if (manufacturerScrollDebounceJob) {
      manufacturerScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    manufacturerScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !manufacturerIsEnd) {
        setManufacturerLoading(true);
        setManufactureDebounceCall((prevState) => prevState + 1);
        manufacturers({
          variables: {
            filter: {
              ...manufacturerFilter,
              skip: (manufactureDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    manufacturerScrollDebounceJob();
  };

  const handleManufacturerChange = (value) => {
    setManufacturerSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setManufacturerLoading(true);
      manufacturers({
        variables: {
          filter: {
            ...manufacturerFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setManufacturerLoading(true);
      manufacturers({
        variables: {
          filter: {
            ...manufacturerFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedManufacturerHandler = useCallback(
    debounce(handleManufacturerChange, 500),
    []
  );

  const handleManufacturerClear = () => {
    form?.setFieldsValue({
      manufacturers: null
    });
    setManufacturersData([]);
    manufacturers({
      variables: {
        filter: manufacturerFilter,
        where: {
          isActive: true
        }
      }
    });
  };

  const handleManufacturerBlur = () => {
    setSearchValue('');
    setManufacturerIsEnd(false);
    setManufactureDebounceCall(0);
  };

  const [productCategories] = useLazyQuery(PRODUCT_CATEGORY, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.productCategories?.data?.length < SKIP_RECORD) {
        setProductCategoryIsEnd(true);
      }
      if (productCategorySearchFlag) {
        setProductCategoriesData([...res?.productCategories?.data]);
      } else {
        if (isEdit && productItemData?.productCategory?.id) {
          const tempProductCategories = [
            productItemData?.productCategory,
            ...res?.productCategories?.data
          ];
          setProductCategoriesData(
            uniqBy([...productCategoriesData, ...tempProductCategories], 'id')
          );
          return;
        }
        setProductCategoriesData([
          ...productCategoriesData,
          ...res?.productCategories?.data
        ]);
      }
      setProductCategoryLoading(false);
    },
    onError() {
      setProductCategoryLoading(false);
    }
  });

  const onProductCategoryScroll = (event) => {
    setProductCategorySearchFlag(false);
    if (productCategoryScrollDebounceJob) {
      productCategoryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    productCategoryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !productCategoryIsEnd) {
        setProductCategoryLoading(true);
        setProductCategoryDebounceCall((prevState) => prevState + 1);
        productCategories({
          variables: {
            filter: {
              ...productCategoryFilter,
              skip: (productCategoryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    productCategoryScrollDebounceJob();
  };

  const handleProductCategoryChange = (value) => {
    setProductCategorySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setProductCategoryLoading(true);
      productCategories({
        variables: {
          filter: {
            ...productCategoryFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setProductCategoryLoading(true);
      productCategories({
        variables: {
          filter: {
            ...productCategoryFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProductCategoryHandler = useCallback(
    debounce(handleProductCategoryChange, 500),
    []
  );

  const handleProductCategoryClear = () => {
    form?.setFieldsValue({
      productCategories: null
    });
    setProductCategoriesData([]);
    productCategories({
      variables: {
        filter: productCategoryFilter,
        where: {
          isActive: true
        }
      }
    });
  };

  const handleProductCategoryBlur = () => {
    setSearchValue('');
    setProductCategoryDebounceCall(0);
    setProductCategoryIsEnd(false);
  };

  const [fetchHeightData] = useLazyQuery(FETCH_HEIGHTS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.heights?.data?.length < SKIP_RECORD) {
        setHeightIsEnd(true);
      }
      if (heightSearchFlag) {
        setHeightData([...res?.heights?.data]);
      } else {
        if (isEdit && productItemData?.heightData) {
          const heightArray = [
            productItemData?.heightData,
            ...res?.heights?.data
          ];
          setHeightData(uniqBy([...heightData, ...heightArray], 'id'));
          return;
        }
        setHeightData([...heightData, ...res?.heights?.data]);
      }
      setHeightLoading(false);
    },
    onError() {
      setHeightLoading(false);
    }
  });

  const onHeightScroll = (event) => {
    setHeightSearchFlag(false);
    if (heightScrollDebounceJob) {
      heightScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    heightScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;

      if (scrolledToBottom && !heightIsEnd) {
        setHeightLoading(true);
        setHeightDebounceCall((prevState) => prevState + 1);
        fetchHeightData({
          filter: {
            ...initialHeightFilter,
            skip: (heightDebounceCall + 1) * SKIP_RECORD,
            search: searchValue
          },
          where: {
            isActive: true
          }
        });
      }
    }, 500);
    heightScrollDebounceJob();
  };

  const handleHeightChange = (value) => {
    setHeightSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setHeightLoading(true);
      fetchHeightData({
        variables: {
          filter: {
            ...initialHeightFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setHeightLoading(false);
      fetchHeightData({
        variables: {
          filter: {
            ...initialHeightFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHeightHandler = useCallback(
    debounce(handleHeightChange, 500),
    []
  );

  const handleHeightBlur = () => {
    setSearchValue('');
    setHeightIsEnd(false);
    setHeightDebounceCall(0);
  };

  const handleHeightClear = () => {
    form?.setFieldsValue({
      height: null
    });
    setHeightData([]);
    fetchHeightData({
      variables: { filter: initialHeightFilter },
      where: {
        isActive: true
      }
    });
  };

  useEffect(() => {
    if (location?.pathname?.includes('/edit')) {
      setDisableSubArea(false);
    }

    if (productItemData?.lineOfBusiness?.id) {
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            lobId: productItemData?.lineOfBusiness?.id
          },
          where: {
            isActive: true
          }
        }
      });
    }
    lineOfBusinesses({
      variables: {
        filter: lineOfBusinessFilter,
        where: {
          isActive: true
        }
      }
    });
    manufacturers({
      variables: {
        filter: manufacturerFilter,
        where: {
          isActive: true
        }
      }
    });
    productCategories({
      variables: {
        filter: productCategoryFilter,
        where: {
          isActive: true
        }
      }
    });
    fetchVentTypeData({
      variables: {
        filter: initialVentTypeFilter,
        where: {
          isActive: true
        }
      }
    });
    fetchFuelSourceData({
      variables: {
        filter: initialFuelSourceFilter,
        where: {
          isActive: true
        }
      }
    });
    fetchCapacityData({
      variables: {
        filter: initialCapacityFilter,
        where: {
          isActive: true
        }
      }
    });
    fetchProductItemTypeData({
      variables: {
        filter: initialProductTypeFilter,
        where: {
          isActive: true
        }
      }
    });
    fetchHeightData({
      variables: {
        filter: initialHeightFilter,
        where: {
          isActive: true
        }
      }
    });
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true }
      }
    });
    regions({
      variables: {
        filter: regionFilter,
        where: { isActive: true }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productModalHeader = (
    <>
      <div className="create-product-modal-info">
        <span>ADD PACKAGE</span>
        <Popover
          content="Please select Region(s) & Retailers(s) from the available list. The respective product will be available within these region(s) for selected retailers(s)."
          placement="top"
          trigger="hover"
        >
          <InfoCircleOutlined />
        </Popover>
      </div>
    </>
  );
  const uniOfMeasureToolTip = (
    <div className="d-flex flex-vertical unit-of-measure-tooltip unit-of-measure-padding">
      <span className="single-unit">Meter (m)</span>
      <span className="single-unit">Inches (In)</span>
      <span className="single-unit">Kilometer (km)</span>
      <span className="single-unit">Yard (yd)</span>
      <span className="single-unit">Feet (ft)</span>
      <span className="single-unit">Mass - Gram (gm)</span>
      <span className="single-unit"> Mass - Pound (lbs)</span>
      <span className="single-unit">Mass - kilogram (kg)</span>
      <span className="single-unit">Pieces (pcs)</span>
    </div>
  );
  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        dispatch({ type: 'SET_SHOW_PROMPT', data: false });
        if (name === 'productItemForm') {
          handleProductItemForm(values);
        }
        if (name === 'createProductForm') {
          handleCreateProductForm(values);
        }
      }}
    >
      <div>
        <AccessControl
          allowedPermissions={
            isEdit ? ['FET_PRODUCT_ITEM_UPDATE'] : ['FET_PRODUCT_ITEM_CREATE']
          }
        >
          <Portal portalId="header-right-content">
            <Button
              className="common-button discard-button"
              icon={<DeleteIconComponent />}
              size="small"
              id="product-item-table-discard-btn"
              onClick={() => history?.push(ROUTES?.PRODUCTS)}
            >
              {isEdit ? 'Discard Changes' : 'Cancel'}
            </Button>
            <Button
              className="common-button"
              icon={<img src={SaveIcon} alt="save-icon" width={12} />}
              size="small"
              htmlType="submit"
              id="product-item-table-save-btn"
              loading={isSubmit}
              type="primary"
              onClick={() => form?.submit()}
            >
              Save
            </Button>
          </Portal>
        </AccessControl>
        <Form
          form={form}
          name="productItemForm"
          initialValues={productItemData}
          layout="vertical"
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
          onValuesChange={() =>
            dispatch({ type: 'SET_SHOW_PROMPT', data: true })
          }
          onFinishFailed={onFinishFailed}
        >
          <fieldset
            disabled={
              !checkPermissions(
                permissions,
                isEdit
                  ? ['FET_PRODUCT_ITEM_UPDATE']
                  : ['FET_PRODUCT_ITEM_CREATE']
              )
            }
          >
            <span className="form-divider-text">MANDATORY</span>
            <Divider className="form-divider" />
            <Row gutter={16} className="required-row">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  rules={[{ ...required, message: 'Please Enter Name' }]}
                  name="name"
                  label="Name"
                >
                  <InputComponent allowClear placeholder="Enter Name" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="manufacturerId"
                  label="Manufacturer"
                  rules={[
                    {
                      required,
                      message: 'Please Select Manufacturer'
                    }
                  ]}
                >
                  <SelectComponent
                    placeholder="Select Manufacturer"
                    allowClear
                    notFoundContent={
                      manufacturerLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleManufacturerBlur}
                    onSearch={debouncedManufacturerHandler}
                    onClear={handleManufacturerClear}
                    onPopupScroll={onManufacturerScroll}
                  >
                    {map(manufacturersData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  rules={[{ ...required, message: 'Please Select SKU' }]}
                  name="sku"
                  label="SKU"
                >
                  <InputComponent allowClear placeholder="Enter SKU" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="lineOfBusinessId"
                  label="Line of Business"
                  rules={[
                    {
                      required,
                      message: 'Please Select Line Of Business'
                    }
                  ]}
                >
                  <SelectComponent
                    placeholder="Select Line Of Business"
                    allowClear
                    notFoundContent={
                      lineOfBusinessLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleLineOfBusinessBlur}
                    onSearch={debouncedLineOfBusinessHandler}
                    onClear={handleLineOfBusinessClear}
                    onPopupScroll={onLineOfBusinessScroll}
                    onSelect={handleLobSelect}
                  >
                    {map(lineOfBusinessesData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="subAreaId"
                  label="Sub-Area"
                  rules={[
                    {
                      required,
                      message: 'Please Select Sub Area'
                    }
                  ]}
                >
                  <SelectComponent
                    placeholder="Select Sub Area"
                    allowClear
                    disabled={disableSubArea}
                    notFoundContent={
                      subAreaLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleSubAreaBlur}
                    onSearch={debouncedSubAreaHandler}
                    onClear={handleSubAreaClear}
                    onPopupScroll={onSubAreaScroll}
                  >
                    {map(subAreasData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Enter Cost Price'
                    }
                  ]}
                  name="costPrice"
                  label="Cost"
                >
                  <PriceComponent isPrice placeholder="Enter Cost Price" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Enter Default Price'
                    }
                  ]}
                  name="defaultPrice"
                  label="Default Price"
                >
                  <PriceComponent isPrice placeholder="Enter Default Price" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="isActive"
                  label="Status"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Status'
                    }
                  ]}
                >
                  <SelectComponent placeholder="Select Status" allowClear>
                    <Option key="active" value>
                      Active
                    </Option>
                    <Option key="inactive" value={false}>
                      InActive
                    </Option>
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="stock"
                  label="Stock"
                  rules={[
                    number,
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Enter Stock'
                    }
                  ]}
                >
                  <NumberComponent placeholder="Enter Stock" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  name="quoteDescription"
                  className="tooltip-label quote-description-label"
                  rules={[
                    { ...required, message: 'Please Enter Quote Description' },
                    {
                      max: WORD_LIMIT,
                      message: `Please Enter Max ${WORD_LIMIT} Characters`
                    }
                  ]}
                  label="Quote Description"
                  tooltip={{
                    title: (
                      <span className="fill-width">
                        This will be shown on the quote page.
                      </span>
                    ),
                    overlayClassName: 'quote-description-popover',
                    icon: <img src={CheckInfo} alt="check-info" />
                  }}
                >
                  <InputComponent
                    showCount
                    maxLength={WORD_LIMIT}
                    placeholder="Enter Quote Description"
                  />
                </Form.Item>
              </Col>
            </Row>
            <span className="form-divider-text">OPTIONAL</span>
            <Divider className="form-divider" />
            <Row gutter={16} className="optional-fields">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item name="description" label="Product Description">
                  <EditorComponent placeholder="Product Description goes here..." />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="productCategories" label="Product Category">
                  <SelectComponent
                    placeholder="Select Product Category"
                    allowClear
                    notFoundContent={
                      productCategoryLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleProductCategoryBlur}
                    onSearch={debouncedProductCategoryHandler}
                    onClear={handleProductCategoryClear}
                    onPopupScroll={onProductCategoryScroll}
                  >
                    {map(productCategoriesData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.title}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="productItemModel" label="Model">
                  <InputComponent
                    allowClear
                    placeholder="Enter Product Model"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="productItemMaterial" label="Material">
                  <InputComponent
                    allowClear
                    placeholder="Enter Product Material"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="productType" label="Type">
                  <SelectComponent
                    placeholder="Select Product Type"
                    allowClear
                    notFoundContent={
                      productTypeLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleProductTypeBlur}
                    onSearch={debouncedProductTypeHandler}
                    onClear={handleProductTypeClear}
                    onPopupScroll={onProductTypeScroll}
                  >
                    {map(productType, (item) => (
                      <Option key={item?.key} value={item?.key}>
                        {item?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item label="HSN/SAC" name="hsn">
                  <InputComponent allowClear placeholder="Enter HSN/SAC" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  className="tooltip-label"
                  name="unitForMeasure"
                  label="Unit Of Measure"
                  tooltip={{
                    title: uniOfMeasureToolTip,
                    icon: <img src={CheckInfo} alt="check-info" />
                  }}
                >
                  <InputComponent
                    allowClear
                    placeholder="Enter Unit Of Measure"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="btu" label="BTU">
                  <InputComponent
                    allowClear
                    placeholder="Enter British Thermal Unit"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="productItemWarranty"
                  label="Product Warranty (year)"
                >
                  <NumberComponent placeholder="Enter Warranty" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="labourWarranty" label="Labor Warranty (year)">
                  <NumberComponent placeholder="Enter Labor Warranty" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="width" label="Width (in)">
                  <PriceComponent
                    showPrefix={false}
                    placeholder="Enter Width"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="depth" label="Depth/Diameter (in)">
                  <PriceComponent
                    showPrefix={false}
                    placeholder="Enter Depth/Diameter"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="heightInNumber" label="Height (in)">
                  <InputComponent placeholder="Enter Height" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="height" label="Height Classification">
                  <SelectComponent
                    placeholder="Select Height Classification"
                    allowClear
                    notFoundContent={
                      heightLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleHeightBlur}
                    onSearch={debouncedHeightHandler}
                    onClear={handleHeightClear}
                    onPopupScroll={onHeightScroll}
                  >
                    {map(heightData, (height) => (
                      <Option key={height?.key} value={height?.key}>
                        {height?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="weight" label="Weight (lbs)">
                  <PriceComponent
                    showPrefix={false}
                    placeholder="Enter Weight"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="fuelSource" label="Fuel Source">
                  <SelectComponent
                    placeholder="Select Fuel Source"
                    allowClear
                    notFoundContent={
                      fuelSourceLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleFuelSourceBlur}
                    onSearch={debouncedFuelSourceHandler}
                    onClear={handleFuelSourceClear}
                    onPopupScroll={onFuelSourceScroll}
                  >
                    {map(fuelSource, (fuel) => (
                      <Option key={fuel?.key} value={fuel?.key}>
                        {fuel?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="ventType" label="Vent Type">
                  <SelectComponent
                    placeholder="Select Vent Type"
                    allowClear
                    notFoundContent={
                      ventTypeLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleVentTypeBlur}
                    onSearch={debouncedVentTypeHandler}
                    onClear={handleVentTypeClear}
                    onPopupScroll={onVentTypeScroll}
                  >
                    {map(ventType, (type) => (
                      <Option key={type?.key} value={type?.key}>
                        {type?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="capacity" label="Capacity">
                  <SelectComponent
                    placeholder="Select Capacity"
                    allowClear
                    notFoundContent={
                      capacityLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleCapacityBlur}
                    onSearch={debouncedCapacityHandler}
                    onClear={handleCapacityClear}
                    onPopupScroll={onCapacityScroll}
                  >
                    {map(capacityData, (capacity) => (
                      <Option key={capacity?.key} value={capacity?.key}>
                        {capacity?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="energyEfficiency" label="Energy Efficiency">
                  <NumberComponent placeholder="Enter Energy Efficiency" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="style" label="Style">
                  <InputComponent
                    allowClear
                    placeholder="Define style of the product"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="softness" label="Softness">
                  <InputComponent allowClear placeholder="Enter Softness" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="noXLevel" label="NOx Level">
                  <InputComponent allowClear placeholder="Enter NOx Level" />
                </Form.Item>
              </Col>
              <AccessControl allowedPermissions={['FET_PRODUCT_CREATE']}>
                {!isEdit && (
                  <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                    <div className="d-flex  create-product">
                      <Form.Item name="createProduct" valuePropName="checked">
                        <Checkbox className="product-item-checkbox">
                          Create Package
                        </Checkbox>
                      </Form.Item>
                      <Popover content="If this option is selected, a package for this product will be created.">
                        <img
                          src={CheckInfo}
                          alt="check-info"
                          className="optional-fields-popover"
                        />
                      </Popover>
                    </div>
                  </Col>
                )}
              </AccessControl>
            </Row>
            <span className="form-divider-text">ADDITIONAL OPTIONS</span>
            <Divider className="form-divider" />
            <Row gutter={16}>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="isSellable" valuePropName="checked">
                    <Checkbox className="product-item-checkbox">
                      Sellable
                    </Checkbox>
                  </Form.Item>
                  <Popover content="This product will be available for sell if this option is selected.">
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="options" valuePropName="checked">
                    <Checkbox className="product-item-checkbox">
                      Options (EnergyStar)
                    </Checkbox>
                  </Form.Item>
                  <Popover content="This product  supports Energy Star ratings if this option is selected.">
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="compostable" valuePropName="checked">
                    <Checkbox className="product-item-checkbox">
                      Compostable
                    </Checkbox>
                  </Form.Item>
                  <Popover content="This product  can be composted if this option is  selected.">
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="lowVoc" valuePropName="checked">
                    <Checkbox className="product-item-checkbox">
                      Low VOC
                    </Checkbox>
                  </Form.Item>
                  <Popover content="This product  has minimal VOC if this option is selected.">
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="allowPriceChange" valuePropName="checked">
                    <Checkbox className="product-item-checkbox">
                      Allow Price Change
                    </Checkbox>
                  </Form.Item>
                  <Popover content="if this option is selected, technicians will be able to adjust the price when they arrive at customer's location?.">
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="manageInventory" valuePropName="checked">
                    <Checkbox className="product-item-checkbox">
                      Manage Inventory
                    </Checkbox>
                  </Form.Item>
                  <Popover content="If you select this option, inventory for this product  will be managed.">
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="paintable" valuePropName="checked">
                    <Checkbox className="product-item-checkbox">
                      Paintable
                    </Checkbox>
                  </Form.Item>
                  <Popover content="This item can be painted if this option is selected.">
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="asset" valuePropName="checked">
                    <Checkbox className="product-item-checkbox">Asset</Checkbox>
                  </Form.Item>
                  <Popover content="This item will be considered as an Asset for the property where it is installed if this option is selected .">
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
            </Row>
          </fieldset>
        </Form>
        <ModalComponent
          title={productModalHeader}
          footer={null}
          wrapClassName="create-product-modal"
          visible={showCreateProductModal}
          onOk={handleCreateProduct}
          destroyOnClose
          onCancel={handleCreateProductCancel}
          width={600}
        >
          <div>
            <Form
              onValuesChange={() =>
                dispatch({ type: 'SET_SHOW_PROMPT', data: true })
              }
              layout="vertical"
              name="createProductForm"
              preserve={false}
            >
              <Row gutter={16} className="create-product-required-row">
                <Col md={24} lg={24} xl={24}>
                  <Form.Item
                    rules={[
                      {
                        required,
                        message: 'Please Select Retailer',
                        type: 'array'
                      }
                    ]}
                    name="brands"
                    label="Retailer"
                  >
                    <SelectComponent
                      placeholder="Select Retailers"
                      allowClear
                      mode="multiple"
                      notFoundContent={
                        brandLoading ? (
                          <LoaderComponent size="small" setHeight={10} />
                        ) : (
                          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                        )
                      }
                      onBlur={handleBrandBlur}
                      onSearch={debouncedBrandHandler}
                      onClear={handleBrandClear}
                      onPopupScroll={onBrandScroll}
                    >
                      {map(brandsData, (item) => (
                        <Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Option>
                      ))}
                    </SelectComponent>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required,
                        message: 'Please Select Region',
                        type: 'array'
                      }
                    ]}
                    name="regions"
                    label="Region"
                  >
                    <SelectComponent
                      placeholder="Select Regions"
                      allowClear
                      mode="multiple"
                      dropdownRender={(menu) => (
                        <>
                          <div className="create-product-select">{menu}</div>
                        </>
                      )}
                      notFoundContent={
                        regionLoading ? (
                          <LoaderComponent size="small" setHeight={10} />
                        ) : (
                          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                        )
                      }
                      onBlur={handleRegionBlur}
                      onSearch={debouncedRegionHandler}
                      onClear={handleRegionClear}
                      onPopupScroll={onRegionScroll}
                    >
                      {map(regionsData, (item) => (
                        <Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Option>
                      ))}
                    </SelectComponent>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        ...requiredWhiteSpaceAllowed,
                        message: 'Please Select Quality'
                      }
                    ]}
                    name="productQuality"
                    label="Package Quality"
                  >
                    <SelectComponent placeholder="Select Quality" allowClear>
                      <Option key="GOOD" value="GOOD">
                        Good
                      </Option>
                      <Option key="BETTER" value="BETTER">
                        Better
                      </Option>
                      <Option key="BEST" value="BEST">
                        Best
                      </Option>
                    </SelectComponent>
                  </Form.Item>
                  <div className="d-flex justify-end">
                    <Form.Item className="btn-bottom-margin">
                      <Button
                        className="common-button"
                        size="small"
                        htmlType="submit"
                        id="product-create-save-btn"
                        type="primary"
                      >
                        Add Package
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </ModalComponent>
      </div>
    </Form.Provider>
  );
};

export default ProductItemForm;
