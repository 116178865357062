import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import './styles/DatePickerComponent.less';

const DatePickerComponent = (props) => {
  const {
    showTime = false,
    disabledDate = false,
    showToday = false,
    className = '',
    disablePastDate = true,
    disableFutureDate = false,
    ...rest
  } = props;

  const disabledDateFun = (current) => {
    if (disableFutureDate) {
      return current && current > moment()?.startOf('day');
    }
    if (disablePastDate) {
      // Can not select days before today
      return current && current < moment()?.startOf('day');
    }
  };

  return (
    <DatePicker
      className={`datepicker-component ${className}`}
      bordered
      format={showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
      showTime={showTime}
      showToday={showToday}
      disabledDate={disabledDate && disabledDateFun}
      {...rest}
    />
  );
};

export default DatePickerComponent;
