import { useLazyQuery, useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import CustomerCreationModal from '../components/CustomerCreationModal';
import CustomerForm from '../components/CustomerForm';
import CardWrapper from '../components/CustomerTabs';
import { CREATE_CUSTOMER } from '../graphql/Mutations';
import { GET_CUSTOMERS } from '../graphql/Queries';

function CustomerCreate() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [duplicateData, setDuplicateData] = useState([]);
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);

  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const submitData = async () => {
    setSubmitLoading(true);
    const newFormValues = {
      userInput: {
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        phoneNo: formData?.phoneNo,
        email: formData?.email
      },
      location: {
        addressLine1: formData?.addressLine1,
        city: formData?.city,
        state: formData?.state,
        country: formData?.country,
        zipCode: formData?.zipCode
      },
      ...formData
    };

    delete newFormValues?.firstName;
    delete newFormValues?.lastName;
    delete newFormValues?.phoneNo;
    delete newFormValues?.email;
    delete newFormValues?.addressLine1;
    delete newFormValues?.city;
    delete newFormValues?.state;
    delete newFormValues?.country;
    delete newFormValues?.zipCode;

    const variables = { data: newFormValues };

    try {
      const response = await createCustomer({
        variables: { ...variables }
      });
      if (response?.data?.createCustomer) {
        const { id } = response?.data?.createCustomer?.data;
        setSubmitLoading(false);
        setDuplicateModalVisible(false);
        history?.push(`${ROUTES?.CUSTOMERS}/edit${ROUTES?.CONTACTS}/${id}`);
      }
    } catch (error) {
      setSubmitLoading(false);
      setDuplicateModalVisible(false);
      return error;
    }
  };

  const [fetchCustomerData] = useLazyQuery(GET_CUSTOMERS, {
    fetchPolicy: 'network-only',
    onCompleted: async (res) => {
      if (res?.customers?.data?.length > 0) {
        setDuplicateData(res?.customers?.data);
        setDuplicateModalVisible(true);
        setSubmitLoading(false);
      } else {
        submitData();
      }
    },
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    setFormData(formValues);
    fetchCustomerData({
      variables: {
        filter: {
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        where: {
          duplicateCheck: true,
          firstName: [formValues?.firstName],
          lastName: [formValues?.lastName],
          phoneNo: [formValues?.phoneNo],
          email: [formValues?.email],
          addressLine: [formValues?.addressLine1]
        }
      }
    });
  };

  const initialValues = {
    country: 'USA',
    descriptionBusiness: ''
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <CustomerCreationModal
        showModal={duplicateModalVisible}
        setShowModal={setDuplicateModalVisible}
        duplicateData={duplicateData}
        submitData={submitData}
        submitLoading={submitLoading}
      />
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CUSTOMERS} />
          <span className="portal-header">Add Customer</span>
        </div>
      </Portal>
      <CardWrapper>
        <CustomerForm
          customerData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
}

export default withRouter(CustomerCreate);
