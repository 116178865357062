import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { uploadLogo } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import VentTypeForm from '../components/VentTypeForm';
import CardWrapper from '../components/VentTypeTabs';
import { CREATE_VENT_TYPE } from '../graphql/Mutations';

function VentTypeCreate() {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [createVentType] = useMutation(CREATE_VENT_TYPE, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const uuid = uuidv4();
    let logo = null;
    if (formValues?.logo) {
      logo = await uploadLogo({
        logoObject: formValues?.logo,
        currentUser,
        uuid,
        folder: 'vent-type'
      });
    }

    const newFormValues = {
      ...formValues,
      ...(logo && { logo }),
      uuid,
      logo
    };

    const variables = newFormValues;

    try {
      const response = await createVentType({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createVentType) {
        history?.push(ROUTES?.VENT_TYPES);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: ''
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.VENT_TYPES} />
          <span className="portal-header">Add Vent Type</span>
        </div>
      </Portal>
      <CardWrapper>
        <VentTypeForm
          ventTypeData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
}

export default withRouter(VentTypeCreate);
