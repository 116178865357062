import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Empty, Form, Row } from 'antd';
import { debounce, map, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { REGEX, SKIP_RECORD } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { FETCH_STATE_CITY } from '../../Signup/graphql/Query';
import { GET_CONTACT_TYPES, GET_PREFERRED_CONTACT } from '../graphql/Queries';

let stateScrollDebounce;
let cityScrollDebounceJob;
let contactScrollDebounceJob;
let preferredContactScrollDebounceJob;

const {
  required,
  requiredWhiteSpaceAllowed,
  email,
  url,
  zipCode,
  firstName,
  lastName,
  address
} = formValidatorRules;

const { Option } = SelectComponent;

const stateCityFilter = {
  skip: 0,
  limit: 20,
  type: 'STATE',
  search: '',
  sortOn: 'name',
  sortBy: 'ASC'
};

const initialContactTypeFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const initialPreferredContactFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const ContactForm = (props) => {
  const { dispatch } = useContext(AppContext);
  const { contactData, handleSubmit, isSubmit, isEdit = false } = props;
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [form] = Form?.useForm();
  const [fetchCity, setFetchCity] = useState(false);
  const [citySearchFlag, setCitySearchFlag] = useState(false);
  const [stateSearchFlag, setStateSearchFlag] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [disableCity, setDisableCity] = useState(true);
  const [callAsync, setCallAsync] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [stateIsEnd, setStateIsEnd] = useState(false);
  const [cityIsEnd, setCityIsEnd] = useState(false);
  const [disableState, setDisableState] = useState(false);

  const [contactTypeData, setContactTypeData] = useState([]);
  const [contactTypeLoading, setContactTypeLoading] = useState(false);
  const [contactTypeSearchFlag, setContactTypeSearchFlag] = useState(false);
  const [contactTypeIsEnd, setContactTypeIsEnd] = useState(false);
  const [contactTypeDebounceCall, setContactTypeDebounceCall] = useState(0);

  const [preferredContactData, setPreferredContact] = useState([]);
  const [preferredContactLoading, setPreferredContactLoading] = useState(false);
  const [preferredContactSearchFlag, setPreferredContactSearchFlag] = useState(
    false
  );
  const [preferredContactIsEnd, setPreferredContactIsEnd] = useState(false);

  const [
    preferredContactDebounceCall,
    setPreferredContactDebounceCall
  ] = useState(0);

  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const moreData = response?.getLocationType?.data;
      if (fetchCity) {
        if (moreData?.length < SKIP_RECORD) {
          setCityIsEnd(true);
        }
        if (citySearchFlag) {
          setCities([...moreData]);
        } else {
          setCities([...cities, ...moreData]);
        }
        setCityLoading(false);
      } else {
        if (moreData?.length < SKIP_RECORD) {
          setStateIsEnd(true);
        }
        if (stateSearchFlag) {
          setStates([...moreData]);
        } else {
          setStates([...states, ...moreData]);
        }
        setStateLoading(false);
        setCallAsync(false);
      }
    },
    onError() {
      setStateLoading(false);
      setCityLoading(false);
    }
  });

  const [fetchContactTypes] = useLazyQuery(GET_CONTACT_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.contactTypes?.data?.length < SKIP_RECORD) {
        setContactTypeIsEnd(true);
      }
      if (contactTypeSearchFlag) {
        setContactTypeData([...res?.contactTypes?.data]);
      } else {
        if (isEdit && contactData?.contactTypeData) {
          const contactArray = [
            contactData?.contactTypeData,
            ...res?.contactTypes?.data
          ];
          setContactTypeData(
            uniqBy([...contactTypeData, ...contactArray], 'id')
          );
          return;
        }
        setContactTypeData([...contactTypeData, ...res?.contactTypes?.data]);
      }
      setContactTypeLoading(false);
    },
    onError() {
      setContactTypeLoading(false);
    }
  });

  const onContactTypeScroll = (event) => {
    setContactTypeSearchFlag(false);
    if (contactScrollDebounceJob) {
      contactScrollDebounceJob?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    contactScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !contactTypeIsEnd) {
        setContactTypeLoading(true);
        setContactTypeDebounceCall((prevState) => prevState + 1);
        fetchContactTypes({
          variables: {
            filter: {
              ...initialContactTypeFilter,
              skip: (contactTypeDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);
    contactScrollDebounceJob();
  };

  const handleContactTypeChange = (value) => {
    setContactTypeSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setContactTypeLoading(true);
      fetchContactTypes({
        variables: {
          filter: {
            ...initialContactTypeFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setContactTypeLoading(false);
      fetchContactTypes({
        variables: {
          filter: {
            ...initialContactTypeFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedContactTypeHandler = useCallback(
    debounce(handleContactTypeChange, 500),
    []
  );

  const handleContactTypeBlur = () => {
    setSearchValue('');
    setContactTypeIsEnd(false);
    setContactTypeDebounceCall(0);
  };

  const handleContactTypeClear = () => {
    form?.setFieldsValue({
      contactType: null
    });
    setContactTypeData([]);
    fetchContactTypes({
      variables: { filter: initialContactTypeFilter },
      where: {
        isActive: true
      }
    });
  };

  const [fetchPreferredContact] = useLazyQuery(GET_PREFERRED_CONTACT, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.preferredContactMethods?.data?.length < SKIP_RECORD) {
        setPreferredContactIsEnd(true);
      }
      if (preferredContactSearchFlag) {
        setPreferredContact([...res?.preferredContactMethods?.data]);
      } else {
        if (isEdit && contactData?.preferredContactData) {
          const preferredContactArray = [
            contactData?.preferredContactData,
            ...res?.preferredContactMethods?.data
          ];
          setPreferredContact(
            uniqBy([...preferredContactData, ...preferredContactArray], 'id')
          );
          return;
        }
        setPreferredContact([
          ...preferredContactData,
          ...res?.preferredContactMethods?.data
        ]);
      }
      setPreferredContactLoading(false);
    },
    onError() {
      setPreferredContactLoading(false);
    }
  });

  const onPreferredContactScroll = (event) => {
    setPreferredContactSearchFlag(false);
    if (preferredContactScrollDebounceJob) {
      preferredContactScrollDebounceJob?.cancel();
    }

    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    preferredContactScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !preferredContactIsEnd) {
        setPreferredContactLoading(true);
        setPreferredContactDebounceCall((prevState) => prevState + 1);
        fetchPreferredContact({
          variables: {
            filter: {
              ...initialPreferredContactFilter,
              skip: (preferredContactDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);
    preferredContactScrollDebounceJob();
  };

  const handlePreferredContactChange = (value) => {
    setPreferredContactSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setPreferredContactLoading(true);
      fetchPreferredContact({
        variables: {
          filter: {
            ...initialPreferredContactFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setPreferredContactLoading(false);
      fetchPreferredContact({
        variables: {
          filter: {
            ...initialPreferredContactFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncePreferredContactHandler = useCallback(
    debounce(handlePreferredContactChange, 500),
    []
  );

  const handlePreferredContactClear = () => {
    form?.setFieldsValue({
      preferredContact: null
    });
    setPreferredContact([]);
    fetchPreferredContact({
      variables: { filter: initialPreferredContactFilter },
      where: {
        isActive: true
      }
    });
  };

  const handlePreferredContactBlur = () => {
    setSearchValue('');
    setPreferredContactIsEnd(false);
    setPreferredContactDebounceCall(0);
  };

  useEffect(() => {
    if (!callAsync && states?.length > 0) {
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: contactData?.state
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAsync]);

  useEffect(() => {
    if (contactData?.country) {
      setDisableState(false);
    }
    if (!contactData?.state) {
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }
    if (contactData?.state) {
      setDisableCity(false);
      setCitySearchFlag(true);
      setCallAsync(true);
      setSelectedState(contactData?.state);
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }

    fetchContactTypes({
      variables: {
        filter: initialContactTypeFilter,
        where: {
          isActive: true
        }
      }
    });

    fetchPreferredContact({
      variables: {
        filter: initialPreferredContactFilter,
        where: {
          isActive: true
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityBlur = () => {
    setSearchValue('');
    setCityIsEnd(false);
  };

  const handleStateBlur = () => {
    setStateIsEnd(false);
  };

  const handleCityClear = () => {
    setFetchCity(true);
    fetchStateAndCity({
      variables: {
        filter: {
          ...stateCityFilter,
          type: 'CITY',
          state: selectedState
        }
      }
    });
  };

  const onStateScroll = (event) => {
    setFetchCity(false);
    setStateSearchFlag(false);
    if (stateScrollDebounce) {
      stateScrollDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    stateScrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !stateIsEnd) {
        setStateLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: states?.length,
              search: searchValue,
              type: 'STATE'
            }
          }
        });
      }
    }, 500);

    stateScrollDebounce();
  };

  const onCityScroll = (event) => {
    setCitySearchFlag(false);
    setFetchCity(true);
    if (cityScrollDebounceJob) {
      cityScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    cityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !cityIsEnd) {
        setCityLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: cities?.length,
              type: 'CITY',
              search: searchValue,
              state: selectedState
            }
          }
        });
      }
    }, 500);

    cityScrollDebounceJob();
  };

  const handleStateChange = (value) => {
    setFetchCity(false);
    setSearchValue(value);
    setStateSearchFlag(true);
    const state = form?.getFieldValue('state');
    if (value) {
      setStateLoading(true);
      setCities([]);
      setDisableCity(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
            search: value
          }
        }
      });
    } else {
      if (!state?.length) {
        setStateLoading(true);
        setDisableCity(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
      if (state?.length > 0) {
        setDisableCity(false);
        setCitySearchFlag(true);
        setCallAsync(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
    }
  };

  const handleStateSelect = (value) => {
    if (value) {
      form?.setFieldsValue({
        city: null
      });
      setSelectedState(value);
      setDisableCity(false);
      setCitySearchFlag(true);
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: value
          }
        }
      });
      setStateLoading(false);
    } else {
      setCitySearchFlag(true);
      setStateLoading(false);
      setDisableCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE'
          }
        }
      });
    }
  };

  const handleCityChange = (value) => {
    setFetchCity(true);
    setCitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCityLoading(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            search: value,
            state: form?.getFieldValue('state')
          }
        }
      });
    } else {
      setCityLoading(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: form?.getFieldValue('state')
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateHandler = useCallback(
    debounce(handleStateChange, 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCityHandler = useCallback(debounce(handleCityChange, 500), []);

  const handleStateClear = () => {
    form?.setFieldsValue({
      state: null,
      city: null
    });
    setFetchCity(false);
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter
      }
    });
    setSelectedState('');
    setCities([]);
    setDisableCity(true);
  };

  const handleSelectCountry = (value) => {
    if (value) {
      setDisableState(false);
    } else {
      setDisableState(true);
    }
  };

  const handleCountryClear = () => {
    form?.setFieldsValue({
      country: null,
      state: null,
      city: null
    });
    setDisableState(true);
    setDisableCity(true);
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <div>
      <Portal portalId="header-right-content">
        <Button
          className="common-button discard-button"
          icon={<DeleteIconComponent />}
          size="small"
          id="contact-table-discard-btn"
          onClick={() => history?.goBack()}
        >
          Discard Changes
        </Button>
        <Button
          className="common-button"
          icon={<img src={SaveIcon} alt="save-icon" width={12} />}
          size="small"
          htmlType="submit"
          id="contact-table-save-btn"
          loading={isSubmit}
          type="primary"
          onClick={() => form?.submit()}
        >
          Save
        </Button>
      </Portal>
      <Form
        form={form}
        initialValues={contactData}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
      >
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider " />
        <Row gutter={16} className="required-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[
                { ...required, message: 'Please Enter First Name' },
                firstName
              ]}
              name="firstName"
              label="First Name"
            >
              <InputComponent allowClear placeholder="Enter First name" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[
                { ...required, message: 'Please Enter Last Name' },
                lastName
              ]}
              name="lastName"
              label="Last Name"
            >
              <InputComponent allowClear placeholder="Enter Last Name" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[{ ...required, message: 'Please Enter Email' }, email]}
              name="email"
              label="Email"
            >
              <InputComponent allowClear placeholder="Enter Email" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              label="Mobile"
              name="phoneNo"
              rules={[
                { ...required, message: 'Please Enter Mobile' },
                () => ({
                  validator(rule, value) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      value = value?.split(' ')?.join('');
                      const numberPattern = REGEX?.PHONE;
                      if (!numberPattern?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'should be a valid phone number'
                        );
                      }
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <ReactInputMask
                mask="(999) 999-9999"
                placeholder="(___) ___-____"
                allowClear
              >
                {(inputProps) => <InputComponent {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[
                { ...required, message: 'Please Enter Home Phone' },
                () => ({
                  validator(rule, value) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      value = value?.split(' ')?.join('');
                      const numberPattern = REGEX?.PHONE;
                      if (!numberPattern?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'should be a valid home phone number'
                        );
                      }
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
              name="homePhone"
              label="Home Phone"
            >
              <ReactInputMask
                mask="(999) 999-9999"
                placeholder="(___) ___-____"
                allowClear
              >
                {(inputProps) => <InputComponent {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[
                { ...required, message: 'Please Enter Main Phone' },
                () => ({
                  validator(rule, value) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      value = value?.split(' ')?.join('');
                      const numberPattern = REGEX?.PHONE;
                      if (!numberPattern?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'should be a valid main phone number'
                        );
                      }
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
              name="mainPhone"
              label="Main Phone"
            >
              <ReactInputMask
                mask="(999) 999-9999"
                placeholder="(___) ___-____"
                allowClear
              >
                {(inputProps) => <InputComponent {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[{ ...required, message: 'Please Enter Web' }, url]}
              name="web"
              label="Web"
            >
              <InputComponent allowClear placeholder="Enter Web" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="addressLine1"
              label="Address Line 1"
              rules={[
                { ...required, message: 'Please Enter AddressLine 1' },
                address
              ]}
            >
              <InputComponent allowClear placeholder="Enter AddressLine 1" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ ...required, message: 'Please Select Country' }]}
            >
              <SelectComponent
                placeholder="Select Country"
                onSelect={handleSelectCountry}
                onClear={handleCountryClear}
                allowClear
              >
                <Option key="USA" value="USA">
                  USA
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ ...required, message: 'Please Select State' }]}
            >
              <SelectComponent
                placeholder="Select State"
                disabled={disableState}
                allowClear
                notFoundContent={
                  stateLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onSearch={debouncedStateHandler}
                onClear={handleStateClear}
                onSelect={handleStateSelect}
                onPopupScroll={onStateScroll}
                onBlur={handleStateBlur}
              >
                {map(states, (state) => (
                  <Option key={state?.id} value={state?.name}>
                    {state?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ ...required, message: 'Please Select City' }]}
            >
              <SelectComponent
                placeholder="Select City"
                disabled={disableCity}
                notFoundContent={
                  cityLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onSearch={debouncedCityHandler}
                onPopupScroll={onCityScroll}
                onClear={handleCityClear}
                onBlur={handleCityBlur}
              >
                {cities?.map((city) => (
                  <Option key={city?.id} value={city?.name}>
                    {city?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="zipCode"
              label="Postal Code"
              rules={[
                { ...required, message: 'Please Enter Postal Code' },
                zipCode
              ]}
            >
              <NumberComponent allowClear placeholder="Enter Postal Code" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="isActive"
              label="Status"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Status'
                }
              ]}
            >
              <SelectComponent placeholder="Status" allowClear>
                <Option key="active" value>
                  Active
                </Option>
                <Option key="inactive" value={false}>
                  InActive
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
        </Row>
        <span className="form-divider-text">OPTIONAL</span>
        <Divider className="form-divider optional-divider" />
        <Row gutter={16}>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="preferredContact" label="Preferred Contact">
              <SelectComponent
                placeholder="Select Preferred Contact"
                allowClear
                notFoundContent={
                  preferredContactLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handlePreferredContactBlur}
                onSearch={debouncePreferredContactHandler}
                onClear={handlePreferredContactClear}
                onPopupScroll={onPreferredContactScroll}
              >
                {map(preferredContactData, (preferredContact) => (
                  <Option
                    key={preferredContact?.key}
                    value={preferredContact?.key}
                  >
                    {preferredContact?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="contactType" label="Contact Type">
              <SelectComponent
                placeholder="Select Contact Type"
                allowClear
                notFoundContent={
                  contactTypeLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleContactTypeBlur}
                onSearch={debouncedContactTypeHandler}
                onClear={handleContactTypeClear}
                onPopupScroll={onContactTypeScroll}
              >
                {map(contactTypeData, (contactType) => (
                  <Option key={contactType?.key} value={contactType?.key}>
                    {contactType?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ContactForm;
