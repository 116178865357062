import { gql } from '@apollo/client';

export const UPDATE_PROPERTY_TYPE = gql`
  mutation updatePropertyType(
    $where: PropertyTypeWhereInput!
    $data: PropertyTypeInput!
  ) {
    updatePropertyType(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_PROPERTY_TYPE = gql`
  mutation createPropertyType($data: PropertyTypeInput!) {
    createPropertyType(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_PROPERTY_TYPE = gql`
  mutation deletePropertyType($where: PropertyTypeWhereInput!) {
    deletePropertyType(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
