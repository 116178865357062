import { nth } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import settingsIcon from '../../../../assets/settings-icon.svg';

const PanelBtn = ({
  history = {},
  sideCloseBtn = null,
  sideOpenBtn = null
}) => {
  const query = new URLSearchParams(history?.location?.search);
  const [sp, setSp] = useState(query?.get('sp') !== 'false');
  const toggleSider = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e?.preventDefault();
    // eslint-disable-next-line no-undef
    const getID = document?.getElementById('sidePanelToggleStatus');
    const temp = getID?.className?.split(' ');
    const temp2 = nth(temp, 3);
    const urlSearchObj = new URLSearchParams(history?.location?.search);
    if (temp2) {
      urlSearchObj?.set('sp', 'false');
      getID?.classList?.remove('side-panel-open');
    } else {
      urlSearchObj?.delete('sp');
      getID?.classList?.add('side-panel-open');
    }
    history?.replace(
      `${history?.location?.pathname}?${urlSearchObj?.toString()}`
    );
  };

  useEffect(() => {
    if (history?.location?.search?.indexOf('sp=false') !== -1) {
      setSp(false);
    } else {
      setSp(true);
    }
    // eslint-disable-next-line
  }, [history, history?.location]);

  return (
    <div
      className={`side-panel-toggle-btn-wrap ${
        !sideCloseBtn && !sideOpenBtn ? 'default-btn-position' : ''
      }`}
    >
      <img
        onClick={toggleSider}
        src={sp ? sideCloseBtn || settingsIcon : sideOpenBtn || settingsIcon}
        alt="side-panel-btn"
      />
    </div>
  );
};

export default withRouter(PanelBtn);
