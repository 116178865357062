import {
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import {
  CONTACT_NUMBER,
  EMAIL_ADDRESS
} from '../../../../../../common/constants';
import { formatPhoneNumber } from '../../../../../../common/utils';

const ContactSection = ({
  contactInfo = true,
  emailMeButtonLabel = 'Email me instead',
  phoneNo = CONTACT_NUMBER,
  email = EMAIL_ADDRESS,
  liveChat,
  helpPage
}) => {
  return (
    <div className="contact-section">
      <div className="email-me-section">
        <Button icon={<RightOutlined />} type="link" className="email-me-btn">
          {emailMeButtonLabel}
        </Button>
      </div>
      {contactInfo && (
        <div className="contact-info">
          {helpPage && (
            <span className="details-text">
              <span>Do you need help?</span>
            </span>
          )}
          {liveChat && (
            <span className="details-text underline-text">
              <SendOutlined />
              <span>Live Chat</span>
            </span>
          )}
          <span className="details-text underline-text">
            <PhoneOutlined rotate={90} />
            <span>{formatPhoneNumber(phoneNo)}</span>
          </span>
          <span className="details-text underline-text">
            <MailOutlined />
            <span>{email}</span>
          </span>
        </div>
      )}
    </div>
  );
};

export default ContactSection;
