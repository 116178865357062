import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_RETAILER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './brandModule.less';
import BrandCreate from './pages/BrandCreate';
import BrandEdit from './pages/BrandEdit';
import BrandList from './pages/BrandList';
import BrandsLobCreate from './pages/BrandsLobCreate';
import BrandsView from './pages/BrandsView';

const BrandWrapper = () => {
  return (
    <div className="brand-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.RETAILERS}
          render={(props) => (
            <AccessControl
              allowedPermissions={PERMISSION_RETAILER}
              showNoAccess
            >
              <BrandList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.RETAILERS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_BRAND_CREATE']}
              showNoAccess
            >
              <BrandCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.RETAILERS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_BRAND_UPDATE', 'FET_BRAND_VIEW']}
              showNoAccess
            >
              <BrandEdit {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.RETAILERS}/view/:id`}
          render={(props) => (
            <AccessControl allowedPermissions={['FET_BRAND_VIEW']} showNoAccess>
              <BrandsView {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.RETAILERS}/edit/lob/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_BRAND_UPDATE', 'FET_BRAND_VIEW']}
              showNoAccess
            >
              <BrandsLobCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.RETAILERS}/view/lob/:id`}
          render={(props) => (
            <AccessControl allowedPermissions={['FET_BRAND_VIEW']} showNoAccess>
              <BrandsLobCreate {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default BrandWrapper;
