import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PropertyTypeForm from '../components/PropertyTypeForm';
import CardWrapper from '../components/PropertyTypeTabs';
import { UPDATE_PROPERTY_TYPE } from '../graphql/Mutations';
import { GET_PROPERTY_TYPE } from '../graphql/Queries';

function PropertyTypeEdit(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { propertyType } = {} } = useQuery(GET_PROPERTY_TYPE, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updatePropertyType] = useMutation(UPDATE_PROPERTY_TYPE, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = { data: newFormValues, where: { id: propertyType?.id } };

    try {
      const response = await updatePropertyType({
        variables: { ...variables }
      });
      if (response?.data?.updatePropertyType) {
        setSubmitLoading(false);
        history?.push(ROUTES?.PROPERTY_TYPES);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.PROPERTY_TYPES} />;
  }

  const initialValues = {
    ...propertyType,

    description: propertyType?.description || '',
    isActive: propertyType?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PROPERTY_TYPES} />
          {propertyType && (
            <span className="portal-header">{propertyType?.label}</span>
          )}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <PropertyTypeForm
            propertyTypeData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
            isEdit
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(PropertyTypeEdit);
