import { Card } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import ProductRankingCollapseListing from '../components/ProductRankingCollapseListing';

const ProductRankingPage = () => {
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <span className="portal-header">
            {MODULES?.QUESTIONNAIRE_RESULT_VALIDATION}
          </span>
        </div>
      </Portal>
      <ProductRankingCollapseListing />
    </Card>
  );
};

export default withRouter(ProductRankingPage);
