import { CalendarFilled, PhoneFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import location from '../../../../../../assets/location-secondary.svg';
import CommonProductDetail from '../../globalComponents/CommonProductDetail';
import PreviewTagComponent from '../../previewTagComponent';
import '../thankYou.less';

const ThankYouComponent = ({ thankYouConfig = null, logoUrl = null }) => {
  return (
    <div className="thank-you-module">
      <div className="thank-you-wrapper">
        <PreviewTagComponent />
        <div className="logo-section">
          <img alt="logo" src={logoUrl} />
        </div>
        <div className="title-section">
          <div className="title">{thankYouConfig?.title}</div>
          <div className="description">{thankYouConfig?.callMeDescription}</div>
        </div>
        <>
          <CommonProductDetail />
          <div className="info-card location-section">
            <img alt="location" src={location} width={18} />
            <span className="info-title">1 Technology Dr Irvine, CA 92618</span>
          </div>
          <div className="info-card schedule-section">
            <CalendarFilled />
            <span className="info-title">Tomorrow morning August 21, 2021</span>
            <Button type="link" className="add-to-calendar">
              add to calendar
            </Button>
          </div>
          <div className="info-card appointment-section">
            <PhoneFilled rotate={100} />
            <span className="info-title appointment-description">
              948-492-1240
            </span>
          </div>
        </>
        <div className="mt-24">
          <Button type="primary" className="common-button-cpq fill-width">
            {thankYouConfig?.label}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouComponent;
