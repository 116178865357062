import { useLazyQuery, useMutation } from '@apollo/client';
import { Card, Checkbox, Divider, Empty } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import clickRateImg from '../../../assets/click-rate.svg';
import timeSpendImg from '../../../assets/time.svg';
import { ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { SKIP_CUSTOMER_GOAL } from '../graphql/Mutations';
import { CPQ_STATISTICS } from '../graphql/Queries';

const LeftSideContent = ({ match: { params: { id } = {} } }) => {
  const location = useLocation();
  const [currentTemplate, setCurrentTemplate] = useState('POSTAL_CODE');
  const handleChangeTemplate = (templateId) => {
    switch (templateId) {
      case 'POSTAL_CODE':
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.POSTAl_CODE}/${id}?sp=false`
        );
        break;
      case 'CUSTOMER_GOAL':
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.CUSTOMER_GOAL}/${id}?sp=false`
        );
        break;
      case 'CURRENT_WATER_HEATER':
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.CURRENT_PRODUCT}/${id}?sp=false`
        );
        break;
      case 'UPGRADE_PAGE':
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.UPGRADE}/${id}?sp=false`
        );
        break;
      case 'NEW_WATER_HEATER':
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.NEW_PRODUCT}/${id}?sp=false`
        );

        break;
      case 'QUOTE':
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.QUOTES}/${id}?sp=false`
        );
        break;
      case 'SCHEDULE':
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.SCHEDULED_APPOINTMENT}/${id}?sp=false`
        );
        break;
      case 'EMAIL_ME':
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.CONTACT_ME}/${id}?sp=false`
        );
        break;
      case 'THANK_YOU':
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.THANK_YOU}/${id}?sp=false`
        );
        break;

      default:
        break;
    }
  };

  const [cpqStatistics, { loading, data, refetch }] = useLazyQuery(
    CPQ_STATISTICS,
    {
      fetchPolicy: 'network-only',
      onError() {}
    }
  );

  const [skipCustomerGoal] = useMutation(SKIP_CUSTOMER_GOAL, {
    onCompleted: () => refetch(),
    onError() {}
  });

  useEffect(() => {
    cpqStatistics({ variables: { where: { questionnaireId: id } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.POSTAl_CODE)) {
      setCurrentTemplate('POSTAL_CODE');
    }
    if (location?.pathname?.includes(ROUTES?.CUSTOMER_GOAL)) {
      setCurrentTemplate('CUSTOMER_GOAL');
    }
    if (location?.pathname?.includes(ROUTES?.CURRENT_PRODUCT)) {
      setCurrentTemplate('CURRENT_WATER_HEATER');
    }
    if (location?.pathname?.includes(ROUTES?.UPGRADE)) {
      setCurrentTemplate('UPGRADE_PAGE');
    }
    if (location?.pathname?.includes(ROUTES?.NEW_PRODUCT)) {
      setCurrentTemplate('NEW_WATER_HEATER');
    }
    if (location?.pathname?.includes(ROUTES?.QUOTES)) {
      setCurrentTemplate('QUOTE');
    }
    if (location?.pathname?.includes(ROUTES?.SCHEDULED_APPOINTMENT)) {
      setCurrentTemplate('SCHEDULE');
    }
    if (location?.pathname?.includes(ROUTES?.CONTACT_ME)) {
      setCurrentTemplate('EMAIL_ME');
    }
    if (location?.pathname?.includes(ROUTES?.THANK_YOU)) {
      setCurrentTemplate('THANK_YOU');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const handleChangeSkip = (e) => {
    e?.stopPropagation();
    skipCustomerGoal({
      variables: {
        value: e?.target?.checked,
        where: {
          questionnaireId: id
        }
      }
    });
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="left-content">
      {data?.cpqStatistics?.length > 0 ? (
        map(data?.cpqStatistics, (item) => {
          let cardClass;
          let tagClass;
          if (
            item?.key === 'CUSTOMER_GOAL' &&
            currentTemplate !== 'CUSTOMER_GOAL'
          ) {
            cardClass = 'template-card skip-card';
          } else if (
            item?.key === 'CUSTOMER_GOAL' &&
            currentTemplate === 'CUSTOMER_GOAL'
          ) {
            cardClass = 'active-template-card skip-card';
          } else if (currentTemplate === item?.key) {
            cardClass = 'active-template-card';
          } else {
            cardClass = 'template-card';
          }

          if (item?.skip && currentTemplate === 'CUSTOMER_GOAL') {
            tagClass = 'title-tag disable-title-color';
          } else if (item?.skip && currentTemplate !== 'CUSTOMER_GOAL') {
            tagClass = 'title-tag disabled-title-tag';
          } else {
            tagClass = 'title-tag';
          }

          return (
            <Card
              bordered={false}
              className={cardClass}
              key={item?.key}
              onClick={() => handleChangeTemplate(item?.key)}
            >
              <div className={tagClass}>{item?.title}</div>
              <div className="click-rate">
                <img
                  className="template-img"
                  alt="click-rate-img"
                  src={clickRateImg}
                />
                <span>{item?.clickRate}% Click-Through Rate</span>
              </div>
              <div className="time-spend">
                <div className="time-row">
                  <img
                    className="template-img"
                    alt="time-img"
                    src={timeSpendImg}
                  />
                  <span>{item?.averageTimeSpent}s</span>
                </div>
                <div className="time-details">
                  <span>Average time spent on this page.</span>
                </div>
                {item?.key !== 'CUSTOMER_GOAL' &&
                  currentTemplate !== item?.key && (
                    <Divider className="template-divider" />
                  )}
              </div>
              {item?.skip !== null && (
                <AccessControl
                  allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}
                >
                  <div className="extra-space">
                    <Checkbox
                      className="skip-checkbox"
                      checked={item?.skip}
                      onClick={(e) => e?.stopPropagation()}
                      onChange={handleChangeSkip}
                    >
                      <span onClick={(e) => e?.stopPropagation()}>
                        Skip this page
                      </span>
                    </Checkbox>
                    {currentTemplate !== item?.key && (
                      <Divider className="template-divider" />
                    )}
                  </div>
                </AccessControl>
              )}
            </Card>
          );
        })
      ) : (
        <div className="template-no-data">
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
};

export default withRouter(LeftSideContent);
