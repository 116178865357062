import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Menu,
  message,
  Popconfirm,
  Popover,
  Tag
} from 'antd';
import { debounce, filter, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import { AppContext } from '../../../AppContext';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import ImportIcon from '../../../assets/import.svg';
import api from '../../../common/api';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import { checkPermissions, handleCsvDownload } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import ImportModal from '../../imports/components/ImportModal';
import {
  DELETE_PRODUCT_ITEM_TYPE,
  UPDATE_PRODUCT_ITEM_TYPE
} from '../graphql/Mutations';
import {
  GET_PRODUCT_ITEM_TYPES,
  PRODUCT_ITEM_TYPE_FILTER
} from '../graphql/Queries';

let scrollDebounce = null;

const ProductItemTypeTable = () => {
  const {
    state: { pageSize, filterData, currentUser, permissions },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialProductItemTypeFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'order',
    sortBy: 'DESC',
    getDBField: []
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [productItemTypeFilter, setProductItemTypeFilter] = useState(
    initialProductItemTypeFilter
  );
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [
    updateProductItemTypeMutate,
    { loading: updateProductItemTypeLoading }
  ] = useMutation(UPDATE_PRODUCT_ITEM_TYPE, {
    onError() {}
  });

  const [fetchProductItemTypeData, { loading, data }] = useLazyQuery(
    GET_PRODUCT_ITEM_TYPES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.productItemTypes?.count
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  const [
    deleteProductItemType,
    { loading: deleteProductItemTypeLoading }
  ] = useMutation(DELETE_PRODUCT_ITEM_TYPE, {
    onError() {}
  });

  const [productItemTypeFilters] = useLazyQuery(PRODUCT_ITEM_TYPE_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.productItemTypeFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.productItemTypeFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      if (res?.productItemTypeFilters?.data?.length < SKIP_RECORD) {
        setFilterIsEnd(true);
      }
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_MASTER_LIST']);
    if (permitted) {
      fetchProductItemTypeData({
        variables: {
          filter: productItemTypeFilter,
          ...(filterData && { where: filterData })
        }
      });
      setFilters(filterData);
      dispatch({ type: 'SET_FILTER_DATA', data: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchProductItemTypeData({
      variables: {
        filter: { ...productItemTypeFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setProductItemTypeFilter({
      ...productItemTypeFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'productItemTypes':
          productItemTypeFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch
              }
            }
          });
          break;

        default:
          productItemTypeFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: [filterIndex]
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'productItemTypes':
            productItemTypeFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch
                }
              }
            });
            break;

          default:
            productItemTypeFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: [filterIndex]
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    fetchProductItemTypeData({
      variables: {
        filter: {
          ...productItemTypeFilter,
          skip: 0,
          sortOn: 'order',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setProductItemTypeFilter({
      ...productItemTypeFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditProductItemType = (record) => {
    if (record?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      history?.push(`${ROUTES?.PRODUCT_ITEM_TYPE}/edit/${record?.id}`);
    } else {
      history?.push(`${ROUTES?.PRODUCT_ITEM_TYPE}/add`);
    }
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setProductItemTypeFilter({
        ...productItemTypeFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchProductItemTypeData({
        variables: {
          filter: {
            ...productItemTypeFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setProductItemTypeFilter({
        ...productItemTypeFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'order',
        sortBy: 'DESC'
      });
      fetchProductItemTypeData({
        variables: {
          filter: {
            ...productItemTypeFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'order',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (filterIndex) {
      case 'productItemTypes':
        productItemTypeFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value
            }
          }
        });
        break;

      default:
        productItemTypeFilters({
          variables: {
            filter: {
              sortOn: filterIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: [dataIndex]
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div className="single-checkbox-div" key={item?.key || item}>
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const handleExport = (e) => {
    const { key } = e;
    if (key === 'SELECTED' && !selectedKeys?.length) {
      message?.destroy();
      message?.error('Please select records!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process?.env?.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module: 'PRODUCT_ITEM_TYPE',
        userId: currentUser?.id,
        filtersObj: {
          ...filters,
          id: key === 'SELECTED' ? selectedKeys : undefined
        }
      }
    })
      .then((res) => {
        handleCsvDownload(res?.data, 'product-types.csv');
        setExportLoading(false);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  const handleProductItemTypeStatus = async (editProductItemTypeData) => {
    const editData = {
      ...editProductItemTypeData,
      isActive: !editProductItemTypeData?.isActive
    };
    // eslint-disable-next-line no-underscore-dangle
    delete editData?.__typename;
    delete editData?.id;
    delete editData?.key;
    delete editData?.order;
    delete editData?.tenantId;
    const response = await updateProductItemTypeMutate({
      variables: {
        data: { ...editData },
        where: { id: editProductItemTypeData?.id }
      }
    });
    if (response?.data?.updateProductItemType) {
      fetchProductItemTypeData({
        variables: {
          filter: productItemTypeFilter,
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleDeleteProductItemType = async (editProductItemTypeData) => {
    const response = await deleteProductItemType({
      variables: { where: { id: editProductItemTypeData?.id } }
    });
    if (response?.data?.deleteProductItemType) {
      fetchProductItemTypeData({
        variables: {
          filter: productItemTypeFilter,
          ...(filters && { where: filters })
        }
      });
    }
  };

  const renderActionButtons = (editProductItemTypeData) => {
    return (
      <div className="d-flex flex-vertical">
        <AccessControl
          allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
        >
          <Button
            id="productItemType-table-edit-btn"
            className="b-0"
            onClick={() =>
              handleAddEditProductItemType(editProductItemTypeData)
            }
          >
            Edit
          </Button>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_MASTER_DELETE']}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() =>
              handleDeleteProductItemType(editProductItemTypeData)
            }
            okText="Yes"
            cancelText="No"
          >
            <Button id="locationType-table-status-btn" className="b-0">
              Remove
            </Button>
          </Popconfirm>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_MASTER_UPDATE']}>
          <Popconfirm
            title={`Are you sure to ${
              editProductItemTypeData?.isActive
                ? 'Mark Inactive'
                : 'Mark Active'
            }?`}
            onConfirm={() =>
              handleProductItemTypeStatus(editProductItemTypeData)
            }
            okText="Yes"
            cancelText="No"
          >
            <Button id="productItemType-table-status-btn" className="b-0">
              {editProductItemTypeData?.isActive
                ? 'Mark Inactive'
                : 'Mark Active'}
            </Button>
          </Popconfirm>
        </AccessControl>
      </div>
    );
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'label',
      key: 'label',
      className: 'max-width-column',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'label' && sortedInfo?.order,
      width: 200
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      className: 'max-width-column',
      key: 'description',
      ellipsis: true,
      render: (description) => <span>{description || '-'}</span>
    },

    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    checkPermissions(permissions, [
      'FET_MASTER_UPDATE',
      'FET_MASTER_VIEW',
      'FET_MASTER_DELETE'
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 30,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onSearchChange = (value) => {
    setProductItemTypeFilter({
      ...productItemTypeFilter,
      skip: value
        ? 0
        : productItemTypeFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchProductItemTypeData({
      variables: {
        filter: {
          ...productItemTypeFilter,
          skip: value
            ? 0
            : productItemTypeFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setProductItemTypeFilter(initialProductItemTypeFilter);
    setSortedInfo({});
    fetchProductItemTypeData({
      variables: { filter: initialProductItemTypeFilter }
    });
  };

  const exportContent = (
    <Menu onClick={handleExport}>
      <Menu.Item key="SELECTED" id="selected-export">
        <span>Selected</span>
      </Menu.Item>
      <Menu.Item key="ALL" id="all-export">
        <span>All</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="product-types"
        folder="PRODUCT_ITEM_TYPE"
      />
      <AccessControl allowedPermissions={['FET_MASTER_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="product-itemType-table-add-btn"
            type="primary"
            onClick={handleAddEditProductItemType}
          >
            Add Product Type
          </Button>
        </Portal>
      </AccessControl>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_MASTER_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Product Type or other detail.."
              name="ProductItemTypes"
              getData={onSearchChange}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_MASTER_IMPORT']}>
            <Button
              size="small"
              className="common-button import-button"
              icon={<img src={ImportIcon} alt="import-icon" width={11} />}
              id="manufacturer-table-import-btn"
              type="primary"
              onClick={() => handleShowImportModal(true)}
            >
              Import
            </Button>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_MASTER_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              overlay={exportContent}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="manufacturer-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl allowedPermissions={['FET_MASTER_LIST']} showNoAccess>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={
                loading ||
                updateProductItemTypeLoading ||
                deleteProductItemTypeLoading
              }
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.productItemTypes?.data || []}
              onChange={handleTableChange}
              rowSelection={rowSelection}
              paginationConfig={paginationProp}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default ProductItemTypeTable;
