import { gql } from '@apollo/client';

export const UPDATE_CONTACT_TYPE = gql`
  mutation updateContactType(
    $where: ContactTypeWhereInput!
    $data: ContactTypeInput!
  ) {
    updateContactType(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_CONTACT_TYPE = gql`
  mutation createContactType($data: ContactTypeInput!) {
    createContactType(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_CONTACT_TYPE = gql`
  mutation deleteContactType($where: ContactTypeWhereInput!) {
    deleteContactType(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
