import { Card } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import PricingModifierForm from '../components/PricingModifierForm';
import CardWrapper from '../components/PricingModifierTabs';

function PricingModifierEdit() {
  return (
    <Card className="full-height-card card-body-p-24">
      <CardWrapper>
        <PricingModifierForm isEdit />
      </CardWrapper>
    </Card>
  );
}

export default withRouter(PricingModifierEdit);
