import {
  Circle,
  GoogleMap,
  Marker,
  MarkerClusterer
} from '@react-google-maps/api';
import { findIndex, map } from 'lodash';
import { React, useCallback, useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { PRIMARY_COLOR } from '../../../common/constants';
import { getRandomColor } from '../../../common/utils';

const mapOptions = {
  clickableIcons: false,
  disableDefaultUI: true,
  zoomControl: true,
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ]
};

const center = { lat: 39.42654846155253, lng: -101.30331157734375 };

Geocode?.setApiKey(process?.env?.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode?.setLanguage('en');
Geocode?.setRegion('us');

const radiusOptions = {
  strokeColor: PRIMARY_COLOR,
  strokeOpacity: 1,
  strokeWeight: 3,
  fillOpacity: 0,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1
};

const svgElement = [
  `<svg width="69" height="102" viewBox="0 0 69 102" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6274 54.7139L34.1544 56.0001L32.6815 54.7139C20.2956 43.8987 14 34.4553 14 26.1544C14 14.3991 23.1749 5.99994 34.1544 5.99994C45.134 5.99994 54.3089 14.3991 54.3089 26.1544C54.3089 34.4553 48.0133 43.8987 35.6274 54.7139ZM27.4363 26.1545C27.4363 22.4441 30.4441 19.4363 34.1544 19.4363C37.8648 19.4363 40.8726 22.4441 40.8726 26.1545C40.8726 29.8648 37.8648 32.8727 34.1544 32.8727C30.4441 32.8727 27.4363 29.8648 27.4363 26.1545Z" fill="{{ color }}"/>
  <circle cx="34" cy="74" r="6" fill="{{ color }}"/>
  </g>
  <defs>
  <filter id="filter0_d" x="0" y="-6.10352e-05" width="68.3089" height="102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
  <feOffset dy="8"/>
  <feGaussianBlur stdDeviation="7"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.15 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
  </filter>
  </defs>
  </svg>
  `
].join('\n');

const MarkerComponent = ({ markerObj }) => {
  return (
    <Marker
      icon={{
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          svgElement?.replaceAll('{{ color }}', PRIMARY_COLOR)
        )}`,
        // eslint-disable-next-line no-undef
        scaledSize: new window.google.maps.Size(30, 30),
        // eslint-disable-next-line no-undef
        origin: new window.google.maps.Point(0, 0),
        // eslint-disable-next-line no-undef
        anchor: new window.google.maps.Point(15, 15)
      }}
      position={{ lat: markerObj.lat, lng: markerObj.lng }}
    />
  );
};

const RadiusComponent = ({ markerObj, radius }) => {
  return <Circle center={markerObj} options={radiusOptions} radius={radius} />;
};

const options = {
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
};

const createKey = (location, index) => {
  return location?.lat + location?.lng + index;
};

const MarkerClustererComponent = ({ zipMarkers = [] }) => {
  return (
    <MarkerClusterer options={options}>
      {(clusterer) =>
        map(zipMarkers, (location, index) => (
          <Marker
            icon={{
              url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                svgElement?.replaceAll('{{ color }}', getRandomColor())
              )}`,
              // eslint-disable-next-line no-undef
              scaledSize: new window.google.maps.Size(30, 30),
              // eslint-disable-next-line no-undef
              origin: new window.google.maps.Point(0, 0),
              // eslint-disable-next-line no-undef
              anchor: new window.google.maps.Point(15, 15)
            }}
            key={createKey(location, index)}
            position={{ lat: location?.lat, lng: location?.lng }}
            clusterer={clusterer}
            title={location?.zipCode?.toString()}
          />
        ))
      }
    </MarkerClusterer>
  );
};

const GoogleMapComponent = ({
  initialMarker = null,
  cityStateInitialMarker = null,
  radius = 20000,
  setMarkerObj,
  setCityStateMarkerObj,
  setPlacesValue,
  loadError,
  radioValue,
  setZipValue,
  zipMarkers = [],
  cityStateZipMarkers = [],
  centerMap = null
}) => {
  const [marker, setMarker] = useState(null);
  const [cityStateMarker, setCityStateMarker] = useState(null);

  useEffect(() => {
    setMarker(initialMarker);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMarker]);

  useEffect(() => {
    setCityStateMarker(cityStateInitialMarker);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityStateInitialMarker]);

  const handleChange = useCallback(
    (e) => {
      const markerObj = {
        lat: e?.latLng?.lat(),
        lng: e?.latLng?.lng()
      };
      if (setPlacesValue) {
        Geocode?.fromLatLng(e?.latLng?.lat(), e?.latLng?.lng()).then(
          (response) => {
            const address =
              response?.results?.length > 0 &&
              response?.results?.[0]?.formatted_address;
            if (address.slice(address?.length - 3, address?.length) === 'USA') {
              if (setMarkerObj) {
                setMarkerObj(markerObj);
              }
              if (setCityStateMarkerObj) {
                setCityStateMarkerObj(markerObj);
              }
              if (setPlacesValue) {
                setPlacesValue(address);
              }
            }
            if (
              address?.slice(address?.length - 3, address?.length) === 'USA'
            ) {
              const zipCodeIndex = findIndex(
                response?.results?.length > 0 &&
                  response?.results?.[0]?.address_components,
                (item) => item?.types?.[0] === 'postal_code'
              );
              if (zipCodeIndex > -1) {
                if (setZipValue) {
                  const zipValue =
                    response?.results?.length > 0 &&
                    response?.results?.[0]?.address_components?.[zipCodeIndex]
                      ?.long_name;
                  setZipValue(zipValue);
                }
              } else if (setZipValue) {
                setZipValue('');
              }
            }
          },
          (error) => {
            return error;
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setMarkerObj]
  );

  if (loadError) return 'Error Loading Maps..!';

  const centerMapFunc = () => {
    if (centerMap) {
      return centerMap;
    }
    if (radioValue === 2 && initialMarker) {
      return initialMarker;
    }
    if (initialMarker) {
      return null;
    }
    return center;
  };

  return (
    <GoogleMap
      mapContainerClassName="common-map"
      zoom={4}
      center={centerMapFunc()}
      options={mapOptions}
      onClick={radioValue === 1 ? handleChange : null}
    >
      {radioValue === 1 && marker && (
        <>
          <MarkerComponent markerObj={marker} />
          {zipMarkers?.length > 0 && (
            <MarkerClustererComponent zipMarkers={zipMarkers} />
          )}
          {initialMarker && radioValue === 1 && (
            <RadiusComponent markerObj={marker} radius={radius * 1609.34} />
          )}
        </>
      )}
      {radioValue === 2 && cityStateMarker && (
        <>
          <MarkerComponent markerObj={cityStateMarker} />
          {cityStateZipMarkers?.length > 0 && (
            <MarkerClustererComponent zipMarkers={cityStateZipMarkers} />
          )}
        </>
      )}
    </GoogleMap>
  );
};

export default GoogleMapComponent;
