import { gql } from '@apollo/client';

export const UPDATE_VENT_TYPE = gql`
  mutation updateVentType($where: VentTypeWhereInput!, $data: VentTypeInput!) {
    updateVentType(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_VENT_TYPE = gql`
  mutation createVentType($data: VentTypeInput!) {
    createVentType(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_VENT_TYPE = gql`
  mutation deleteVentType($where: VentTypeWhereInput!) {
    deleteVentType(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
