import { gql } from '@apollo/client';

export const UPDATE_PRODUCT_ITEM = gql`
  mutation updateProductItem(
    $where: ProductItemWhereInput!
    $data: ProductItemInput!
  ) {
    updateProductItem(where: $where, data: $data) {
      data {
        id
        name
        productItemCode
        refData
        description
        quoteDescription
        sku
        uuid
        lineOfBusinessId
        subAreaId
        productType
        defaultPrice
        hsn
        btu
        manufacturerId
        productCategoryId
        costPrice
        isActive
        isSellable
        unitForMeasure
        manageInventory
        width
        height
        depth
        weight
        productItemModel
        productItemMaterial
        productItemWarranty
        labourWarranty
        fuelSource
        ventType
        capacity
        energyEfficiency
        style
        softness
        noXLevel
        locationType
        options
        compostable
        allowPriceChange
        lowVoc
        paintable
        asset
        tenantId
      }
      status
      message
    }
  }
`;

export const CREATE_PRODUCT_ITEM = gql`
  mutation createProductItem($data: ProductItemInput!) {
    createProductItem(data: $data) {
      data {
        id
        name
        productItemCode
        refData
        description
        quoteDescription
        sku
        lineOfBusinessId
        subAreaId
        productType
        defaultPrice
        hsn
        btu
        manufacturerId
        productCategoryId
        costPrice
        isActive
        isSellable
        unitForMeasure
        manageInventory
        width
        height
        depth
        weight
        productItemModel
        productItemMaterial
        productItemWarranty
        labourWarranty
        fuelSource
        ventType
        capacity
        energyEfficiency
        style
        softness
        noXLevel
        locationType
        options
        compostable
        allowPriceChange
        lowVoc
        paintable
        asset
        tenantId
      }
      status
      message
    }
  }
`;

export const CREATE_PRODUCT_SUB_ITEM = gql`
  mutation createProductSubItem($data: ProductSubItemInput!) {
    createProductSubItem(data: $data) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const UPDATE_PRODUCT_SUB_ITEM = gql`
  mutation updateProductSubItem(
    $data: ProductItemArrayInput!
    $where: ProductWhereInput!
  ) {
    updateProductSubItem(data: $data, where: $where) {
      data {
        id
      }
      status
      message
    }
  }
`;

export const DELETE_PRODUCT_ITEM = gql`
  mutation deleteProductItem($where: ProductItemWhereInput!) {
    deleteProductItem(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const DELETE_PRODUCT_SUB_ITEM = gql`
  mutation deleteProductSubItem($where: ProductWhereInput!) {
    deleteProductSubItem(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
