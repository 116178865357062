import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { uploadLogo } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import BrandForm from '../components/BrandForm';
import CardWrapper from '../components/BrandTabs';
import { CREATE_BRAND } from '../graphql/Mutations';

function BrandCreate() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const [createBrand] = useMutation(CREATE_BRAND, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const uuid = uuidv4();
    const logo = await uploadLogo({
      logoObject: formValues?.logo,
      currentUser,
      uuid,
      folder: 'brand'
    });

    const newFormValues = {
      ...formValues,
      logo,
      uuid
    };

    const variables = newFormValues;

    try {
      const response = await createBrand({
        variables: { data: { ...variables } }
      });
      if (response?.data?.createBrand) {
        const { id } = response?.data?.createBrand?.data;
        history?.push(`${ROUTES?.RETAILERS}/edit/lob/${id}`);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: '',
    waiverText: '',
    priceGuarantee: '',
    offerText: '',
    productDetails: ''
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.RETAILERS} />
          <span className="portal-header">Add Retailer</span>
        </div>
      </Portal>
      <CardWrapper>
        <BrandForm
          brandData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
}

export default withRouter(BrandCreate);
