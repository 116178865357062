import { Card, Checkbox, Form, TimePicker } from 'antd';
import React from 'react';

const CutoffTimeForm = () => {
  return (
    <Card>
      <div className="setting-labels">
        <span className="row-title">CUT-OFF Times</span>
        <span className="row-description">
          Define cut-off times for the selected days of the week
        </span>
      </div>
      <div className="setting-collapse-content">
        <div className="cut-off-form">
          <Form.Item name="mondayCheckbox" valuePropName="checked">
            <Checkbox className="common-checkbox">Monday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item name="monday">
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item name="tuesdayCheckbox" valuePropName="checked">
            <Checkbox className="common-checkbox">Tuesday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item name="tuesday">
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item name="wednesdayCheckbox" valuePropName="checked">
            <Checkbox className="common-checkbox">Wednesday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item name="wednesday">
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item name="thursdayCheckbox" valuePropName="checked">
            <Checkbox className="common-checkbox">Thursday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item name="thursday">
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item name="fridayCheckbox" valuePropName="checked">
            <Checkbox className="common-checkbox">Friday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item name="friday">
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item name="saturdayCheckbox" valuePropName="checked">
            <Checkbox className="common-checkbox">Saturday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item name="saturday">
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
        <div className="cut-off-form">
          <Form.Item name="sundayCheckbox" valuePropName="checked">
            <Checkbox className="common-checkbox">Sunday</Checkbox>
          </Form.Item>
          <span>
            <Form.Item name="sunday">
              <TimePicker
                className="common-time-picker"
                use12Hours
                format="h:mm a"
                allowClear={false}
              />
            </Form.Item>
          </span>
        </div>
      </div>
    </Card>
  );
};

export default CutoffTimeForm;
