import { gql } from '@apollo/client';

export const UPDATE_PROPERTY_FINISHED_BASEMENT = gql`
  mutation updatePropertyFinishedBasement(
    $where: PropertyFinishedBasementWhereInput!
    $data: PropertyFinishedBasementInput!
  ) {
    updatePropertyFinishedBasement(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_PROPERTY_FINISHED_BASEMENT = gql`
  mutation createPropertyFinishedBasement(
    $data: PropertyFinishedBasementInput!
  ) {
    createPropertyFinishedBasement(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_PROPERTY_FINISHED_BASEMENT = gql`
  mutation deletePropertyFinishedBasement(
    $where: PropertyFinishedBasementWhereInput!
  ) {
    deletePropertyFinishedBasement(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
