import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PropertyFinishBasementCeilingCreate from './pages/PropertyFinishBasementCeilingCreate';
import PropertyFinishBasementCeilingEdit from './pages/PropertyFinishBasementCeilingEdit';
import PropertyFinishedBasementCeilingList from './pages/PropertyFinishedBasementCeilingList';
import './propertyFinishedBasementModule.less';

const PropertyFinishedBasementCeilingWrapper = () => {
  return (
    <div className="property-finished-basement-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PROPERTY_FINISHED_BASEMENTS_CEILING}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyFinishedBasementCeilingList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PROPERTY_FINISHED_BASEMENTS_CEILING}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <PropertyFinishBasementCeilingCreate {...props} />
            </AccessControl>
          )}
        />

        <Route
          path={`${ROUTES?.PROPERTY_FINISHED_BASEMENTS_CEILING}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <PropertyFinishBasementCeilingEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default PropertyFinishedBasementCeilingWrapper;
