import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import LineOfBusinessForm from '../components/LineOfBusinessForm';
import CardWrapper from '../components/LineOfBusinessTabs';
import { UPDATE_LINE_OF_BUSINESS } from '../graphql/Mutations';
import { GET_LINE_OF_BUSINESS } from '../graphql/Queries';

function LineOfBusinessEdit(props) {
  const { match: { params: { lobId } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { lineOfBusiness } = {} } = useQuery(GET_LINE_OF_BUSINESS, {
    variables: { id: lobId },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateLineOfBusinessMutate] = useMutation(UPDATE_LINE_OF_BUSINESS, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    delete newFormValues?.lobCode;

    const variables = {
      data: newFormValues,
      where: { id: lineOfBusiness?.id }
    };

    try {
      const response = await updateLineOfBusinessMutate({
        variables: { ...variables }
      });
      if (response?.data?.updateLineOfBusiness) {
        setSubmitLoading(false);
        history?.push(ROUTES?.LINE_OF_BUSINESSES);
      }
    } catch (error) {
      return error;
    }
  };

  if (!lobId) {
    return <Redirect to={ROUTES?.LINE_OF_BUSINESSES} />;
  }

  const initialValues = {
    ...lineOfBusiness,
    description: lineOfBusiness?.description || '',
    isActive: lineOfBusiness?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.LINE_OF_BUSINESSES} />
          {lineOfBusiness && (
            <span className="portal-header">{lineOfBusiness?.label}</span>
          )}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <LineOfBusinessForm
            isEdit
            lineOfBusinessData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(LineOfBusinessEdit);
