import { Button, Checkbox, Form } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { SAME_DAY_TIME } from '../../../../../../common/constants';
import CommonProductDetail from '../../globalComponents/CommonProductDetail';
import InputComponent from '../../globalComponents/InputComponent';
import PreviewTagComponent from '../../previewTagComponent';
import '../contactMe.less';

const ContactMeComponent = ({ contactMeConfig = null, logoUrl = null }) => {
  const [form] = Form?.useForm();

  const checkBoxTitle = {
    URGENT: 'It’s urgent, contact me today',
    CONFIRMATION: 'Send confirmation to my email'
  };

  return (
    <div className="contact-me-module">
      <PreviewTagComponent />
      <div className="logo-section">
        <img alt="logo" src={logoUrl} />
      </div>
      <div className="title-section">
        <span className="thin-title">{contactMeConfig?.thinLabel}</span>
        <span className="bold-title">{contactMeConfig?.boldLabel}</span>
      </div>
      <CommonProductDetail />
      {contactMeConfig?.showCountDown && (
        <div className="count-down-section">
          <div className="time">
            <span>00:13:11</span>
          </div>
          <div className="description">{SAME_DAY_TIME}</div>
        </div>
      )}
      <Form name="contactMeForm" form={form}>
        <div className="form-section">
          {contactMeConfig?.showComment && (
            <>
              <span className="comment-title">Optional comments</span>
              <Form.Item
                name="optionalComment"
                className="comment-input fill-width"
              >
                <InputComponent
                  readOnly
                  placeholder={contactMeConfig?.commentPlaceHolder}
                />
              </Form.Item>
            </>
          )}
          {contactMeConfig?.additionalOptions?.length > 0 && (
            <Form.Item
              className="fill-width"
              name="additionalOptions"
              normalize={() => []}
            >
              <Checkbox.Group className="d-flex flex-vertical">
                {map(
                  contactMeConfig?.additionalCheckBoxOptions,
                  (singleCheckbox) => {
                    if (
                      contactMeConfig?.additionalOptions.includes(
                        singleCheckbox?.key
                      )
                    ) {
                      return (
                        <Checkbox
                          key={singleCheckbox?.key}
                          value={singleCheckbox?.key}
                          className={`common-checkbox-cpq ${
                            singleCheckbox?.key === 'CONFIRMATION'
                              ? 'confirm-email'
                              : ''
                          }`}
                        >
                          {checkBoxTitle[singleCheckbox?.key]}
                        </Checkbox>
                      );
                    }
                  }
                )}
              </Checkbox.Group>
            </Form.Item>
          )}
        </div>
        <div className="email-section">
          <Form.Item name="email">
            <InputComponent readOnly placeholder={contactMeConfig?.email} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="common-button-cpq fill-width submit-btn"
            >
              {contactMeConfig?.label}
            </Button>
          </Form.Item>
        </div>
      </Form>
      <div className="contact-me-footer">
        <Button type="link" className="fill-width back-link">
          Back
        </Button>
      </div>
    </div>
  );
};

export default ContactMeComponent;
