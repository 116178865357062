import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PropertyHeatSourceCreate from './pages/PropertyHeatSourceCreate';
import PropertyHeatSourceEdit from './pages/PropertyHeatSourceEdit';
import PropertyHeatSourceList from './pages/PropertyHeatSourceList';
import './propertyHeatSource.less';

const PropertyHeatSourceWrapper = () => {
  return (
    <div className="property-heat-source-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PROPERTY_HEAT_SOURCE}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PropertyHeatSourceList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PROPERTY_HEAT_SOURCE}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <PropertyHeatSourceCreate {...props} />
            </AccessControl>
          )}
        />

        <Route
          path={`${ROUTES?.PROPERTY_HEAT_SOURCE}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <PropertyHeatSourceEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};
export default PropertyHeatSourceWrapper;
