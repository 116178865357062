import { MenuOutlined, UpOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Collapse, message, notification } from 'antd';
import { every, find, forEach, lowerCase } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { AppContext } from '../../../AppContext';
import { checkPermissions, formatPrice } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import TableComponent from '../../../components/TableComponent';
import { CHANGE_PRODUCT_RANKING } from '../graphql/Mutations';
import { RANKING_QUALITY_LISTING } from '../graphql/Queries';

const { Panel } = Collapse;

const ProductRankingCollapseListing = () => {
  const [loading, setLoading] = useState(true);
  const {
    state: { productRankingConfig, permissions, showNotificationWarning }
  } = useContext(AppContext);
  const [productRankingQualityListing, { data }] = useLazyQuery(
    RANKING_QUALITY_LISTING,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false)
    }
  );

  const [changeProductRanking] = useMutation(CHANGE_PRODUCT_RANKING, {
    onCompleted() {},
    onError() {}
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, [
      'FET_PRODUCT_RANKING_LIST'
    ]);
    if (productRankingConfig && permitted && !showNotificationWarning) {
      setLoading(true);
      productRankingQualityListing({
        variables: {
          where: {
            capacity: productRankingConfig?.capacity?.key,
            fuelSource: productRankingConfig?.fuelSource?.key,
            height: productRankingConfig?.height?.key,
            subArea: productRankingConfig?.subArea?.id,
            region: productRankingConfig?.region?.id,
            brand: productRankingConfig?.brand?.id,
            lineOfBusiness: productRankingConfig?.lineOfBusiness?.id,
            ...(productRankingConfig?.fuelSource?.key === 'NATURAL_GAS' && {
              ventType: productRankingConfig?.ventType?.key
            })
          }
        }
      });
    } else {
      setLoading(false);
    }
    if (showNotificationWarning) {
      notification?.warn({
        className: 'top-notification',
        description:
          'Please create/select some Lines of Business, Sub Areas, Regions, Products, and Packages for a seamless experience.'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRankingConfig]);

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const DragHandle = sortableHandle(({ record = null }) => {
    return (
      <MenuOutlined
        className={record?.highlighted ? 'drag-row highlight' : 'drag-row'}
      />
    );
  });

  const columns = [
    checkPermissions(permissions, ['FET_PRODUCT_RANKING_UPDATE']) && {
      title: 'ORDER',
      dataIndex: 'sort',
      fixed: 'left',
      align: 'center',
      width: 100,
      className: 'drag-visible',
      render: (sort, record) => <DragHandle record={record} />
    },
    {
      title: 'RANK',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
      ellipsis: true,
      width: 100,
      className: 'max-width-column'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      ellipsis: true,
      width: 150,
      className: 'max-width-column'
    },
    {
      title: 'PACKAGE',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      className: 'max-width-column'
    },
    {
      title: 'STOCK',
      dataIndex: 'stock',
      key: 'stock',
      ellipsis: true,
      align: 'center',
      width: 100,
      className: 'max-width-column',
      render: (stock) => stock || '-'
    },
    {
      title: 'MANUFACTURER',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      ellipsis: true,
      width: 150,
      className: 'max-width-column'
    },
    {
      title: 'WARRANTY',
      dataIndex: 'productItemWarranty',
      key: 'productItemWarranty',
      ellipsis: true,
      width: 150,
      className: 'max-width-column',
      render: (productItemWarranty) =>
        productItemWarranty ? `${productItemWarranty} years` : '-'
    },
    {
      title: 'COST PER DAY',
      dataIndex: 'costPerDay',
      fixed: 'right',
      key: 'costPerDay',
      ellipsis: true,
      onHeaderCell: () => {
        return {
          style: {
            textAlign: 'right'
          }
        };
      },
      align: 'right',
      width: 130,
      render: (costPerDay) => (costPerDay ? formatPrice(costPerDay) : '-')
    },
    {
      title: 'SELLING PRICE',
      dataIndex: 'sellingPrice',
      fixed: 'right',
      key: 'sellingPrice',
      ellipsis: true,
      onHeaderCell: () => {
        return {
          style: {
            textAlign: 'right'
          }
        };
      },
      align: 'right',
      width: 130,
      render: (sellingPrice) => (sellingPrice ? formatPrice(sellingPrice) : '-')
    }
  ];

  const onSortEnd = async ({ oldIndex, newIndex }, productQuality) => {
    if (oldIndex !== newIndex) {
      setLoading(true);
      const isConfigId = every(
        data?.productRankingQualityListing?.[lowerCase(productQuality)],
        (item) => item?.configId
      );
      const productInfo = [];
      if (!isConfigId) {
        forEach(data?.productRankingQualityListing?.good || [], (item) =>
          productInfo?.push({
            order: item?.rank,
            productQuality: item?.productQuality,
            productId: item?.id
          })
        );
        forEach(data?.productRankingQualityListing?.better || [], (item) =>
          productInfo?.push({
            order: item?.rank,
            productQuality: item?.productQuality,
            productId: item?.id
          })
        );
        forEach(data?.productRankingQualityListing?.best || [], (item) =>
          productInfo?.push({
            order: item?.rank,
            productQuality: item?.productQuality,
            productId: item?.id
          })
        );
      }
      try {
        const response = await changeProductRanking({
          variables: {
            data: {
              changeRanking: {
                oldIndex,
                newIndex,
                productQuality
              },
              productInfo: !isConfigId ? productInfo : []
            },
            where: {
              capacity: productRankingConfig?.capacity?.key,
              fuelSource: productRankingConfig?.fuelSource?.key,
              height: productRankingConfig?.height?.key,
              subArea: productRankingConfig?.subArea?.id,
              region: productRankingConfig?.region?.id,
              brand: productRankingConfig?.brand?.id,
              lineOfBusiness: productRankingConfig?.lineOfBusiness?.id,
              ...(productRankingConfig?.fuelSource?.key === 'NATURAL_GAS' && {
                ventType: productRankingConfig?.ventType?.key
              })
            }
          }
        });
        if (response) {
          productRankingQualityListing({
            variables: {
              where: {
                capacity: productRankingConfig?.capacity?.key,
                fuelSource: productRankingConfig?.fuelSource?.key,
                height: productRankingConfig?.height?.key,
                subArea: productRankingConfig?.subArea?.id,
                region: productRankingConfig?.region?.id,
                brand: productRankingConfig?.brand?.id,
                lineOfBusiness: productRankingConfig?.lineOfBusiness?.id,
                ...(productRankingConfig?.fuelSource?.key === 'NATURAL_GAS' && {
                  ventType: productRankingConfig?.ventType?.key
                })
              }
            }
          });
          setLoading(false);
        }
      } catch (error) {
        message?.error('got some problem');
      }
    }
  };

  const DraggableContainer = (props, productQuality = 'GOOD') => {
    return (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={(sortProps) => onSortEnd(sortProps, productQuality)}
        axis="y"
        lockAxis="y"
        lockOffset={['0%', '100%']}
        {...props}
      />
    );
  };

  const DraggableBodyRow = ({ className, style, ...restProps }, tableData) => {
    const index = find(
      tableData,
      (item) => item?.id === restProps?.['data-row-key']
    );
    if (index) return <SortableItem index={index?.rank} {...restProps} />;
    return null;
  };

  return (
    <div className="product-ranking-listing">
      <AccessControl
        allowedPermissions={['FET_PRODUCT_RANKING_LIST']}
        showNoAccess
      >
        <Collapse
          bordered={false}
          defaultActiveKey={['GOOD', 'BETTER', 'BEST']}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 0 : 180} />
          )}
          className="common-collapse ranking-listing-collapse"
        >
          <Panel forceRender header="Good" key="GOOD">
            <TableComponent
              isSearch={false}
              fullHeight={false}
              scroll={{ x: 'max-content' }}
              loadingData={loading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.productRankingQualityListing?.good || []}
              rowKey="id"
              components={
                data?.productRankingQualityListing?.good?.length > 0
                  ? {
                      body: {
                        wrapper: (props) => DraggableContainer(props, 'GOOD'),
                        row: (props) =>
                          DraggableBodyRow(
                            props,
                            data?.productRankingQualityListing?.good
                          )
                      }
                    }
                  : {}
              }
            />
          </Panel>
          <Panel forceRender header="Better" key="BETTER">
            <TableComponent
              isSearch={false}
              fullHeight={false}
              scroll={{ x: 'max-content' }}
              loadingData={loading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.productRankingQualityListing?.better || []}
              rowKey="id"
              components={
                data?.productRankingQualityListing?.better?.length > 0
                  ? {
                      body: {
                        wrapper: (props) => DraggableContainer(props, 'BETTER'),
                        row: (props) =>
                          DraggableBodyRow(
                            props,
                            data?.productRankingQualityListing?.better
                          )
                      }
                    }
                  : {}
              }
            />
          </Panel>
          <Panel forceRender header="Best" key="BEST">
            <TableComponent
              isSearch={false}
              fullHeight={false}
              scroll={{ x: 'max-content' }}
              loadingData={loading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.productRankingQualityListing?.best || []}
              rowKey="id"
              components={
                data?.productRankingQualityListing?.best?.length > 0
                  ? {
                      body: {
                        wrapper: (props) => DraggableContainer(props, 'BEST'),
                        row: (props) =>
                          DraggableBodyRow(
                            props,
                            data?.productRankingQualityListing?.best
                          )
                      }
                    }
                  : {}
              }
            />
          </Panel>
        </Collapse>
      </AccessControl>
    </div>
  );
};

export default ProductRankingCollapseListing;
