import { gql } from '@apollo/client';

export const GET_LOCATION_TYPES = gql`
  query locationTypes(
    $filter: LocationTypeFilter!
    $where: LocationTypeWhereFilter
  ) {
    locationTypes(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const GET_LOCATION_TYPE = gql`
  query locationType($where: LocationTypeWhereInput!) {
    locationType(where: $where) {
      id
      label
      key
      description
      order
      isActive
      tenantId
      referenceId
    }
  }
`;

export const LOCATION_TYPE_FILTER = gql`
  query locationTypeFilters($filter: LocationTypeFilter!) {
    locationTypeFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
