import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Empty, Form, Row } from 'antd';
import { debounce, forEach, map, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  checkPermissions,
  dateFormatWithoutTime,
  formValidatorRules
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DatePickerComponent from '../../../components/DatePickerComponent';
import EditorComponent from '../../../components/EditorComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { BRAND, LINE_OF_BUSINESS, REGION, SUB_AREA } from '../graphql/Queries';

let brandScrollDebounceJob;
let regionScrollDebounceJob;
let lineOfBusinessScrollDebounceJob;
let subAreaScrollDebounceJob;
const {
  required,
  requiredWhiteSpaceAllowed,
  startDate,
  endDate
} = formValidatorRules;

const brandFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  defaultBrand: true
};
const regionFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: ''
};
const lineOfBusinessFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true
};
const subAreaFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true
};

const { Option } = SelectComponent;

const ProductForm = (props) => {
  const { productData, handleSubmit, isSubmit, isEdit = false } = props;
  const {
    state: { globalDateFormat, permissions },
    dispatch
  } = useContext(AppContext);

  const [validationTriggered, setValidationTriggered] = useState(false);

  const [brandLoading, setBrandLoading] = useState(false);
  const [brandSearchFlag, setBrandSearchFlag] = useState(false);
  const [brandsData, setBrandsData] = useState([]);

  const [isEnd, setIsEnd] = useState(false);
  const [debounceCall, setDebounceCall] = useState(0);

  const [regionLoading, setRegionLoading] = useState(false);
  const [regionSearchFlag, setRegionSearchFlag] = useState(false);
  const [regionsData, setRegionsData] = useState([]);
  const [regionIsEnd, setRegionIsEnd] = useState(false);
  const [regionDebounceCall, setRegionDebounceCall] = useState(0);

  const [lineOfBusinessLoading, setLineOfBusinessLoading] = useState(false);
  const [lineOfBusinessSearchFlag, setLineOfBusinessSearchFlag] = useState(
    false
  );
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessIsEnd, setLineOfBusinessIsEnd] = useState(false);
  const [lineOfBusinessDebounceCall, setLineOfBusinessDebounceCall] = useState(
    0
  );

  const [subAreaLoading, setSubAreaLoading] = useState(false);
  const [subAreaSearchFlag, setSubAreaSearchFlag] = useState(false);
  const [subAreasData, setSubAreasData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [subAreaIsEnd, setSubAreaIsEnd] = useState(false);
  const [subAreaDebounceCall, setSubAreaDebounceCall] = useState(0);

  const [disableSubArea, setDisableSubArea] = useState(true);
  const [selectedLob, setSelectedLob] = useState('');

  const [startDateForm, setStartDate] = useState(null);
  const [endDateForm, setEndDate] = useState(null);

  const [form] = Form?.useForm();

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const [brands] = useLazyQuery(BRAND, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.brands?.data?.length < SKIP_RECORD) {
        setIsEnd(true);
      }
      if (brandSearchFlag) {
        setBrandsData([...res?.brands?.data]);
      } else {
        if (isEdit) {
          const tempBrands = [
            ...productData?.productBrands,
            ...res?.brands?.data
          ];
          setBrandsData(uniqBy([...brandsData, ...tempBrands], 'id'));
          return;
        }
        setBrandsData([...brandsData, ...res?.brands?.data]);
      }
      setBrandLoading(false);
    },
    onError() {
      setBrandLoading(false);
    }
  });

  const onBrandScroll = (event) => {
    setBrandSearchFlag(false);
    if (brandScrollDebounceJob) {
      brandScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    brandScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setBrandLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        brands({
          variables: {
            filter: {
              ...brandFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    brandScrollDebounceJob();
  };

  const handleBrandChange = (value) => {
    setBrandSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setBrandLoading(true);
      brands({
        variables: {
          filter: {
            ...brandFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    } else {
      setBrandLoading(true);
      brands({
        variables: {
          filter: {
            ...brandFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedBrandHandler = useCallback(
    debounce(handleBrandChange, 500),
    []
  );

  const handleBrandClear = () => {
    form?.setFieldsValue({
      brands: null
    });
    setBrandsData([]);
    brands({ variables: { filter: brandFilter, where: { isActive: true } } });
  };

  const handleBrandBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
    setBrandSearchFlag(true);
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true }
      }
    });
  };

  const [regions] = useLazyQuery(REGION, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.regions?.data?.length < SKIP_RECORD) {
        setRegionIsEnd(true);
      }
      if (regionSearchFlag) {
        setRegionsData([...res?.regions?.data]);
      } else {
        if (isEdit) {
          const tempRegions = [
            ...productData?.productRegions,
            ...res?.regions?.data
          ];
          const regionsCopy = [];
          forEach(productData?.productRegions, (item) => {
            regionsCopy?.push(item);
            if (item?.subRegions?.length > 0) {
              forEach(item?.subRegions, (data) =>
                regionsCopy?.push({ ...data, parentId: item?.id })
              );
            }
          });
          setRegionsData(uniqBy([...regionsCopy, ...tempRegions], 'id'));
          return;
        }
        setRegionsData([...regionsData, ...res?.regions?.data]);
      }
      setRegionLoading(false);
    },
    onError() {
      setRegionLoading(false);
    }
  });

  const onRegionScroll = (event) => {
    setRegionSearchFlag(false);
    if (regionScrollDebounceJob) {
      regionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    regionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !regionIsEnd) {
        setRegionLoading(true);
        setRegionDebounceCall((prevState) => prevState + 1);
        regions({
          variables: {
            filter: {
              ...regionFilter,
              skip: (regionDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    regionScrollDebounceJob();
  };

  const handleRegionChange = (value) => {
    setRegionSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setRegionLoading(true);
      regions({
        variables: {
          filter: {
            ...regionFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    } else {
      setRegionLoading(true);
      regions({
        variables: {
          filter: {
            ...regionFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRegionHandler = useCallback(
    debounce(handleRegionChange, 500),
    []
  );

  const handleRegionClear = () => {
    form?.setFieldsValue({
      regions: null
    });
    setRegionsData([]);
    regions({ variables: { filter: regionFilter, where: { isActive: true } } });
  };

  const handleRegionBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
    setRegionSearchFlag(true);
    regions({
      variables: {
        filter: regionFilter,
        where: { isActive: true }
      }
    });
  };

  const [lineOfBusinesses] = useLazyQuery(LINE_OF_BUSINESS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.lineOfBusinesses?.data?.length < SKIP_RECORD) {
        setLineOfBusinessIsEnd(true);
      }
      if (lineOfBusinessSearchFlag) {
        setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
      } else {
        if (isEdit) {
          const tempLineOfBusiness = [
            productData?.productLob,
            ...res?.lineOfBusinesses?.data
          ];
          setLineOfBusinessesData(
            uniqBy([...lineOfBusinessesData, ...tempLineOfBusiness], 'id')
          );
          return;
        }
        setLineOfBusinessesData([
          ...lineOfBusinessesData,
          ...res?.lineOfBusinesses?.data
        ]);
      }
      setLineOfBusinessLoading(false);
    },
    onError() {
      setLineOfBusinessLoading(false);
    }
  });

  const onLineOfBusinessScroll = (event) => {
    setLineOfBusinessSearchFlag(false);
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessIsEnd) {
        setLineOfBusinessLoading(true);
        setLineOfBusinessDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              skip: (lineOfBusinessDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    lineOfBusinessScrollDebounceJob();
  };

  const handleLineOfBusinessChange = (value) => {
    setLineOfBusinessSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setLineOfBusinessLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            search: value
          }
        }
      });
    } else {
      setLineOfBusinessLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLineOfBusinessHandler = useCallback(
    debounce(handleLineOfBusinessChange, 500),
    []
  );

  const handleLineOfBusinessClear = () => {
    form?.setFieldsValue({
      lineOfBusinesses: null
    });
    setLineOfBusinessesData([]);
    lineOfBusinesses({
      variables: { filter: lineOfBusinessFilter, where: { isActive: true } }
    });
  };

  const handleLineOfBusinessBlur = () => {
    setSearchValue('');
    setLineOfBusinessDebounceCall(0);
    setLineOfBusinessIsEnd(false);
  };

  const [subAreas] = useLazyQuery(SUB_AREA, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.subAreas?.data?.length < SKIP_RECORD) {
        setSubAreaIsEnd(true);
      }
      if (subAreaSearchFlag) {
        setSubAreasData([...res?.subAreas?.data]);
      } else {
        if (isEdit) {
          const tempSubAreas = [
            productData?.productSubArea,
            ...res?.subAreas?.data
          ];
          setSubAreasData(uniqBy([...subAreasData, ...tempSubAreas], 'id'));
          return;
        }
        setSubAreasData([...subAreasData, ...res?.subAreas?.data]);
      }
      setSubAreaLoading(false);
    },
    onError() {
      setSubAreaLoading(false);
    }
  });

  const onSubAreaScroll = (event) => {
    setSubAreaSearchFlag(false);
    if (subAreaScrollDebounceJob) {
      subAreaScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    subAreaScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !subAreaIsEnd) {
        setSubAreaLoading(true);
        setSubAreaDebounceCall((prevState) => prevState + 1);
        subAreas({
          variables: {
            filter: {
              ...subAreaFilter,
              skip: (subAreaDebounceCall + 1) * SKIP_RECORD,
              lobId: selectedLob,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    subAreaScrollDebounceJob();
  };

  const handleSubAreaChange = (value) => {
    setSubAreaSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setSubAreaLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            search: value,
            lobId: selectedLob
          },
          where: { isActive: true }
        }
      });
    } else {
      setSubAreaLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            search: value,
            lobId: selectedLob
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubAreaHandler = useCallback(
    debounce(handleSubAreaChange, 500),
    []
  );

  const handleSubAreaClear = () => {
    form?.setFieldsValue({
      subAreas: null
    });
    setSubAreasData([]);
    subAreas({
      variables: {
        filter: { ...subAreaFilter, lobId: selectedLob },
        where: { isActive: true }
      }
    });
  };

  const handleSubAreaBlur = () => {
    setSearchValue('');
    setSubAreaDebounceCall(0);
    setSubAreaIsEnd(false);
  };

  useEffect(() => {
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true }
      }
    });
    regions({
      variables: {
        filter: regionFilter,
        where: { isActive: true }
      }
    });
    lineOfBusinesses({
      variables: {
        filter: lineOfBusinessFilter,
        where: { isActive: true }
      }
    });
    if (productData?.lineOfBusinessId) {
      setDisableSubArea(false);
      subAreas({
        variables: {
          filter: { ...subAreaFilter, lobId: productData?.lineOfBusinessId },
          where: { isActive: true }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLineOfBusinessSelect = (value) => {
    if (value) {
      form?.setFieldsValue({
        subAreas: null
      });
      setSelectedLob(value);
      setDisableSubArea(false);
      setSubAreaSearchFlag(true);
      setLineOfBusinessLoading(false);
      subAreas({
        variables: {
          filter: { ...subAreaFilter, lobId: value },
          where: { isActive: true }
        }
      });
    } else {
      setSubAreaSearchFlag(true);
      setLineOfBusinessLoading(false);
      setDisableSubArea(true);
      lineOfBusinesses({
        variables: {
          filter: lineOfBusinessFilter,
          where: { isActive: true }
        }
      });
    }
  };

  const handleChangeStartDate = (value) => {
    if (!value) {
      setStartDate(null);
      return;
    }
    setStartDate(moment(value)?.startOf('day'));
  };

  const handleChangeEndDate = (value) => {
    if (!value) {
      setEndDate(null);
      return;
    }
    setEndDate(moment(value)?.endOf('day'));
  };

  return (
    <div>
      <AccessControl
        allowedPermissions={
          isEdit ? ['FET_PRODUCT_UPDATE'] : ['FET_PRODUCT_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="product-table-discard-btn"
            onClick={() => history?.push(ROUTES?.PACKAGES)}
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="product-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={productData}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        onFinishFailed={onFinishFailed}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit ? ['FET_PRODUCT_UPDATE'] : ['FET_PRODUCT_CREATE']
            )
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter SKU' }]}
                name="sku"
                label="SKU"
              >
                <InputComponent allowClear placeholder="Enter SKU" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Select Package Type' }]}
                name="productType"
                label="Package Type"
              >
                <SelectComponent placeholder="Select Package Type" allowClear>
                  <Option key="single" value="Single">
                    Single
                  </Option>
                  <Option key="bundle" value="Bundle">
                    Bundle
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Name' }]}
                name="title"
                label="Name"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
            </Col>
            {isEdit && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="price" label="Price">
                  <PriceComponent disabled placeholder="Price" isPrice />
                </Form.Item>
              </Col>
            )}
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="lineOfBusinessId"
                label="Line Of Business"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Line Of Business'
                  }
                ]}
              >
                <SelectComponent
                  placeholder="Select Line Of Business"
                  allowClear
                  notFoundContent={
                    lineOfBusinessLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSelect={handleLineOfBusinessSelect}
                  onBlur={handleLineOfBusinessBlur}
                  onSearch={debouncedLineOfBusinessHandler}
                  onClear={handleLineOfBusinessClear}
                  onPopupScroll={onLineOfBusinessScroll}
                >
                  {map(lineOfBusinessesData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="subAreaId"
                label="Sub Area"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Sub Area'
                  }
                ]}
              >
                <SelectComponent
                  disabled={disableSubArea}
                  placeholder="Select Sub Area"
                  allowClear
                  notFoundContent={
                    subAreaLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleSubAreaBlur}
                  onSearch={debouncedSubAreaHandler}
                  onClear={handleSubAreaClear}
                  onPopupScroll={onSubAreaScroll}
                >
                  {map(subAreasData, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status'
                  }
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            {isEdit && (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="sellingPrice" label="Selling Price">
                  <PriceComponent
                    disabled
                    placeholder="Enter Selling Price"
                    isPrice
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Package Quality'
                  }
                ]}
                name="productQuality"
                label="Package Quality"
              >
                <SelectComponent
                  placeholder="Select Package Quality"
                  allowClear
                >
                  <Option key="GOOD" value="GOOD">
                    Good
                  </Option>
                  <Option key="BETTER" value="BETTER">
                    Better
                  </Option>
                  <Option key="BEST" value="BEST">
                    Best
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="required-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Form.Item
                rules={[
                  {
                    required,
                    message: 'Please Select Retailers',
                    type: 'array'
                  }
                ]}
                name="brands"
                label="Retailers"
              >
                <SelectComponent
                  placeholder="Select Retailers"
                  allowClear
                  mode="multiple"
                  notFoundContent={
                    brandLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleBrandBlur}
                  onSearch={debouncedBrandHandler}
                  onClear={handleBrandClear}
                  onPopupScroll={onBrandScroll}
                >
                  {isEdit ? (
                    <>
                      {map(brandsData, (item) => (
                        <Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Option>
                      ))}
                    </>
                  ) : (
                    <>
                      {map(brandsData, (item) => (
                        <Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Option>
                      ))}
                    </>
                  )}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Form.Item
                rules={[
                  { required, message: 'Please Select Regions', type: 'array' }
                ]}
                name="regions"
                label="Regions"
              >
                <SelectComponent
                  placeholder="Select Regions"
                  allowClear
                  mode="multiple"
                  notFoundContent={
                    regionLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleRegionBlur}
                  onSearch={debouncedRegionHandler}
                  onClear={handleRegionClear}
                  onPopupScroll={onRegionScroll}
                  optionLabelProp="key"
                >
                  {map(regionsData, (item) => {
                    return (
                      <Option key={item?.name} value={item?.id}>
                        <span
                          className={`${item?.parentId ? 'sub-region' : ''}`}
                        >
                          {item?.name}
                        </span>
                      </Option>
                    );
                  })}
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <span className="form-divider-text">OPTIONAL</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" label="Package Description">
                <EditorComponent placeholder="Package Description Goes here..." />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="startData"
                label="Start Date"
                normalize={(value) => {
                  if (value) {
                    return moment(value)?.startOf('day');
                  }
                }}
                rules={[
                  {
                    ...startDate(endDateForm)
                  }
                ]}
              >
                <DatePickerComponent
                  disabledDate
                  onChange={handleChangeStartDate}
                  placeholder="Select Start Date"
                  format={dateFormatWithoutTime(globalDateFormat)}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="endDate"
                label="Expiry Date"
                normalize={(value) => {
                  if (value) {
                    return moment(value)?.endOf('day');
                  }
                  return null;
                }}
                rules={[
                  {
                    ...endDate(startDateForm)
                  }
                ]}
              >
                <DatePickerComponent
                  disabledDate
                  onChange={handleChangeEndDate}
                  placeholder="Select Expiry Date"
                  format={dateFormatWithoutTime(globalDateFormat)}
                />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default ProductForm;
