import { gql } from '@apollo/client';

export const GET_PREFERRED_CONTACT_METHODS = gql`
  query preferredContactMethods(
    $filter: PreferredContactMethodFilter!
    $where: PreferredContactMethodWhereFilter
  ) {
    preferredContactMethods(filter: $filter, where: $where) {
      count
      data {
        id
        label
        refData
        key
        description
        order
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const GET_PREFERRED_CONTACT_METHOD = gql`
  query preferredContactMethod($id: ID!) {
    preferredContactMethod(where: { id: $id }) {
      id
      label
      refData
      key
      description
      order
      isActive
      tenantId
      referenceId
    }
  }
`;

export const PREFERRED_CONTACT_METHOD_FILTER = gql`
  query preferredContactMethodFilters($filter: PreferredContactMethodFilter!) {
    preferredContactMethodFilters(filter: $filter) {
      count
      data {
        label
        refData
        key
        description
        order
        isActive
        tenantId
        referenceId
      }
    }
  }
`;
