import { CloseOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Collapse,
  Empty,
  Form,
  List,
  Popconfirm,
  Radio,
  Row,
  Tag
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { forEach, map, set } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { AppContext } from '../../../AppContext';
import closeImg from '../../../assets/close.svg';
import deleteIcon from '../../../assets/delete-red.svg';
import SaveIcon from '../../../assets/save.svg';
import { ROUTES } from '../../../common/constants';
import {
  checkPermissions,
  dateFormatWithoutTime,
  formValidatorRules
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DatePickerComponent from '../../../components/DatePickerComponent';
import GoBackButton from '../../../components/GoBackButton';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import history from '../../../historyData';
import {
  CREATE_PRICING_MODIFIER,
  UPDATE_PRICING_MODIFIER
} from '../graphql/Mutations';
import {
  GET_PRICING_MODIFIER,
  PRICING_MODIFIER_CONDITIONS,
  PRICING_MODIFIER_STATUS
} from '../graphql/Queries';
import PricingConditionModal from './PricingConditionModal';
import {
  addItemAsOptions,
  billingAsOptions,
  modifierOnOptions,
  pricingOperationOptions,
  pricingValueTypeOptions
} from './radioOptions';

const { Panel } = Collapse;

const { required, startDate, endDate } = formValidatorRules;

let initialValues;

const attributeFilter = {
  sortOn: 'order',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: ''
};

const PricingModifierForm = ({
  isEdit = false,
  match: { params: { id } = {} } = {}
}) => {
  const {
    state: { globalDateFormat, permissions },
    dispatch
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [conditionData, setConditionData] = useState({
    brands: [],
    regions: [],
    lineOfBusiness: [],
    manufacturers: []
  });
  const [pricingValue, setPricingValue] = useState('');
  const [pricingValueType, setPricingValueType] = useState('$');
  const [changeAddItemAs, setChangeAddItemAs] = useState('MAIN');
  const [status, setStatus] = useState('PM_ACTIVE');

  const [startDateForm, setStartDate] = useState(null);
  const [endDateForm, setEndDate] = useState(null);

  const [statusOptions, setStatusOptions] = useState([]);

  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    '1',
    '2',
    '3',
    '4'
  ]);

  const [conditionValidation, setConditionValidation] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [attributeLoading, setAttributeLoading] = useState(false);
  const [attributesData, setAttributesData] = useState([]);

  const [form] = Form?.useForm();

  useEffect(() => {
    if (validationTriggered) {
      if (
        conditionData?.brands?.length > 0 ||
        conditionData?.manufacturers?.length > 0 ||
        conditionData?.regions?.length > 0 ||
        conditionData?.lineOfBusiness?.length > 0
      ) {
        setConditionValidation(false);
      } else {
        setConditionValidation(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionData]);

  const [pricingModifierConditions] = useLazyQuery(
    PRICING_MODIFIER_CONDITIONS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (res) => {
        setAttributesData([...res.pricingModifierConditions?.data]);
        setAttributeLoading(false);
      },
      onError() {
        setAttributeLoading(false);
      }
    }
  );

  const [createPricingModifier] = useMutation(CREATE_PRICING_MODIFIER, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const [updatePricingModifier] = useMutation(UPDATE_PRICING_MODIFIER, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const [pricingModifier, { data }] = useLazyQuery(GET_PRICING_MODIFIER, {
    onCompleted: () => {
      initialValues = {
        ...data?.pricingModifier,
        pricingValue: data?.pricingModifier?.pricingValue?.toString(),
        ...(data?.pricingModifier?.startDate && {
          startDate: moment(data?.pricingModifier?.startDate)
        }),
        ...(data?.pricingModifier?.endDate && {
          endDate: moment(data?.pricingModifier?.endDate)
        })
      };
      const conditionObject = {
        brands: [],
        regions: [],
        lineOfBusiness: [],
        manufacturers: []
      };
      forEach(data?.pricingModifier?.brands, (item) =>
        conditionObject?.brands?.push({ id: item?.id, label: item?.name })
      );
      forEach(data?.pricingModifier?.lineOfBusiness, (item) =>
        conditionObject?.lineOfBusiness?.push({
          id: item?.id,
          label: item?.label,
          all: item?.all,
          subArea: map(item?.subAreas, (subArea) => {
            return {
              id: subArea?.id,
              label: subArea?.label
            };
          })
        })
      );
      forEach(data?.pricingModifier?.regions, (item) =>
        conditionObject?.regions?.push({
          id: item?.id,
          label: item?.name,
          all: item?.all,
          zipCodes: map(item?.zipCodes, (zipCode) => zipCode)
        })
      );
      forEach(data?.pricingModifier?.manufacturers, (item) =>
        conditionObject?.manufacturers?.push({
          id: item?.id,
          label: item?.name
        })
      );
      setConditionData(conditionObject);
      setPricingValueType(data?.pricingModifier?.pricingValueType);
      setPricingValue(data?.pricingModifier?.pricingValue?.toString());
      setChangeAddItemAs(data?.pricingModifier?.addItemAs);
      setStatus(data?.pricingModifier?.status);
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    if (
      !conditionData?.brands?.length &&
      !conditionData?.manufacturers?.length &&
      !conditionData?.regions?.length &&
      !conditionData?.lineOfBusiness?.length
    ) {
      setConditionValidation(true);
      return;
    }
    setSubmitLoading(true);
    const conditions = {};
    if (conditionData?.brands?.length > 0) {
      set(
        conditions,
        'brands',
        conditionData?.brands?.map((item) => item?.id)
      );
    }
    if (conditionData?.regions?.length > 0) {
      set(
        conditions,
        'regions',
        conditionData?.regions?.map((item) => {
          return {
            id: item?.id || null,
            all: item?.all || false,
            zipCodes: item?.all ? [] : item?.zipCodes
          };
        })
      );
    }
    if (conditionData?.lineOfBusiness?.length > 0) {
      set(
        conditions,
        'lineOfBusiness',
        conditionData?.lineOfBusiness?.map((item) => {
          return {
            id: item?.id,
            subArea: item?.subArea?.map((subArea) => subArea?.id)
          };
        })
      );
    }
    if (conditionData?.manufacturers?.length > 0) {
      set(
        conditions,
        'manufacturers',
        conditionData?.manufacturers?.map((item) => item?.id)
      );
    }
    if (!isEdit) {
      const newFormValues = {
        ...formValues,
        pricingValue: Number(formValues?.pricingValue),
        conditions
      };
      const variables = newFormValues;
      try {
        const response = await createPricingModifier({
          variables: { data: { ...variables } }
        });
        if (response?.data?.createPricingModifier) {
          history?.push(ROUTES?.PRICING_MODIFIERS);
        }
      } catch (error) {
        setSubmitLoading(false);
        return error;
      }
    } else {
      const newFormValues = {
        ...formValues,
        pricingValue: Number(formValues?.pricingValue),
        conditions
      };
      const variables = {
        data: newFormValues,
        where: { id: data?.pricingModifier?.id }
      };
      try {
        const response = await updatePricingModifier({
          variables: { ...variables }
        });
        if (response?.data?.updatePricingModifier) {
          setSubmitLoading(false);
          history?.push(ROUTES?.PRICING_MODIFIERS);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const onFinishFailed = () => {
    setCollapseActiveKeys(['1', '2', '3', '4']);
    if (
      !conditionData.brands?.length &&
      !conditionData.manufacturers?.length &&
      !conditionData.regions?.length &&
      !conditionData.lineOfBusiness?.length
    ) {
      setConditionValidation(true);
    }
    setValidationTriggered(true);
  };

  const [pricingModifierStatuses] = useLazyQuery(PRICING_MODIFIER_STATUS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const statusOptionsCopy = [];
      forEach(res?.pricingModifierStatuses?.data, (item) =>
        statusOptionsCopy?.push({ value: item?.key, label: item?.label })
      );
      setStatusOptions(statusOptionsCopy);
    },
    onError() {}
  });

  useEffect(() => {
    pricingModifierStatuses({
      variables: {
        filter: {
          sortOn: 'key',
          sortBy: 'ASC'
        },
        where: {
          isActive: true
        }
      }
    });
    pricingModifierConditions({
      variables: {
        filter: attributeFilter,
        where: { isActive: true }
      }
    });
    if (isEdit) {
      pricingModifier({ variables: { id } });
    } else {
      initialValues = {
        pricingOperation: 'ADD',
        pricingValueType: '$',
        modifierOn: 'BASE',
        addItemAs: 'MAIN',
        billingAs: 'PRODUCT',
        status: 'PM_ACTIVE'
      };
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteBrands = () => {
    setConditionData({ ...conditionData, brands: [] });
  };
  const handleDeleteManufacturers = () => {
    setConditionData({ ...conditionData, manufacturers: [] });
  };
  const handleDeleteRegions = () => {
    setConditionData({ ...conditionData, regions: [] });
  };
  const handleDeleteLOB = () => {
    setConditionData({ ...conditionData, lineOfBusiness: [] });
  };

  const handleDeleteTagBrands = (brandIndex) => {
    const brandsCopy = [...conditionData?.brands];
    brandsCopy?.splice(brandIndex, 1);
    setConditionData({ ...conditionData, brands: brandsCopy });
  };

  const handleDeleteTagManufacturers = (brandIndex) => {
    const manufacturersCopy = [...conditionData?.manufacturers];
    manufacturersCopy.splice(brandIndex, 1);
    setConditionData({ ...conditionData, manufacturers: manufacturersCopy });
  };

  const handleDeleteTag = (regionIndex, zipIndex) => {
    const regionCopy = [...conditionData?.regions];
    if (zipIndex) {
      regionCopy?.[regionIndex]?.zipCodes?.splice(zipIndex, 1);
      if (!regionCopy?.[regionIndex]?.zipCodes?.length) {
        regionCopy?.splice(regionIndex, 1);
      }
    } else {
      regionCopy?.splice(regionIndex, 1);
    }
    setConditionData({ ...conditionData, regions: regionCopy });
  };

  const handleDeleteTagLob = (lobIndex, subAreaIndex) => {
    const lobCopy = [...conditionData?.lineOfBusiness];
    if (subAreaIndex) {
      lobCopy?.[lobIndex]?.subArea.splice(subAreaIndex, 1);
      if (!lobCopy?.[lobIndex]?.subArea?.length) {
        lobCopy?.splice(lobIndex, 1);
      }
    } else {
      lobCopy?.splice(lobIndex, 1);
    }
    setConditionData({ ...conditionData, lineOfBusiness: lobCopy });
  };

  const handleOpenModal = (visible = false, record = null, event) => {
    if (event) {
      event?.stopPropagation();
    }
    setShowModal(visible);
    setSelectedCondition(record);
  };

  const handlePricingValueChange = (value) => {
    if (value) {
      setPricingValue(value?.toString());
    } else {
      setPricingValue('');
    }
  };

  const handleChangePricingValueType = (e) => {
    setPricingValueType(e?.target?.value);
    if (Number(form?.getFieldValue('pricingValue')) > 100) {
      setPricingValue('100');
      form?.setFieldsValue({ pricingValue: '100' });
    }
  };

  const handleChangeAddItemAs = (e) => {
    setChangeAddItemAs(e?.target?.value);
  };

  const handleChangeStatus = (e) => {
    setStatus(e?.target?.value);
  };

  const handleChangeStartDate = (value) => {
    if (!value) {
      setStartDate(null);
      return;
    }
    setStartDate(moment(value)?.startOf('day'));
  };

  const handleChangeEndDate = (value) => {
    if (!value) {
      setEndDate(null);
      return;
    }
    setEndDate(moment(value)?.endOf('day'));
  };

  if (loading) {
    return <LoaderComponent spinning={loading} />;
  }

  return (
    <div>
      {showModal && (
        <PricingConditionModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedCondition={selectedCondition}
          setConditionDataCopy={setConditionData}
          conditionDataCopy={conditionData}
          setCollapseActiveKeys={setCollapseActiveKeys}
          collapseActiveKeys={collapseActiveKeys}
          attributeLoading={attributeLoading}
          setAttributeLoading={setAttributeLoading}
          attributesData={attributesData}
        />
      )}
      {isEdit && (
        <Portal portalId="header-left-content">
          <div className="d-flex align-center">
            <GoBackButton customLink={ROUTES?.PRICING_MODIFIERS} />
            {data?.pricingModifier?.title && (
              <span className="portal-header">
                {data?.pricingModifier?.title}
              </span>
            )}
          </div>
        </Portal>
      )}
      <AccessControl
        allowedPermissions={
          isEdit
            ? ['FET_PRICING_MODIFIER_UPDATE']
            : ['FET_PRICING_MODIFIER_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="pricingModifier-table-discard-btn"
            onClick={() => history?.push(ROUTES?.PRICING_MODIFIERS)}
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="pricingmodifier-table-save-btn"
            loading={submitLoading}
            type="primary"
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit
                ? ['FET_PRICING_MODIFIER_UPDATE']
                : ['FET_PRICING_MODIFIER_CREATE']
            )
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="Basic Information *" key="1">
              <>
                <Row gutter={16}>
                  <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                    <Form.Item
                      rules={[{ ...required, message: 'Please Enter Title' }]}
                      name="title"
                      label="Title"
                    >
                      <InputComponent allowClear placeholder="Enter Title" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item name="description" label="Description">
                      <TextArea
                        className="common-textarea"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        placeholder="Description is optional..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            </Panel>
            <Panel
              forceRender
              header="Conditions *"
              key="2"
              extra={
                (conditionData?.brands?.length > 0 ||
                  conditionData?.manufacturers?.length > 0 ||
                  conditionData?.regions?.length > 0 ||
                  conditionData?.lineOfBusiness?.length > 0) && (
                  <Button
                    className="common-button"
                    onClick={(e) => handleOpenModal(true, null, e)}
                    icon={<PlusOutlined />}
                    size="small"
                    type="primary"
                  >
                    Add Condition
                  </Button>
                )
              }
            >
              {!conditionData?.brands?.length &&
              !conditionData?.manufacturers?.length &&
              !conditionData?.regions?.length &&
              !conditionData?.lineOfBusiness?.length ? (
                <Empty
                  image={Empty?.PRESENTED_IMAGE_SIMPLE}
                  description="Add at least one Condition."
                >
                  <Button
                    className="common-button"
                    onClick={() => handleOpenModal(true)}
                    icon={<PlusOutlined />}
                    size="small"
                    type="primary"
                  >
                    Add Condition
                  </Button>
                </Empty>
              ) : (
                <>
                  {conditionData?.brands?.length > 0 && (
                    <List
                      className="pricing-list"
                      bordered
                      dataSource={[conditionData]}
                      renderItem={(item) => {
                        if (item?.brands?.length > 0) {
                          return (
                            <div className="single-row" key={item?.id}>
                              <div className="pricing-head-section">
                                <span className="pricing-title">Retailers</span>
                              </div>

                              <div className="pricing-middle-section">
                                {map(item?.brands, (singleData, tagIndex) => (
                                  <Tag
                                    className="single-item"
                                    title={singleData?.label}
                                    key={singleData?.id}
                                  >
                                    <span className="content-text">
                                      {singleData?.label}
                                      <CloseOutlined
                                        className="close-img"
                                        onClick={() =>
                                          handleDeleteTagBrands(tagIndex)
                                        }
                                      />
                                    </span>
                                  </Tag>
                                ))}
                                <Tag
                                  className="tag-plus"
                                  onClick={() =>
                                    handleOpenModal(true, 'PMC_BRAND')
                                  }
                                >
                                  <span className="content-text">
                                    <PlusOutlined /> Add Retailers
                                  </span>
                                </Tag>
                              </div>
                              <div className="pricing-end-section">
                                <Popconfirm
                                  title="Are you sure to delete?"
                                  onConfirm={() => handleDeleteBrands(item)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="delete-icon"
                                    className="pricing-delete"
                                  />
                                </Popconfirm>
                              </div>
                            </div>
                          );
                        }
                      }}
                    />
                  )}
                  {conditionData?.manufacturers?.length > 0 && (
                    <List
                      className="pricing-list"
                      bordered
                      dataSource={[conditionData]}
                      renderItem={(item) => {
                        if (item?.manufacturers?.length > 0) {
                          return (
                            <div className="single-row" key={item?.id}>
                              <div className="pricing-head-section">
                                <span className="pricing-title">
                                  Manufacturers
                                </span>
                              </div>

                              <div className="pricing-middle-section">
                                {map(
                                  item?.manufacturers,
                                  (singleData, tagIndex) => (
                                    <Tag
                                      className="single-item"
                                      title={singleData?.label}
                                      key={singleData?.id}
                                    >
                                      <span className="content-text">
                                        {singleData?.label}
                                        <CloseOutlined
                                          className="close-img"
                                          onClick={() =>
                                            handleDeleteTagManufacturers(
                                              tagIndex
                                            )
                                          }
                                        />
                                      </span>
                                    </Tag>
                                  )
                                )}
                                <Tag
                                  className="tag-plus"
                                  onClick={() =>
                                    handleOpenModal(true, 'PMC_MANUFACTURER')
                                  }
                                >
                                  <span className="content-text">
                                    <PlusOutlined /> Add Manufacturers
                                  </span>
                                </Tag>
                              </div>
                              <div className="pricing-end-section">
                                <Popconfirm
                                  title="Are you sure to delete?"
                                  onConfirm={() =>
                                    handleDeleteManufacturers(item)
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="delete-icon"
                                    className="pricing-delete"
                                  />
                                </Popconfirm>
                              </div>
                            </div>
                          );
                        }
                      }}
                    />
                  )}
                  {conditionData?.regions?.length > 0 && (
                    <List
                      className="pricing-list"
                      bordered
                      dataSource={[conditionData]}
                      renderItem={(item) => {
                        if (item?.regions?.length > 0) {
                          return (
                            <div className="single-row" key={item?.id}>
                              <div className="pricing-head-section">
                                <span className="pricing-title">Regions</span>
                              </div>

                              <div className="pricing-middle-section">
                                {map(
                                  conditionData?.regions,
                                  (singleData, tagIndex) => {
                                    if (singleData?.all) {
                                      return (
                                        <Tag
                                          className="single-item region-tags"
                                          title={singleData?.label}
                                          key={singleData?.id}
                                        >
                                          <span className="content-text">
                                            <span className="region-only">
                                              {singleData?.label}
                                            </span>
                                            <CloseOutlined
                                              className="close-img"
                                              onClick={() =>
                                                handleDeleteTag(tagIndex)
                                              }
                                            />
                                          </span>
                                        </Tag>
                                      );
                                    }
                                    return map(
                                      singleData?.zipCodes,
                                      (singleZip, singleIndex) => {
                                        return (
                                          <Tag
                                            className="single-item region-tags"
                                            title={`${
                                              singleData?.label
                                                ? `${singleData?.label} - `
                                                : ''
                                            }${singleZip}`}
                                            key={`${singleZip}${tagIndex}`}
                                          >
                                            <span className="content-text">
                                              <span>{`${
                                                singleData?.label
                                                  ? `${singleData?.label} - `
                                                  : ''
                                              }${singleZip}`}</span>
                                              <img
                                                onClick={() =>
                                                  handleDeleteTag(
                                                    tagIndex,
                                                    singleIndex
                                                  )
                                                }
                                                className="close-img"
                                                alt="close-img"
                                                src={closeImg}
                                              />
                                            </span>
                                          </Tag>
                                        );
                                      }
                                    );
                                  }
                                )}
                                <Tag
                                  className="tag-plus"
                                  onClick={() =>
                                    handleOpenModal(true, 'PMC_REGION')
                                  }
                                >
                                  <span className="content-text">
                                    <PlusOutlined /> Add Regions
                                  </span>
                                </Tag>
                              </div>
                              <div className="pricing-end-section">
                                <Popconfirm
                                  title="Are you sure to delete?"
                                  onConfirm={() => handleDeleteRegions(item)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="delete-icon"
                                    className="pricing-delete"
                                  />
                                </Popconfirm>
                              </div>
                            </div>
                          );
                        }
                      }}
                    />
                  )}
                  {conditionData?.lineOfBusiness?.length > 0 && (
                    <List
                      className="pricing-list"
                      bordered
                      dataSource={[conditionData]}
                      renderItem={(item) => {
                        if (item?.lineOfBusiness?.length > 0) {
                          return (
                            <div className="single-row" key={item?.id}>
                              <div className="pricing-head-section">
                                <span className="pricing-title">
                                  Line Of Business
                                </span>
                              </div>

                              <div className="pricing-middle-section">
                                {map(
                                  conditionData?.lineOfBusiness,
                                  (singleData, tagIndex) => {
                                    if (singleData?.all) {
                                      return (
                                        <Tag
                                          className="single-item region-tags"
                                          title={singleData?.label}
                                          key={singleData?.id}
                                        >
                                          <span className="content-text">
                                            <span className="region-only">
                                              {singleData?.label}
                                            </span>
                                            <CloseOutlined
                                              className="close-img"
                                              onClick={() =>
                                                handleDeleteTagLob(tagIndex)
                                              }
                                            />
                                          </span>
                                        </Tag>
                                      );
                                    }
                                    return map(
                                      singleData?.subArea,
                                      (singleLob, singleIndex) => {
                                        return (
                                          <Tag
                                            className="single-item region-tags"
                                            title={`${
                                              singleData?.label
                                                ? `${singleData?.label} - `
                                                : ''
                                            }${singleLob?.label}`}
                                            key={`${singleLob}${tagIndex}`}
                                          >
                                            <span className="content-text">
                                              <span>{`${
                                                singleData?.label
                                                  ? `${singleData?.label} - `
                                                  : ''
                                              }${singleLob?.label}`}</span>
                                              <img
                                                onClick={() =>
                                                  handleDeleteTagLob(
                                                    tagIndex,
                                                    singleIndex
                                                  )
                                                }
                                                className="close-img"
                                                alt="close-img"
                                                src={closeImg}
                                              />
                                            </span>
                                          </Tag>
                                        );
                                      }
                                    );
                                  }
                                )}
                                <Tag
                                  className="tag-plus"
                                  onClick={() =>
                                    handleOpenModal(true, 'PMC_LOB')
                                  }
                                >
                                  <span className="content-text">
                                    <PlusOutlined /> Add Line of Business
                                  </span>
                                </Tag>
                              </div>
                              <div className="pricing-end-section">
                                <Popconfirm
                                  title="Are you sure to delete?"
                                  onConfirm={() => handleDeleteLOB(item)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="delete-icon"
                                    className="pricing-delete"
                                  />
                                </Popconfirm>
                              </div>
                            </div>
                          );
                        }
                      }}
                    />
                  )}
                </>
              )}
              {conditionValidation && (
                <span className="error-text">
                  Please Add at least one Condition
                </span>
              )}
            </Panel>
            <Panel forceRender header="Price *" key="3">
              <div className="radio-description">
                Select type of price change
              </div>
              <Form.Item name="pricingOperation" noStyle>
                <Radio.Group
                  className="common-radio"
                  options={pricingOperationOptions}
                />
              </Form.Item>
              <Row gutter={16} className="top-margin">
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <div className="radio-description input-margin">
                    Enter price change amount
                  </div>
                  <Form.Item
                    name="pricingValue"
                    rules={[
                      {
                        ...required,
                        message: `Please Enter ${
                          pricingValueType === '%' ? 'Percentage' : 'Amount'
                        }`
                      }
                    ]}
                    normalize={(value, prevValue) => {
                      if (pricingValueType === '%') {
                        if (
                          (value?.length > 0 && Number(value) < 0) ||
                          Number(value) > 100
                        ) {
                          return prevValue?.toString();
                        }
                        return value?.toString();
                      }
                      if (value) {
                        return value?.toString();
                      }
                      return '';
                    }}
                  >
                    <PriceComponent
                      placeholder="Enter Price"
                      onChange={handlePricingValueChange}
                      isPrice
                      prefix={pricingValueType === '$' ? '$' : ''}
                      suffix={pricingValueType === '%' ? '%' : ''}
                      isAllowed={(inputObj) => {
                        const { value } = inputObj;
                        if (pricingValueType === '%') {
                          if (Number(value) < 0 || Number(value) > 100) {
                            return false;
                          }
                          return true;
                        }
                        return true;
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={18} xxl={18}>
                  <div className="radio-description">
                    Select dollar or percent price change
                  </div>
                  <Form.Item name="pricingValueType" noStyle>
                    <Radio.Group
                      onChange={handleChangePricingValueType}
                      className="common-radio"
                      options={pricingValueTypeOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {pricingValue?.length > 0 && (
                <>
                  <div className="top-margin radio-description">
                    Apply price change only to the base package or base package
                    and add-ons
                  </div>
                  <Form.Item name="modifierOn" noStyle>
                    <Radio.Group
                      className="common-radio"
                      options={modifierOnOptions}
                    />
                  </Form.Item>
                  <div className="top-margin radio-description">
                    Apply price change to Base Package or list Add-Ons on the
                    separate line item
                  </div>
                  <Form.Item name="addItemAs" noStyle>
                    <Radio.Group
                      onChange={handleChangeAddItemAs}
                      className="common-radio radio-description"
                      options={addItemAsOptions}
                    />
                  </Form.Item>
                  {changeAddItemAs === 'OTHER' && (
                    <Row gutter={16}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                        <Form.Item
                          rules={[
                            {
                              ...required,
                              message: 'Please Enter Line Item Name'
                            }
                          ]}
                          name="lineItemName"
                          label="Line Item Name"
                        >
                          <InputComponent
                            allowClear
                            placeholder="Enter Line Item Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={18}
                        xxl={18}
                        className="d-flex align-center"
                      >
                        <Form.Item name="billingAs" noStyle>
                          <Radio.Group
                            className="common-radio"
                            options={billingAsOptions}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Panel>
            <Panel forceRender header="Status *" key="4">
              {statusOptions && (
                <>
                  <div className="radio-description">
                    Would you like to mark this Pricing Modifier as Active or
                    schedule it for a time period?
                  </div>
                  <Form.Item name="status" noStyle>
                    <Radio.Group
                      className="common-radio radio-description"
                      onChange={handleChangeStatus}
                      options={statusOptions}
                    />
                  </Form.Item>
                </>
              )}
              {status === 'PM_SCHEDULE' && (
                <Row gutter={16}>
                  <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                    <Form.Item
                      name="startDate"
                      label="Start Date"
                      normalize={(value) => {
                        if (value) {
                          return moment(value)?.startOf('day');
                        }
                      }}
                      rules={[
                        {
                          ...startDate(endDateForm)
                        }
                      ]}
                    >
                      <DatePickerComponent
                        disabledDate
                        onChange={handleChangeStartDate}
                        placeholder="Start Date"
                        format={dateFormatWithoutTime(globalDateFormat)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                    <Form.Item
                      name="endDate"
                      label="Expire Date"
                      normalize={(value) => {
                        if (value) {
                          return moment(value)?.endOf('day');
                        }
                        return null;
                      }}
                      rules={[
                        {
                          ...endDate(startDateForm)
                        }
                      ]}
                    >
                      <DatePickerComponent
                        disabledDate
                        onChange={handleChangeEndDate}
                        placeholder="Expire Date"
                        format={dateFormatWithoutTime(globalDateFormat)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};

export default withRouter(PricingModifierForm);
