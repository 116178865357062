import React from 'react';
import './previewTagComponent.less';

const PreviewTagComponent = () => {
  return (
    <div className="preview-tag">
      <span>Example</span>
    </div>
  );
};

export default PreviewTagComponent;
