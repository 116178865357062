import {
  FileExcelOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileWordOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { message, Upload } from 'antd';
import { filter, forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import api from '../common/api';
import { ALLOW_FILE_TYPES, ROUTES, WRITE } from '../common/constants';
import {
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
  GET_SIGN_URL
} from './graphql/Mutation';
import RouterPrompt from './RouterPrompt';
import './styles/DocumentComponent.less';

let tempFileArray = [];

const DocumentComponent = (props) => {
  const {
    uuid = null,
    recordId = null,
    tenantId = null,
    module = 'product',
    moduleKey = 'PRODUCT',
    fileListProps = [],
    refetch,
    isEdit = true
  } = props;

  const [fileList, setFileList] = useState([]);
  const [newFileList, setNewFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (fileListProps) {
      setFileList(fileListProps);
      tempFileArray = [];
    }
  }, [fileListProps]);

  const [createAttachment] = useMutation(CREATE_ATTACHMENT, {
    onError: () => {}
  });

  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
    onError: () => {}
  });

  const [uploadFile] = useMutation(GET_SIGN_URL, {
    onError: () => {}
  });

  const handleRemove = async (file) => {
    const response = await deleteAttachment({
      variables: {
        where: {
          id: file?.id
        }
      }
    });
    if (response) {
      setFileList(filter(fileList, (item) => item?.id !== file?.id));
    }
  };

  const uploadToGoogleStorage = (uploadProps) =>
    new Promise((res, rej) => {
      const { onSuccess, onError, file, onProgress } = uploadProps;
      const { name, type } = file;
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      if (ALLOW_FILE_TYPES.includes(ext)) {
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const timestamp = Date?.now();
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `organization/${tenantId}/${module}/${uuid}${ROUTES?.DOCUMENTS}/${newFilename}`;
        uploadFile({
          variables: {
            action: WRITE,
            extension: `.${ext}`,
            contentType: type,
            key: fileKey
          }
        }).then((uploadFileData) => {
          if (uploadFileData?.errors) {
            setFileList(filter(fileList, (item) => item?.uid !== file?.uid));
          }
          if (uploadFileData?.data?.generateSignedUrl) {
            const {
              signedRequest,
              url: URL
            } = uploadFileData?.data?.generateSignedUrl;
            // above url is send to the backend
            try {
              api(signedRequest, {
                method: 'PUT',
                data: file,
                headers: {
                  'Content-Type': ext === 'csv' ? 'text/csv' : file?.type
                },
                onUploadProgress: (event) => {
                  onProgress({ percent: (event?.loaded / event?.total) * 100 });
                }
              })
                .then((response) => {
                  if (response) {
                    // For sending url to backend
                    createAttachment({
                      variables: {
                        data: {
                          key: fileKey,
                          label: filename,
                          type: 'DOCUMENT',
                          isActive: true,
                          url: URL,
                          referenceId: recordId,
                          referenceKey: moduleKey
                        }
                      }
                    })
                      .then((finalResponse) => {
                        if (finalResponse) {
                          onSuccess(null, file);
                          res({
                            id: finalResponse?.data?.createAttachment?.data?.id,
                            url:
                              finalResponse?.data?.createAttachment?.data?.url
                          });
                        }
                      })
                      .catch((error) => {
                        setUploading(false);
                        setFileList(
                          filter(fileList, (item) => item?.uid !== file?.uid)
                        );
                        onError(error?.responseText, error?.response, file);
                      });
                  }
                })
                .catch((error) => {
                  setUploading(false);
                  setFileList(
                    filter(fileList, (item) => item?.uid !== file?.uid)
                  );
                  onError(error?.responseText, error?.response, file);
                });
            } catch (error) {
              setUploading(false);
              setFileList(filter(fileList, (item) => item?.uid !== file?.uid));
              onError(error?.responseText, error?.response, file);
              rej(error);
            }
          }
        });
      } else {
        message?.destroy();
        message?.error(
          `Please upload file with .pdf,.ppt,.pptx,.doc,.docx,.xls,.csv extensions`
        );
      }
    });

  const handleUpload = async (uploadProps) => {
    setUploading(true);
    const data = await uploadToGoogleStorage(uploadProps);
    tempFileArray?.push(data);
    if (tempFileArray?.length === newFileList?.length) {
      refetch();
      setUploading(false);
    }
  };

  const handleChange = ({ file, fileList: files }) => {
    if (file.status === 'removed') {
      return;
    }
    const docFiles = [];
    const newFiles = [];
    forEach(files, (item) => {
      const ext = item?.name?.substring(item?.name?.lastIndexOf('.') + 1);
      if (ALLOW_FILE_TYPES.includes(ext) || item?.url) {
        docFiles?.push(item);
      }
      if (ALLOW_FILE_TYPES.includes(ext) && !item?.url) {
        newFiles?.push(item);
      }
    });

    setFileList(docFiles);
    setNewFileList(newFiles);
  };

  const customOrigin = (originNode, file) => {
    const {
      props: nodeProps,
      props: { children }
    } = originNode;
    const ext = file?.key?.substring(file?.key?.lastIndexOf('.') + 1);
    const newChildren = [children?.[1], children?.[2]];
    let icon = '';
    switch (ext) {
      case 'xlsx':
      case 'xls':
      case 'csv':
        icon = <FileExcelOutlined className="file-type" />;
        break;
      case 'pdf':
        icon = <FilePdfOutlined className="file-type" />;
        break;
      case 'ppt':
      case 'pptx':
        icon = <FilePptOutlined className="file-type" />;
        break;
      case 'doc':
      case 'docx':
        icon = <FileWordOutlined className="file-type" />;
        break;
      default:
        break;
    }

    return (
      <div className={nodeProps.className}>
        <div className="ant-upload-list-item-info ">{icon}</div>
        {newChildren}
      </div>
    );
  };

  return (
    <div className="document-component">
      <RouterPrompt when={uploading} />
      <Upload
        multiple
        listType="picture-card"
        itemRender={(originNode, file) => (
          <div>
            {customOrigin(originNode, file)}
            <div title={file?.label} className="document-label">
              {file?.name?.split('.')?.slice(0, -1)?.join('.') || file?.label}
            </div>
          </div>
        )}
        fileList={fileList}
        showUploadList={{
          showPreviewIcon: true,
          showDownloadIcon: false,
          showRemoveIcon: isEdit
        }}
        customRequest={handleUpload}
        onChange={handleChange}
        onRemove={handleRemove}
      >
        {isEdit && (
          <div className="upload-content">
            <span className="icon-header">
              <PlusOutlined />
            </span>
            <span className="upload-title">Upload</span>
            <span className="upload-description">
              ...one or multiple documents
            </span>
          </div>
        )}
      </Upload>
    </div>
  );
};

export default DocumentComponent;
