import {
  ArrowDownOutlined,
  CreditCardOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Popover,
  Row
} from 'antd';
import locale from 'antd/es/locale/en_US';
import { map } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import MarkerIcon from '../../../../../../assets/marker-icon.svg';
import { SAME_DAY_TIME } from '../../../../../../common/constants';
import CommonProductDetail from '../../globalComponents/CommonProductDetail';
import InputComponent from '../../globalComponents/InputComponent';
import NumberComponent from '../../globalComponents/NumberComponent';
import SelectComponent from '../../globalComponents/SelectComponent';
import PreviewTagComponent from '../../previewTagComponent';
import ContactSection from '../../quotes/components/ContactSection';
import '../scheduled-appointment.less';

const { Option } = SelectComponent;

const firstOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
};

const ScheduledAppointmentComponent = ({
  scheduledConfig = null,
  logoUrl = null
}) => {
  const [form] = Form?.useForm();
  moment?.updateLocale('en', {
    week: {
      dow: firstOfWeek[scheduledConfig?.firstOfWeek] /// Date offset
    }
  });

  const [selectedDate] = useState(moment());
  const [cities] = useState([]);
  const [states] = useState([]);

  const disabledDateFun = (current) => {
    return current < moment()?.startOf('day');
  };

  const getClassName = (value) => {
    let className;
    map(scheduledConfig?.holidayList, (item) => {
      if (item?.date === moment(value)?.format('yyyy-MM-DD')) {
        className = 'single-date holiday';
      }
      if (
        moment(selectedDate)?.format('yyyy-MM-DD') ===
        moment(value)?.format('yyyy-MM-DD')
      ) {
        className = 'single-date selected-date';
      }
    });
    return className || 'single-date';
  };

  const dateFullCellRender = (value) => {
    const className = getClassName(value);
    return <span className={className}>{value.date()}</span>;
  };

  return (
    <div className="scheduled-appointment-page">
      <PreviewTagComponent />
      <div className="logo-section">
        <img alt="logo" src={logoUrl} />
      </div>
      <div className="title-section">
        <span className="bold-label">{scheduledConfig?.title}</span>
      </div>
      <CommonProductDetail />
      {scheduledConfig?.countdown && (
        <div className="countdown-section">
          <span className="countdown-text">
            <span className="timer">00:13:37</span>
          </span>
          <span className="label-description">{SAME_DAY_TIME}</span>
        </div>
      )}
      <Form form={form} layout="vertical" autoComplete="off">
        <div className="location-section">
          <span className="location-title">
            {scheduledConfig?.locationTitle}
          </span>
          <Form.Item name="street">
            <InputComponent
              readOnly
              allowClear
              placeholder="Street *"
              suffix={
                <Popover
                  placement="topRight"
                  overlayClassName="overlay-text"
                  content={<span>Your current location will be changed.</span>}
                >
                  <Button
                    className="marker-btn"
                    type="primary"
                    icon={
                      <img
                        src={MarkerIcon}
                        alt="import-icon"
                        width={11}
                        height={13}
                      />
                    }
                  />
                </Popover>
              }
            />
          </Form.Item>
          <Form.Item name="state" initialValue="Irvine">
            <SelectComponent placeholder="State*" disabled>
              {map(states, (state) => (
                <Option key={state?.id} value={state?.name}>
                  {state?.name}
                </Option>
              ))}
            </SelectComponent>
          </Form.Item>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item name="city" initialValue="CA">
                <SelectComponent placeholder="City*" disabled>
                  {cities?.map((city) => (
                    <Option key={city?.id} value={city?.name}>
                      {city?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="zipCode">
                <NumberComponent
                  readOnly
                  allowClear
                  placeholder="Postal Code*"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="time-section">
          <span className="time-title">
            {scheduledConfig?.appointmentTimeTitle}
          </span>
          <div id="expanded-date-picker">
            <ConfigProvider locale={locale}>
              <DatePicker
                inputReadOnly
                value={selectedDate}
                dateRender={dateFullCellRender}
                dropdownClassName="calendar-picker"
                open
                disabledDate={disabledDateFun}
                getPopupContainer={(trigger) => trigger?.parentNode}
              />
            </ConfigProvider>
          </div>
          {/* section hide #DRF-5425 */}
          {/* {scheduledConfig?.timeOfDayChecked && (
            <Radio.Group
              className="time-slots"
              value={scheduledConfig?.timesArray?.[0]}
            >
              {map(scheduledConfig?.timesArray, (item) => {
                if (item?.length > 0) {
                  return (
                    <Radio.Button key={item} value={item}>
                      {`${moment(item?.[0])?.format('LT')} -
                            ${moment(item?.[1])?.format('LT')}`}
                    </Radio.Button>
                  );
                }
              })}
            </Radio.Group>
          )} */}
          <ArrowDownOutlined className="down-arrow" />
        </div>

        <div className="appointment-section">
          <span className="appointment-title">
            {scheduledConfig?.appointmentResTitle}
          </span>
          {scheduledConfig?.creditCardEnabled && (
            <div className="info-card appointment-card">
              <CreditCardOutlined />
              <span className="info-title appointment-description">
                {scheduledConfig?.creditCardTitle}
              </span>
              <Button
                className="common-button-cpq half-width"
                htmlType="submit"
                id="next-btn"
                type="primary"
              >
                {scheduledConfig?.creditCardLabel}
              </Button>
            </div>
          )}
          <Divider className="divider" />
          {scheduledConfig?.phoneCallEnabled && (
            <div className="info-card appointment-card">
              <PhoneOutlined rotate={90} />
              <span className="info-title appointment-description">
                {scheduledConfig?.phoneCallTitle}
              </span>
              <Button
                className="common-button-cpq half-width"
                htmlType="submit"
                id="next-btn"
                type="primary"
              >
                {scheduledConfig?.phoneCallLabel}
              </Button>
            </div>
          )}
        </div>
      </Form>
      <ContactSection
        contactInfo={scheduledConfig?.contactInfo}
        emailMeButtonLabel={scheduledConfig?.emailMeButtonLabel}
        phoneNo={scheduledConfig?.contactData?.phoneNo}
        email={scheduledConfig?.contactData?.email}
        liveChat={scheduledConfig?.contactData?.liveChat}
        helpPage={scheduledConfig?.contactData?.helpPage}
      />
    </div>
  );
};

export default ScheduledAppointmentComponent;
