import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Layout, Menu } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { ROUTES } from '../../../common/constants';
import { cpqRetailUrl } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import history from '../../../historyData';
import { GET_BRANDS } from '../../brands/graphql/Queries';
import {
  CREATE_QUESTIONNAIRES,
  UPDATE_QUESTIONNAIRES
} from '../graphql/Mutations';
import {
  CPQ_LINE_OF_BUSINESS,
  GET_QUESTIONNAIRE,
  GET_TENANT
} from '../graphql/Queries';
import CutoffTimeForm from './CutoffTimeForm';
import GeneralForm from './GeneralForm';

const { Sider, Content, Footer } = Layout;

const SettingModal = ({
  showModal,
  setShowModal,
  refetchData,
  activeKeyProps = 'general',
  setActiveKeyProps,
  match: { params: { id } = {} } = {}
}) => {
  const [form] = Form?.useForm();
  const {
    state: { currentUser },
    dispatch
  } = useContext(AppContext);
  const defaultTime = moment('5:30 pm', 'h:mm a');
  const initialValues = {
    brandId: null,
    isDefault: false,
    mondayCheckbox: false,
    monday: defaultTime,
    tuesdayCheckbox: false,
    tuesday: defaultTime,
    wednesdayCheckbox: false,
    wednesday: defaultTime,
    thursdayCheckbox: false,
    thursday: defaultTime,
    fridayCheckbox: false,
    friday: defaultTime,
    saturdayCheckbox: false,
    saturday: defaultTime,
    sundayCheckbox: false,
    sunday: defaultTime,
    lowCost: 'Good',
    recommended: 'Better',
    highEnd: 'Best',
    useRetailerLogoAndColor: false,
    showPricingInEmailQuote: false
  };

  const [generalActivePanelKey, setGeneralActivePanelKey] = useState([
    'title_subdomain'
  ]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [initialValue, setInitialValue] = useState(null);
  const [brandsLoading, setBrandsLoading] = useState(true);
  const [tenantLoading, setTenantLoading] = useState(true);
  const [lineOfBusiness, setLineOfBusiness] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('general');

  useEffect(() => {
    setActiveKey(activeKeyProps);
  }, [activeKeyProps]);

  const onMenuSelect = (e) => {
    setActiveKey(e?.key);
    if (setActiveKeyProps) {
      setActiveKeyProps(e?.key);
    }
  };

  const [getQuestionnaire] = useLazyQuery(GET_QUESTIONNAIRE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      if (res?.questionnaire?.isScheduleEnabled) {
        setShowDatePicker(true);
      }
      const newStartDate = res?.questionnaire?.startDate
        ? moment(res?.questionnaire?.startDate, 'YYYY-MM-DD')
        : null;
      const newExpiryDate = res?.questionnaire?.endDate
        ? moment(res?.questionnaire?.endDate, 'YYYY-MM-DD')
        : null;
      const questionObj = {
        title: res?.questionnaire?.title,
        uuid: res?.questionnaire?.uuid
      };
      dispatch({ type: 'SET_CPQ_QUESTION_OBJECT', data: questionObj });
      const tempObj = {
        title: res?.questionnaire?.title,
        link: res?.questionnaire?.link?.split('/')?.[1],
        slug: res?.questionnaire?.slug,
        useRetailerLogoAndColor: res?.questionnaire?.useRetailerLogoAndColor,
        showPricingInEmailQuote: res?.questionnaire?.showPricingInEmailQuote,
        isDefault: res?.questionnaire?.default,
        isActive: res?.questionnaire?.isActive,
        brandId: res?.questionnaire?.brandId?.toString(),
        mondayCheckbox: res?.questionnaire?.cutOffTime?.monday?.checked,
        monday: moment(res?.questionnaire?.cutOffTime?.monday?.time, 'h:mm a'),
        tuesdayCheckbox: res?.questionnaire?.cutOffTime?.tuesday?.checked,
        tuesday: moment(
          res?.questionnaire?.cutOffTime?.tuesday?.time,
          'h:mm a'
        ),
        wednesdayCheckbox: res?.questionnaire?.cutOffTime?.wednesday?.checked,
        wednesday: moment(
          res?.questionnaire?.cutOffTime?.wednesday?.time,
          'h:mm a'
        ),
        thursdayCheckbox: res?.questionnaire?.cutOffTime?.thursday?.checked,
        thursday: moment(
          res?.questionnaire?.cutOffTime?.thursday?.time,
          'h:mm a'
        ),
        fridayCheckbox: res?.questionnaire?.cutOffTime?.friday?.checked,
        friday: moment(res?.questionnaire?.cutOffTime?.friday?.time, 'h:mm a'),
        saturdayCheckbox: res?.questionnaire?.cutOffTime?.saturday?.checked,
        saturday: moment(
          res?.questionnaire?.cutOffTime?.saturday?.time,
          'h:mm a'
        ),
        sundayCheckbox: res?.questionnaire?.cutOffTime?.sunday?.checked,
        sunday: moment(res?.questionnaire?.cutOffTime?.sunday?.time, 'h:mm a'),
        lowCost: res?.questionnaire?.quoteLabels?.lowCost,
        recommended: res?.questionnaire?.quoteLabels?.recommended,
        highEnd: res?.questionnaire?.quoteLabels?.highEnd,
        isScheduleEnabled: res?.questionnaire?.isScheduleEnabled,
        startDate: newStartDate,
        expiryDate: newExpiryDate,
        uuid: res?.questionnaire?.uuid
      };
      setInitialValue(tempObj);
      setLoading(false);
    }
  });

  const [brands, { data: brandsData }] = useLazyQuery(GET_BRANDS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const brandId = res?.brands?.data?.[0]?.id;
      if (!id) {
        const tempObj = {
          ...initialValue,
          brandId
        };
        setInitialValue(tempObj);
      }
      setBrandsLoading(false);
    },
    onError: () => {
      setBrandsLoading(false);
    }
  });

  const [fetchTenant, { data: tenantData }] = useLazyQuery(GET_TENANT, {
    fetchPolicy: 'cache-and-network',
    onCompleted() {
      setTenantLoading(false);
    },
    onError() {
      setTenantLoading(false);
    }
  });

  const [fetchCpqLineOfBusiness] = useLazyQuery(CPQ_LINE_OF_BUSINESS, {
    onCompleted: (res) => {
      setLineOfBusiness(res?.lineOfBusiness);
    },
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  useEffect(() => {
    fetchCpqLineOfBusiness({});
    fetchTenant({
      variables: {
        where: {
          id: currentUser?.tenantId
        }
      }
    });
    brands({
      variables: {
        filter: {
          sortOn: 'createdAt',
          sortBy: 'DESC',
          defaultBrand: true
        },
        where: { isActive: true }
      }
    });
    if (id) {
      setLoading(true);
      getQuestionnaire({
        variables: {
          id
        }
      });
    } else {
      setInitialValue(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [createQuestionnaire] = useMutation(CREATE_QUESTIONNAIRES, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const [updateQuestionnaire] = useMutation(UPDATE_QUESTIONNAIRES, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = () => {
    form?.submit();
  };

  const onCancel = () => {
    setShowModal(false);
    setActiveKey('general');
    if (setActiveKeyProps) {
      setActiveKeyProps('general');
    }
  };

  const onFinish = async (values) => {
    setSubmitLoading(true);
    const { id: lineOfBusinessId } = lineOfBusiness;
    let startDate;
    let endDate;
    const cutOfTimeObj = {
      monday: {
        checked: values?.mondayCheckbox,
        time: moment(values?.monday)?.format('h:mm a')
      },
      tuesday: {
        checked: values?.tuesdayCheckbox,
        time: moment(values?.tuesday)?.format('h:mm a')
      },
      wednesday: {
        checked: values?.wednesdayCheckbox,
        time: moment(values?.wednesday)?.format('h:mm a')
      },
      thursday: {
        checked: values?.thursdayCheckbox,
        time: moment(values?.thursday)?.format('h:mm a')
      },
      friday: {
        checked: values?.fridayCheckbox,
        time: moment(values?.friday)?.format('h:mm a')
      },
      saturday: {
        checked: values?.saturdayCheckbox,
        time: moment(values?.saturday)?.format('h:mm a')
      },
      sunday: {
        checked: values?.sundayCheckbox,
        time: moment(values?.sunday)?.format('h:mm a')
      }
    };

    const quoteLabels = {
      lowCost: values?.lowCost,
      recommended: values?.recommended,
      highEnd: values?.highEnd
    };
    const newLink = `${cpqRetailUrl(tenantData?.tenant?.subDomain)}/${
      values?.link
    }`;

    if (values?.startDate) {
      startDate = moment(values?.startDate)?.format('YYYY-MM-DD');
    }
    if (values?.expiryDate) {
      endDate = moment(values?.expiryDate)?.format('YYYY-MM-DD');
    }

    if (id) {
      const newValues = {
        quoteLabels,
        default: values?.isDefault,
        isScheduleEnabled: values?.isScheduleEnabled,
        cutOffTime: cutOfTimeObj,
        link: newLink,
        slug: initialValue?.slug,
        isActive: initialValue?.isActive,
        title: values?.title,
        brandId: values?.brandId,
        startDate,
        endDate,
        uuid: initialValue?.uuid,
        lineOfBusinessId,
        showPricingInEmailQuote: values?.showPricingInEmailQuote,
        useRetailerLogoAndColor: values?.useRetailerLogoAndColor
      };
      const response = await updateQuestionnaire({
        variables: {
          data: newValues,
          where: {
            id
          }
        }
      });
      if (response?.data?.updateQuestionnaire) {
        setShowModal(false);
        setActiveKey('general');
        if (setActiveKeyProps) {
          setActiveKeyProps('general');
        }
        setSubmitLoading(false);
        if (refetchData) {
          refetchData();
        }
      }
    } else {
      const uuid = uuidv4();
      const newValues = {
        quoteLabels,
        uuid,
        default: values?.isDefault,
        isScheduleEnabled: values?.isScheduleEnabled,
        cutOffTime: cutOfTimeObj,
        link: newLink,
        slug: values?.link,
        isActive: true,
        title: values?.title,
        brandId: values?.brandId,
        startDate,
        endDate,
        lineOfBusinessId: lineOfBusiness?.id,
        showPricingInEmailQuote: values?.showPricingInEmailQuote,
        useRetailerLogoAndColor: values?.useRetailerLogoAndColor
      };
      const response = await createQuestionnaire({
        variables: {
          data: newValues
        }
      });
      if (response?.data?.createQuestionnaire) {
        setSubmitLoading(false);
        history?.push(
          `${ROUTES?.QUESTIONNAIRES}/edit${ROUTES?.POSTAl_CODE}/${response?.data?.createQuestionnaire?.data?.id}`
        );
      }
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
    setGeneralActivePanelKey([
      'title_subdomain',
      'retailer',
      'quote_label',
      'expiration',
      'default_questionnaire'
    ]);
  };

  return (
    <ModalComponent
      width={1500}
      visible={showModal}
      onCancel={onCancel}
      footer={null}
      closable={false}
      destroyOnClose
      wrapClassName="setting-modal"
    >
      <Layout>
        <Sider className="setting-layout">
          <div className="sider-header">
            <span>Settings</span>
          </div>
          <Menu theme="light" selectedKeys={activeKey} onSelect={onMenuSelect}>
            <Menu.Item className="d-flex align-center" key="general">
              General
            </Menu.Item>
            <Menu.Item key="cut-off-time">Cut-off Time</Menu.Item>
          </Menu>
        </Sider>
        <Layout className="setting-modal-content">
          <Content>
            <div className="content-wrapper">
              {brandsLoading || tenantLoading || loading ? (
                <LoaderComponent setHeight={75} />
              ) : (
                <Form
                  form={form}
                  preserve={false}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  name="settingForm"
                  layout="vertical"
                  initialValues={initialValue}
                  validateTrigger={
                    validationTriggered ? 'onChange' : 'onSubmit'
                  }
                >
                  <div
                    className={activeKey === 'cut-off-time' ? 'hide-form' : ''}
                  >
                    <GeneralForm
                      brandsData={brandsData}
                      tenantData={tenantData}
                      lineOfBusinessData={lineOfBusiness}
                      setShowDatePicker={setShowDatePicker}
                      showDatePicker={showDatePicker}
                      generalActivePanelKey={generalActivePanelKey}
                      setGeneralActivePanelKey={setGeneralActivePanelKey}
                    />
                  </div>
                  <div className={activeKey === 'general' ? 'hide-form' : ''}>
                    <CutoffTimeForm />
                  </div>
                </Form>
              )}
            </div>
          </Content>
          <Footer>
            <Button
              className="setting-cancel-btn discard-button"
              size="small"
              icon={<DeleteIconComponent />}
              id="setting-cancel-btn"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className="setting-btn"
              size="small"
              icon={<img src={SaveIcon} alt="save-icon" width={12} />}
              htmlType="submit"
              id="setting-save-btn"
              loading={submitLoading}
              type="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Footer>
        </Layout>
      </Layout>
    </ModalComponent>
  );
};

export default withRouter(SettingModal);
