import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Portal from '../../../components/Portal';
import HeightTable from '../components/HeightTable';

const HeightList = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <span className="portal-header">Heights</span>
      </Portal>
      {pageSize && <HeightTable />}
    </Card>
  );
};

export default HeightList;
