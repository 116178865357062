import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import DocumentIconComponent from '../../../app/components/iconComponents/DocumentIconComponent';
import GalleryIconComponent from '../../../app/components/iconComponents/GalleryIconComponent';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import { MODULES, ROUTES } from '../../../common/constants';

const { TabPane } = Tabs;

const ProductTabs = ({ match: { params: { id } = {} } = {}, children }) => {
  const location = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState('INFORMATION');
  const infoTitle = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">Information</span>
    </div>
  );

  const infoTitleProductItems = (
    <div>
      <InfoIconComponent className="tab-icons" />
      <span className="card-title">{MODULES?.PRODUCTS}</span>
    </div>
  );

  const infoTitleGallery = (
    <div>
      <GalleryIconComponent className="tab-icons" />
      <span className="card-title">Gallery</span>
    </div>
  );

  const infoTitleDocuments = (
    <div>
      <DocumentIconComponent className="tab-icons" />
      <span className="card-title">Documents</span>
    </div>
  );

  useEffect(() => {
    if (
      location?.pathname?.includes('/edit') ||
      location?.pathname?.includes('/view') ||
      location?.pathname?.includes('/add')
    ) {
      setActiveKey('INFORMATION');
    }
    if (location?.pathname?.includes(ROUTES?.PRODUCTS)) {
      setActiveKey('PRODUCTS');
    }
    if (location?.pathname?.includes(ROUTES?.GALLERY)) {
      setActiveKey('GALLERY');
    }
    if (location?.pathname?.includes(ROUTES?.DOCUMENTS)) {
      setActiveKey('DOCUMENTS');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTab = (key) => {
    switch (key) {
      case 'INFORMATION':
        if (location?.pathname?.includes('/add')) {
          history?.push(`${ROUTES?.PRODUCTS}/add`);
        } else if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PACKAGES}/edit/${id}`);
        } else {
          history?.push(`${ROUTES?.PACKAGES}/view/${id}`);
        }
        break;

      case 'PRODUCTS':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PACKAGES}/edit${ROUTES?.PRODUCTS}/${id}`);
        } else {
          history?.push(`${ROUTES?.PACKAGES}/view${ROUTES?.PRODUCTS}/${id}`);
        }
        break;

      case 'GALLERY':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PACKAGES}/edit${ROUTES?.GALLERY}/${id}`);
        } else {
          history?.push(`${ROUTES?.PACKAGES}/view${ROUTES?.GALLERY}/${id}`);
        }
        break;

      case 'DOCUMENTS':
        if (location?.pathname?.includes('/edit')) {
          history?.push(`${ROUTES?.PACKAGES}/edit${ROUTES?.DOCUMENTS}/${id}`);
        } else {
          history?.push(`${ROUTES?.PACKAGES}/view${ROUTES?.DOCUMENTS}/${id}`);
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="card-container">
      <Tabs type="card" activeKey={activeKey} onChange={handleSelectTab}>
        <TabPane tab={infoTitle} key="INFORMATION">
          {children}
        </TabPane>
        {!location?.pathname?.includes('/add') && (
          <TabPane tab={infoTitleProductItems} key="PRODUCTS">
            {children}
          </TabPane>
        )}
        {!location?.pathname?.includes('/add') && (
          <TabPane tab={infoTitleGallery} key="GALLERY">
            {children}
          </TabPane>
        )}
        {!location?.pathname?.includes('/add') && (
          <TabPane tab={infoTitleDocuments} key="DOCUMENTS">
            {children}
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default withRouter(ProductTabs);
