import { useLazyQuery } from '@apollo/client';
import { Button, Card, Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import SettingsIcon from '../../../assets/settings.svg';
import TryBrowserIcon from '../../../assets/try-browser.svg';
import { ROUTES } from '../../../common/constants';
import { cpqRetailUrl } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import SettingModal from '../components/SettingModal';
import { GET_QUESTIONNAIRE } from '../graphql/Queries';

const CpqTemplate = ({ centerContent, rightSideContent }) => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const { dispatch } = useContext(AppContext);
  const [getQuestionnaire, { data }] = useLazyQuery(GET_QUESTIONNAIRE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      const questionObj = {
        title: res?.questionnaire?.title,
        uuid: res?.questionnaire?.uuid
      };
      dispatch({ type: 'SET_CPQ_QUESTION_OBJECT', data: questionObj });
    }
  });

  useEffect(() => {
    getQuestionnaire({
      variables: {
        id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTryBrowser = () => {
    // eslint-disable-next-line no-undef
    window
      .open(
        `https://${cpqRetailUrl(data?.questionnaire?.tenantSubDomain)}/${
          data?.questionnaire?.slug
        }`,
        '_blank'
      )
      ?.focus();
  };

  return (
    <Card className="full-height-card card-body-p-24 cpq-padding">
      {showModal && (
        <SettingModal
          isEdit
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.QUESTIONNAIRES} />
          <span className="portal-header">{data?.questionnaire?.title}</span>
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button try-button"
          icon={<img src={TryBrowserIcon} alt="try-browser-icon" width={12} />}
          size="small"
          type="primary"
          id="try-in-browser-btn"
          onClick={handleTryBrowser}
        >
          Try in Browser
        </Button>
        <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
          <Button
            className="common-button settings-button"
            icon={<img src={SettingsIcon} alt="settings-icon" width={12} />}
            size="small"
            id="settings-btn"
            type="primary"
            onClick={() => setShowModal(true)}
          >
            Settings
          </Button>
        </AccessControl>
      </Portal>
      <Row>
        <Col
          xs={14}
          sm={14}
          md={14}
          lg={14}
          xl={14}
          xxl={14}
          className="center-col"
        >
          <div className="center-content">{centerContent}</div>
        </Col>
        <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
          <div className="right-content">{rightSideContent}</div>
        </Col>
      </Row>
    </Card>
  );
};

export default CpqTemplate;
