import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import { forEach } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import HoursOfOperationForm from '../components/HoursOfOperationForm';
import { FETCH_BUSINESS_HOURS, FETCH_HOLIDAYS } from '../graphql/Queries';

const initialBusinessHourFilter = {
  skip: 0,
  limit: 10,
  sortBy: 'ASC',
  sortOn: 'order'
};
const initialHolidayFilter = {
  skip: 0,
  limit: 10,
  sortBy: 'ASC',
  sortOn: 'date'
};
const HoursOfOperationPage = () => {
  const [businessHoursLoading, setBusinessHoursLoading] = useState(true);
  const [HolidayLoading, setHolidayLoading] = useState(true);
  const [businessHoursData, setBusinessHoursData] = useState([]);

  const [holidaysData, setHolidaysData] = useState({});

  const [fetchBusinessHours] = useLazyQuery(FETCH_BUSINESS_HOURS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const businessHourArray = [];
      forEach(res?.businessHours?.data, (item) => {
        businessHourArray?.push({
          ...item,
          startTime: item?.startTime && moment(item?.startTime, 'h:mm a'),
          endTime: item?.endTime && moment(item?.endTime, 'h:mm a')
        });
      });
      setBusinessHoursData(businessHourArray);
      setBusinessHoursLoading(false);
    },
    onError() {
      setBusinessHoursLoading(false);
    }
  });

  const [fetchHolidaysData, { refetch }] = useLazyQuery(FETCH_HOLIDAYS, {
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      const holidayArray = [];
      forEach(res?.holidays?.data, (item) => {
        holidayArray?.push({
          id: item?.id,
          name: item?.name,
          date: moment(item?.date, 'YYYY/MM/DD'), // MMM Do YY
          allDayChecked: item?.allDayChecked,
          open: item?.open && moment(item?.open, 'h:mm a'),
          close: item?.close && moment(item?.close, 'h:mm a'),
          cutOfTimeChecked: item?.cutOfTimeChecked,
          cutOfTime: item?.cutOfTime && moment(item?.cutOfTime, 'h:mm a'),
          holidayMessage: item?.holidayMessage
        });
      });

      setHolidaysData(holidayArray);
      setHolidayLoading(false);
    },
    onError() {
      setHolidayLoading(false);
    }
  });

  useEffect(() => {
    fetchBusinessHours({
      variables: {
        filter: {
          ...initialBusinessHourFilter
        }
      }
    });
    fetchHolidaysData({
      variables: {
        filter: {
          ...initialHolidayFilter
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <span className="portal-header">Hours Of Operation</span>
        </div>
      </Portal>
      {HolidayLoading || businessHoursLoading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <HoursOfOperationForm
          businessHoursData={businessHoursData}
          holidaysData={holidaysData}
          refetch={refetch}
        />
      )}
    </Card>
  );
};
export default HoursOfOperationPage;
