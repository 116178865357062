import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PropertyWaterHookUpForm from '../components/PropertyWaterHookUpForm';
import CardWrapper from '../components/PropertyWaterHookUpTabs';
import { UPDATE_PROPERTY_WATER_HOOK_UP } from '../graphql/Mutations';
import { GET_PROPERTY_WATER_HOOK_UP } from '../graphql/Queries';

function PropertyWaterHookUpEdit(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { propertyWaterHookUp } = {} } = useQuery(
    GET_PROPERTY_WATER_HOOK_UP,
    {
      variables: { id },
      onCompleted: () => {
        setLoading(false);
      },
      fetchPolicy: 'network-only',
      onError: () => {
        setLoading(false);
        setSubmitLoading(false);
      }
    }
  );

  const [updatePropertyWaterHookUp] = useMutation(
    UPDATE_PROPERTY_WATER_HOOK_UP,
    {
      onError: () => {
        setSubmitLoading(false);
      }
    }
  );

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = {
      data: newFormValues,
      where: { id: propertyWaterHookUp?.id }
    };

    try {
      const response = await updatePropertyWaterHookUp({
        variables: { ...variables }
      });
      if (response?.data?.updatePropertyWaterHookUp) {
        setSubmitLoading(false);
        history?.push(ROUTES?.PROPERTY_WATER_HOOK_UPS);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.PROPERTY_WATER_HOOK_UPS} />;
  }

  const initialValues = {
    ...propertyWaterHookUp,
    description: propertyWaterHookUp?.description || '',
    isActive: propertyWaterHookUp?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PROPERTY_WATER_HOOK_UPS} />
          {propertyWaterHookUp && (
            <span className="portal-header">{propertyWaterHookUp?.label}</span>
          )}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <PropertyWaterHookUpForm
            propertyWaterHookUpData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
            isEdit
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(PropertyWaterHookUpEdit);
