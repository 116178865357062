import { gql } from '@apollo/client';

export const GET_PROPERTY_TYPES = gql`
  query propertyTypes(
    $filter: PropertyTypeFilter!
    $where: PropertyTypeWhereFilter
  ) {
    propertyTypes(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const GET_PROPERTY_TYPE = gql`
  query propertyType($id: ID!) {
    propertyType(where: { id: $id }) {
      id
      label
      key
      description
      order
      isActive
      tenantId
      referenceId
    }
  }
`;

export const PROPERTY_TYPE_FILTER = gql`
  query propertyTypeFilters($filter: PropertyTypeFilter!) {
    propertyTypeFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
