import { gql } from '@apollo/client';

export const GET_TENANT_BRAND = gql`
  query {
    fetchTenantBrand {
      id
      isActive
      name
      uuid
      primaryColor
      logo {
        url
        key
        name
        extension
        contentType
      }
      secondaryColors
      tertiaryColors
      colorPallete
      description
      waiverText
      priceGuarantee
      offerText
      productDetails
    }
  }
`;

export const GET_TENANT_BRAND_LOB = gql`
  query {
    fetchTenantBrandLineOfBusinesses {
      id
      brandId
      lineOfBusinessId
      subAreaIds
      tenantId
      createdAt
      updatedAt
    }
  }
`;

export const GET_LOB_LIST = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lobCode
        description
        subAreas {
          id
          label
          key
          saCode
          description
          estimation
          isActive
          waiverText
        }
        createdAt
      }
    }
  }
`;
