import { gql } from '@apollo/client';

export const UPDATE_PRODUCT_ITEM_TYPE = gql`
  mutation updateProductItemType(
    $where: ProductItemTypeWhereInput!
    $data: ProductItemTypeInput!
  ) {
    updateProductItemType(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_PRODUCT_ITEM_TYPE = gql`
  mutation createProductItemType($data: ProductItemTypeInput!) {
    createProductItemType(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_PRODUCT_ITEM_TYPE = gql`
  mutation deleteProductItemType($where: ProductItemTypeWhereInput!) {
    deleteProductItemType(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
