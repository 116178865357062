import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import LocationTypeForm from '../components/LocationTypeForm';
import CardWrapper from '../components/LocationTypeTabs';
import { UPDATE_LOCATION_TYPE } from '../graphql/Mutations';
import { GET_LOCATION_TYPE } from '../graphql/Queries';

function LocationTypeEdit(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { locationType } = {} } = useQuery(GET_LOCATION_TYPE, {
    variables: {
      where: {
        id
      }
    },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateLocationType] = useMutation(UPDATE_LOCATION_TYPE, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    const variables = { data: newFormValues, where: { id: locationType?.id } };

    try {
      const response = await updateLocationType({
        variables: { ...variables }
      });
      if (response?.data?.updateLocationType) {
        setSubmitLoading(false);
        history?.push(ROUTES?.LOCATION_TYPES);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Redirect to={ROUTES?.LOCATION_TYPES} />;
  }

  const initialValues = {
    ...locationType,

    description: locationType?.description || '',
    isActive: locationType?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.LOCATION_TYPES} />
          {locationType && (
            <span className="portal-header">{locationType?.label}</span>
          )}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <LocationTypeForm
            isEdit
            locationTypeData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(LocationTypeEdit);
