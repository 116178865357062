import { UpOutlined } from '@ant-design/icons';
import { Card, Checkbox, Col, Collapse, Empty, Form, Radio, Row } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import { ROUTES } from '../../../common/constants';
import {
  cpqRetailUrl,
  dateFormatWithoutTime,
  formValidatorRules
} from '../../../common/utils';
import DatePickerComponent from '../../../components/DatePickerComponent';
import InputComponent from '../../../components/InputComponent';

const { required, requiredWhiteSpaceAllowed, slug } = formValidatorRules;
const { Panel } = Collapse;
const GeneralForm = (props) => {
  const {
    showDatePicker,
    setShowDatePicker,
    brandsData,
    tenantData,
    lineOfBusinessData,
    generalActivePanelKey,
    setGeneralActivePanelKey
  } = props;
  const {
    state: { globalDateFormat }
  } = useContext(AppContext);

  const onCheckChange = ({ target: { checked } }) => {
    setShowDatePicker(checked);
  };

  return (
    <Card>
      <Collapse
        bordered={false}
        activeKey={generalActivePanelKey}
        onChange={(keys) => setGeneralActivePanelKey(keys)}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        className="common-collapse"
      >
        <Panel forceRender header="GENERAL *" key="title_subdomain">
          <span className="panel-description">
            General settings of this questionnaire
          </span>
          <Row className="setting-collapse-content">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="title"
                label="Questionnaire Name"
                rules={[
                  { ...required, message: 'Please Enter Questionnaire Name' }
                ]}
              >
                <InputComponent
                  allowClear
                  placeholder="Enter Questionnaire Name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="link"
                label="Subdomain"
                rules={[
                  { ...required, message: 'Please Enter SubDomain' },
                  slug
                ]}
              >
                <InputComponent
                  className="subdomain-input"
                  addonBefore={`${cpqRetailUrl(
                    tenantData?.tenant?.subDomain
                  )}/`}
                  allowClear
                  placeholder="Enter Slug"
                />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
        <Panel forceRender header="RETAILER *" key="retailer">
          <div>
            <span className="panel-description font-10">
              Go to <Link to={ROUTES?.RETAILERS}>Retailers</Link> tab to add
              Retailer
            </span>
          </div>
          <span className="panel-description">
            Select Retailer for this Questionnaire
          </span>
          <div className="setting-collapse-content">
            {brandsData?.brands?.count > 0 ? (
              <Form.Item
                name="brandId"
                className="brand-select"
                rules={[{ ...required, message: 'Please Select Retailer' }]}
              >
                <Radio.Group>
                  <Row gutter={[16, 16]}>
                    {map(brandsData?.brands?.data, (item) => (
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        xxl={6}
                        key={item?.id}
                      >
                        <Radio
                          className="common-radio setting-brands-radio"
                          value={item?.id}
                        >
                          <img
                            src={item?.logo?.url || placeholderImage}
                            alt="brand-img"
                            onError={(e) => {
                              e.target.src = placeholderImage;
                            }}
                            className="list-img image-contain"
                            width={24}
                            height={24}
                          />
                          {item?.name}
                        </Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Form.Item>
            ) : (
              <Empty
                className="no-data-image"
                image={Empty?.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </div>
        </Panel>
        <Panel forceRender header="QUOTES LABELS *" key="quote_label">
          <span className="panel-description">
            Customize Package Tier names for your customers
          </span>
          <Row className="setting-collapse-content" gutter={16}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Good"
                name="lowCost"
                rules={[{ ...required, message: 'Please Enter Good Title' }]}
              >
                <InputComponent allowClear placeholder="Enter Good title" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="recommended"
                label="Better"
                rules={[{ ...required, message: 'Please Enter Better Title' }]}
              >
                <InputComponent allowClear placeholder="Enter Better title" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="highEnd"
                label="Best"
                rules={[{ ...required, message: 'Please Enter Best Title' }]}
              >
                <InputComponent allowClear placeholder="Enter Best title" />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
        <Panel forceRender header="EXPIRATION" key="expiration">
          <span className="panel-description">
            Set expiration for this questionnaire
          </span>
          <Row>
            <Col
              className="setting-collapse-content"
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              <Form.Item name="isScheduleEnabled" valuePropName="checked">
                <Checkbox onChange={onCheckChange} className="common-checkbox">
                  Enabled
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {showDatePicker && (
            <Row gutter={8}>
              <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Start Date'
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise?.resolve();
                        }

                        if (moment(value)?.isBefore(moment(), 'day')) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'Start date cannot be less than current date!'
                          );
                        }
                        if (
                          getFieldValue('expiryDate') &&
                          value > getFieldValue('expiryDate')
                        ) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'Start date cannot be greater than expiry date!'
                          );
                        }

                        return Promise?.resolve();
                      }
                    })
                  ]}
                >
                  <DatePickerComponent
                    disabledDate
                    placeholder="Select Start Date"
                    format={dateFormatWithoutTime(globalDateFormat)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
                <Form.Item
                  name="expiryDate"
                  label="Expiry Date"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Expiry Date'
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || !getFieldValue('startDate')) {
                          return Promise?.resolve();
                        }

                        if (moment(value)?.isBefore(moment(), 'day')) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'Expiry date cannot be less than current date!'
                          );
                        }
                        if (
                          getFieldValue('startDate') &&
                          value < getFieldValue('startDate')
                        ) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'Expiry date cannot be less than start date!'
                          );
                        }
                        return Promise?.resolve();
                      }
                    })
                  ]}
                >
                  <DatePickerComponent
                    disabledDate
                    placeholder="Select Expiry Date"
                    format={dateFormatWithoutTime(globalDateFormat)}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Panel>
        <Panel
          forceRender
          header="DEFAULT QUESTIONNAIRE"
          key="default_questionnaire"
        >
          <span className="panel-description">
            Set this questionnaire as default
          </span>
          <Row>
            <Col>
              <Form.Item name="isDefault" valuePropName="checked">
                <Checkbox className="common-checkbox">Set Default </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Panel>
        <Panel forceRender header="OTHERS" key="other">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <span className="panel-description">
                Use Retailer Logo & Color in Email Template
              </span>
              <Form.Item name="useRetailerLogoAndColor">
                <Radio.Group className="common-radio">
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <span className="panel-description">
              Show Itemised pricing in the quote
            </span>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="showPricingInEmailQuote">
                <Radio.Group className="common-radio">
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <div className="setting-labels">
        <span className="row-title lob-span ">Line of Business</span>
        <span className="row-description"> {lineOfBusinessData?.label}</span>
      </div>
    </Card>
  );
};

export default GeneralForm;
