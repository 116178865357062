import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PreferredContactMethodCreate from './pages/PreferredContactMethodCreate';
import PreferredContactMethodEdit from './pages/PreferredContactMethodEdit';
import PreferredContactMethodList from './pages/PreferredContactMethodList';
import './preferredContactMethodModule.less';

const PreferredContactMethodWrapper = () => {
  return (
    <div className="preferred-contact-method-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.PREFERRED_CONTACT_METHODS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <PreferredContactMethodList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PREFERRED_CONTACT_METHODS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <PreferredContactMethodCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.PREFERRED_CONTACT_METHODS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <PreferredContactMethodEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default PreferredContactMethodWrapper;
