import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SubAreaForm from '../components/SubAreaForm';
import CardWrapper from '../components/SubAreaTabs';
import { UPDATE_SUB_AREA } from '../graphql/Mutations';
import { GET_SUB_AREA } from '../graphql/Queries';

function SubAreaEdit(props) {
  const { match: { params: { lobId, subId } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { subArea } = {} } = useQuery(GET_SUB_AREA, {
    variables: { id: subId },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateSubAreaMutate] = useMutation(UPDATE_SUB_AREA, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues
    };

    delete newFormValues?.saCode;
    delete newFormValues?.lineOfBusinessId;

    const variables = { data: newFormValues, where: { id: subArea?.id } };

    try {
      const response = await updateSubAreaMutate({
        variables: { ...variables }
      });
      if (response?.data?.updateSubArea) {
        setSubmitLoading(false);
        history?.push(
          `${ROUTES?.LINE_OF_BUSINESSES}/${lobId}${ROUTES?.SUB_AREAS}`
        );
      }
    } catch (error) {
      return error;
    }
  };

  if (!subId) {
    <Redirect
      to={`${ROUTES?.LINE_OF_BUSINESSES}/${lobId}${ROUTES?.SUB_AREAS}`}
    />;
  }

  const initialValues = {
    ...subArea,
    lineOfBusinessId: subArea?.lineOfBusinessId,
    description: subArea?.description || '',
    isActive: subArea?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton
            customLink={`${ROUTES?.LINE_OF_BUSINESSES}/${lobId}${ROUTES?.SUB_AREAS}`}
          />
          {subArea && <span className="portal-header">{subArea?.label}</span>}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <SubAreaForm
            isEdit
            lobOption={[subArea?.lineOfBusiness]}
            subAreaData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(SubAreaEdit);
