import { gql } from '@apollo/client';

export const GET_CUSTOMER_TYPES = gql`
  query customerTypes(
    $filter: CustomerTypeFilter!
    $where: CustomerTypeWhereFilter
  ) {
    customerTypes(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const GET_CUSTOMER_TYPE = gql`
  query customerType($id: ID!) {
    customerType(where: { id: $id }) {
      id
      label
      key
      description
      order
      isActive
    }
  }
`;

export const CUSTOMER_TYPE_FILTER = gql`
  query customerTypeFilters($filter: CustomerTypeFilter!) {
    customerTypeFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
