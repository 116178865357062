import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import SubAreaTable from '../components/SubAreaTable';

const SubAreaList = () => {
  const {
    state: { pageSize }
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-p-24">
      {pageSize && <SubAreaTable />}
    </Card>
  );
};

export default SubAreaList;
