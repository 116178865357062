import { DeleteOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Collapse, Form } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { CPQ_MAX_LIMIT } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import { UPDATE_CPQ_UPGRADE } from '../graphql/Mutations';
import { CPQ_UPGRADE } from '../graphql/Queries';

const { Panel } = Collapse;
const { Option } = SelectComponent;

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const UpgradeForm = ({
  match: { params: { id } = {} } = {},
  loading = false,
  setUpgradeProductConfig,
  setLoading,
  setLogoUrl
}) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [initialValues, setInitialValues] = useState(null);

  const [validationTriggered, setValidationTriggered] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [valuesChanged, setValuesChanged] = useState(false);

  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'title',
    'search',
    'checklist',
    'button',
    'footnote'
  ]);

  const [updateCpqUpgrade] = useMutation(UPDATE_CPQ_UPGRADE, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const [cpqUpgrade] = useLazyQuery(CPQ_UPGRADE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const initialValuesCopy = {
        mainTitle: res?.cpqUpgrade?.title?.mainTitle,
        shortDescription: res?.cpqUpgrade?.title?.shortDescription,
        search: res?.cpqUpgrade?.search,
        showSearchBar: res?.cpqUpgrade?.showSearchBar,
        checklists: map(res?.cpqUpgrade?.checkList?.mainCheckList, (item) => {
          return { checklist: item };
        }),
        inputBoxPlaceholder: res?.cpqUpgrade?.checkList?.inputBoxPlaceholder,
        buttonLabel: res?.cpqUpgrade?.buttonLabel,
        footNoteLocation: res?.cpqUpgrade?.footNoteLocation
      };
      setInitialValues(initialValuesCopy);
      setUpgradeProductConfig(initialValuesCopy);
      setLogoUrl(res?.cpqUpgrade?.brandInfo?.logo?.url);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    cpqUpgrade({
      variables: {
        where: {
          questionnaireId: id
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const newFormValues = {
      title: {
        mainTitle: formValues?.mainTitle,
        shortDescription: formValues?.shortDescription
      },
      search: formValues?.search,
      showSearchBar: formValues?.showSearchBar,
      checkList: {
        mainCheckList: map(formValues?.checklists, (item) => item?.checklist),
        inputBoxPlaceholder: formValues?.inputBoxPlaceholder
      },
      buttonLabel: formValues?.buttonLabel,
      footNoteLocation: formValues?.footNoteLocation
    };

    const variables = {
      data: newFormValues,
      where: { questionnaireId: id }
    };

    try {
      const response = await updateCpqUpgrade({
        variables: { ...variables }
      });
      if (response?.data?.updateCpqUpgrade) {
        setValuesChanged(false);
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const handleOnChangeValue = (changedValues, allValues) => {
    setUpgradeProductConfig({
      ...allValues
    });
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
    setValuesChanged(true);
  };

  if (loading) {
    return <LoaderComponent setHeight="80" />;
  }

  return (
    <div className="upgrade-form">
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="upgrade-save-btn"
            loading={submitLoading}
            type="primary"
            disabled={!valuesChanged}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onValuesChange={handleOnChangeValue}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_QUESTIONNAIRE_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(keys) => setCollapseActiveKeys(keys)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
          >
            <Panel forceRender header="Title" key="title">
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="mainTitle"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Short Description' }
                ]}
                name="shortDescription"
                label="Short Description"
              >
                <InputComponent
                  allowClear
                  placeholder="Enter Short Description"
                />
              </Form.Item>
            </Panel>
            <Panel forceRender header="Search" key="search">
              <Form.Item name="showSearchBar" valuePropName="checked">
                <Checkbox className="common-checkbox">Show Search</Checkbox>
              </Form.Item>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Search' }]}
                name="search"
                label="Search"
              >
                <InputComponent allowClear placeholder="Enter Search" />
              </Form.Item>
            </Panel>
            <Panel forceRender header="Checklist" key="checklist">
              <Form.List name="checklists">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <div key={key} className="d-flex align-baseline">
                          <Form.Item
                            className="fill-width"
                            {...restField}
                            name={[name, 'checklist']}
                            fieldKey={[fieldKey, 'checklist']}
                            rules={[required]}
                          >
                            <InputComponent placeholder="Add Checklist" />
                          </Form.Item>
                          {index > 2 && (
                            <DeleteOutlined
                              className="delete-icon"
                              onClick={() => remove(name)}
                            />
                          )}
                        </div>
                      )
                    )}
                    {fields?.length < CPQ_MAX_LIMIT && (
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                        type="link"
                        className="add-link-btn"
                      >
                        Add
                      </Button>
                    )}
                  </>
                )}
              </Form.List>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Inputbox placeholder' }
                ]}
                name="inputBoxPlaceholder"
                label="Inputbox placeholder"
              >
                <InputComponent
                  allowClear
                  placeholder="Enter Inputbox placeholder"
                />
              </Form.Item>
            </Panel>
            <Panel forceRender header="Button" key="button">
              <Form.Item
                name="buttonLabel"
                label="Label"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Label'
                  }
                ]}
              >
                <SelectComponent placeholder="Select Label" allowClear>
                  <Option key="CONTINUE" value="Continue">
                    Continue
                  </Option>
                  <Option key="NEXT" value="Next">
                    Next
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Panel>
            <Panel forceRender header="Footnote" key="footnote">
              <Form.Item name="footNoteLocation" valuePropName="checked">
                <Checkbox className="common-checkbox">Location</Checkbox>
              </Form.Item>
            </Panel>
          </Collapse>
        </fieldset>
      </Form>
    </div>
  );
};

export default withRouter(UpgradeForm);
