import { CheckCircleFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, message, Radio, Steps } from 'antd';
import { keys } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import { importObj, ROUTES } from '../../../common/constants';
import { fetchStep } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import RouterPrompt from '../../../components/RouterPrompt';
import history from '../../../historyData';
import { CREATE_QUESTIONNAIRE, UPDATE_USER_STEP } from '../graphql/Mutations';
import StepProcess from '../pages/StepProcess';

const Products = () => {
  const { Step } = Steps;
  const { dispatch } = useContext(AppContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [value, setValue] = useState('1');
  const [showProducts, setShowProducts] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showText, setShowText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brandTotal, setBrandTotal] = useState(null);
  const [regionTotal, setRegionTotal] = useState(null);
  const [manufactureTotal, setManufactureTotal] = useState(null);
  const [categoryTotal, setCategoryTotal] = useState(null);
  const [productItemTotal, setProductItemTotal] = useState(null);
  const [productTotal, setProductTotal] = useState(null);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);

  const [updateUserStep, { loading: productLoading }] = useMutation(
    UPDATE_USER_STEP,
    {
      onCompleted: async () => {
        fetchStep({ dispatch, setLoading: false, changeRoute: false });
        history?.push(`${ROUTES?.ONBOARDING}/invite`);
      },
      onError: () => {}
    }
  );

  const [createQuestionnaire] = useMutation(CREATE_QUESTIONNAIRE, {
    onCompleted() {
      setShowQuestionnaire(true);
      setLoading(false);
      setShowButton(true);
      setShowText(true);
    }
  });

  const options = [
    { label: 'Start with Sample Data', value: '1' },
    { label: 'SetUp Manually', value: '2' }
  ];

  const onChange = (e) => {
    setValue(e?.target?.value);
  };

  const handleChangeScreen = (current) => {
    setCurrentStep(current);
  };

  const handleChangeStep = () => {
    updateUserStep({
      variables: {
        data: {
          step: 'DATA_ONBOARD5',
          isAgain: false
        }
      }
    });
  };

  const handleImports = async () => {
    setLoading(true);
    setShowProducts(true);
    const statusArray = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys(importObj)) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await api({
          method: 'POST',
          url: `${process?.env?.REACT_APP_SERVER_REST_URL}/import`,
          data: {
            importType: key,
            fileObj: importObj?.[key]?.fileObj,
            isOnboarding: true
          }
        });
        if (response) {
          const successData = {
            [key]: response?.headers?.['x-success-count']
          };
          statusArray.push(successData);

          if (key === 'REGION') {
            setRegionTotal(response?.headers?.['x-success-count']);
          }
          if (key === 'BRAND') {
            setBrandTotal(response?.headers?.['x-success-count']);
          }
          if (key === 'MANUFACTURER') {
            setManufactureTotal(response?.headers?.['x-success-count']);
          }
          if (key === 'PRODUCT_CATEGORY') {
            setCategoryTotal(response?.headers?.['x-success-count']);
          }
          if (key === 'PRODUCT_ITEM') {
            setProductItemTotal(response?.headers?.['x-success-count']);
          }
          if (key === 'PRODUCT') {
            setProductTotal(response?.headers?.['x-success-count']);
            createQuestionnaire();
          }
        }
      } catch (error) {
        setLoading(false);
        setShowButton(true);
        setShowText(true);
        message?.destroy();
        message?.error(
          'Due to some technical issues, import process has been stopped. Please try adding data manually.'
        );
        break;
      }
    }
  };

  return (
    <StepProcess>
      <div className="products">
        <RouterPrompt when={loading} />
        {!showProducts && (
          <>
            <span className="steps-content-title">
              Add Data to your Business
            </span>
            <Radio.Group
              size="large"
              className="radio-button"
              options={options}
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
            />
          </>
        )}
        {showProducts && (
          <div className="d-flex flex-vertical justify-between">
            {!showText ? (
              <span className="steps-content-title">
                Adding Data to your Business...
              </span>
            ) : (
              <span className="steps-content-title">
                Data has been successfully added!
              </span>
            )}
            <LoaderComponent spinning={loading}>
              <div className="d-flex justify-center">
                <div className="list-items-wrapper">
                  {regionTotal > 0 && (
                    <span className="list-item slide-up3">
                      <CheckCircleFilled className="check-products" />
                      <span>{regionTotal} Regions</span>
                    </span>
                  )}
                  {brandTotal > 0 && (
                    <span className="list-item slide-up3">
                      <CheckCircleFilled className="check-products" />
                      <span>{brandTotal} Retailers</span>
                    </span>
                  )}
                  {manufactureTotal > 0 && (
                    <span className="list-item slide-up3">
                      <CheckCircleFilled className="check-products" />
                      <span>{manufactureTotal} Manufacturer</span>
                    </span>
                  )}
                  {categoryTotal > 0 && (
                    <span className="list-item slide-up3">
                      <CheckCircleFilled className="check-products" />
                      <span>{categoryTotal} Product Category</span>
                    </span>
                  )}
                  {productItemTotal > 0 && (
                    <span className="list-item slide-up3">
                      <CheckCircleFilled className="check-products" />
                      <span>{productItemTotal} Products</span>
                    </span>
                  )}
                  {productTotal > 0 && (
                    <span className="list-item slide-up3">
                      <CheckCircleFilled className="check-products" />
                      <span>{productTotal} Packages</span>
                    </span>
                  )}
                  {showQuestionnaire > 0 && (
                    <span className="list-item slide-up3">
                      <CheckCircleFilled className="check-products" />
                      <span>1 Questionnaire</span>
                    </span>
                  )}
                </div>
              </div>
            </LoaderComponent>
          </div>
        )}
        {showButton && (
          <>
            <span className="steps-content-description">
              Review these items in the Tenant Portal after the Onboarding.
            </span>
            <Button
              type="primary"
              className="question-button submit-btn"
              loading={productLoading}
              onClick={handleChangeStep}
            >
              Continue to Invite Team Members
            </Button>
          </>
        )}

        {value === '1' && !showProducts && (
          <div className="d-flex flex-vertical justify-between">
            <span className="top-space steps-content-description">
              We are going to add Packages with Pricing, and Questionnaire to
              give you a head start.
            </span>
            <Button
              type="primary"
              className="sample-button submit-btn"
              onClick={handleImports}
            >
              Continue
            </Button>
          </div>
        )}
        {value === '2' && (
          <div className="d-flex flex-vertical justify-between">
            <Steps
              progressDot
              current={currentStep}
              onChange={handleChangeScreen}
              className="steps-margin"
            >
              <Step title="Packages" />
              <Step title="Questionnaire" />
              <Step title="Associated Pricing" />
            </Steps>
            <Button
              type="primary"
              loading={productLoading}
              className="question-button submit-btn"
              onClick={handleChangeStep}
            >
              {`Add ${currentStep === 0 ? ' Products' : ''}
              ${currentStep === 1 ? ' Questionnaire' : ''}
              ${currentStep === 2 ? ' Associated Pricing' : ''}`}
            </Button>
          </div>
        )}
      </div>
    </StepProcess>
  );
};

export default Products;
