import { gql } from '@apollo/client';

export const UPDATE_CUSTOMER_TYPE = gql`
  mutation updateCustomerType(
    $where: CustomerTypeWhereInput!
    $data: CustomerTypeInput!
  ) {
    updateCustomerType(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_CUSTOMER_TYPE = gql`
  mutation createCustomerType($data: CustomerTypeInput!) {
    createCustomerType(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_CUSTOMER_TYPE = gql`
  mutation deleteCustomerType($where: CustomerTypeWhereInput!) {
    deleteCustomerType(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
