import React, { useState } from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import UpgradeComponent from '../components/previewComponents/upgrade/components/UpgradeComponent';
import UpgradeForm from '../components/UpgradeForm';
import CpqTemplate from './CpqTemplate';

const UpgradePage = () => {
  const [upgradeProductConfig, setUpgradeProductConfig] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  return (
    <CpqTemplate
      centerContent={
        loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <UpgradeComponent
            upgradeProductConfig={upgradeProductConfig}
            logoUrl={logoUrl}
          />
        )
      }
      rightSideContent={
        <UpgradeForm
          setUpgradeProductConfig={setUpgradeProductConfig}
          loading={loading}
          setLoading={setLoading}
          setLogoUrl={setLogoUrl}
        />
      }
    />
  );
};

export default UpgradePage;
