import { gql } from '@apollo/client';

export const GET_PRODUCT_ITEM_TYPES = gql`
  query productItemTypes(
    $filter: ProductItemTypeFilter!
    $where: ProductItemTypesWhereFilter
  ) {
    productItemTypes(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        isActive
        description
        order
      }
    }
  }
`;

export const GET_PRODUCT_ITEM_TYPE = gql`
  query productItemType($where: ProductItemTypeWhereInput!) {
    productItemType(where: $where) {
      id
      label
      key
      description
      order
      isActive
    }
  }
`;

export const PRODUCT_ITEM_TYPE_FILTER = gql`
  query productItemTypeFilters($filter: ProductItemTypeFilter!) {
    productItemTypeFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
