import { gql } from '@apollo/client';

export const UPDATE_PREFERRED_CONTACT_METHOD = gql`
  mutation updatePreferredContactMethod(
    $where: PreferredContactMethodWhereInput!
    $data: PreferredContactMethodInput!
  ) {
    updatePreferredContactMethod(where: $where, data: $data) {
      data {
        id
        label
        refData
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_PREFERRED_CONTACT_METHOD = gql`
  mutation createPreferredContactMethod($data: PreferredContactMethodInput!) {
    createPreferredContactMethod(data: $data) {
      data {
        id
        label
        refData
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_PREFERRED_CONTACT_METHOD = gql`
  mutation deletePreferredContactMethod(
    $where: PreferredContactMethodWhereInput!
  ) {
    deletePreferredContactMethod(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
