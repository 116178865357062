import { Button, Checkbox, Col, Empty, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import location from '../../../../../../assets/location-icon.svg';
import InputComponent from '../../globalComponents/InputComponent';
import SearchComponent from '../../globalComponents/SearchComponent';
import PreviewTagComponent from '../../previewTagComponent';
import '../upgrade.less';

const UpgradeComponent = ({ upgradeProductConfig = null, logoUrl = null }) => {
  return (
    <div className="upgrade-page">
      <PreviewTagComponent />
      <div className="logo-section">
        <img alt="logo" src={logoUrl} />
      </div>
      <div className="title-section">
        <span className="bold-label">{upgradeProductConfig?.mainTitle}</span>
        <span className="thin-label">
          {upgradeProductConfig?.shortDescription}
        </span>
      </div>
      <div className="checkbox-section">
        {upgradeProductConfig?.showSearchBar && (
          <div className="search-checkbox-cpq">
            <SearchComponent
              readOnly
              className="list-search-box-cpq"
              id="search-container-id"
              placeholder={upgradeProductConfig?.search}
              name="checklist"
            />
          </div>
        )}
        {upgradeProductConfig?.checklists?.length > 0 ? (
          <Checkbox.Group className="common-checkbox-cpq" value={[]}>
            <Row gutter={[0, 16]}>
              {map(upgradeProductConfig?.checklists, (item) => (
                <Col span={24} key={item?.checklist}>
                  <Checkbox value={item?.checklist}>{item?.checklist}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )}
        <InputComponent
          readOnly
          className="other-input"
          allowClear
          placeholder={upgradeProductConfig?.inputBoxPlaceholder}
        />
        <Button
          className="common-button-cpq"
          htmlType="submit"
          id="upgrade-save-btn"
          type="primary"
        >
          {upgradeProductConfig?.buttonLabel}
        </Button>
      </div>
      {upgradeProductConfig?.footNoteLocation && (
        <div className="location-footer">
          <img src={location} alt="location-icon" />
          <span className="location-info">Irvine, CA</span>
        </div>
      )}
    </div>
  );
};

export default UpgradeComponent;
