import { PoweroffOutlined } from '@ant-design/icons';
import { Layout, Menu, notification } from 'antd';
import { capitalize, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import collapseIcon from '../../../assets/collapse.svg';
import expandIcon from '../../../assets/expand.svg';
import logoCollapsed from '../../../assets/logo-collapsed.svg';
import logoDark from '../../../assets/logo-dark.svg';
import { MODULES, ROUTES, TABLET_BREAKPOINT } from '../../../common/constants';
import { checkPermissions } from '../../../common/utils';
import UserIconComponent from '../iconComponents/UserIconComponent';
import sideMenuConfig from './sideMenuConfig';

const { Sider } = Layout;
const { SubMenu } = Menu;

const subMenus = [
  {
    moduleKey: MODULES?.MY_QUESTIONNAIRES,
    routes: [ROUTES?.DASHBOARD, ROUTES?.QUESTIONNAIRES]
  },
  {
    moduleKey: MODULES?.SALES,
    routes: [
      ROUTES?.QUOTE_VIEWER,
      ROUTES?.CUSTOMERS,
      ROUTES?.CONTACTS,
      ROUTES?.PROPERTIES
    ]
  },
  {
    moduleKey: MODULES?.CATALOG,
    routes: [
      ROUTES?.PRODUCTS,
      ROUTES?.PACKAGES,
      ROUTES?.PRODUCT_PRICING,
      ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION,
      ROUTES?.PRICING_MODIFIERS,
      ROUTES?.PRODUCT_CATEGORIES,
      ROUTES?.MANUFACTURERS
    ]
  },
  {
    moduleKey: MODULES?.USERS_MANAGEMENT,
    routes: [ROUTES?.USERS, ROUTES?.ROLES, ROUTES?.PERMISSIONS]
  },
  {
    moduleKey: MODULES?.MASTERS,
    routes: [
      ROUTES?.PRODUCT_ITEM_TYPE,
      ROUTES?.FUEL_SOURCES,
      ROUTES?.VENT_TYPES,
      ROUTES?.LOCATION_TYPES,
      ROUTES?.CAPACITIES,
      ROUTES?.HEIGHTS,
      ROUTES?.CONTACT_TYPES,
      ROUTES?.CUSTOMER_TYPES,
      ROUTES?.PREFERRED_CONTACT_METHODS,
      ROUTES?.PROPERTY_FINISHED_BASEMENTS,
      ROUTES?.PROPERTY_TYPES,
      ROUTES?.PROPERTY_WATER_HOOK_UPS,
      ROUTES?.PROPERTY_HEAT_SOURCE,
      ROUTES?.PROPERTY_FINISHED_BASEMENTS_CEILING
    ]
  },
  {
    moduleKey: MODULES?.SETTING,
    routes: [
      ROUTES?.TENANT_SETTING,
      ROUTES?.API_SETTING,
      ROUTES?.HOURS_OF_OPERATION,
      ROUTES?.BUSINESS_INFORMATION,
      ROUTES?.PLAN_SETTING,
      ROUTES?.MY_ACCOUNT_SETTING
    ]
  }
];

function Sidebar({ location: { pathname }, history }) {
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const {
    state: { currentUser, windowWidth, permissions }
  } = useContext(AppContext);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const onMenuSelect = (e) => {
    notification?.destroy();
    history?.push(e?.key);
  };

  useEffect(() => {
    if (windowWidth < TABLET_BREAKPOINT) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [windowWidth]);

  const getOpenKeys = () => {
    forEach(subMenus, (item) => {
      forEach(item?.routes, (route) => {
        if (pathname?.includes(route)) {
          setOpenKeys([item?.moduleKey]);
        }
      });
    });
  };

  useEffect(() => {
    getOpenKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div>
        <div className="gx-layout-sider-header">
          <div>
            <Link to={ROUTES?.MAIN}>
              <img
                src={collapsed ? logoCollapsed : logoDark}
                alt="logo"
                className="gx-site-logo"
              />
            </Link>
          </div>
          <div onClick={toggle} className="gx-icon-btn icon pointer">
            {collapsed ? (
              <span>
                <img src={expandIcon} alt="expand" />
              </span>
            ) : (
              <span>
                <img src={collapseIcon} alt="collapse" />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="gx-sidebar-content">
        <Menu
          theme="lite"
          mode="inline"
          selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
          openKeys={openKeys}
          defaultSelectedKeys={[ROUTES?.USERS]}
          onSelect={onMenuSelect}
          onOpenChange={(openKeysArray) => setOpenKeys(openKeysArray)}
        >
          {map(sideMenuConfig, (menuItem) => {
            if (menuItem?.children?.length > 0) {
              const permitted = checkPermissions(
                permissions,
                menuItem?.permissions
              );
              if (permitted) {
                return (
                  <SubMenu
                    icon={menuItem?.icon}
                    title={menuItem?.title}
                    key={menuItem?.key}
                  >
                    {map(menuItem?.children, (item) => {
                      const menuItemPermitted = checkPermissions(
                        permissions,
                        item?.permissions
                      );
                      if (menuItemPermitted) {
                        return (
                          <Menu.Item key={item?.key}>{item?.title}</Menu.Item>
                        );
                      }
                    })}
                  </SubMenu>
                );
              }
            } else {
              const permitted = checkPermissions(
                permissions,
                menuItem?.permissions
              );
              if (permitted) {
                return (
                  <Menu.Item
                    title={
                      <>
                        {menuItem?.icon}
                        &nbsp;
                        <span>{menuItem?.title}</span>
                      </>
                    }
                    key={menuItem?.key}
                    className="d-flex align-center"
                  >
                    {menuItem?.icon}
                    <span>{menuItem?.title}</span>
                  </Menu.Item>
                );
              }
            }
          })}
        </Menu>
      </div>
      <div className="gx-linebar">
        {!collapsed ? (
          <div className="gx-bottom-content">
            <div className="user-name-section">
              <UserIconComponent
                className={collapsed ? 'icon-hover' : ''}
                onClick={() => history?.push(ROUTES?.MY_ACCOUNT_SETTING)}
              />
              <span
                className="user-name pointer"
                title={`${capitalize(currentUser?.firstName)} ${capitalize(
                  currentUser?.lastName
                )}`}
                onClick={() => history?.push(ROUTES?.MY_ACCOUNT_SETTING)}
              >
                {capitalize(currentUser?.firstName)}{' '}
                {capitalize(currentUser?.lastName)}
              </span>
            </div>
            <div
              className="logout-btn"
              onClick={() => history?.push(ROUTES?.LOGOUT)}
            >
              LOGOUT
            </div>
          </div>
        ) : (
          <Menu
            selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
            theme="lite"
            mode="inline"
            onSelect={onMenuSelect}
          >
            <Menu.Item
              title={
                <>
                  <UserIconComponent />
                  &nbsp;
                  <span>{MODULES?.MY_ACCOUNT_SETTING}</span>
                </>
              }
              key={ROUTES?.MY_ACCOUNT_SETTING}
              className="d-flex align-center"
            >
              <UserIconComponent />
              <span>{MODULES?.MY_ACCOUNT_SETTING}</span>
            </Menu.Item>
            <Menu.Item
              title={
                <>
                  <PoweroffOutlined />
                  &nbsp;
                  <span>{MODULES?.LOGOUT}</span>
                </>
              }
              key={ROUTES?.LOGOUT}
              className="d-flex align-center"
            >
              <PoweroffOutlined />
              <span>{MODULES?.LOGOUT}</span>
            </Menu.Item>
          </Menu>
        )}
      </div>
    </Sider>
  );
}

export default withRouter(Sidebar);
