import { gql } from '@apollo/client';

export const UPDATE_PROPERTY_HEAT_SOURCE = gql`
  mutation updatePropertyHeatSource(
    $where: PropertyHeatSourceWhereInput!
    $data: PropertyHeatSourceInput!
  ) {
    updatePropertyHeatSource(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_PROPERTY_HEAT_SOURCE = gql`
  mutation createPropertyHeatSource($data: PropertyHeatSourceInput!) {
    createPropertyHeatSource(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_PROPERTY_HEAT_SOURCE = gql`
  mutation deletePropertyHeatSource($where: PropertyHeatSourceWhereInput!) {
    deletePropertyHeatSource(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
