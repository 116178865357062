import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import { getLogo } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ManufacturerDetails from '../components/ManufacturerDetails';
import CardWrapper from '../components/ManufacturerTabs';
import { GET_MANUFACTURER } from '../graphql/Queries';

function ManufacturerView(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const { data: { manufacturer } = {} } = useQuery(GET_MANUFACTURER, {
    variables: { id },
    onCompleted: (res) => {
      if (!res?.manufacturer?.logo?.url) {
        setLoading(false);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (manufacturer?.logo?.url) {
      getLogo({
        logoObject: manufacturer?.logo,
        setDataState: setLogoUrl,
        setLoadingState: setLoading
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturer]);

  if (!id) {
    return <Redirect to="/manufacturers" />;
  }

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.MANUFACTURERS} />
          {manufacturer && (
            <span className="portal-header">{manufacturer?.name}</span>
          )}
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_MANUFACTURER_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="manufacturer-table-save-btn"
            type="primary"
            onClick={() => history?.push(`${ROUTES?.MANUFACTURERS}/edit/${id}`)}
          >
            Edit
          </Button>
        </Portal>
      </AccessControl>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ManufacturerDetails data={manufacturer} logoUrl={logoUrl} />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(ManufacturerView);
