import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './fuelSourceModule.less';
import FuelSourceCreate from './pages/FuelSourceCreate';
import FuelSourceEdit from './pages/FuelSourceEdit';
import FuelSourceList from './pages/FuelSourceList';

const FuelSourceWrapper = () => {
  return (
    <div className="fuel-source-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.FUEL_SOURCES}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <FuelSourceList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.FUEL_SOURCES}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <FuelSourceCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.FUEL_SOURCES}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <FuelSourceEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default FuelSourceWrapper;
