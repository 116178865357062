import { gql } from '@apollo/client';

export const GET_CONTACT_TYPES = gql`
  query contactTypes(
    $filter: ContactTypeFilter!
    $where: ContactTypeWhereFilter
  ) {
    contactTypes(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const GET_CONTACT_TYPE = gql`
  query contactType($id: ID!) {
    contactType(where: { id: $id }) {
      id
      label
      key
      description
      order
      isActive
    }
  }
`;

export const CONTACT_TYPE_FILTER = gql`
  query contactTypeFilters($filter: ContactTypeFilter!) {
    contactTypeFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
