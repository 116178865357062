import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_MASTER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './heightModule.less';
import HeightCreate from './pages/HeightCreate';
import HeightEdit from './pages/HeightEdit';
import HeightList from './pages/HeightList';

const HeightWrapper = () => {
  return (
    <div className="height-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.HEIGHTS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_MASTER} showNoAccess>
              <HeightList {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.HEIGHTS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_CREATE']}
              showNoAccess
            >
              <HeightCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          path={`${ROUTES?.HEIGHTS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_MASTER_UPDATE', 'FET_MASTER_VIEW']}
              showNoAccess
            >
              <HeightEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default HeightWrapper;
