import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PERMISSION_REGION, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import RegionCreate from './pages/RegionCreate';
import RegionEdit from './pages/RegionEdit';
import RegionList from './pages/RegionList';
import './regionModule.less';

const RegionWrapper = () => {
  return (
    <div className="region-module">
      <Switch>
        <Route
          exact
          path={ROUTES?.REGIONS}
          render={(props) => (
            <AccessControl allowedPermissions={PERMISSION_REGION} showNoAccess>
              <RegionList {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.REGIONS}/add`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_REGION_CREATE']}
              showNoAccess
            >
              <RegionCreate {...props} />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={`${ROUTES?.REGIONS}/edit/:id`}
          render={(props) => (
            <AccessControl
              allowedPermissions={['FET_REGION_UPDATE', 'FET_REGION_VIEW']}
              showNoAccess
            >
              <RegionEdit {...props} />
            </AccessControl>
          )}
        />
      </Switch>
    </div>
  );
};

export default RegionWrapper;
