import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import CustomerCreationModal from '../components/CustomerCreationModal';
import CustomerForm from '../components/CustomerForm';
import CardWrapper from '../components/CustomerTabs';
import { UPDATE_CUSTOMER } from '../graphql/Mutations';
import { GET_CUSTOMER, GET_CUSTOMERS } from '../graphql/Queries';

function CustomerEdit(props) {
  const { match: { params: { id } = {} } = {}, history } = props;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [duplicateData, setDuplicateData] = useState([]);
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const { data: { customer } = {} } = useQuery(GET_CUSTOMER, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const submitData = async () => {
    setSubmitLoading(true);
    const newFormValues = {
      ...formData,
      userInput: {
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        phoneNo: formatPhoneNumberWithoutMask(formData?.phoneNo),
        email: formData?.email
      },
      location: {
        addressLine1: formData?.addressLine1,
        city: formData?.city,
        state: formData?.state,
        country: formData?.country,
        zipCode: formData?.zipCode
      },
      customerType: formData?.customerType || null,
      tollFree:
        formData?.tollFree && formatPhoneNumberWithoutMask(formData?.tollFree),
      fax: formData?.fax && formatPhoneNumberWithoutMask(formData?.fax)
    };

    delete newFormValues?.firstName;
    delete newFormValues?.lastName;
    delete newFormValues?.phoneNo;
    delete newFormValues?.email;
    delete newFormValues?.addressLine1;
    delete newFormValues?.city;
    delete newFormValues?.state;
    delete newFormValues?.country;
    delete newFormValues?.zipCode;

    const variables = { data: newFormValues, where: { id: customer?.id } };

    try {
      const response = await updateCustomer({
        variables: { ...variables }
      });
      if (response?.data?.updateCustomer) {
        setSubmitLoading(false);
        setDuplicateModalVisible(false);
        history?.push(ROUTES?.CUSTOMERS);
      }
    } catch (error) {
      setSubmitLoading(false);
      setDuplicateModalVisible(false);
      return error;
    }
  };

  const [fetchCustomerData] = useLazyQuery(GET_CUSTOMERS, {
    fetchPolicy: 'network-only',
    onCompleted: async (res) => {
      if (res?.customers?.data?.length > 0) {
        setDuplicateData(res?.customers?.data);
        setDuplicateModalVisible(true);
        setSubmitLoading(false);
      } else {
        submitData();
      }
    },
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    setFormData(formValues);
    fetchCustomerData({
      variables: {
        filter: {
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        where: {
          id: [id],
          duplicateCheck: true,
          firstName: [formValues?.firstName],
          lastName: [formValues?.lastName],
          phoneNo: [formValues?.phoneNo],
          email: [formValues?.email],
          addressLine: [formValues?.addressLine1]
        }
      }
    });
  };

  if (!id) {
    return <Redirect to={ROUTES?.CUSTOMERS} />;
  }

  const initialValues = {
    ...customer,
    ...customer?.user,
    ...customer?.location,
    descriptionBusiness: customer?.descriptionBusiness || '',
    isActive: customer?.isActive
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <CustomerCreationModal
        showModal={duplicateModalVisible}
        setShowModal={setDuplicateModalVisible}
        duplicateData={duplicateData}
        submitData={submitData}
        submitLoading={submitLoading}
        isEdit
      />
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CUSTOMERS} />
          {customer && (
            <span className="portal-header">
              {customer?.user?.firstName} {customer?.user?.lastName}
            </span>
          )}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <CustomerForm
            customerData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
            isEdit
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default withRouter(CustomerEdit);
