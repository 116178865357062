import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoaderComponent from '../../../components/LoaderComponent';
import PostalCodeForm from '../components/PostalCodeForm';
import PostalCodeComponent from '../components/previewComponents/postalCode/components/PostalCodeComponent';
import { CPQ_POSTAL_CODE } from '../graphql/Queries';
import CpqTemplate from './CpqTemplate';

const PostalCodePage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [initialValue, setInitialValue] = useState({});
  const [postalConfig, setPostalConfig] = useState(null);
  const [getPostalCodeFormData, { data }] = useLazyQuery(CPQ_POSTAL_CODE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const tempObj = {
        thinLabel: res?.cpqZipCode?.title?.thinLabel,
        boldLabel: res?.cpqZipCode?.title?.boldLabel,
        showMap: res?.cpqZipCode?.map?.checked,
        inputPlaceHolder: res?.cpqZipCode?.map?.key,
        label: res?.cpqZipCode?.buttonLabel,
        footNote: res?.cpqZipCode?.footNote
      };
      setInitialValue(tempObj);
      setPostalConfig(tempObj);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    getPostalCodeFormData({
      variables: {
        id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="postal-code-page">
      <CpqTemplate
        centerContent={
          loading ? (
            <LoaderComponent setHeight={80} />
          ) : (
            <PostalCodeComponent
              postalConfig={postalConfig}
              logoUrl={data?.cpqZipCode?.brandInfo?.logo?.url}
            />
          )
        }
        rightSideContent={
          loading ? (
            <LoaderComponent setHeight={80} />
          ) : (
            <PostalCodeForm
              questionnaireId={id}
              initialValue={initialValue}
              setPostalConfig={setPostalConfig}
            />
          )
        }
      />
    </div>
  );
};

export default PostalCodePage;
