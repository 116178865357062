import React from 'react';
import checkCircleLight from '../../../assets/check-circle-light.svg';
import { PLAN_DETAILS } from '../../../common/constants';

const PlanSettingForm = () => {
  return (
    <div className="content-header">
      <div className="d-flex justify-center align-center flex-horizontal plan-card-wrapper">
        <div className="plan-card">
          <span className="plan-title">{PLAN_DETAILS?.title}</span>
          <span className="plan-description">{PLAN_DETAILS?.description}</span>
          <div className="bullet-points">
            <div>
              <img src={checkCircleLight} alt="check-circle" />
              <span>{PLAN_DETAILS?.firstBullet}</span>
            </div>
            <div>
              <img src={checkCircleLight} alt="check-circle" />
              <span>{PLAN_DETAILS?.secondBullet}</span>
            </div>
            <div>
              <img src={checkCircleLight} alt="check-circle" />
              <span>{PLAN_DETAILS?.thirdBullet}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlanSettingForm;
