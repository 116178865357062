import { gql } from '@apollo/client';

export const UPDATE_PROPERTY_FINISHED_BASEMENT_CEILING = gql`
  mutation updatePropertyFinishedBasementCeiling(
    $where: PropertyFinishedBasementCeilingWhereInput!
    $data: PropertyFinishedBasementCeilingInput!
  ) {
    updatePropertyFinishedBasementCeiling(where: $where, data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const CREATE_PROPERTY_FINISHED_BASEMENT_CEILING = gql`
  mutation createPropertyFinishedBasementCeiling(
    $data: PropertyFinishedBasementCeilingInput!
  ) {
    createPropertyFinishedBasementCeiling(data: $data) {
      data {
        id
        label
        key
        description
        order
        isActive
      }
      status
      message
    }
  }
`;

export const DELETE_PROPERTY_FINISHED_BASEMENT_CEILING = gql`
  mutation deletePropertyFinishedBasementCeiling(
    $where: PropertyFinishedBasementCeilingWhereInput!
  ) {
    deletePropertyFinishedBasementCeiling(where: $where) {
      message
      status
      data {
        id
      }
    }
  }
`;
