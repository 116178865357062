/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  SIGNUP: '/signup',
  RESET: '/forgot-password',
  CHANGE: '/reset',
  VERIFY: '/verify',
  JOBS: '/jobs',
  NOTIFICATION: '/notification',
  PROFILE: '/profile',
  CUSTOMERS: '/customers',
  EMPLOYEES: '/employees',
  IMPORTS: '/imports',
  ONBOARDING: '/onboarding',
  MANUFACTURERS: '/manufacturers',
  PRODUCT_CATEGORIES: '/product-categories',
  LINE_OF_BUSINESSES: '/line-of-businesses',
  SUB_AREAS: '/sub-areas',
  ROLES: '/roles',
  PRODUCT_ITEM_TYPE: '/product-types',
  RETAILERS: '/retailers',
  REGIONS: '/regions',
  FUEL_SOURCES: '/fuel-sources',
  VENT_TYPES: '/vent-types',
  LOCATION_TYPES: '/location-types',
  PRODUCTS: '/products',
  PACKAGES: '/packages',
  PRICING_MODIFIERS: '/pricing-modifiers',
  QUESTIONNAIRES: '/questionnaires',
  POSTAl_CODE: '/postal-code',
  CUSTOMER_GOAL: '/customer-goal',
  CURRENT_PRODUCT: '/current-product',
  NEW_PRODUCT: '/new-product',
  QUOTES: '/quotes',
  SCHEDULED_APPOINTMENT: '/scheduled-appointment',
  CONTACT_ME: '/contact-me',
  UPGRADE: '/upgrade',
  CAPACITIES: '/capacities',
  THANK_YOU: '/thank-you',
  PROPERTIES: '/properties',
  CONTACTS: '/contacts',
  CONTACT_TYPES: '/contact-types',
  TENANT_SETTING: '/tenant-setting',
  BUSINESS_INFORMATION: '/business-information',
  HOURS_OF_OPERATION: '/hours-of-operation',
  MY_ACCOUNT_SETTING: '/my-account',
  PLAN_SETTING: '/plan-setting',
  CUSTOMER_TYPES: '/customer-types',
  HEIGHTS: '/height',
  PREFERRED_CONTACT_METHODS: '/preferred-contact-methods',
  PROPERTY_FINISHED_BASEMENTS: '/property-finished-basements',
  PROPERTY_TYPES: '/property-types',
  PROPERTY_WATER_HOOK_UPS: '/property-water-hookups',
  PROPERTY_HEAT_SOURCE: '/property-heat-sources',
  PROPERTY_FINISHED_BASEMENTS_CEILING: '/property-finished-basements-ceiling',
  QUOTE_VIEWER: '/quote-viewer',
  DASHBOARD: '/dashboard',
  QUESTIONNAIRE_RESULT_VALIDATION: '/questionnaire-result-validation',
  API_SETTING: '/api-setting',
  GALLERY: '/gallery',
  DOCUMENTS: '/documents',
  PERMISSIONS: '/permissions',
  NO_ACCESS: '/no-access',
  PRODUCT_PRICING: '/product-pricing',
  VERIFY_EMAIL: '/verify-email'
};

/*  Modules */
export const MODULES = {
  USERS_MANAGEMENT: 'User Management',
  USERS: 'Users',
  JOBS: 'Jobs',
  NOTIFICATION: 'Notifications',
  PROFILE: 'Profile',
  LOGOUT: 'Logout',
  USER_ACCOUNT: 'User Account',
  CUSTOMERS: 'Customers',
  EMPLOYEES: 'Employees',
  IMPORTS: 'Imports',
  ONBOARDING: 'OnBoarding',
  CATALOG: 'Catalog',
  MANUFACTURERS: 'Manufacturers',
  PRODUCT_CATEGORIES: 'Categories',
  LINE_OF_BUSINESSES: 'Lines of Business',
  SUB_AREAS: 'Sub Areas',
  MASTERS: 'Category Picklist',
  ROLES: 'Roles',
  PRODUCT_ITEM_TYPE: 'Product Types',
  RETAILERS: 'Retailers',
  REGIONS: 'Regions',
  FUEL_SOURCES: 'Fuel Sources',
  VENT_TYPES: 'Vent Types',
  LOCATION_TYPES: 'Location Types',
  PRODUCTS: 'Products',
  PACKAGES: 'Packages',
  PRICING_MODIFIERS: 'Pricing Modifiers',
  MY_QUESTIONNAIRES: 'My Questionnaires',
  QUESTIONNAIRES: 'Questionnaires',
  CAPACITIES: 'Capacities',
  PROPERTIES: 'Properties',
  SALES: 'Sales',
  CONTACTS: 'Contacts',
  CONTACT_TYPES: 'Contact Types',
  SETTING: 'Settings',
  TENANT_SETTING: 'Tenant',
  BUSINESS_INFORMATION: 'Business Information',
  HOURS_OF_OPERATION: 'Hours Of Operation',
  MY_ACCOUNT_SETTING: 'My Account',
  PLAN_SETTING: 'Plans',
  CUSTOMER_TYPES: 'Customer Types',
  HEIGHTS: 'Heights',
  PREFERRED_CONTACT_METHODS: 'Preferred Contact Methods',
  PROPERTY_FINISHED_BASEMENTS: 'Finish Basements',
  PROPERTY_TYPES: 'Property Types',
  PROPERTY_WATER_HOOK_UPS: 'Property Hook Ups',
  PROPERTY_HEAT_SOURCE: 'Property Heat Source',
  PROPERTY_FINISHED_BASEMENTS_CEILING: 'Finish Basements Ceiling',
  QUOTE_VIEWER: 'Quote Viewer',
  QUESTIONNAIRE_RESULT_VALIDATION: 'Questionnaire Results Validation',
  QRV: 'QRV',
  DASHBOARD: 'Dashboard',
  API_SETTING: 'API Setting',
  PERMISSIONS: 'Permissions',
  PRODUCT_PRICING: 'Product Pricing'
};

/* primary color config for js files */

export const PRIMARY_COLOR = '#328B09';

/* signup steps */
export const STEPS = {
  ADMINISTRATOR: 'Administrator',
  BUSINESS_INFORMATION: 'Business Info',
  PLAN: 'Plan',
  SUCCESS: 'Success'
};
/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const SIGNUP_AUTH = 'SIGNUP_AUTH';

/* LocalStorage */
export const PACKAGE_RANKINGS = 'PACKAGE_RANKINGS';
export const QUESTIONNAIRE_RESULT_VALIDATION =
  'QUESTIONNAIRE_RESULT_VALIDATION';
export const DASHBOARD = 'DASHBOARD';
export const PRODUCT_PRICING = 'PRODUCT_PRICING';

/* cpq common string */
export const SAME_DAY_TIME = 'Time to schedule same day service';

export const ROLES = {
  ADMIN: 'Admin',
  USER: 'User',
  SALES: 'Sales'
};

export const ROLE_KEYS = {
  TENANT_ADMIN: 'TENANT_ADMIN',
  SALES_MANAGER: 'SALES_MANAGER',
  DISPATCH_MANAGER: 'DISPATCH_MANAGER',
  BILLING_MANAGER: 'BILLING_MANAGER',
  CSR: 'CSR',
  ISR: 'ISR',
  PARTNER: 'PARTNER',
  CUSTOMER: 'CUSTOMER',
  TECHNICIAN: 'TECHNICIAN'
};

/*  Google Upload Action */

export const READ = 'read';
export const WRITE = 'write';

/* allow file types  */

export const ALLOW_TYPES = ['application/vnd.ms-excel', 'text/csv'];

export const ALLOW_IMPORT_TYPES = ['csv'];

export const ALLOW_FILE_TYPES = [
  'docx',
  'doc',
  'pdf',
  'ppt',
  'pptx',
  'xls',
  'csv',
  'xlsx'
];

export const ALLOW_IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'svg'];

/* Date and time */
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

/* Table Height */
export const TABLE_HEIGHT = 225;
export const TABLE_HEIGHT_SEARCH = 263;

export const REGEX = {
  NAME: /^[a-zA-Z\s():;`~'",._\-[\]]*$/i,
  ADDRESS: /^[a-z0-9 ,.'-]+$/i,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  COLOR: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  LOWER_CASE: /[a-z]/g,
  UPPER_CASE: /[A-Z]/g,
  DIGIT: /[0-9]/g,
  SPECIAL_CHAR: /[`!@#$%^&*]/g,
  ZIPCODE: /^[0-9]{5}(?:-[0-9]{4})?$/,
  SUB_DOMAIN: /^[a-z0-9-]+(?:-[a-z0-9]+)*$/,
  DATE_FORMAT: /^[lL]{0,4}$/,
  HTML: /<[a-z/][\s\S]*>/i
};

export const WHOM_TO_SHOW_SIDE_PANEL = [
  ROUTES?.DASHBOARD,
  `${ROUTES?.QUESTIONNAIRES}/edit`,
  ROUTES?.PRODUCT_PRICING,
  ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION,
  ROUTES?.JOBS
];

export const USER_PERMISSION = [
  'read',
  'write',
  'update',
  'delete',
  'showNotification'
];

export const IMPORT_STATUS = {
  VERIFIED: 'Verified',
  INPROGRESS: 'In Progress',
  FAILED: 'Failed'
};

export const SMALL_TABLET = 1000;
export const TABLET_BREAKPOINT = 1200;
export const BIG_SCREEN = 1600;

export const SKIP_RECORD = 20;
export const CONTACT_NUMBER = '8669288545';
export const EMAIL_ADDRESS = 'contact@drftps.com';

export const CPQ_MAX_LIMIT = 10;

export const CPQ_URL = 'path.pro';

export const importObj = {
  REGION: {
    fileObj: {
      key: 'assets/imports/regions-sample.csv',
      url: `https://storage.googleapis.com/${process?.env?.REACT_APP_BUCKET_NAME}/assets/imports/regions-sample.csv`,
      name: 'regions-sample.csv'
    }
  },
  BRAND: {
    fileObj: {
      key: 'assets/imports/retailers-sample.csv',
      url: `https://storage.googleapis.com/${process?.env?.REACT_APP_BUCKET_NAME}/assets/imports/retailers-sample.csv`,
      name: 'retailers-sample.csv'
    }
  },
  MANUFACTURER: {
    fileObj: {
      key: 'assets/imports/manufacturers-sample.csv',
      url: `https://storage.googleapis.com/${process?.env?.REACT_APP_BUCKET_NAME}/assets/imports/manufacturers-sample.csv`,
      name: 'manufacturers-sample.csv'
    }
  },
  PRODUCT_CATEGORY: {
    fileObj: {
      key: 'assets/imports/product-categories-sample.csv',
      url: `https://storage.googleapis.com/${process?.env?.REACT_APP_BUCKET_NAME}/assets/imports/product-categories-sample.csv`,
      name: 'product-categories-sample.csv'
    }
  },
  PRODUCT_ITEM: {
    fileObj: {
      key: 'assets/imports/products-sample.csv',
      url: `https://storage.googleapis.com/${process?.env?.REACT_APP_BUCKET_NAME}/assets/imports/products-sample.csv`,
      name: 'products-sample.csv'
    }
  },
  PRODUCT: {
    fileObj: {
      key: 'assets/imports/packages-sample.csv',
      url: `https://storage.googleapis.com/${process?.env?.REACT_APP_BUCKET_NAME}/assets/imports/packages-sample.csv`,
      name: 'packages-sample.csv'
    }
  }
};

// Permissions

export const PERMISSION_DASHBOARD = [
  'FET_DASHBOARD_LIST',
  'FET_DASHBOARD_EXPORT'
];

export const PERMISSION_QUESTIONNAIRE = [
  'FET_QUESTIONNAIRE_CREATE',
  'FET_QUESTIONNAIRE_UPDATE',
  'FET_QUESTIONNAIRE_DELETE',
  'FET_QUESTIONNAIRE_VIEW',
  'FET_QUESTIONNAIRE_LIST'
];

export const PERMISSION_QUOTE_VIEW = [
  'FET_QUOTE_VIEW_LIST',
  'FET_QUOTE_VIEW_EXPORT'
];

export const PERMISSION_PRODUCT_ITEM = [
  'FET_PRODUCT_ITEM_CREATE',
  'FET_PRODUCT_ITEM_UPDATE',
  'FET_PRODUCT_ITEM_DELETE',
  'FET_PRODUCT_ITEM_VIEW',
  'FET_PRODUCT_ITEM_LIST',
  'FET_PRODUCT_ITEM_IMPORT',
  'FET_PRODUCT_ITEM_EXPORT'
];

export const PERMISSION_PRODUCT = [
  'FET_PRODUCT_CREATE',
  'FET_PRODUCT_UPDATE',
  'FET_PRODUCT_DELETE',
  'FET_PRODUCT_VIEW',
  'FET_PRODUCT_LIST',
  'FET_PRODUCT_IMPORT',
  'FET_PRODUCT_EXPORT'
];

export const PERMISSION_PRODUCT_PRICING = [
  'FET_PRODUCT_PRICING_UPDATE',
  'FET_PRODUCT_PRICING_LIST',
  'FET_PRODUCT_PRICING_IMPORT',
  'FET_PRODUCT_PRICING_EXPORT'
];

export const PERMISSION_PRODUCT_RANKING = [
  'FET_PRODUCT_RANKING_UPDATE',
  'FET_PRODUCT_RANKING_LIST'
];

export const PERMISSION_PRICING_MODIFIER = [
  'FET_PRICING_MODIFIER_CREATE',
  'FET_PRICING_MODIFIER_UPDATE',
  'FET_PRICING_MODIFIER_DELETE',
  'FET_PRICING_MODIFIER_VIEW',
  'FET_PRICING_MODIFIER_LIST'
];

export const PERMISSION_PRODUCT_CATEGORY = [
  'FET_PRODUCT_CATEGORY_CREATE',
  'FET_PRODUCT_CATEGORY_UPDATE',
  'FET_PRODUCT_CATEGORY_DELETE',
  'FET_PRODUCT_CATEGORY_VIEW',
  'FET_PRODUCT_CATEGORY_LIST',
  'FET_PRODUCT_CATEGORY_IMPORT',
  'FET_PRODUCT_CATEGORY_EXPORT'
];

export const PERMISSION_MANUFACTURER = [
  'FET_MANUFACTURER_CREATE',
  'FET_MANUFACTURER_UPDATE',
  'FET_MANUFACTURER_DELETE',
  'FET_MANUFACTURER_VIEW',
  'FET_MANUFACTURER_LIST',
  'FET_MANUFACTURER_IMPORT',
  'FET_MANUFACTURER_EXPORT'
];

export const PERMISSION_REGION = [
  'FET_REGION_CREATE',
  'FET_REGION_UPDATE',
  'FET_REGION_DELETE',
  'FET_REGION_VIEW',
  'FET_REGION_LIST',
  'FET_REGION_IMPORT',
  'FET_REGION_EXPORT'
];

export const PERMISSION_RETAILER = [
  'FET_BRAND_CREATE',
  'FET_BRAND_UPDATE',
  'FET_BRAND_DELETE',
  'FET_BRAND_VIEW',
  'FET_BRAND_LIST',
  'FET_BRAND_IMPORT',
  'FET_BRAND_EXPORT'
];

export const PERMISSION_USER = [
  'FET_USER_CREATE',
  'FET_USER_UPDATE',
  'FET_USER_DELETE',
  'FET_USER_VIEW',
  'FET_USER_LIST',
  'FET_USER_IMPORT',
  'FET_USER_EXPORT'
];

export const PERMISSION_ROLE = [
  'FET_ROLE_CREATE',
  'FET_ROLE_UPDATE',
  'FET_ROLE_VIEW',
  'FET_ROLE_LIST'
];

export const PERMISSION_USER_PERMISSION = [
  'FET_USER_PERMISSION_UPDATE',
  'FET_USER_PERMISSION_LIST'
];

export const PERMISSION_LOB = [
  'FET_LINE_OF_BUSINESS_CREATE',
  'FET_LINE_OF_BUSINESS_UPDATE',
  'FET_LINE_OF_BUSINESS_VIEW',
  'FET_LINE_OF_BUSINESS_LIST'
];

export const PERMISSION_SUBAREA = [
  'FET_SUBAREA_CREATE',
  'FET_SUBAREA_UPDATE',
  'FET_SUBAREA_VIEW',
  'FET_SUBAREA_LIST'
];

export const PERMISSION_MASTER = [
  'FET_MASTER_CREATE',
  'FET_MASTER_UPDATE',
  'FET_MASTER_DELETE',
  'FET_MASTER_VIEW',
  'FET_MASTER_LIST',
  'FET_MASTER_IMPORT',
  'FET_MASTER_EXPORT'
];

export const PERMISSION_TENANT_SETTING = [
  'FET_TENANT_SETTINGS_UPDATE',
  'FET_TENANT_SETTINGS_VIEW'
];

export const PERMISSION_API_SETTING = [
  'FET_API_SETTINGS_UPDATE',
  'FET_API_SETTINGS_VIEW'
];

export const PERMISSION_HOURS_OF_OPERATION_SETTING = [
  'FET_HOURS_OF_OPERATION_SETTINGS_UPDATE',
  'FET_HOURS_OF_OPERATION_SETTINGS_VIEW'
];

export const PERMISSION_RETAILER_SETTING = [
  'FET_RETAILER_SETTINGS_UPDATE',
  'FET_RETAILER_SETTINGS_VIEW'
];

export const PERMISSION_PLAN_SETTING = ['FET_PLAN_SETTINGS_VIEW'];

export const PERMISSION_MY_ACCOUNT_SETTING = [
  'FET_MY_ACCOUNT_SETTINGS_VIEW',
  'FET_MY_ACCOUNT_SETTINGS_UPDATE'
];

// static plan details
export const PLAN_DETAILS = {
  title: 'Free Trial',
  description:
    'Make more money in less time using configurable price quoting tools',
  firstBullet: 'All-in-one pricing engine',
  secondBullet: 'More sales at less cost improve your margins',
  thirdBullet: 'Powerful real-time result dashboard'
};
