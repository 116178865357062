import { gql } from '@apollo/client';

export const GET_HEIGHTS = gql`
  query heights($filter: HeightFilter!, $where: HeightWhereFilter) {
    heights(filter: $filter, where: $where) {
      count
      data {
        id
        label
        refData
        key
        description
        order
        isActive
        thumbnail {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const GET_HEIGHT = gql`
  query height($id: ID!) {
    height(where: { id: $id }) {
      id
      label
      refData
      key
      description
      order
      isActive
      logo {
        url
        key
        name
        extension
        contentType
      }
    }
  }
`;

export const HEIGHT_FILTER = gql`
  query heightFilters($filter: HeightFilter!) {
    heightFilters(filter: $filter) {
      count
      data {
        label
        refData
        key
        description
        order
        isActive
      }
    }
  }
`;
