import { gql } from '@apollo/client';

export const GET_PROPERTY_WATER_HOOK_UPS = gql`
  query propertyWaterHookUps(
    $filter: PropertyWaterHookUpFilter!
    $where: PropertyWaterHookUpWhereFilter
  ) {
    propertyWaterHookUps(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
      }
    }
  }
`;

export const GET_PROPERTY_WATER_HOOK_UP = gql`
  query propertyWaterHookUp($id: ID!) {
    propertyWaterHookUp(where: { id: $id }) {
      id
      label
      key
      description
      order
      isActive
    }
  }
`;

export const PROPERTY_WATER_HOOK_UP_FILTER = gql`
  query propertyWaterHookUpFilters($filter: PropertyWaterHookUpFilter!) {
    propertyWaterHookUpFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        order
        isActive
      }
    }
  }
`;
